import { useQuery, type QueryHookOptions } from '@apollo/client';
import { useLanguageStoreActions } from '@shared/frontend/hooks/use-language-setting';
import LOGGED_IN_USER, { type LoggedInUserResponse } from '@shared/graphql/queries/logged-in-user';
import { getBrowserLanguage } from '@shared/utils/browser-language';
import { useEffect } from 'react';

import { useUpdatePersonPreferredLocale } from './use-update-person-preferred-locale';

export const useQueryLoggedInUser = (options?: QueryHookOptions) => {
  const { setShowTranslationLanguagePrompt } = useLanguageStoreActions();
  const { loading, data, error } = useQuery<LoggedInUserResponse>(LOGGED_IN_USER, options);
  const [updatePersonPreferredLocale] = useUpdatePersonPreferredLocale();

  useEffect(() => {
    const userDoesNotHavePreferredLocale =
      !loading && !!data?.loggedInUser?.user && !data?.loggedInUser?.user?.preferredLocale;
    const autoTranslateConversationsEnabled =
      !!data?.loggedInUser?.autoTranslateConversationsEnabled;

    if (userDoesNotHavePreferredLocale && autoTranslateConversationsEnabled) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updatePersonPreferredLocale({ variables: { preferredLocale: getBrowserLanguage() } });

      setShowTranslationLanguagePrompt(true);
    }
  }, [loading, updatePersonPreferredLocale]);

  return { loading, data, error };
};
