import { useNavigate } from '@tanstack/react-router';
import React from 'react';

import { styled, theme } from '../theme';
import { H2 } from './base/typography';
import {
  ChatAction,
  ClickableText,
  EmailAction,
  ExternalLink,
  InformationCard,
} from './information-card';

type Variant =
  | 'error'
  | 'authentication'
  | 'tooManyRequests'
  | 'notFound'
  | 'maintenance'
  | 'welcome'
  | 'noResults'
  | 'noSelectedConversationTeamMember'
  | 'noSelectedConversationOrgUi'
  | 'screenResolutionWarningCalendar'
  | 'screenResolutionWarningBoard'
  | 'screenResolutionWarningGallery'
  | 'emptyGallery'
  | 'noResultsGallery'
  | 'noSelectedCampaignDashboard'
  | 'engagementHeatmapNoData';

const ScreenResolutionWarningCalendar = () => (
  <InformationCard
    icon="ScreenshotMonitor"
    $colorScheme="kereruPurple"
    title="Use a laptop or desktop to see the calendar."
  >
    <H2>The calendar is currently only supported by screens with a minimum width of 900px.</H2>
  </InformationCard>
);

const ScreenResolutionWarningBoard = () => (
  <InformationCard
    icon="ScreenshotMonitor"
    $colorScheme="kereruPurple"
    title="Use a laptop or desktop to see the board."
  >
    <H2>The board is currently only supported by screens with a minimum width of 900px.</H2>
  </InformationCard>
);

const ScreenResolutionWarningGallery = () => (
  <InformationCard
    icon="ScreenshotMonitor"
    $colorScheme="kereruPurple"
    title="Use a laptop or desktop to see the gallery."
  >
    <H2>The gallery is currently only supported by screens with a minimum width of 900px.</H2>
  </InformationCard>
);

const NoSelectedConversationTeamMember = () => (
  <InformationCard
    icon="LocalLibrary"
    $colorScheme="ducksBlue"
    title="Select a conversation from the chat list to view"
  >
    <H2>
      Have something to say but not sure how to say it? Check out the top tips for giving
      constructive feedback on our{' '}
      <a href="https://help.joyoushq.com/how-do-i-give-constructive-feedback" target="__blank">
        Help Center
      </a>
      .
    </H2>
  </InformationCard>
);

const NoSelectedConversationOrgUi = () => (
  <InformationCard
    icon="LocalLibrary"
    $colorScheme="ducksBlue"
    title="Select a conversation from the chat list to view"
  >
    <H2>
      Have something to say but not sure how to say it? Check out the top tips for giving
      constructive feedback on our{' '}
      <a href="https://help.joyoushq.com/tips-for-responding-to-feedback" target="__blank">
        Help Center
      </a>
      .
    </H2>
  </InformationCard>
);

const NoResults = () => (
  <InformationCard
    icon="Air"
    $colorScheme="kereruPurple"
    title="There's nothing to see here right now."
  >
    <H2>
      It’s possible that the combination of filters you have applied does not match any
      conversations. Try adjusting your filters.
    </H2>
    <H2>
      If that doesn’t work, please contact us via <ChatAction $colorScheme="kereruPurple" /> or{' '}
      <EmailAction $colorScheme="kereruPurple" /> so we can help you.
    </H2>
  </InformationCard>
);

const EngagementHeatmapNoData = () => (
  <InformationCard
    icon="Air"
    $colorScheme="kereruPurple"
    title="There’s nothing to see here right now."
  >
    <H2>Insights will show after the campaign has launched</H2>
  </InformationCard>
);

const NoSelectedCampaignDashboard = () => (
  <InformationCard
    icon="Air"
    $colorScheme="kereruPurple"
    title="Select a campaign or apply a campaign filter to view the dashboard
"
  >
    <H2>
      Show the campaign timeline to select a campaign or apply a campaign filter to view the
      dashboard.
    </H2>
    <H2>
      If that doesn’t work, please contact us via <ChatAction $colorScheme="kereruPurple" /> or{' '}
      <EmailAction $colorScheme="kereruPurple" /> so we can help you.
    </H2>
  </InformationCard>
);

const WelcomeToJoyous = () => {
  return (
    <>
      <InformationCard icon="Web" $colorScheme="kereruPurple" title="Welcome to Joyous!">
        <H2>
          We’re so pleased you are joining this exciting journey with Joyous. <br /> <br />
          Check out{' '}
          <ExternalLink $colorScheme="kereruPurple" href="https://joyoushq.com">
            our website
          </ExternalLink>{' '}
          to learn more about Joyous and how we’ve helped other organisations.
        </H2>
      </InformationCard>

      <InformationCard
        icon="LocalLibrary"
        $colorScheme="ducksBlue"
        title="Get some tips on responding to feedback!"
      >
        <H2>
          Hearing back from a real person is part of the magic of Joyous. It makes employees feel
          heard and also gives us better information to use for our analysis. <br /> <br />
          Here are some awesome tips on our{' '}
          <ExternalLink
            $colorScheme="ducksBlue"
            href="https://help.joyoushq.com/facilitator-resources"
          >
            Help Center
          </ExternalLink>{' '}
          on how to respond.
        </H2>
      </InformationCard>
    </>
  );
};

const AuthenticationError = () => (
  <InformationCard icon="Token" $colorScheme="kereruPurple" title="Your Joyous token has expired!">
    <H2>
      Renewing your token is easy, simply follow{' '}
      <ExternalLink
        $colorScheme="kereruPurple"
        href="https://help.joyoushq.com/why-did-my-token-expire"
      >
        these instructions
      </ExternalLink>
      .
      <br /> <br /> If that doesn’t work, please contact us via{' '}
      <ChatAction $colorScheme="kereruPurple" /> or <EmailAction $colorScheme="kereruPurple" /> so
      we can help you.
    </H2>
  </InformationCard>
);

const TooManyRequestsError = () => {
  const navigate = useNavigate();
  const goBack = () => navigate({ to: '/' });

  return (
    <InformationCard
      icon="SentimentDissatisfied"
      $colorScheme="kereruPurple"
      title="We're experiencing unusually high traffic right now."
    >
      <H2>
        Please try{' '}
        <ClickableText $colorScheme="kereruPurple" onClick={goBack}>
          reloading
        </ClickableText>{' '}
        the page.
      </H2>
    </InformationCard>
  );
};

const NotFoundError = () => (
  <InformationCard icon="SentimentDissatisfied" $colorScheme="kereruPurple" title="Page not found">
    <H2>
      Sorry, we couldn’t find what you are looking for. <br /> <br />
      You can refresh the page or try our{' '}
      <a href="https://help.joyoushq.com/" target="__blank">
        Help Center.
      </a>{' '}
    </H2>
  </InformationCard>
);

const MaintenanceError = () => (
  <InformationCard
    icon="Handyman"
    $colorScheme="kereruPurple"
    title="We're doing some planned maintenance."
  >
    <H2>
      Our team are currently performing scheduled maintenance. Please check back later. <br />
      <br />
      Please contact us via <ChatAction $colorScheme="kereruPurple" /> or{' '}
      <EmailAction $colorScheme="kereruPurple" /> if you have any questions.
    </H2>
  </InformationCard>
);

const DefaultError = () => (
  <InformationCard
    icon="SentimentDissatisfied"
    $colorScheme="kereruPurple"
    title="Woops! This was unexpected."
  >
    <H2>
      It looks like we’re experiencing an issue at the moment and are unable to complete your
      request. <br /> <br />
      Please try reloading the page, or contact us via <ChatAction $colorScheme="kereruPurple" /> or{' '}
      <EmailAction $colorScheme="kereruPurple" />.
    </H2>
  </InformationCard>
);

const EmptyGalleryMessage = ({ createBlankCampaign }: { createBlankCampaign?: () => void }) => (
  <InformationCard
    icon="Air"
    $colorScheme="kereruPurple"
    title="There's nothing to see here right now."
  >
    <H2>A library of campaigns will be available soon!</H2>
    {!!createBlankCampaign && (
      <H2>
        In the meantime, click{' '}
        <ClickableText $colorScheme="ducksBlue" onClick={createBlankCampaign}>
          here
        </ClickableText>{' '}
        to create a custom campaign.
      </H2>
    )}
  </InformationCard>
);

const NoResultsGalleryMessage = ({ createBlankCampaign }: { createBlankCampaign?: () => void }) => (
  <InformationCard icon="Air" $colorScheme="kereruPurple" title="No templates match your search.">
    {createBlankCampaign ? (
      <H2>
        Try a different keyword or click{' '}
        <ClickableText $colorScheme="ducksBlue" onClick={createBlankCampaign}>
          here
        </ClickableText>{' '}
        to create a custom campaign.
      </H2>
    ) : (
      <H2>Try a different keyword.</H2>
    )}
  </InformationCard>
);

type Props = {
  variant: Variant;
  createBlankCampaign?: () => void;
};

const RenderPage = ({ variant, createBlankCampaign }: Props) => {
  switch (variant) {
    case 'authentication':
      return <AuthenticationError />;
    case 'tooManyRequests':
      return <TooManyRequestsError />;
    case 'maintenance':
      return <MaintenanceError />;
    case 'notFound':
      return <NotFoundError />;
    case 'welcome':
      return <WelcomeToJoyous />;
    case 'noResults':
      return <NoResults />;
    case 'engagementHeatmapNoData':
      return <EngagementHeatmapNoData />;
    case 'noSelectedConversationTeamMember':
      return <NoSelectedConversationTeamMember />;
    case 'noSelectedConversationOrgUi':
      return <NoSelectedConversationOrgUi />;
    case 'screenResolutionWarningCalendar':
      return <ScreenResolutionWarningCalendar />;
    case 'screenResolutionWarningBoard':
      return <ScreenResolutionWarningBoard />;
    case 'screenResolutionWarningGallery':
      return <ScreenResolutionWarningGallery />;
    case 'emptyGallery':
      return <EmptyGalleryMessage createBlankCampaign={createBlankCampaign} />;
    case 'noResultsGallery':
      return <NoResultsGalleryMessage createBlankCampaign={createBlankCampaign} />;
    case 'noSelectedCampaignDashboard':
      return <NoSelectedCampaignDashboard />;
    case 'error':
    default:
      return <DefaultError />;
  }
};

const whiteBackgroundVariants: Variant[] = [
  'screenResolutionWarningGallery',
  'emptyGallery',
  'noResultsGallery',
  'engagementHeatmapNoData',
];

export const StatusMessage = styled(({ variant, createBlankCampaign, ...restOfProps }: Props) => (
  <div {...restOfProps}>
    <RenderPage variant={variant} createBlankCampaign={createBlankCampaign} />
  </div>
))`
  width: 100%;
  height: 100%;
  padding: ${theme.spacing.l};

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ variant }) =>
    whiteBackgroundVariants.includes(variant) ? theme.color.white : theme.background};
  flex-direction: column;
  gap: ${theme.spacing.xl};
`;
