import React, { DependencyList, useLayoutEffect, useRef } from 'react';

// https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433
const isElementInView = (element: HTMLDivElement) => {
  const rect = element.getBoundingClientRect();
  const parent = element.parentElement?.getBoundingClientRect();

  if (parent) {
    const elementWithinParent =
      rect.top >= 0 && rect.left >= 0 && rect.bottom <= parent.bottom && rect.right <= parent.right;
    const parentWithinWindowViewport =
      parent &&
      parent.top >= 0 &&
      parent.left >= 0 &&
      parent.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
      parent.right <=
        (window.innerWidth || document.documentElement.clientWidth); /* or $(window).width() */

    return elementWithinParent && parentWithinWindowViewport;
  }

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
};

export const ScrollIntoView = ({ deps }: { deps?: DependencyList }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  // we useLayoutEffect instead of useEffect here so it doesn't render initial scroll position then flash to scrolled down
  // instead it renders the scrollbar at the bottom from the beginning
  useLayoutEffect(() => {
    // only scroll into view if not already in view
    if (ref?.current && !isElementInView(ref.current)) {
      ref?.current?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? []);

  return <div ref={ref} />;
};
