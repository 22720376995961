import { JoyousError } from '@shared/types';
import { isProviderGCP } from '@shared/utils/cloud-provider';

// This was generated with AI by merging all the LOCATION_TO_{PROVIDER}_REGION mappings from Atlas as the base.
export const COUNTRY_CODES_TO_NAMES = {
  AD: 'Andorra',
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AO: 'Angola',
  AQ: 'Antarctica',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Åland Islands',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'Saint Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei Darussalam',
  BO: 'Bolivia',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos (Keeling) Islands',
  CD: 'Democratic Republic of the Congo',
  CF: 'Central African Republic',
  CG: 'Republic of the Congo',
  CH: 'Switzerland',
  CI: "Côte d'Ivoire",
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cape Verde',
  CW: 'Curaçao',
  CX: 'Christmas Island',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands (Malvinas)',
  FM: 'Micronesia, Federated States of',
  FO: 'Faroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GS: 'South Georgia and the South Sandwich Islands',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'Heard Island and McDonald Islands',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'India',
  IO: 'British Indian Ocean Territory',
  IQ: 'Iraq',
  IR: 'Iran',
  IS: 'Iceland',
  IT: 'Italy',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts and Nevis',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: 'Laos',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MF: 'Saint Martin (French part)',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'North Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macau',
  MP: 'Northern Mariana Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PM: 'Saint Pierre and Miquelon',
  PN: 'Pitcairn',
  PR: 'Puerto Rico',
  PS: 'Palestine, State of',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russia',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  SI: 'Slovenia',
  SJ: 'Svalbard and Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'South Sudan',
  ST: 'Sao Tome and Principe',
  SV: 'El Salvador',
  SX: 'Sint Maarten (Dutch part)',
  SY: 'Syria',
  SZ: 'Eswatini',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'Timor-Leste',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkey',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ukraine',
  UG: 'Uganda',
  UM: 'United States Minor Outlying Islands',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatican City',
  VC: 'Saint Vincent and the Grenadines',
  VE: 'Venezuela',
  VG: 'British Virgin Islands',
  VI: 'U.S. Virgin Islands',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis and Futuna',
  WS: 'Samoa',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
} as const;

// Use carefully as ALL_LOCATIONS shouldn't usually be used as a selector except for some cases (e.g. auth).
export const ALL_LOCATIONS = Object.keys(COUNTRY_CODES_TO_NAMES);

export type ValidLocation = keyof typeof COUNTRY_CODES_TO_NAMES;
export const isValidLocation = (location: string): location is ValidLocation =>
  ALL_LOCATIONS.includes(location);

export function assertValidLocation(location: string): asserts location is ValidLocation {
  if (!isValidLocation(location)) {
    throw new Error('Invalid location');
  }
}

export type ValidGCPRegion = 'us-west-2';
const isValidGCPRegion = (region: string): region is ValidGCPRegion =>
  ['us-west-2'].includes(region);

export type ValidAWSRegion = 'us-west-2' | 'ap-southeast-2' | 'eu-central-1' | 'ca-central-1';
const isValidAWSRegion = (region: string): region is ValidAWSRegion =>
  ['us-west-2', 'ap-southeast-2', 'eu-central-1', 'ca-central-1'].includes(region);

/*
  This map will need to be regenerated based off Atlas sharding if we add another region shard
  Country code or subdivision code maps to location e.g. US, AU-NSW
  The values here need to match with the values available in process.env.AWS_REGION
*/
const LOCATION_TO_AWS_REGION: Record<ValidLocation, ValidAWSRegion> = {
  AD: 'eu-central-1',
  AE: 'eu-central-1',
  AF: 'eu-central-1',
  AG: 'ca-central-1',
  AI: 'ca-central-1',
  AL: 'eu-central-1',
  AM: 'eu-central-1',
  AO: 'eu-central-1',
  AQ: 'ap-southeast-2',
  AR: 'us-west-2',
  AS: 'ap-southeast-2',
  AT: 'eu-central-1',
  AU: 'ap-southeast-2',
  AW: 'ca-central-1',
  AX: 'eu-central-1',
  AZ: 'eu-central-1',
  BA: 'eu-central-1',
  BB: 'ca-central-1',
  BD: 'eu-central-1',
  BE: 'eu-central-1',
  BF: 'eu-central-1',
  BG: 'eu-central-1',
  BH: 'eu-central-1',
  BI: 'eu-central-1',
  BJ: 'eu-central-1',
  BL: 'ca-central-1',
  BM: 'ca-central-1',
  BN: 'ap-southeast-2',
  BO: 'us-west-2',
  BQ: 'ca-central-1',
  BR: 'ca-central-1',
  BS: 'ca-central-1',
  BT: 'eu-central-1',
  BV: 'ap-southeast-2',
  BW: 'eu-central-1',
  BY: 'eu-central-1',
  BZ: 'us-west-2',
  CA: 'ca-central-1',
  CC: 'ap-southeast-2',
  CD: 'eu-central-1',
  CF: 'eu-central-1',
  CG: 'eu-central-1',
  CH: 'eu-central-1',
  CI: 'eu-central-1',
  CK: 'ap-southeast-2',
  CL: 'us-west-2',
  CM: 'eu-central-1',
  CN: 'eu-central-1',
  CO: 'us-west-2',
  CR: 'us-west-2',
  CU: 'ca-central-1',
  CV: 'eu-central-1',
  CW: 'ca-central-1',
  CX: 'ap-southeast-2',
  CY: 'eu-central-1',
  CZ: 'eu-central-1',
  DE: 'eu-central-1',
  DJ: 'eu-central-1',
  DK: 'eu-central-1',
  DM: 'ca-central-1',
  DO: 'ca-central-1',
  DZ: 'eu-central-1',
  EC: 'us-west-2',
  EE: 'eu-central-1',
  EG: 'eu-central-1',
  EH: 'eu-central-1',
  ER: 'eu-central-1',
  ES: 'eu-central-1',
  ET: 'eu-central-1',
  FI: 'eu-central-1',
  FJ: 'ap-southeast-2',
  FK: 'ap-southeast-2',
  FM: 'ap-southeast-2',
  FO: 'eu-central-1',
  FR: 'eu-central-1',
  GA: 'eu-central-1',
  GB: 'eu-central-1',
  GD: 'ca-central-1',
  GE: 'eu-central-1',
  GF: 'ca-central-1',
  GG: 'eu-central-1',
  GH: 'eu-central-1',
  GI: 'eu-central-1',
  GL: 'eu-central-1',
  GM: 'eu-central-1',
  GN: 'eu-central-1',
  GP: 'ca-central-1',
  GQ: 'eu-central-1',
  GR: 'eu-central-1',
  GS: 'ap-southeast-2',
  GT: 'us-west-2',
  GU: 'ap-southeast-2',
  GW: 'eu-central-1',
  GY: 'ca-central-1',
  HK: 'ap-southeast-2',
  HM: 'ap-southeast-2',
  HN: 'us-west-2',
  HR: 'eu-central-1',
  HT: 'ca-central-1',
  HU: 'eu-central-1',
  ID: 'ap-southeast-2',
  IE: 'eu-central-1',
  IL: 'eu-central-1',
  IM: 'eu-central-1',
  IN: 'eu-central-1',
  IO: 'ap-southeast-2',
  IQ: 'eu-central-1',
  IR: 'eu-central-1',
  IS: 'eu-central-1',
  IT: 'eu-central-1',
  JE: 'eu-central-1',
  JM: 'ca-central-1',
  JO: 'eu-central-1',
  JP: 'ap-southeast-2',
  KE: 'eu-central-1',
  KG: 'eu-central-1',
  KH: 'ap-southeast-2',
  KI: 'ap-southeast-2',
  KM: 'eu-central-1',
  KN: 'ca-central-1',
  KP: 'ca-central-1',
  KR: 'ap-southeast-2',
  KW: 'eu-central-1',
  KY: 'ca-central-1',
  KZ: 'eu-central-1',
  LA: 'ap-southeast-2',
  LB: 'eu-central-1',
  LC: 'ca-central-1',
  LI: 'eu-central-1',
  LK: 'eu-central-1',
  LR: 'eu-central-1',
  LS: 'eu-central-1',
  LT: 'eu-central-1',
  LU: 'eu-central-1',
  LV: 'eu-central-1',
  LY: 'eu-central-1',
  MA: 'eu-central-1',
  MC: 'eu-central-1',
  MD: 'eu-central-1',
  ME: 'eu-central-1',
  MF: 'ca-central-1',
  MG: 'eu-central-1',
  MH: 'ap-southeast-2',
  MK: 'eu-central-1',
  ML: 'eu-central-1',
  MM: 'ap-southeast-2',
  MN: 'eu-central-1',
  MO: 'ap-southeast-2',
  MP: 'ap-southeast-2',
  MQ: 'ca-central-1',
  MR: 'eu-central-1',
  MS: 'ca-central-1',
  MT: 'eu-central-1',
  MU: 'ap-southeast-2',
  MV: 'eu-central-1',
  MW: 'eu-central-1',
  MX: 'us-west-2',
  MY: 'ap-southeast-2',
  MZ: 'eu-central-1',
  NA: 'eu-central-1',
  NC: 'ap-southeast-2',
  NE: 'eu-central-1',
  NF: 'ap-southeast-2',
  NG: 'eu-central-1',
  NI: 'us-west-2',
  NL: 'eu-central-1',
  NO: 'eu-central-1',
  NP: 'eu-central-1',
  NR: 'ap-southeast-2',
  NU: 'ap-southeast-2',
  NZ: 'ap-southeast-2',
  OM: 'eu-central-1',
  PA: 'us-west-2',
  PE: 'us-west-2',
  PF: 'ap-southeast-2',
  PG: 'ap-southeast-2',
  PH: 'ap-southeast-2',
  PK: 'eu-central-1',
  PL: 'eu-central-1',
  PM: 'ca-central-1',
  PN: 'ap-southeast-2',
  PR: 'ca-central-1',
  PS: 'eu-central-1',
  PT: 'eu-central-1',
  PW: 'ap-southeast-2',
  PY: 'us-west-2',
  QA: 'eu-central-1',
  RE: 'eu-central-1',
  RO: 'eu-central-1',
  RS: 'eu-central-1',
  RU: 'eu-central-1',
  RW: 'eu-central-1',
  SA: 'eu-central-1',
  SB: 'ap-southeast-2',
  SC: 'eu-central-1',
  SD: 'eu-central-1',
  SE: 'eu-central-1',
  SG: 'ap-southeast-2',
  SH: 'eu-central-1',
  SI: 'eu-central-1',
  SJ: 'eu-central-1',
  SK: 'eu-central-1',
  SL: 'eu-central-1',
  SM: 'eu-central-1',
  SN: 'eu-central-1',
  SO: 'eu-central-1',
  SR: 'ca-central-1',
  SS: 'eu-central-1',
  ST: 'eu-central-1',
  SV: 'us-west-2',
  SX: 'ca-central-1',
  SY: 'eu-central-1',
  SZ: 'eu-central-1',
  TC: 'ca-central-1',
  TD: 'eu-central-1',
  TF: 'ap-southeast-2',
  TG: 'eu-central-1',
  TH: 'ap-southeast-2',
  TJ: 'eu-central-1',
  TK: 'ap-southeast-2',
  TL: 'ap-southeast-2',
  TM: 'eu-central-1',
  TN: 'eu-central-1',
  TO: 'ap-southeast-2',
  TR: 'eu-central-1',
  TT: 'ca-central-1',
  TV: 'ap-southeast-2',
  TW: 'ap-southeast-2',
  TZ: 'eu-central-1',
  UA: 'eu-central-1',
  UG: 'eu-central-1',
  UM: 'us-west-2',
  US: 'us-west-2',
  UY: 'us-west-2',
  UZ: 'eu-central-1',
  VA: 'eu-central-1',
  VC: 'ca-central-1',
  VE: 'ca-central-1',
  VG: 'ca-central-1',
  VI: 'ca-central-1',
  VN: 'ap-southeast-2',
  VU: 'ap-southeast-2',
  WF: 'ap-southeast-2',
  WS: 'ap-southeast-2',
  YE: 'eu-central-1',
  YT: 'eu-central-1',
  ZA: 'eu-central-1',
  ZM: 'eu-central-1',
  ZW: 'eu-central-1',
};

/*
  This map will need to be regenerated based off Atlas sharding if we add another region shard
  Country code or subdivision code maps to location e.g. US, AU-NSW
  The values here need to match with the values available in process.env.AWS_REGION
*/
const LOCATION_TO_GCP_REGION: Record<ValidLocation, ValidGCPRegion> = {
  AD: 'us-west-2',
  AE: 'us-west-2',
  AF: 'us-west-2',
  AG: 'us-west-2',
  AI: 'us-west-2',
  AL: 'us-west-2',
  AM: 'us-west-2',
  AO: 'us-west-2',
  AQ: 'us-west-2',
  AR: 'us-west-2',
  AS: 'us-west-2',
  AT: 'us-west-2',
  AU: 'us-west-2',
  AW: 'us-west-2',
  AX: 'us-west-2',
  AZ: 'us-west-2',
  BA: 'us-west-2',
  BB: 'us-west-2',
  BD: 'us-west-2',
  BE: 'us-west-2',
  BF: 'us-west-2',
  BG: 'us-west-2',
  BH: 'us-west-2',
  BI: 'us-west-2',
  BJ: 'us-west-2',
  BL: 'us-west-2',
  BM: 'us-west-2',
  BN: 'us-west-2',
  BO: 'us-west-2',
  BQ: 'us-west-2',
  BR: 'us-west-2',
  BS: 'us-west-2',
  BT: 'us-west-2',
  BV: 'us-west-2',
  BW: 'us-west-2',
  BY: 'us-west-2',
  BZ: 'us-west-2',
  CA: 'us-west-2',
  CC: 'us-west-2',
  CD: 'us-west-2',
  CF: 'us-west-2',
  CG: 'us-west-2',
  CH: 'us-west-2',
  CI: 'us-west-2',
  CK: 'us-west-2',
  CL: 'us-west-2',
  CM: 'us-west-2',
  CN: 'us-west-2',
  CO: 'us-west-2',
  CR: 'us-west-2',
  CU: 'us-west-2',
  CV: 'us-west-2',
  CW: 'us-west-2',
  CX: 'us-west-2',
  CY: 'us-west-2',
  CZ: 'us-west-2',
  DE: 'us-west-2',
  DJ: 'us-west-2',
  DK: 'us-west-2',
  DM: 'us-west-2',
  DO: 'us-west-2',
  DZ: 'us-west-2',
  EC: 'us-west-2',
  EE: 'us-west-2',
  EG: 'us-west-2',
  EH: 'us-west-2',
  ER: 'us-west-2',
  ES: 'us-west-2',
  ET: 'us-west-2',
  FI: 'us-west-2',
  FJ: 'us-west-2',
  FK: 'us-west-2',
  FM: 'us-west-2',
  FO: 'us-west-2',
  FR: 'us-west-2',
  GA: 'us-west-2',
  GB: 'us-west-2',
  GD: 'us-west-2',
  GE: 'us-west-2',
  GF: 'us-west-2',
  GG: 'us-west-2',
  GH: 'us-west-2',
  GI: 'us-west-2',
  GL: 'us-west-2',
  GM: 'us-west-2',
  GN: 'us-west-2',
  GP: 'us-west-2',
  GQ: 'us-west-2',
  GR: 'us-west-2',
  GS: 'us-west-2',
  GT: 'us-west-2',
  GU: 'us-west-2',
  GW: 'us-west-2',
  GY: 'us-west-2',
  HK: 'us-west-2',
  HM: 'us-west-2',
  HN: 'us-west-2',
  HR: 'us-west-2',
  HT: 'us-west-2',
  HU: 'us-west-2',
  ID: 'us-west-2',
  IE: 'us-west-2',
  IL: 'us-west-2',
  IM: 'us-west-2',
  IN: 'us-west-2',
  IO: 'us-west-2',
  IQ: 'us-west-2',
  IR: 'us-west-2',
  IS: 'us-west-2',
  IT: 'us-west-2',
  JE: 'us-west-2',
  JM: 'us-west-2',
  JO: 'us-west-2',
  JP: 'us-west-2',
  KE: 'us-west-2',
  KG: 'us-west-2',
  KH: 'us-west-2',
  KI: 'us-west-2',
  KM: 'us-west-2',
  KN: 'us-west-2',
  KP: 'us-west-2',
  KR: 'us-west-2',
  KW: 'us-west-2',
  KY: 'us-west-2',
  KZ: 'us-west-2',
  LA: 'us-west-2',
  LB: 'us-west-2',
  LC: 'us-west-2',
  LI: 'us-west-2',
  LK: 'us-west-2',
  LR: 'us-west-2',
  LS: 'us-west-2',
  LT: 'us-west-2',
  LU: 'us-west-2',
  LV: 'us-west-2',
  LY: 'us-west-2',
  MA: 'us-west-2',
  MC: 'us-west-2',
  MD: 'us-west-2',
  ME: 'us-west-2',
  MF: 'us-west-2',
  MG: 'us-west-2',
  MH: 'us-west-2',
  MK: 'us-west-2',
  ML: 'us-west-2',
  MM: 'us-west-2',
  MN: 'us-west-2',
  MO: 'us-west-2',
  MP: 'us-west-2',
  MQ: 'us-west-2',
  MR: 'us-west-2',
  MS: 'us-west-2',
  MT: 'us-west-2',
  MU: 'us-west-2',
  MV: 'us-west-2',
  MW: 'us-west-2',
  MX: 'us-west-2',
  MY: 'us-west-2',
  MZ: 'us-west-2',
  NA: 'us-west-2',
  NC: 'us-west-2',
  NE: 'us-west-2',
  NF: 'us-west-2',
  NG: 'us-west-2',
  NI: 'us-west-2',
  NL: 'us-west-2',
  NO: 'us-west-2',
  NP: 'us-west-2',
  NR: 'us-west-2',
  NU: 'us-west-2',
  NZ: 'us-west-2',
  OM: 'us-west-2',
  PA: 'us-west-2',
  PE: 'us-west-2',
  PF: 'us-west-2',
  PG: 'us-west-2',
  PH: 'us-west-2',
  PK: 'us-west-2',
  PL: 'us-west-2',
  PM: 'us-west-2',
  PN: 'us-west-2',
  PR: 'us-west-2',
  PS: 'us-west-2',
  PT: 'us-west-2',
  PW: 'us-west-2',
  PY: 'us-west-2',
  QA: 'us-west-2',
  RE: 'us-west-2',
  RO: 'us-west-2',
  RS: 'us-west-2',
  RU: 'us-west-2',
  RW: 'us-west-2',
  SA: 'us-west-2',
  SB: 'us-west-2',
  SC: 'us-west-2',
  SD: 'us-west-2',
  SE: 'us-west-2',
  SG: 'us-west-2',
  SH: 'us-west-2',
  SI: 'us-west-2',
  SJ: 'us-west-2',
  SK: 'us-west-2',
  SL: 'us-west-2',
  SM: 'us-west-2',
  SN: 'us-west-2',
  SO: 'us-west-2',
  SR: 'us-west-2',
  SS: 'us-west-2',
  ST: 'us-west-2',
  SV: 'us-west-2',
  SX: 'us-west-2',
  SY: 'us-west-2',
  SZ: 'us-west-2',
  TC: 'us-west-2',
  TD: 'us-west-2',
  TF: 'us-west-2',
  TG: 'us-west-2',
  TH: 'us-west-2',
  TJ: 'us-west-2',
  TK: 'us-west-2',
  TL: 'us-west-2',
  TM: 'us-west-2',
  TN: 'us-west-2',
  TO: 'us-west-2',
  TR: 'us-west-2',
  TT: 'us-west-2',
  TV: 'us-west-2',
  TW: 'us-west-2',
  TZ: 'us-west-2',
  UA: 'us-west-2',
  UG: 'us-west-2',
  UM: 'us-west-2',
  US: 'us-west-2',
  UY: 'us-west-2',
  UZ: 'us-west-2',
  VA: 'us-west-2',
  VC: 'us-west-2',
  VE: 'us-west-2',
  VG: 'us-west-2',
  VI: 'us-west-2',
  VN: 'us-west-2',
  VU: 'us-west-2',
  WF: 'us-west-2',
  WS: 'us-west-2',
  YE: 'us-west-2',
  YT: 'us-west-2',
  ZA: 'us-west-2',
  ZM: 'us-west-2',
  ZW: 'us-west-2',
};

// Multiple user locations map to the same region
const groupByRegion = <T extends string>(locationToRegion: Record<ValidLocation, T>) =>
  Object.entries(locationToRegion).reduce<Record<T, ValidLocation[]>>(
    (memo, [countryCode, region]) => {
      const typedCountryCode = countryCode as ValidLocation;
      if (memo[region]?.length) {
        memo[region].push(typedCountryCode);
      } else {
        memo[region] = [typedCountryCode];
      }
      return memo;
    },
    {} as Record<T, ValidLocation[]>,
  );

const AWS_REGION_TO_LOCATIONS = groupByRegion(LOCATION_TO_AWS_REGION);
const GCP_REGION_TO_LOCATIONS = groupByRegion(LOCATION_TO_GCP_REGION);

export const getRegionFromLocation = (
  location: string,
  provider: string,
): ValidAWSRegion | ValidGCPRegion | null => {
  if (!isValidLocation(location)) {
    return null;
  }

  return isProviderGCP(provider)
    ? LOCATION_TO_GCP_REGION[location]
    : LOCATION_TO_AWS_REGION[location];
};

export const getLocationsInRegion = (region: string, provider: string) => {
  if (isProviderGCP(provider) && isValidGCPRegion(region)) {
    return GCP_REGION_TO_LOCATIONS[region];
  }

  if (isValidAWSRegion(region)) {
    return AWS_REGION_TO_LOCATIONS[region];
  }

  return [];
};

enum Crew {
  US = 'us_crew',
  ANZ = 'anz_crew',
  EU = 'eu_crew',
  CA = 'ca_crew',
}

export const getAdminCrewFromLocation = (location: ValidLocation, provider: string) => {
  const region = getRegionFromLocation(location, provider);

  switch (region) {
    case 'ap-southeast-2':
      return Crew.ANZ;

    case 'us-west-2':
      return Crew.US;

    case 'eu-central-1':
      return Crew.EU;

    case 'ca-central-1':
      return Crew.CA;

    default:
      throw new Error(`Invalid region from location: ${location}`);
  }
};

export const REGION_NUMBER_DESIGNATION: Record<string, number> = {
  'ap-southeast-2': 1, // Sydney
  'us-east-1': 2, // N. Virginia
  'us-east-2': 3, // Ohio
  'us-west-1': 4, // N. California
  'us-west-2': 5, // Oregon
  'eu-central-1': 6, // Frankfurt
  'eu-west-1': 7, // Ireland
  'eu-west-2': 8, // London
  'eu-south-1': 9, // Milan
  'eu-west-3': 10, // Paris
  'eu-north-1': 11, // Stockholm
  'af-south-1': 12, // Cape Town
  'ap-east-1': 13, // Hong Kong
  'ap-south-1': 14, // Mumbai
  'ap-northeast-2': 15, // Seoul
  'ap-southeast-1': 16, // Singapore
  'ap-northeast-1': 17, // Tokyo
  'ca-central-1': 18, // montreal
  'cn-north-1': 19, // Beijing
  'cn-northwest-1': 20, // Ningxia
  'me-south-1': 21, // Bahrain
  'sa-east-1': 22, // São Paulo
};

// ISO-3166-1 for regions that are the sole region in their respective country
// Second part of ISO 3166-2 for regions that share a country with another region
export const REGION_TWO_CHAR_DESIGNATION: Record<string, string> = {
  'ap-southeast-2': 'au', // Sydney
  'us-east-1': 'va', // N. Virginia
  'us-east-2': 'oh', // Ohio
  'us-west-1': 'ca', // N. California
  'us-west-2': 'or', // Oregon
  'eu-central-1': 'de', // Frankfurt
  'eu-west-1': 'ie', // Ireland
  'eu-west-2': 'gb', // London
  'eu-south-1': 'it', // Milan
  'eu-west-3': 'fr', // Paris
  'eu-north-1': 'se', // Stockholm
  'af-south-1': 'za', // Cape Town
  'ap-east-1': 'hk', // Hong Kong
  'ap-south-1': 'in', // Mumbai
  'ap-northeast-2': 'kr', // Seoul
  'ap-southeast-1': 'sg', // Singapore
  'ap-northeast-1': 'jp', // Tokyo
  'ca-central-1': 'qc', // montreal
  'cn-north-1': 'bj', // Beijing
  'cn-northwest-1': 'nx', // Ningxia
  'me-south-1': 'bh', // Bahrain
  'sa-east-1': 'br', // São Paulo
};

export const getLocalEndpoint = (
  apolloUri: string,
  isCrossDeviceTesting: boolean,
  localIp: string,
) => {
  return isCrossDeviceTesting ? apolloUri.replace('localhost', localIp) : apolloUri;
};

export const getRegionNumber = (
  userLocation: string | undefined,
  env: string,
  provider: string,
) => {
  const DEFAULT_REGION = 'ap-southeast-2';
  const region =
    !userLocation || !getRegionFromLocation(userLocation, provider)
      ? DEFAULT_REGION
      : getRegionFromLocation(userLocation, provider);

  const devRegionNumbers = [
    REGION_NUMBER_DESIGNATION['ap-southeast-2'],
    REGION_NUMBER_DESIGNATION['us-west-2'],
  ];
  const regionNumber = REGION_NUMBER_DESIGNATION[region ? region : DEFAULT_REGION];

  // We do not have EU infra on deployed dev envs, so we default to an available one instead
  if (env.startsWith('dev') && !devRegionNumbers.includes(regionNumber)) {
    return REGION_NUMBER_DESIGNATION['ap-southeast-2'];
  }

  return REGION_NUMBER_DESIGNATION[region ? region : DEFAULT_REGION];
};

export const getApiEndpoint = (userLocation: string | undefined, env: string, provider: string) => {
  const providerPrefix = isProviderGCP(provider) ? 'gcp.' : '';
  const apiRegionNumber = getRegionNumber(userLocation, env, provider);

  return env === 'prod'
    ? `https://api${apiRegionNumber}.${providerPrefix}joyoushq.com/graphql`
    : `https://api${apiRegionNumber}.${providerPrefix}${env}.joyoushq.com/graphql`;
};

export const getSubscriptionEndpoint = (
  userLocation: string | undefined,
  env: string,
  provider: string,
) => {
  const providerPrefix = isProviderGCP(provider) ? 'gcp.' : '';
  const apiRegionNumber = getRegionNumber(userLocation, env, provider);
  return env === 'prod'
    ? `wss://ss${apiRegionNumber}.${providerPrefix}joyoushq.com/sub`
    : `wss://ss${apiRegionNumber}.${providerPrefix}${env}.joyoushq.com/sub`;
};

const getPipEndpointAWS = (userLocation: string | undefined, env: string) => {
  const envSubDomain = env === 'prod' ? '' : `${env}.`;
  const regionNumber = getRegionNumber(userLocation, env, '');

  return `https://pip${regionNumber}.${envSubDomain}joyoushq.com/persons-ingestion-initiator`;
};

const getPipEndpointGCP = () => {
  const { GCP_PIP_INITIATOR_URL } = process.env;

  if (!GCP_PIP_INITIATOR_URL) {
    throw new JoyousError('Missing GCP_PIP_INITIATOR_URL env variable');
  }
  return GCP_PIP_INITIATOR_URL;
};

export const getPipEndpoint = (userLocation: string | undefined, env: string, provider: string) => {
  return isProviderGCP(provider) ? getPipEndpointGCP() : getPipEndpointAWS(userLocation, env);
};
