import { IconButton } from '@shared/frontend/components/base/icon-button';
import React, { useState } from 'react';

type Props = {
  onFileSelected: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const UploadImageButton = ({ onFileSelected }: Props) => {
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  return (
    <IconButton
      iconName="PhotoCamera"
      aria-label="camera"
      onClick={() => {
        inputRef?.click();
      }}
    >
      <input
        data-cy="image-upload-input"
        ref={setInputRef}
        type="file"
        accept="image/*"
        capture="environment"
        hidden
        onChange={onFileSelected}
      />
    </IconButton>
  );
};

export default UploadImageButton;
