// The follow was generated with the help of chatGPT:
//
// observe the follow typescript object:
// export const STATIC_LOOKUP = Record<string, Record<SupportedLanguageCode, string>>{
//   Confirm: {
//     en: 'Confirm',
//   },
//   'Confirm and send': {
//     en: 'Confirm and send',
//   },
//   'See original language': {
//     en: 'See original language',
//   },
//   'Show original': {
//   },
//   'Show translation': {
//     en: 'Show translation',
//   },
//   Skip: {
//     en: 'Skip',
//   },
//   'Skipped question': {
//     en: 'Skipped question',
//   },
//   'View translation': {
//     en: 'View translation',
//   },
// };
// and the following typescript type:
// export type SupportedLanguageCode =
// | 'en'
// | 'de'
// | 'es'
// | 'es-MX'
// | 'fi'
// | 'fr'
// | 'it'
// | 'ja'
// | 'ko'
// | 'nl'
// | 'pl'
// | 'pt'
// | 'pt-BR'
// | 'ru'
// | 'sv'
// | 'zh-CN'
// | 'zh-TW';
// The keys of the STATIC_LOOKUP object are english phrases
// and the values are an object of are ietf language tags, corresponding to SupportedLanguageCode, and their translations of the english phrase.
// I want you to output STATIC_LOOKUP populated with the rest of the missing language tags and their missing translations.

// To add a new phrase:

// Observe the following typescript type of ietf language tags:
// export type SupportedLanguageCode =
// | 'en'
// | 'de'
// | 'es'
// | 'es-MX'
// | 'fi'
// | 'fr'
// | 'it'
// | 'ja'
// | 'ko'
// | 'nl'
// | 'pl'
// | 'pt'
// | 'pt-BR'
// | 'ru'
// | 'sv'
// | 'zh-CN'
// | 'zh-TW';
// I want you to output an object with the following structure which has an english phrase as the key
// and the value is an object of SupportedLanguageCode and their translations of the english phrase:
// {
//   'not answered': {
//      en: 'not answered',
//      [... complete the rest of the language codes and the translatin of the phrase]
//   },
//   'edited': {
//      en: 'not answered',
//      [... complete the rest of the language codes and the translatin of the phrase]
//   }
// }
//

const ENGLISH_TEXT = {
  Confirm: 'Confirm',
  'Confirm and send': 'Confirm and send',
  'See original language': 'See original language',
  'Show original': 'Show original',
  'Show translation': 'Show translation',
  Skip: 'Skip',
  'View translation': 'View translation',
  'Skipped question': 'Skipped question',
  'not answered': 'not answered',
  edited: 'edited',
  Respond: 'Respond',
};

// these are the most common codes found on the web
export type SupportedLanguageCode =
  | 'en'
  | 'de'
  | 'es'
  | 'es-MX'
  | 'fi'
  | 'fr'
  | 'it'
  | 'ja'
  | 'ko'
  | 'nl'
  | 'pl'
  | 'pt'
  | 'pt-BR'
  | 'ru'
  | 'sv'
  | 'zh-CN'
  | 'zh-TW';

export type SupportedText = keyof typeof ENGLISH_TEXT;

export const STATIC_LOOKUP: Record<
  keyof typeof ENGLISH_TEXT,
  Record<SupportedLanguageCode, string>
> = {
  Confirm: {
    en: 'Confirm',
    de: 'Bestätigen',
    es: 'Confirmar',
    'es-MX': 'Confirmar',
    fi: 'Vahvista',
    fr: 'Confirmer',
    it: 'Conferma',
    ja: '確認',
    ko: '확인',
    nl: 'Bevestigen',
    pl: 'Potwierdź',
    'pt-BR': 'Confirmar',
    pt: 'Confirmar',
    ru: 'Подтвердить',
    sv: 'Bekräfta',
    'zh-CN': '确认',
    'zh-TW': '確認',
  },
  'Confirm and send': {
    en: 'Confirm and send',
    de: 'Bestätigen und senden',
    es: 'Confirmar y enviar',
    'es-MX': 'Confirmar y enviar',
    fi: 'Vahvista ja lähetä',
    fr: 'Confirmer et envoyer',
    it: 'Conferma e invia',
    ja: '確認して送信',
    ko: '확인하고 보내기',
    nl: 'Bevestigen en verzenden',
    pl: 'Potwierdź i wyślij',
    'pt-BR': 'Confirmar e enviar',
    pt: 'Confirmar e enviar',
    ru: 'Подтвердить и отправить',
    sv: 'Bekräfta och skicka',
    'zh-CN': '确认并发送',
    'zh-TW': '確認並發送',
  },
  'See original language': {
    en: 'See original language',
    de: 'Originalsprache anzeigen',
    es: 'Ver idioma original',
    'es-MX': 'Ver idioma original',
    fi: 'Katso alkuperäinen kieli',
    fr: "Voir la langue d'origine",
    it: 'Vedi lingua originale',
    ja: '元の言語を見る',
    ko: '원본 언어 보기',
    nl: 'Bekijk originele taal',
    pl: 'Zobacz oryginalny język',
    'pt-BR': 'Ver idioma original',
    pt: 'Ver língua original',
    ru: 'Посмотреть оригинальный язык',
    sv: 'Se originalspråk',
    'zh-CN': '查看原文',
    'zh-TW': '查看原文',
  },
  'Show original': {
    en: 'Show original',
    de: 'Original anzeigen',
    es: 'Mostrar original',
    'es-MX': 'Mostrar original',
    fi: 'Näytä alkuperäinen',
    fr: "Afficher l'original",
    it: 'Mostra originale',
    ja: '元を表示',
    ko: '원본 보기',
    nl: 'Origineel tonen',
    pl: 'Pokaż oryginał',
    'pt-BR': 'Mostrar original',
    pt: 'Mostrar original',
    ru: 'Показать оригинал',
    sv: 'Visa original',
    'zh-CN': '显示原文',
    'zh-TW': '顯示原文',
  },
  'Show translation': {
    en: 'Show translation',
    de: 'Übersetzung anzeigen',
    es: 'Mostrar traducción',
    'es-MX': 'Mostrar traducción',
    fi: 'Näytä käännös',
    fr: 'Afficher la traduction',
    it: 'Mostra traduzione',
    ja: '翻訳を表示',
    ko: '번역 보기',
    nl: 'Vertaling tonen',
    pl: 'Pokaż tłumaczenie',
    'pt-BR': 'Mostrar tradução',
    pt: 'Mostrar tradução',
    ru: 'Показать перевод',
    sv: 'Visa översättning',
    'zh-CN': '显示翻译',
    'zh-TW': '顯示翻譯',
  },
  Skip: {
    en: 'Skip',
    de: 'Überspringen',
    es: 'Omitir',
    'es-MX': 'Saltar',
    fi: 'Ohita',
    fr: 'Passer',
    it: 'Salta',
    ja: 'スキップ',
    ko: '건너뛰기',
    nl: 'Overslaan',
    pl: 'Pomiń',
    'pt-BR': 'Pular',
    pt: 'Saltar',
    ru: 'Пропустить',
    sv: 'Hoppa över',
    'zh-CN': '跳过',
    'zh-TW': '跳過',
  },
  'Skipped question': {
    en: 'Skipped question',
    de: 'Übersprungene Frage',
    es: 'Pregunta omitida',
    'es-MX': 'Pregunta omitida',
    fi: 'Ohitettu kysymys',
    fr: 'Question passée',
    it: 'Domanda saltata',
    ja: 'スキップされた質問',
    ko: '건너뛴 질문',
    nl: 'Overgeslagen vraag',
    pl: 'Pominięte pytanie',
    'pt-BR': 'Pergunta pulada',
    pt: 'Pergunta saltada',
    ru: 'Пропущенный вопрос',
    sv: 'Överhoppad fråga',
    'zh-CN': '跳过的问题',
    'zh-TW': '跳過的問題',
  },
  'View translation': {
    en: 'View translation',
    de: 'Übersetzung ansehen',
    es: 'Ver traducción',
    'es-MX': 'Ver traducción',
    fi: 'Näytä käännös',
    fr: 'Voir la traduction',
    it: 'Vedi traduzione',
    ja: '翻訳を表示',
    ko: '번역 보기',
    nl: 'Bekijk vertaling',
    pl: 'Zobacz tłumaczenie',
    'pt-BR': 'Ver tradução',
    pt: 'Ver tradução',
    ru: 'Посмотреть перевод',
    sv: 'Visa översättning',
    'zh-CN': '查看翻译',
    'zh-TW': '查看翻译',
  },
  'not answered': {
    en: 'not answered',
    de: 'nicht beantwortet',
    es: 'no respondido',
    'es-MX': 'no respondido',
    fi: 'ei vastattu',
    fr: 'non répondu',
    it: 'non risposto',
    ja: '未回答',
    ko: '답변 없음',
    nl: 'niet beantwoord',
    pl: 'nie odpowiedziano',
    pt: 'não respondido',
    'pt-BR': 'não respondido',
    ru: 'не отвечено',
    sv: 'inte besvarad',
    'zh-CN': '未回答',
    'zh-TW': '未回答',
  },
  edited: {
    en: 'edited',
    de: 'bearbeitet',
    es: 'editado',
    'es-MX': 'editado',
    fi: 'muokattu',
    fr: 'modifié',
    it: 'modificato',
    ja: '編集済み',
    ko: '편집됨',
    nl: 'bewerkt',
    pl: 'edytowane',
    pt: 'editado',
    'pt-BR': 'editado',
    ru: 'отредактировано',
    sv: 'redigerad',
    'zh-CN': '已编辑',
    'zh-TW': '已編輯',
  },
  Respond: {
    en: 'Respond',
    de: 'Antworten',
    es: 'Responder',
    'es-MX': 'Responder',
    fi: 'Vastaa',
    fr: 'Répondre',
    it: 'Rispondere',
    ja: '応答',
    ko: '응답하다',
    nl: 'Reageren',
    pl: 'Odpowiadać',
    pt: 'Responder',
    'pt-BR': 'Responder',
    ru: 'Ответить',
    sv: 'Svara',
    'zh-CN': '回应',
    'zh-TW': '回應',
  },
};

export const lookupStaticTranslation = (text: string, languageCode: string) => {
  const normalisedLanguageCode = languageCode.toLocaleLowerCase() as SupportedLanguageCode;
  const possibleTranslation = STATIC_LOOKUP?.[text as SupportedText]?.[normalisedLanguageCode];

  if (possibleTranslation) {
    return possibleTranslation;
  }

  // zh is a special case where we don't have a translation for the major language code
  // fall back to zh-CN
  if (normalisedLanguageCode.includes('zh')) {
    return STATIC_LOOKUP?.[text as SupportedText]?.['zh-CN'];
  }

  // in all other cases, try to fallback to a major language code if no exact match
  if (normalisedLanguageCode.includes('-')) {
    const majorLanguageCode = languageCode.slice(0, 2) as SupportedLanguageCode;
    return STATIC_LOOKUP?.[text as SupportedText]?.[majorLanguageCode];
  }
};
