import { type RumErrorEvent, type RumEvent, datadogRum } from '@datadog/browser-rum';
import { type AccessTokenPayload, type IdTokenPayload } from '@shared/types/token-payload-types';

export enum Service {
  ORG_UI = 'org-ui',
  TEAM_MEMBER_UI = 'team-member-ui',
}

const ERRORS_TO_IGNORE = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',
];

const shouldIgnoreError = (event: RumErrorEvent) =>
  ERRORS_TO_IGNORE.some((errorMessage) => event.error.message.includes(errorMessage));

export const removePathFromUrl = (url: string) => {
  const { pathname } = new URL(url);
  return url.replace(pathname, '/');
};

export const initializeDatadogRum = (
  service: Service,
  beforeSendHandler?: (event: RumEvent) => boolean | void,
) => {
  const { BUILD_ENV, BUILD_RUM_APPLICATION_ID, BUILD_RUM_CLIENT_TOKEN, BUILD_VERSION } =
    process.env;

  if (!BUILD_RUM_APPLICATION_ID || !BUILD_RUM_CLIENT_TOKEN) {
    return;
  }

  datadogRum.init({
    applicationId: BUILD_RUM_APPLICATION_ID,
    clientToken: BUILD_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service,
    env: BUILD_ENV,
    version: BUILD_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    beforeSend: (event) => {
      if (event.type === 'error' && shouldIgnoreError(event)) {
        return false;
      }

      if (beforeSendHandler) {
        return beforeSendHandler(event);
      }
    },
  });
};

export const initializeDatadogRumUser = (
  accessTokenPayload: AccessTokenPayload | undefined,
  idTokenPayload: IdTokenPayload | undefined,
) => {
  if (accessTokenPayload) {
    const { _id, orgId, location } = accessTokenPayload;
    datadogRum.setUser({ personId: _id, orgId, location });
  }

  if (idTokenPayload) {
    const { _id, orgId, location } = idTokenPayload;
    datadogRum.setUser({
      personId: _id,
      orgId,
      location,
      isExtendedSignIn: true,
    });
  }
};
