import { styled, theme } from '@shared/frontend/theme';
import React, { type ComponentProps } from 'react';

type TypingIndicatorProps = {
  $width?: string;
  $height?: string;
  $background?: string;
  $dotSize?: string;
  $dotColor?: string;
} & ComponentProps<'div'>;

export const TypingIndicator = styled(({ ...props }: TypingIndicatorProps) => (
  <div {...props}>
    <div />
    <div />
    <div />
  </div>
))`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $width, $height }) => `
    width: ${$width ?? '5.6rem'};
    height: ${$height ?? '3.5rem'};
  `}
  background: ${({ $background }) => $background ?? theme.color.ducksBlue[25]};
  border: 0.1rem solid transparent;
  border-radius: 2.5rem;

  div {
    width: ${({ $dotSize }) => $dotSize ?? '0.7rem'};
    height: ${({ $dotSize }) => $dotSize ?? '0.7rem'};
    margin: 0.3rem;
    border-radius: 50%;
    animation: blink 2s infinite;
  }

  div:nth-of-type(1) {
    background: linear-gradient(90deg, #fcb316, #fbae16, #f9a118, #f68a1b, #f26b20, #f15e22);
  }
  div:nth-of-type(2) {
    background: linear-gradient(90deg, #f21196, #c430ae, #9650c8, #895bc9);

    animation-delay: 0.3s;
  }

  div:nth-of-type(3) {
    background: linear-gradient(90deg, #35add2, #23c0d5, #22b7d1, #1f9ec8);
    animation-delay: 0.5s;
  }

  @keyframes blink {
    0% {
      opacity: 0.5;
      transform: scale(1);
    }
    20% {
      opacity: 1;
      transform: scale(1.5);
    }
    100% {
      opacity: 0.5;
      transform: scale(1);
    }
  }
`;
