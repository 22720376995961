import { StyledIcon } from '@components/base/styled-icon';
import { getTypographyStyles } from '@components/base/typography';
import { styled, theme } from '@frontend/theme';
import { paperClasses } from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import { snackbarContentClasses } from '@mui/material/SnackbarContent';
import { useNavigate, useParams } from '@tanstack/react-router';
import React from 'react';

const StyledSnackbar = styled(Snackbar)`
  && {
    top: 7rem;

    .${paperClasses.root} {
      padding: 0.5rem ${theme.spacing.s};
      border: 0.1rem solid ${theme.color.happyYellow[100]};
      border-radius: 1.4rem;
      background-color: ${theme.color.happyYellow[50]};
      flex-direction: row-reverse;
      flex-shrink: 0;
      box-shadow: none;
      flex-grow: 0;
      min-width: 0;
    }

    .${snackbarContentClasses.message} {
      ${getTypographyStyles({ $variant: 'BodyUi', $customColor: theme.text.primary })}
      padding: 0 0 0 0.4rem;
    }

    .${snackbarContentClasses.action} {
      padding: 0;
      margin: 0;
    }
  }
`;

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const NewDeviceAddedNotification = ({ isOpen, setIsOpen }: Props) => {
  const navigate = useNavigate();

  const { selectedConversationId } = useParams({
    from: '/AppLayout/conversations/$selectedConversationId',
  });

  return (
    <StyledSnackbar
      open={isOpen}
      autoHideDuration={3000}
      onClose={async () => {
        setIsOpen(false);
        await navigate({
          to: '/conversations/$selectedConversationId',
          params: { selectedConversationId },
        });
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      message="New device added to your profile"
      action={
        <StyledIcon iconName="VerifiedUserOutlined" $colorScheme="happyYellow" $size="1.8rem" />
      }
    />
  );
};
