import { LinearProgress, ProgressColorScheme } from '@shared/frontend/components/base/progress';
import { styled } from '@shared/frontend/theme';
import React, { ComponentProps } from 'react';

import { getTypographyStyles } from './typography';

const StyledLinearProgress = styled(LinearProgress)`
  height: 1.4rem;
`;

type Props = {
  value: number;
  left?: React.ReactNode;
  right?: React.ReactNode;
  $colorScheme?: ProgressColorScheme;
} & ComponentProps<'div'>;

export const ProgressBar = styled(({ left, value, right, $colorScheme, ...rest }: Props) => (
  <div {...rest} data-cy="progress-bar">
    {left}
    <StyledLinearProgress variant="determinate" value={value} $colorScheme={$colorScheme} />
    {right}
  </div>
))`
  ${getTypographyStyles({ $variant: 'Caption', $fontWeight: 'medium' })};

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  gap: 1.2rem;
`;
