import { MANA_ORA_MAP } from '@shared/frontend/components/conversation/message/rating-mana-ora/rating-mana-ora-utils';
import {
  isGraphQLComment,
  isGraphQLConversationPrompt,
  isGraphQLFilteredSelfSelect,
  isGraphQLRating11,
  isGraphQLRatingENPS,
  isGraphQLRatingManaOra,
  isGraphQLSelectMany,
  isGraphQLSelectOne,
  isGraphQLSelfSelect,
  isPosedGraphQLWidget,
  isSkippedGraphQLWidget,
  type GraphQLMessage,
} from '@shared/graphql/message-utils';
import text from '@shared/utils/text.json';

import {
  useGetStaticTranslation,
  useGetToggleableTranslatedText,
} from '../frontend/components/translate';

const getLastMessagePreview = (
  lastMessage: GraphQLMessage,
  messageBeforeLast: GraphQLMessage | undefined,
): string | null => {
  if (isSkippedGraphQLWidget(lastMessage)) {
    return useGetStaticTranslation(text.SKIPPED_QUESTION);
  }
  if (isPosedGraphQLWidget(lastMessage) || isGraphQLConversationPrompt(lastMessage)) {
    // if last message is a widget then the message before last *should* always be the question text in the form of a joyous comment
    if (!!messageBeforeLast && isGraphQLComment(messageBeforeLast)) {
      return useGetToggleableTranslatedText(
        messageBeforeLast.text ?? null,
        messageBeforeLast.translatedText ?? null,
      );
    }
    const {
      metadata: { questionText },
    } = lastMessage;

    return questionText;
  }

  if (isGraphQLSelectMany(lastMessage)) {
    return lastMessage.selectedValues?.join(', ') ?? null;
  }

  if (
    isGraphQLSelectOne(lastMessage) ||
    isGraphQLSelfSelect(lastMessage) ||
    isGraphQLFilteredSelfSelect(lastMessage)
  ) {
    return lastMessage.selectedValue ?? null;
  }
  if (isGraphQLRating11(lastMessage) || isGraphQLRatingENPS(lastMessage)) {
    return lastMessage?.ratingValue?.rating?.toString() ?? null;
  }
  if (isGraphQLRatingManaOra(lastMessage)) {
    const rating = lastMessage?.ratingValue?.rating || 0;
    return MANA_ORA_MAP[rating].text;
  }
  if (isGraphQLComment(lastMessage)) {
    return lastMessage.image
      ? useGetStaticTranslation('Sent an image')
      : useGetToggleableTranslatedText(
          lastMessage.text ?? null,
          lastMessage.translatedText ?? null,
        );
  }
  return null;
};

export default getLastMessagePreview;
