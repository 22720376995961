import createSvgIcon from '@mui/material/utils/createSvgIcon';
import React from 'react';

export const SearchCommentIcon = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    strokeWidth={0}
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="search-comment-icon-title"
  >
    <title id="search-comment-icon-title">Search comment icon</title>
    <path d="M22 4C22 2.9 21.1 2 20 2H4C2.9 2 2 2.9 2 4V16C2 17.1 2.9 18 4 18H18L22 22V4Z" />
    <path
      d="M16.79 16.21L13.88 13.3C13.19 13.74 12.37 14 11.49 14C9.01 14 7 11.99 7 9.5C7 7.01 9.01 5 11.5 5C13.99 5 16 7.01 16 9.5C16 10.38 15.74 11.19 15.3 11.89L18.21 14.79L16.79 16.21Z"
      fill="white"
    />
    <path d="M11.5 7C10.12 7 9 8.12 9 9.5C9 10.88 10.12 12 11.5 12C12.88 12 14 10.88 14 9.5C14 8.12 12.88 7 11.5 7Z" />
  </svg>,
  'SearchCommentIcon',
);
