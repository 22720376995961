import { makeVar, useReactiveVar } from '@apollo/client';
import { QueryOperations } from '@shared/graphql/parse-query-response';
import { useEffect } from 'react';

export const reconcileAutoAnswerConversationVar = makeVar(true);

const REFETCH_ATTEMPTS = 6;

const isHtmlImageElement = (elem: HTMLElement | null): elem is HTMLImageElement =>
  !!elem && 'src' in elem;

const getAutoAnswerConversationId = () => {
  // imgElem is the gif img inserted by the worker-ui-service
  const imgElem = document.getElementById('anti-spam-bot-pixel-img');
  if (!isHtmlImageElement(imgElem)) {
    return undefined;
  }

  return imgElem.src.split('~')[1];
};

// This hook was added as a patch for the email answer via pixel race condition.
// When our users answer questions via email they are redirected to the team-member-ui.
// Team-member-ui html+assets are served from cloudfront, and, in parallel, the worker-ui-services
//    lambda@edge is invoked.
// The worker-ui-services first modifies the served html by adding a 1x1 px fake-gif img tag
//    pointing its src attribute to a very specific url that contains the conversation id,
//    the widget message id and the answer.
// When the browser loads this html it tries to fetch the gif img by requesting it to the given url.
// This again triggers the worker-ui-services that now proceeds on calling the answerRatingWidgetViaPixel
//    mutation.
// There is a race condition here because the gif img loading (second worker-ui-services invocation)
//    happens in parallel to the ui bootstrapping, which could imply that the given conversation is
//    loaded before the answer was effectively recorded in the db, making the users see an un-answered
//    widget for the question they just replied to.
// In order to patch this behavior, this hook is reconciling the loaded conversation with the backend's data
//    during a limited reconciliation window (~3 secs) by polling any changes occurred in the db.
export const useEmailPixelAnswerReconciliator = <T>(
  selectedConversationId: string,
  queryOperations: QueryOperations<T>,
) => {
  const reconcileAutoAnswerConversation = useReactiveVar(reconcileAutoAnswerConversationVar);

  useEffect(() => {
    const isAutoAnswerConversation = getAutoAnswerConversationId() === selectedConversationId;

    if (!isAutoAnswerConversation) {
      reconcileAutoAnswerConversationVar(false);
      return undefined;
    }

    if (reconcileAutoAnswerConversation) {
      let remainingRefetchAttempts = REFETCH_ATTEMPTS;
      const intervalId = setInterval(() => {
        if (queryOperations.refetch && remainingRefetchAttempts > 0) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          queryOperations.refetch();
          remainingRefetchAttempts -= 1;
        } else {
          clearInterval(intervalId);
        }
      }, 500);
      return () => {
        clearInterval(intervalId);
      };
    }

    return undefined;
    // We don't want nor need to re-trigger the side effect when reconcileAutoAnswerConversation changes
    // This is because we only want the useEffect to execute once at the start of the application
    // Therefore we don't care if the flag goes back to true at some point
    // Moreover, if we would listen to the flag the interval would be cleaned immediately and never executed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversationId, queryOperations.refetch]);
};
