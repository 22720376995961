import { ApolloClient } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';

import { initializeLink } from './link';

const { BUILD_ENABLE_APOLLO_DEV_TOOLS = 'false' } = process.env;

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        conversationWithParticipants: (existing, { args, toReference, readField }) => {
          // If Apollo already found it in the cache then return that.
          if (existing) {
            return existing;
          }
          // If we have no args then let Apollo handle it. (undefined seems to result in default behaviour)
          if (!args?._id) {
            return undefined;
          }

          const { _id } = args as { _id: string };
          const reference = toReference({ __typename: 'Conversation', _id });

          if (readField('_id', reference)) {
            return reference;
          }
          return undefined;
        },
        teamMemberConversations: {
          keyArgs: false,
        },
      },
    },
    // A new messages array will overwrite the existing messages array
    // This is the default Apollo behaviour (merge: false)
    // We need to explicitly define it here to remove a warning about potentially losing cache data
    Conversation: {
      fields: {
        messages: {
          merge: false,
        },
        attributes: {
          merge: false,
        },
        owner: {
          // Queries load in different parts of the person record, merge them together instead of overwriting
          merge: (existing, incoming) => ({ ...existing, ...incoming }),
        },
      },
    },
    // Prevent Apollo from normalising the Conversation data into separate cache items
    Person: { keyFields: false },
    ConversationComment: { keyFields: false },
    RatingAnswer: { keyFields: false },
    ConversationPrompt: { keyFields: false },
    SelectOneAnswer: { keyFields: false },
    SelectManyAnswer: { keyFields: false },
    SelfSelectAnswer: { keyFields: false },
    FilteredSelfSelectAnswer: { keyFields: false },
  },
  possibleTypes: {
    BaseMessage: [
      'ConversationComment',
      'RatingAnswer',
      'SelectOneAnswer',
      'SelectManyAnswer',
      'ConversationPrompt',
      'SelfSelectAnswer',
      'FilteredSelfSelectAnswer',
    ],
  },
  canonizeResults: true,
});

export const getApolloClient = () =>
  new ApolloClient({
    link: initializeLink(),
    cache,
    connectToDevTools: BUILD_ENABLE_APOLLO_DEV_TOOLS === 'true',
  });
