export type OneToManyMap<T> = { [key: string]: T[] };
export type OneToOneMap<T> = { [key: string]: T };
export type Partitions<T, U extends string> = { [K in U]?: T[] };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyObject = { [key: string]: any };

export const createOneToManyMap = <T, K extends keyof T>(
  arr: T[],
  keyProperty: K,
): OneToManyMap<T> =>
  arr.reduce(
    (memo, element): OneToManyMap<T> => {
      if (memo[element[keyProperty]]) {
        memo[element[keyProperty]].push(element);
      } else {
        memo[element[keyProperty]] = [element];
      }
      return memo;
    },
    Object.create(null, {}),
  );

type KeySelector<T> = ((item: T) => string) | keyof T;

export function createOneToOneMap<T extends object>(
  arr: T[],
  keySelector: KeySelector<T>,
): Record<string, T>;
export function createOneToOneMap<T extends object, U>(
  arr: T[],
  keySelector: KeySelector<T>,
  valueSelector: (item: T) => U,
): Record<string, U>;
export function createOneToOneMap<T extends object>(
  arr: T[],
  keySelector: KeySelector<T>,
  valueSelector: keyof T,
): Record<string, ValueOf<T>>;
export function createOneToOneMap<T extends object, U>(
  arr: T[],
  keySelector: KeySelector<T>,
  valueSelector?: ((item: T) => U) | keyof T,
): Record<string, T | U | ValueOf<T>> {
  return arr.reduce<Record<string, T | U | ValueOf<T>>>((memo, element) => {
    let key: string;
    if (typeof keySelector === 'function') {
      key = keySelector(element);
    } else {
      key = String(element[keySelector]);
    }
    if (valueSelector && typeof valueSelector === 'function') {
      memo[key] = valueSelector(element);
    } else if (valueSelector && typeof valueSelector === 'string') {
      memo[key] = element[valueSelector];
    } else {
      memo[key] = element;
    }
    return memo;
  }, {});
}

export const partitionBy = <T, U extends string>(
  arr: T[],
  partitioner: (element: T) => U,
): Partitions<T, U> =>
  arr.reduce<{ [K in U]?: T[] }>((memo, element) => {
    const key = partitioner(element);
    const value = memo[key];
    memo[key] = [...(value instanceof Array ? value : []), element];
    return memo;
  }, {});

export const dedupeArray = <T>(arr: T[]): T[] => {
  return Array.from(new Set(arr));
};

// Gets unique values for a specific property in an array of objects
export const dedupePropertyFromArrayOfObjects = <T, K extends keyof T>(
  arr: T[],
  property: K,
): Array<T[K]> => {
  const uniquePropertyValues = arr.reduce<Set<T[K]>>((memo, object): Set<T[K]> => {
    if (object[property]) {
      memo.add(object[property]);
    }
    return memo;
  }, new Set());

  return Array.from(uniquePropertyValues);
};

export type ValueOf<T> = T[keyof T];
type FilterFunction<T> = (key?: keyof T, value?: T[keyof T]) => boolean;

export const filterObject = <T extends Record<string, unknown>, R>(
  object: T,
  filterFunction: FilterFunction<T>,
): R => {
  const result: Partial<T> = {};
  const objectKeys = Object.keys(object) as Array<keyof T>;
  objectKeys.forEach((key) => {
    if (filterFunction(key, object[key])) {
      result[key] = object[key];
    }
  });
  return result as R;
};

type NoUndefinedField<T> = { [K in keyof T]-?: NoUndefinedField<NonNullable<T[K]>> };

export const removeUndefinedAndNullValues = <T extends Record<string, unknown>>(
  object: T,
): NoUndefinedField<T> => filterObject(object, (_, value) => value !== undefined && value !== null);

export const aliasFields = <
  T extends Record<string, unknown>,
  K extends keyof T,
  F extends { [key in keyof Partial<T>]: string },
>(
  object: T,
  fieldAliases: F,
) =>
  Object.entries(object).reduce<{ [key in Exclude<K, keyof F> | ValueOf<F>]: T[K] }>(
    (memo, [fieldName, value]) => {
      if (fieldAliases[fieldName as K]) {
        const newMemo = { ...memo, [fieldAliases[fieldName as K]]: value };
        delete newMemo[fieldName];
        return newMemo;
      }

      return { ...memo, [fieldName]: value };
    },
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    object as unknown as { [key in Exclude<K, keyof F> | ValueOf<F>]: T[K] },
  );

export const batchArray = <T>(arr: T[], batchCount: number): T[][] => {
  const batchedArray: T[][] = [];

  for (let i = 0; i < arr.length; i += batchCount) {
    batchedArray.push(arr.slice(i, i + batchCount));
  }

  return batchedArray;
};

export type KeysMatchingValueType<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

export const sleep = (ms: number): Promise<unknown> =>
  new Promise((resolve) => setTimeout(resolve, ms));

const CSV_DATE_FORMAT = /^\d{2}\/\d{2}\/\d{4}$/;
const ISO_DATE_FORMAT = /^\d{4}-\d{2}-\d{2}$/;
/*
 * parses date from yyyy-mm-dd or dd/mm/yyyy format to js Date
 * returns null back if in unknown format
 */
export const parseDate = (dateStr: string): Date | null => {
  const trimmed = dateStr.trim();
  if (ISO_DATE_FORMAT.test(trimmed)) {
    return new Date(trimmed);
  }
  if (!CSV_DATE_FORMAT.test(trimmed)) {
    return null;
  }
  const inISOFormat = trimmed.split('/').reverse().join('-');

  return new Date(inISOFormat);
};

export const partition = <A, B>(
  array: Array<A | B>,
  filter: (element: A | B, i?: number, array?: Array<A | B>) => element is A,
): [A[], B[]] => {
  return array.reduce<[A[], B[]]>(
    (memo, element, i) => {
      if (filter(element, i, array)) {
        memo[0].push(element);
      } else {
        memo[1].push(element);
      }

      return memo;
    },
    [[], []],
  );
};

/**
 * Split an array in 2 parts.
 * Each element that passes the filter function will be put into the 1st array, and the rest in the 2nd.
 * @param array
 * @param filter
 * @returns
 */
export const conditionalPartition = <A>(
  array: A[],
  filter: (element: A, i?: number, array?: A[]) => boolean,
): [A[], A[]] => {
  return array.reduce<[A[], A[]]>(
    (memo, element, i) => {
      if (filter(element, i, array)) {
        memo[0].push(element);
      } else {
        memo[1].push(element);
      }

      return memo;
    },
    [[], []],
  );
};

export const isNotNullOrUndefined = <T>(arrayEntry: T | null | undefined): arrayEntry is T =>
  arrayEntry !== null && arrayEntry !== undefined;

export const isNullOrUndefined = <T>(entry: T | null | undefined): entry is null | undefined =>
  entry === null || entry === undefined;

export const shiftArrayItemToIndex = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
  if (toIndex < 0 || toIndex > array.length) {
    return array;
  }

  const updatedArray = [...array];
  const itemsToShift = array[fromIndex];

  updatedArray.splice(fromIndex, 1);
  updatedArray.splice(toIndex, 0, itemsToShift);

  return updatedArray;
};

export const splitIntoBatches = <T>(arr: T[], limit: number): T[][] => {
  const res = [];
  for (let i = 0; i < arr.length; i += limit) {
    const chunk = arr.slice(i, i + limit);
    res.push(chunk);
  }
  return res;
};
