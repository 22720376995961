import React from 'react';
import { NumericFormat, type NumericFormatProps } from 'react-number-format';

import { styled } from '../../theme';
import { TextInput, type TextInputProps } from './text';

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props: CustomProps, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  },
);

// number input component supporting things like thousand separators, '$' prefix, etc.
// see https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries

type Props = TextInputProps & {
  inputProps: TextInputProps['InputProps'] & NumericFormatProps;
};

export const NumberInput = styled(
  React.forwardRef<HTMLInputElement, Props>((props, ref) => (
    <TextInput
      {...props}
      InputProps={{
        ...props.InputProps,
        inputRef: ref,
        inputComponent: NumericFormatCustom as any,
      }}
    />
  )),
)<TextInputProps>``;
