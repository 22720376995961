import { makeVar } from '@apollo/client';

// app type
export enum AppType {
  ORG = 'ORG_UI',
  TEAM_MEMBER = 'TEAM_MEMBER',
}
export const appTypeVar = makeVar<AppType | null>(null);
export const getIsOrgUI = () => appTypeVar() === AppType.ORG;

// extended login check
export const extendedLoginVar = makeVar<boolean>(false);
export const getIsExtendedLogin = () => extendedLoginVar();
export const setIsExtendedLogin = (value: boolean) => extendedLoginVar(value);
