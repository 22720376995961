import { ButtonBaseProps } from '@mui/material/ButtonBase';
import { UnstyledButton } from '@shared/frontend/components/base/button';
import React from 'react';

import { styled, theme, transientOptions } from '../theme';

export type BorderRadii = {
  topLeft?: boolean;
  topRight?: boolean;
  bottomRight?: boolean;
  bottomLeft?: boolean;
};

export const getBorderRadii = (
  messageIndex: number,
  messagesLength: number,
  isAuthorJoyous: boolean,
  isRight: boolean,
  isNextConversationPromptOrSelfSelect?: boolean,
): BorderRadii => {
  const isFirstMessage = messageIndex === 0;
  const isLastMessage = messageIndex === messagesLength - 1;

  return isRight
    ? {
        topLeft: true,
        topRight: isFirstMessage,
        bottomRight: isLastMessage,
        bottomLeft: true,
      }
    : {
        topLeft: isFirstMessage,
        topRight: !isAuthorJoyous || isFirstMessage,
        bottomRight: !isAuthorJoyous || isNextConversationPromptOrSelfSelect || isLastMessage,
        bottomLeft: isNextConversationPromptOrSelfSelect || isLastMessage,
      };
};

export type Props = ButtonBaseProps & {
  $width?: string;
  $height?: string;
  $borderRadii?: BorderRadii;
  $padding?: string;
};

export const MessageBubble = styled(
  ({ children, ...props }: Props) => (
    <UnstyledButton disableRipple {...props}>
      {children}
    </UnstyledButton>
  ),
  transientOptions,
)`
  display: inline-flex;
  white-space: pre-line;
  width: ${({ $width }: Props) => $width || '100%'};
  max-width: 100%;
  height: ${({ $height }: Props) => $height || '100%'};
  padding: ${theme.spacing.xs} ${theme.spacing.s};
  border: 0.1rem solid transparent;
  text-align: left;
  ${({ $borderRadii }: Props) => {
    return $borderRadii
      ? `border-radius:
          ${$borderRadii.topLeft ? theme.borderRadius.md : '0.1rem'}
          ${$borderRadii.topRight ? theme.borderRadius.md : '0.1rem'}
          ${$borderRadii.bottomRight ? theme.borderRadius.md : '0.1rem'}
          ${$borderRadii.bottomLeft ? theme.borderRadius.md : '0.1rem'};`
      : `border-radius: ${theme.borderRadius.md};`;
  }}
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  user-select: text;
`;
