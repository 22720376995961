import './polyfills';

import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client';
import {
  type RumErrorEvent,
  type RumEvent,
  type RumResourceEvent,
  datadogRum,
} from '@datadog/browser-rum';
import { muiTheme } from '@frontend/theme/mui-theme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { ReactContext } from '@shared/components/react-context';
import {
  Service,
  initializeDatadogRum,
  initializeDatadogRumUser,
  removePathFromUrl,
} from '@shared/utils/datadog-rum';
import { initHeap, setHeapUtilsErrorHandler } from '@shared/utils/heap-utils';
import { RouterProvider } from '@tanstack/react-router';
import React, { useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom/client';

import { getApolloClient } from './apollo/apollo-client';
import { apolloRegionModuleInstance } from './apollo/link';
import { router } from './router/router';
import { tokenManager } from './utils/token-manager';

// Sub paths under these will also be allowed
const RUM_EVENT_ALLOWED_PATHS = ['/error', '/conversations'];

const { BUILD_HEAP_ID, BUILD_DISABLE_STRICT_MODE = 'true' } = process.env;

const BASE_FUNCTION_PATH = 'team-member-ui.src.index';

let root: ReactDOM.Root | undefined;
type RumEventsWithResource = RumErrorEvent | RumResourceEvent;

const datadogRumBeforeSendHandler = (event: RumEvent) => {
  const { url, referrer } = event.view;

  const isUrlNotAllowed = !RUM_EVENT_ALLOWED_PATHS.some((path) => url.includes(path));

  if (isUrlNotAllowed) {
    event.view.url = removePathFromUrl(url);

    if (event.resource) {
      (event.resource as RumEventsWithResource).url = removePathFromUrl(url);
    }
  }

  const isReferrerNotAllowed =
    referrer && !RUM_EVENT_ALLOWED_PATHS.some((path) => referrer.includes(path));

  if (isReferrerNotAllowed) {
    event.view.referrer = removePathFromUrl(referrer);
  }
};

initializeDatadogRum(Service.TEAM_MEMBER_UI, datadogRumBeforeSendHandler);

window.addEventListener('storage', (event) => {
  const functionPath = `${BASE_FUNCTION_PATH}.storageEvent`;
  datadogRum.addAction('Local storage event triggered.', {
    functionPath,
    eventKey: event?.key,
    eventURL: event?.url,
    isSettingNewValue: event?.newValue !== null,
    isRemovingValue: event?.newValue === null,
    hadOldValue: event?.oldValue !== null,
  });
});

const datadogRumUser = datadogRum.getUser();
if (!datadogRumUser.personId || !datadogRumUser.orgId) {
  const accessTokenPayload = tokenManager.getAccessTokenPayload();
  const idTokenPayload = tokenManager.getExtendedIdTokenPayload();

  initializeDatadogRumUser(accessTokenPayload, idTokenPayload);
}

const heapUtilsErrorHandler = (error: unknown) => {
  // eslint-disable-next-line no-console
  console.error(error);
  datadogRum.addError(error, { source: 'heap-utils' });
};
setHeapUtilsErrorHandler(heapUtilsErrorHandler);
initHeap('team-member-ui', tokenManager, BUILD_HEAP_ID);

const getUserLocation = async () => {
  const response = await fetch(`${window.origin}/location`);
  return response.headers.get('cloudfront-viewer-country') ?? '';
};

const App = () => {
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      apolloRegionModuleInstance.setUris(await getUserLocation());
      const client = getApolloClient();
      tokenManager.setApolloClient(client);
      setApolloClient(client);
    })();
  }, []);

  if (!apolloClient) {
    return null;
  }

  return (
    <ReactContext apolloClient={apolloClient}>
      <ThemeProvider theme={muiTheme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </ReactContext>
  );
};

const container = document.getElementById('root');
if (!root) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  root = ReactDOM.createRoot(container!);
}
const application =
  BUILD_DISABLE_STRICT_MODE === 'true' ? (
    <App />
  ) : (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

root.render(application);
