import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const LANGUAGE_SETTING_LOCAL_STORAGE_KEY = 'LANGUAGE_SETTING';

export enum LanguageSetting {
  ORIGINAL,
  TRANSLATED,
}

type State = {
  languageSetting: LanguageSetting;
  languageSettingLoading: boolean;
  // is only true when content with translations are in view
  hasTranslationsInView: boolean;
  showTranslationLanguagePrompt: boolean;
  actions: {
    toggleLanguageSetting: () => void;
    toggleLanguageSettingLoading: () => void;
    setHasTranslationsInView: (value: boolean) => void;
    setShowTranslationLanguagePrompt: (value: boolean) => void;
  };
};

const useLanguageSettingStore = create<State>()(
  persist(
    (set) => ({
      languageSetting: LanguageSetting.TRANSLATED,
      languageSettingLoading: false,
      hasTranslationsInView: false,
      showTranslationLanguagePrompt: false,
      actions: {
        toggleLanguageSetting: () => {
          set(({ languageSetting: currentSetting }) => ({
            languageSetting:
              currentSetting === LanguageSetting.ORIGINAL
                ? LanguageSetting.TRANSLATED
                : LanguageSetting.ORIGINAL,
          }));
        },
        toggleLanguageSettingLoading: () => {
          set(({ languageSettingLoading: currentLoading }) => ({
            languageSettingLoading: !currentLoading,
          }));
        },
        setHasTranslationsInView: (hasTranslationsInView: boolean) => {
          set({ hasTranslationsInView });
        },
        setShowTranslationLanguagePrompt: (showTranslationLanguagePrompt: boolean) => {
          set({ showTranslationLanguagePrompt });
        },
      },
    }),
    {
      name: LANGUAGE_SETTING_LOCAL_STORAGE_KEY,
      partialize: ({ languageSetting }) => ({
        languageSetting,
      }),
    },
  ),
);

export const useLanguageStoreActions = () => useLanguageSettingStore((state) => state.actions);
export const useLanguageSetting = () => useLanguageSettingStore((state) => state.languageSetting);
export const useLanguageSettingLoading = () =>
  useLanguageSettingStore((state) => state.languageSettingLoading);
export const useToggleLanguageSetting = () =>
  useLanguageSettingStore((state) => state.actions.toggleLanguageSetting);
export const useToggleLanguageSettingLoading = () =>
  useLanguageSettingStore((state) => state.actions.toggleLanguageSettingLoading);

export const useHasTranslationsInView = () =>
  useLanguageSettingStore((state) => state.hasTranslationsInView);
export const useSetHasTranslationsInView = () =>
  useLanguageSettingStore((state) => state.actions.setHasTranslationsInView);
export const useShowTranslationLanguagePrompt = () =>
  useLanguageSettingStore((state) => state.showTranslationLanguagePrompt);
