import FormControlLabel, {
  formControlLabelClasses,
  type FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import MuiRadio, { radioClasses, type RadioProps } from '@mui/material/Radio';
import svgIconClasses from '@mui/material/SvgIcon/svgIconClasses';
import { BodyUi, Caption } from '@shared/frontend/components/base/typography';
import { styled, theme, type ThemeColorScheme, transientOptions } from '@shared/frontend/theme';
import React from 'react';

type RadioSize = '1.8rem' | '2.0rem';
type RadioColorScheme = Extract<
  ThemeColorScheme,
  'ducksBlue' | 'kereruPurple' | 'microPigPink' | 'katipoBlack' | 'happyYellow' | 'joyousDogOrange'
>;

type Props = RadioProps & {
  $size: RadioSize;
  $colorScheme?: RadioColorScheme;
};

export const Radio = styled(
  ({ $colorScheme, ...props }: Props) => (
    <MuiRadio
      size="medium"
      data-cy={props.checked ? 'checked-radio' : 'unchecked-radio'}
      {...props}
    />
  ),
  transientOptions,
)`
  &&.${radioClasses.root} {
    width: ${({ $size }) => $size};
    height: ${({ $size }) => $size};
    ${({ readOnly, disabled }) => (readOnly ?? disabled ? 'pointer-events: none; padding: 0' : '')};

    .${svgIconClasses.root} {
      width: ${({ $size }) => $size};
      height: ${({ $size }) => $size};

      color: ${({ disabled, $colorScheme = 'ducksBlue' }) =>
        disabled ? theme.color[$colorScheme][100] : theme.color[$colorScheme][400]};
    }
  }
`;

type RadioWithText = Omit<FormControlLabelProps, 'control'> & {
  $size: RadioSize;
  label: string;
  caption?: string;
};

const RadioTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const RadioWithText = styled(({ $size, label, caption, ...props }: RadioWithText) => (
  <FormControlLabel
    {...props}
    control={<Radio $size={$size} />}
    label={
      <RadioTextWrapper>
        <BodyUi $fontWeight="medium">{label}</BodyUi>
        <Caption>{caption}</Caption>
      </RadioTextWrapper>
    }
  />
))`
  && {
    &.${formControlLabelClasses.root} {
      margin: 0;
      display: flex;
      flex-direction: row;
      column-gap: ${theme.spacing.s};
    }

    && .${radioClasses.root} {
      /* vertically align the radio with the label */
      align-self: flex-start;
      padding: 1.1rem;
      margin-top: -0.25rem;

      &:hover {
        background-color: ${theme.color.ducksBlue[100]};
      }
    }
  }
`;
