import { type ColorOptions } from '@shared/frontend/components/conversation/message/types';
import { theme } from '@shared/frontend/theme';

export const MAX_MESSAGE_COUNT = 4;
export const MAX_GRADIENT_INDEX = 3;

type Gradient = Record<number, ColorOptions>;

export const commentGradient: Record<number, Gradient> = {
  0: {
    0: {
      border: theme.color.microPigPink[200],
      selectedBackground:
        'linear-gradient(322.83deg, rgba(254, 179, 19, 0.1) -81.56%, rgba(251, 174, 22, 0.1) -65.54%, rgba(249, 161, 24, 0.1) -44.18%, rgba(246, 138, 27, 0.1) -24.78%, rgba(242, 107, 32, 0.1) -8.76%, rgba(241, 94, 34, 0.1) 0.23%, rgba(242, 17, 150, 0.1) 76.97%, rgba(196, 48, 174, 0.1) 140.49%)',
      background: `linear-gradient(
          322.83deg,
          rgba(254, 179, 19, 0.25) -81.56%,
          rgba(251, 174, 22, 0.25) -65.54%,
          rgba(249, 161, 24, 0.25) -44.18%,
          rgba(246, 138, 27, 0.25) -24.78%,
          rgba(242, 107, 32, 0.25) -8.76%,
          rgba(241, 94, 34, 0.25) 0.23%,
          rgba(242, 17, 150, 0.25) 76.97%,
          rgba(196, 48, 174, 0.25) 140.49%
        )`,
    },
  },
  1: {
    0: {
      border: theme.color.microPigPink[200],
      selectedBackground:
        'linear-gradient(322.83deg, rgba(242, 17, 150, 0.1) 33.26%, rgba(196, 48, 174, 0.1) 73.86%)',
      background:
        'linear-gradient(322.83deg, rgba(242, 17, 150, 0.25) 33.26%, rgba(196, 48, 174, 0.25) 73.86%)',
    },
    1: {
      border: theme.color.joyousDogOrange[200],
      selectedBackground:
        'linear-gradient(321.6deg, rgba(254, 179, 19, 0.1) -40.57%, rgba(251, 174, 22, 0.1) -27.5%, rgba(249, 161, 24, 0.1) -10.08%, rgba(246, 138, 27, 0.1) 5.74%, rgba(242, 107, 32, 0.1) 18.81%, rgba(241, 94, 34, 0.1) 26.15%, rgba(242, 17, 150, 0.1) 88.75%)',
      background:
        'linear-gradient(321.6deg, rgba(254, 179, 19, 0.25) -40.57%, rgba(251, 174, 22, 0.25) -27.5%, rgba(249, 161, 24, 0.25) -10.08%, rgba(246, 138, 27, 0.25) 5.74%, rgba(242, 107, 32, 0.25) 18.81%, rgba(241, 94, 34, 0.25) 26.15%, rgba(242, 17, 150, 0.25) 88.75%)',
    },
  },
  2: {
    0: {
      border: theme.color.microPigPink[200],
      selectedBackground:
        'linear-gradient(323.22deg, rgba(254, 179, 19, 0.1) -240.12%, rgba(251, 174, 22, 0.1) -213.3%, rgba(249, 161, 24, 0.1) -177.53%, rgba(246, 138, 27, 0.1) -145.06%, rgba(242, 107, 32, 0.1) -118.23%, rgba(241, 94, 34, 0.1) -103.17%, rgba(242, 17, 150, 0.1) 25.31%, rgba(196, 48, 174, 0.1) 131.67%)',
      background:
        'linear-gradient(323.22deg, rgba(254, 179, 19, 0.25) -240.12%, rgba(251, 174, 22, 0.25) -213.3%, rgba(249, 161, 24, 0.25) -177.53%, rgba(246, 138, 27, 0.25) -145.06%, rgba(242, 107, 32, 0.25) -118.23%, rgba(241, 94, 34, 0.25) -103.17%, rgba(242, 17, 150, 0.25) 25.31%, rgba(196, 48, 174, 0.25) 131.67%)',
    },
    1: {
      border: theme.color.microPigPink[200],
      selectedBackground:
        'linear-gradient(323.57deg, rgba(254, 179, 19, 0.1) -160.04%, rgba(251, 174, 22, 0.1) -133.58%, rgba(249, 161, 24, 0.1) -98.31%, rgba(246, 138, 27, 0.1) -66.29%, rgba(242, 107, 32, 0.1) -39.83%, rgba(241, 94, 34, 0.1) -24.98%, rgba(242, 17, 150, 0.1) 101.73%, rgba(196, 48, 174, 0.1) 206.62%)',
      background:
        'linear-gradient(323.57deg, rgba(254, 179, 19, 0.25) -160.04%, rgba(251, 174, 22, 0.25) -133.58%, rgba(249, 161, 24, 0.25) -98.31%, rgba(246, 138, 27, 0.25) -66.29%, rgba(242, 107, 32, 0.25) -39.83%, rgba(241, 94, 34, 0.25) -24.98%, rgba(242, 17, 150, 0.25) 101.73%, rgba(196, 48, 174, 0.25) 206.62%)',
    },
    2: {
      border: theme.color.joyousDogOrange[200],
      selectedBackground:
        'linear-gradient(322.73deg, rgba(254, 179, 19, 0.1) -71.8%, rgba(251, 174, 22, 0.1) -47.85%, rgba(249, 161, 24, 0.1) -15.92%, rgba(246, 138, 27, 0.1) 13.07%, rgba(242, 107, 32, 0.1) 37.02%, rgba(241, 94, 34, 0.1) 50.46%, rgba(242, 17, 150, 0.1) 165.16%, rgba(196, 48, 174, 0.1) 260.12%)',
      background:
        'linear-gradient(322.73deg, rgba(254, 179, 19, 0.25) -71.8%, rgba(251, 174, 22, 0.25) -47.85%, rgba(249, 161, 24, 0.25) -15.92%, rgba(246, 138, 27, 0.25) 13.07%, rgba(242, 107, 32, 0.25) 37.02%, rgba(241, 94, 34, 0.25) 50.46%, rgba(242, 17, 150, 0.25) 165.16%, rgba(196, 48, 174, 0.25) 260.12%)',
    },
  },
  3: {
    0: {
      border: theme.color.microPigPink[200],
      selectedBackground:
        'linear-gradient(321.6deg, rgba(254, 179, 19, 0.1) -272.9%, rgba(251, 174, 22, 0.1) -244.09%, rgba(249, 161, 24, 0.1) -205.66%, rgba(246, 138, 27, 0.1) -170.78%, rgba(242, 107, 32, 0.1) -141.96%, rgba(241, 94, 34, 0.1) -125.78%, rgba(242, 17, 150, 0.1) 12.24%, rgba(196, 48, 174, 0.1) 126.49%)',
      background:
        'linear-gradient(321.6deg, rgba(254, 179, 19, 0.25) -272.9%, rgba(251, 174, 22, 0.25) -244.09%, rgba(249, 161, 24, 0.25) -205.66%, rgba(246, 138, 27, 0.25) -170.78%, rgba(242, 107, 32, 0.25) -141.96%, rgba(241, 94, 34, 0.25) -125.78%, rgba(242, 17, 150, 0.25) 12.24%, rgba(196, 48, 174, 0.25) 126.49%)',
    },
    1: {
      border: theme.color.microPigPink[200],
      selectedBackground:
        'linear-gradient(321.97deg, rgba(254, 179, 19, 0.1) -125.21%, rgba(251, 174, 22, 0.1) -106.35%, rgba(249, 161, 24, 0.1) -81.21%, rgba(246, 138, 27, 0.1) -58.38%, rgba(242, 107, 32, 0.1) -39.52%, rgba(241, 94, 34, 0.1) -28.93%, rgba(242, 17, 150, 0.1) 61.39%, rgba(196, 48, 174, 0.1) 136.16%)',
      background:
        'linear-gradient(321.97deg, rgba(254, 179, 19, 0.25) -125.21%, rgba(251, 174, 22, 0.25) -106.35%, rgba(249, 161, 24, 0.25) -81.21%, rgba(246, 138, 27, 0.25) -58.38%, rgba(242, 107, 32, 0.25) -39.52%, rgba(241, 94, 34, 0.25) -28.93%, rgba(242, 17, 150, 0.25) 61.39%, rgba(196, 48, 174, 0.25) 136.16%)',
    },
    2: {
      border: theme.color.joyousDogOrange[200],
      selectedBackground:
        'linear-gradient(321.23deg, rgba(254, 179, 19, 0.1) -75.42%, rgba(251, 174, 22, 0.1) -56.84%, rgba(249, 161, 24, 0.1) -32.06%, rgba(246, 138, 27, 0.1) -9.56%, rgba(242, 107, 32, 0.1) 9.02%, rgba(241, 94, 34, 0.1) 19.45%, rgba(242, 17, 150, 0.1) 108.46%, rgba(196, 48, 174, 0.1) 182.14%)',
      background:
        'linear-gradient(321.23deg, rgba(254, 179, 19, 0.25) -75.42%, rgba(251, 174, 22, 0.25) -56.84%, rgba(249, 161, 24, 0.25) -32.06%, rgba(246, 138, 27, 0.25) -9.56%, rgba(242, 107, 32, 0.25) 9.02%, rgba(241, 94, 34, 0.25) 19.45%, rgba(242, 17, 150, 0.25) 108.46%, rgba(196, 48, 174, 0.25) 182.14%)',
    },
    3: {
      border: theme.color.joyousDogOrange[200],
      selectedBackground:
        'linear-gradient(321.4deg, rgb(255, 247, 231) -65.04%, rgb(255, 247, 232) -37.57%, rgb(254, 246, 232) -0.95%, rgb(254, 243, 232) 32.3%, rgb(254, 240, 233) 59.77%, rgb(254, 239, 233) 75.19%, rgb(254, 231, 244) 206.73%, rgb(249, 234, 247) 315.64%, rgb(249, 234, 247) 340.16%)',
      background:
        'linear-gradient(321.4deg, rgb(255, 236, 196, 0.25) -65.04%, rgb(254, 235, 197) -37.57%, rgb(253, 231, 197) -0.95%, rgb(253, 226, 198) 32.3%, rgb(252, 218, 199) 59.77%, rgb(251, 215, 200) 75.19%, rgb(252, 195, 229) 206.73%, rgb(240, 203, 235) 315.64%, rgb(240, 203, 235) 340.16%)',
    },
  },
};
