import { useOpenHubSpotChat } from '@shared/frontend/hooks/use-open-hubspot-chat';
import React, { ComponentProps, ReactNode } from 'react';

import { ThemeColorScheme, styled, theme, transientOptions } from '../theme';
import { StyledIcon } from './base/styled-icon';
import { H1, getTypographyStyles } from './base/typography';
import { IconName } from './icons';

type ColorScheme = Extract<ThemeColorScheme, 'ducksBlue' | 'kereruPurple'>;

const colorMap: Record<
  ColorScheme,
  {
    iconColor: string;
    borderColor: string;
    linkColor: string;
  }
> = {
  ducksBlue: {
    iconColor: theme.color.ducksBlue[400],
    borderColor: theme.color.ducksBlue[50],
    linkColor: theme.color.ducksBlue[500],
  },
  kereruPurple: {
    iconColor: theme.color.kereruPurple[400],
    borderColor: theme.color.kereruPurple[50],
    linkColor: theme.color.kereruPurple[500],
  },
};

const Container = styled('div', transientOptions)<{
  $colorScheme: ColorScheme;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${theme.spacing.l};
  max-width: 70rem;
  padding: ${theme.spacing.l} ${theme.spacing.xl};
  background-color: ${theme.color.white};
  border-radius: ${theme.borderRadius.md};
  overflow-x: auto;
  border: ${({ $colorScheme }) => `0.1rem solid ${colorMap[$colorScheme].borderColor}`};

  @media (max-width: ${theme.breakpointPixels.sm}) {
    flex-direction: column;
    row-gap: ${theme.spacing.l};
  }
`;

const TextContainer = styled('div', transientOptions)<{ $colorScheme: ColorScheme }>`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.l};
  justify-content: center;
  ${getTypographyStyles({ $variant: 'H2' })}
  a {
    color: ${({ $colorScheme }) => colorMap[$colorScheme].linkColor};
  }
`;

type ClickableTextProps = {
  $colorScheme: ColorScheme;
};

export const ClickableText = styled(
  ({ children, ...restOfProps }: ClickableTextProps & ComponentProps<'span'>) => (
    <span {...restOfProps} role="button" tabIndex={0}>
      {children}
    </span>
  ),
  transientOptions,
)`
  cursor: pointer;
  color: ${({ $colorScheme }) => colorMap[$colorScheme].linkColor};
`;

export const ExternalLink = styled(
  ({ children, ...restOfProps }: ClickableTextProps & ComponentProps<'a'>) => (
    <a {...restOfProps} role="button" tabIndex={0} target="_blank" rel="noreferrer">
      {children}
    </a>
  ),
  transientOptions,
)`
  cursor: pointer;
  color: ${({ $colorScheme }) => colorMap[$colorScheme].linkColor};
`;

type InformationCardProps = {
  icon: IconName;
  title: string;
  $colorScheme: ColorScheme;
  children?: ReactNode;
};

export const InformationCard: React.FC<InformationCardProps> = ({
  icon,
  title,
  $colorScheme,
  children,
}: InformationCardProps) => {
  return (
    <Container $colorScheme={$colorScheme} data-cy="information-card">
      <StyledIcon iconName={icon} $size="15.0rem" $colorScheme={$colorScheme} />

      <TextContainer $colorScheme={$colorScheme}>
        <H1 $fontWeight="medium">{title}</H1>
        {children}
      </TextContainer>
    </Container>
  );
};

export const ChatAction = ({
  $colorScheme,
  children,
  ...restOfProps
}: ComponentProps<typeof ClickableText>) => {
  const [, openHubSpotChat] = useOpenHubSpotChat();

  return (
    <ClickableText
      $colorScheme={$colorScheme}
      as="span"
      onClick={openHubSpotChat}
      role="button"
      tabIndex={0}
      {...restOfProps}
    >
      {children ?? 'chat'}
    </ClickableText>
  );
};

const SUPPORT_EMAIL = 'support@joyoushq.com';

export const EmailAction = ({
  $colorScheme,
  children,
  ...restOfProps
}: ComponentProps<typeof ExternalLink>) => {
  return (
    <ExternalLink
      $colorScheme={$colorScheme}
      href={`mailto: ${SUPPORT_EMAIL}?subject=Help with Joyous`}
      {...restOfProps}
    >
      {children ?? 'email'}
    </ExternalLink>
  );
};
