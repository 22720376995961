import createSvgIcon from '@mui/material/utils/createSvgIcon';
import React from 'react';

export const ExpandIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(6, 2)">
      <path
        d="M6 20L0 14L1.425 12.575L6 17.15L10.575 12.575L12 14L6 20ZM1.45 7.4L0 6L6 0L12 6L10.55 7.4L6 2.85L1.45 7.4Z"
        fill="#1F78BA"
      />
    </g>
  </svg>,
  'CollapseIcon',
);
