import { TextTokens } from '@shared/text-tokens/utils';

export const CURRENT_ROUND_QUESTION_PREFIX = '_CURRENTROUND:';
export const PREVIOUS_ROUND_QUESTION_PREFIX = '_PREVIOUSROUND:';
export const JOYOUS_ORG_ID = 'bkilima6z';
export const JOYOUS_ENG_TEST_ORG_ID = 'V5IbtsDa47T261RL';
export const MAX_CAMPAIGN_NAME_LENGTH = 140;
export const MAX_EMAIL_FROM_NAME_LENGTH = 40;
export const MAX_EMAIL_SUBJECT_LENGTH = 998;

export const MAX_SMS_TEXT_LENGTH = 320;
export const URL_CHARACTER_LENGTH_BUFFER = 48;
export const URL_REGEX = /{{url}}/;

// https://stackoverflow.com/a/30145010
export const ONLY_ONE_URL_REGEX = /^(?:(?!{{url}}).)*{{url}}(?!.*{{url}}).*$/s;
export const ORG_NAME_INJECTION_PLACEHOLDER = new RegExp(`{{${TextTokens.OrgName}}}`, 'gi');

export const WS_EXPIRED_ACCESS_TOKEN_CODE = 3001;
export const WS_UNAUTHENTICATED_CODE = 4403;

// these dimensions are to ensure the pdf converted to pptx looks good
export const PDF_PAGE_DIMENSIONS = {
  width: 1280,
  height: 720,
};

// max allowable value for an int graphql tyle
export const MAX_GRAPHQL_INT = 2_147_483_647;

export const DEFAULT_NUDGE_TEXT = {
  emailSubject: 'It’s not too late to share your ideas!',
  emailText: `Hi {{${TextTokens.GivenName}}},

You still have time to share your ideas on the {{${TextTokens.CampaignName}}} campaign. Thanks to everyone who has responded so far.

For those who have not had a chance yet - share your ideas by responding to this week's question.`,
  smsText: `Hi {{${TextTokens.GivenName}}}, there’s still time to respond to the {{${TextTokens.CampaignName}}} campaign! Use this unique link to answer -

{{${TextTokens.Url}}}`,
  chatAppsText: `Hi {{${TextTokens.GivenName}}},

You still have time to share your ideas on the {{${TextTokens.CampaignName}}} campaign. Thanks to everyone who has responded so far.

For those who have not had a chance yet - share your ideas by responding to this week's question.

Use this unique link to answer - {{${TextTokens.Url}}} `,
};

export const MANY_CONVERSATIONS_UPDATED_SUB_NAME = 'MANY_CONVERSATIONS_UPDATED';

export const ORG_INDUSTRIES = [
  'Airline',
  'Communications',
  'General Contractor',
  'Healthcare',
  'Infrastructure',
  'Managed Services',
  'Retailer',
  'Utility',
  'Other',
] as const;

export const JOYOUS_AI_ID = 'AI facilitator';
export const JOYOUS_QUESTIONS_AUTHOR = 'joyous';

export const RATE_LIMITER_HEADER = 'joyous-authenticated';
