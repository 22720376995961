import { Avatar } from '@shared/frontend/components/avatar';
import { StyledIcon } from '@shared/frontend/components/base/styled-icon';
import { BodyUi } from '@shared/frontend/components/base/typography';
import { useConversationContext } from '@shared/frontend/components/conversation/conversation-context';
import { type ColorOptions } from '@shared/frontend/components/conversation/message/types';
import { useMessageProperties } from '@shared/frontend/components/conversation/message/use-message-properties';
import { MESSAGE_COMPONENT_MAP } from '@shared/frontend/components/conversation/message/widget-map-utils';
import { useLoggedInUser } from '@shared/frontend/components/logged-in-user-context';
import { type BorderRadii } from '@shared/frontend/components/message-bubble';
import { css, keyframes, styled, theme } from '@shared/frontend/theme';
import { getIsOrgUI } from '@shared/graphql/app-state';
import { isGraphQLComment, isSkippedGraphQLWidget } from '@shared/graphql/message-utils';
import React, { useCallback, type ForwardedRef } from 'react';

import MessageDetails from './message-details';
import { MessageSendingStatus } from './message-sending-status';

const expand = keyframes`
  0% {
    transform: scale(0.01);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

type ContainerProps = {
  $hasReactionMargin: boolean;
  $hasDetailsMargin: boolean;
  $isRight: boolean;
  $shouldExpand: boolean;
  $isConversationPrompt: boolean;
};

const MessageContainer = styled('div')<ContainerProps>`
  max-width: 100%;
  position: relative;
  padding: 0.2rem 0;
  row-gap: ${theme.spacing.xxs};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ $isRight }) => ($isRight ? 'margin-right: 3.2rem;' : 'margin-left: 3.2rem;')}
  ${({ $hasReactionMargin }) => ($hasReactionMargin ? 'margin-bottom: 1.4rem;' : '')}
  ${({ $hasDetailsMargin }) => ($hasDetailsMargin ? 'margin-top: 2rem;' : '')}
  align-self: ${({ $isRight }) => ($isRight ? 'flex-end' : 'flex-start')};
  ${({ $shouldExpand }) =>
    $shouldExpand
      ? css`
          animation: ${expand} 0.3s ease;
        `
      : ''}
  transform-origin: ${({ $isRight }) => ($isRight ? 'bottom right' : 'bottom left')};
  ${({ $isConversationPrompt }) => ($isConversationPrompt ? 'justify-content: center' : '')};
`;

type StyledAvatarProps = {
  $isRight: boolean;
  $colorOptions: ColorOptions;
  $isResponderMessage: boolean;
};

export const StyledAvatar = styled(Avatar)<StyledAvatarProps>`
  && {
    position: absolute;
    top: 0;
    right: ${({ $isRight }) => ($isRight ? '-3.2rem' : 'auto')};
    left: ${({ $isRight }) => ($isRight ? 'auto' : '-3.2rem')};
    margin: 0.3rem;
    margin-right: ${({ $isRight }) => ($isRight ? '0.2rem' : theme.spacing.xs)};
    margin-left: ${({ $isRight }) => ($isRight ? theme.spacing.xs : '0.2rem')};

    ${({ $isResponderMessage, $colorOptions }) =>
      $isResponderMessage
        ? `
          background: ${theme.color.ducksBlue[300]};
          color: ${theme.color.white};
        `
        : `
          background: ${$colorOptions.background};
          color: ${theme.text.primary};
        `}
  }
`;

const AICommentReportedCommentContainer = styled('div')`
  align-self: flex-start;
  padding: 0 ${theme.spacing.xs} 0 ${theme.spacing.s};
  display: flex;
  gap: ${theme.spacing.xs};
`;

type AICommentReportedMessageProps = {
  isMessageReported: boolean;
  isMessageFlagged: boolean;
  userIsJoyousAdmin: boolean;
};

const AICommentReportedMessage = ({
  isMessageReported,
  isMessageFlagged,
  userIsJoyousAdmin,
}: AICommentReportedMessageProps) => {
  // If message is flagged and user is admin, we always show the flagged message
  if (isMessageFlagged && userIsJoyousAdmin) {
    return (
      <AICommentReportedCommentContainer>
        <StyledIcon iconName="Flag" $size="1.6rem" $colorScheme="kereruPurple"></StyledIcon>
        <BodyUi $customColor={theme.color.kereruPurple[300]}>This comment has been flagged.</BodyUi>
      </AICommentReportedCommentContainer>
    );
  }

  // If message is reported and not flagged, we always show the flagged message
  // If reported and flagged and not joyous admin, show reported message
  const showReported =
    (isMessageReported && !isMessageFlagged) ||
    (isMessageReported && isMessageFlagged && !userIsJoyousAdmin);
  if (showReported) {
    return (
      <AICommentReportedCommentContainer>
        <StyledIcon iconName="ThumbDown" $size="1.6rem" $colorScheme="kereruPurple"></StyledIcon>
        <BodyUi $customColor={theme.color.kereruPurple[300]}>
          This comment has been reported, we'll take a look.
        </BodyUi>
      </AICommentReportedCommentContainer>
    );
  }

  return null;
};

type Props = {
  isSelected: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  borderRadii?: BorderRadii;
  groupLength: number;
  groupPosition: number;
  isBorderHighlighted: boolean;
};

const Message = React.forwardRef(
  (
    { isSelected, onClick, borderRadii, groupLength, groupPosition, isBorderHighlighted }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { setMessageInEditMode } = useConversationContext();
    const loggedInUser = useLoggedInUser();
    const {
      message,
      isRight,
      isClickable,
      isAuthorUser,
      isAuthorJoyous,
      isAuthorJoyousAI,
      isFirstMessage,
      isResponderMessage,
      isNewMessage,
      alwaysShowMessageDetails,
      colorOptions,
    } = useMessageProperties();

    const isAICommentReported =
      isAuthorJoyousAI && isGraphQLComment(message) && !!message.hasAIMessageBeenReported;
    const isAICommentFlagged =
      isAuthorJoyousAI && isGraphQLComment(message) && !!message.hasAIMessageBeenFlagged;

    const { type: messageType, reactions } = message;

    const isOrgUi = getIsOrgUI();
    // so the skip buttons cannot be edited in org ui`
    const editOnClick = isAuthorUser && isSkippedGraphQLWidget(message) && !isOrgUi;

    const hasReactionMargin = groupPosition < groupLength - 1 && !!reactions?.length;

    const MessageComponent = MESSAGE_COMPONENT_MAP[messageType];

    const handleMessageClick = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        if (editOnClick) {
          setMessageInEditMode(message);
        } else {
          onClick(event);
        }
      },
      [editOnClick, message, onClick, setMessageInEditMode],
    );

    if (!MessageComponent) {
      return null;
    }

    return (
      <MessageContainer
        $hasReactionMargin={hasReactionMargin}
        $hasDetailsMargin={alwaysShowMessageDetails || isSelected}
        $isRight={isRight}
        $shouldExpand={isNewMessage && isGraphQLComment(message)}
        $isConversationPrompt={message.type === 'CONVERSATION_PROMPT'}
        ref={ref}
        id={message._id}
        data-cy="message"
        data-dd-action-name="message"
      >
        {(alwaysShowMessageDetails || isSelected) && <MessageDetails />}
        <MessageComponent
          isSelected={isSelected}
          isBorderHighlighted={isBorderHighlighted}
          borderRadii={borderRadii}
          onClick={isClickable && !isSelected ? handleMessageClick : undefined}
        />
        <AICommentReportedMessage
          isMessageReported={isAICommentReported}
          isMessageFlagged={isAICommentFlagged}
          userIsJoyousAdmin={!!loggedInUser?.isJoyousAdmin}
        />
        {isFirstMessage && (
          <StyledAvatar
            $size="2.4rem"
            $isRight={isAuthorUser}
            $isResponderMessage={isResponderMessage}
            person={isAuthorJoyous || isAuthorJoyousAI ? null : message.author}
            $colorOptions={colorOptions}
          />
        )}
        <MessageSendingStatus
          messageId={message._id}
          isFirstMessage={isFirstMessage}
          messageGroupLength={groupLength}
          messageIndex={groupPosition}
        />
      </MessageContainer>
    );
  },
);

export default React.memo(Message);
