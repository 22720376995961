import JoybowLogoImage from '@frontend/assets/joybow-logo.png';
import { useIsExtraSmallResolution } from '@frontend/hooks/use-resolution';
import { styled, theme } from '@frontend/theme';
import { useSearch } from '@tanstack/react-router';
import React, { useState } from 'react';

import { MobileConversationHeader } from './mobile-conversation-header';
import { NewDeviceAddedNotification } from './new-device-added-notification';
import { TranslationPromptButton } from './translation-prompt-button';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 5.6rem;
  padding: ${theme.spacing.s};
  width: 100%;
  position: absolute;
  z-index: ${theme.zIndex.min};
  text-align: left;
  border: 0;
  ${theme.glassBlur};
`;

const JoybowLogo = styled('img')`
  height: 3rem;
  position: absolute;
  right: ${theme.spacing.m};
  top: 1.3rem;
  z-index: ${theme.zIndex.onTop};
`;

export const ConversationHeader = () => {
  const isMobileResolution = useIsExtraSmallResolution();
  const { newDevice } = useSearch({ from: '/AppLayout/conversations/$selectedConversationId' });

  const [isDeviceVerified, setIsDeviceVerified] = useState(!!newDevice);

  if (isMobileResolution) {
    return (
      <Wrapper>
        <MobileConversationHeader
          isDeviceVerified={isDeviceVerified}
          setIsDeviceVerified={setIsDeviceVerified}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <JoybowLogo src={JoybowLogoImage} alt="logo" />
      <TranslationPromptButton />
      <NewDeviceAddedNotification isOpen={isDeviceVerified} setIsOpen={setIsDeviceVerified} />
    </Wrapper>
  );
};
