import MuiBackdrop from '@mui/material/Backdrop';
import Grow from '@mui/material/Grow';
import MuiPopper, { type PopperProps } from '@mui/material/Popper';
import Portal from '@mui/material/Portal';
import { styled, theme } from '@shared/frontend/theme';
import React, { type RefObject, createContext, useCallback, useRef, useState } from 'react';

const TRANSITION = 'cubic-bezier(0.175, 0.885, 0.32, 1.275)';

// TODO: Fix types so it supports both MouseEvent and KeyboardEvent
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventType = any;

const Backdrop = styled(MuiBackdrop)`
  && {
    background-color: transparent;
    z-index: ${theme.zIndex.max};
  }
`;

const StyledMuiPopper = styled(MuiPopper)`
  && {
    z-index: ${theme.zIndex.max};
  }
`;

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.white};
  border-radius: ${theme.borderRadius.sm};
  border: ${theme.border};
  overflow: hidden;
  box-shadow: ${theme.shadows.medium};
`;

type PopperContextProps = {
  openPopper: () => void;
  closePopper: () => void;
};

const PopperContext = createContext<PopperContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openPopper: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closePopper: () => {},
});

const useReusablePopper = <TElement extends HTMLElement>() => {
  const [open, setOpen] = useState(false);
  const anchorElRef = useRef<null | TElement>(null);

  const openPopper = () => {
    setOpen(true);
  };

  const closePopper = () => {
    setOpen(false);
  };

  return {
    openPopper,
    closePopper,
    anchorElRef,
    open,
  };
};

type ReusablePopperProps<TElement> = Omit<PopperProps, 'children' | 'open' | 'anchorEl'> & {
  renderTriggerComponent: ({
    ref,
    isOpen,
    openPopper,
  }: {
    ref: RefObject<TElement>;
    isOpen: boolean;
    openPopper: (event: EventType) => void;
  }) => React.ReactElement;
  children:
    | (({
        closePopper,
        isOpen,
      }: {
        closePopper: () => void;
        isOpen: boolean;
      }) => React.ReactElement)
    | React.ReactElement;
  onOpen?: () => void;
  onClose?: () => void;
  open?: boolean; // This is used to forcefully open the popper without affecting the state
};

export const Popper = <TElement extends HTMLElement>({
  open: isOpen,
  children,
  renderTriggerComponent,
  onOpen,
  onClose,
  ...popperProps
}: ReusablePopperProps<TElement>) => {
  const { openPopper, closePopper, anchorElRef, open } = useReusablePopper<TElement>();

  const contextValue: PopperContextProps = {
    openPopper,
    closePopper,
  };

  const handleOpen = useCallback(
    (event: EventType) => {
      event.stopPropagation();

      if (onOpen) {
        onOpen();
      }

      // This is to prevent the popper from opening on the previous
      // position/location of the anchor
      setTimeout(() => {
        openPopper();
      }, 0);
    },
    [onOpen, openPopper],
  );

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    closePopper();
  }, [onClose, closePopper]);

  return (
    <PopperContext.Provider value={contextValue}>
      {renderTriggerComponent({
        ref: anchorElRef,
        isOpen: isOpen || open,
        openPopper: handleOpen,
      })}
      <Portal>
        <Backdrop
          open={isOpen || open}
          unmountOnExit
          onClick={(event) => {
            handleClose();
            event.stopPropagation();
          }}
        >
          <StyledMuiPopper
            {...popperProps}
            transition
            anchorEl={anchorElRef?.current}
            open={isOpen || open}
            onKeyDown={(event) => {
              if (event.key === 'Escape') {
                handleClose();
              }
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                easing={{ enter: TRANSITION, exit: TRANSITION }}
                timeout={300}
              >
                <div>
                  <ContentWrapper data-cy="popper-content">
                    {typeof children === 'function'
                      ? children({ closePopper, isOpen: open })
                      : children}
                  </ContentWrapper>
                </div>
              </Grow>
            )}
          </StyledMuiPopper>
        </Backdrop>
      </Portal>
    </PopperContext.Provider>
  );
};
