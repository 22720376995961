const fontFamily = 'Inter, Roboto, Helvetica, Arial, sans-serif';

const size = {
  xs: '1rem',
  sm: '1.2rem',
  md: '1.4rem',
  lg: '1.6rem',
  xl: '1.8rem',
  xxl: '2.0rem',
} as const;

const weight = {
  regular: 400,
  medium: 500,
  bold: 700,
} as const;

const style = {
  normal: 'normal',
  italic: 'italic',
};

const letterSpacing = {
  sm: '0.025rem',
  md: '0.05rem',
} as const;

const lineHeight = {
  xs: '1.2rem',
  sm: '1.4rem',
  md: '1.8rem',
  lg: '2.0rem',
  xl: '2.2rem',
} as const;

export const font = {
  size,
  weight,
  style,
  letterSpacing,
  lineHeight,
  fontFamily,
};
