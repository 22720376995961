import { gql } from '@apollo/client';

import conversationUpdatedFields from '../fragments/conversation-updated-fields';

const ON_CONVERSATION_UPDATED = gql`
  subscription onConversationUpdated($client: ClientType!) {
    conversationUpdated(client: $client) {
      ...conversationUpdatedFields
    }
  }
  ${conversationUpdatedFields}
`;

export default ON_CONVERSATION_UPDATED;
