import { gql } from '@apollo/client';

export const UPDATE_WORKER_NOTIFICATIONS_FOR_CONVERSATION = gql`
  mutation updateOwnerNotificationsForConversation(
    $conversationId: String!
    $viewStatus: NotificationViewStatus!
  ) {
    updateOwnerNotificationsForConversation(
      conversationId: $conversationId
      viewStatus: $viewStatus
    )
  }
`;

export const UPDATE_LEADER_NOTIFICATIONS_FOR_CONVERSATIONS = gql`
  mutation updateParticipantNotificationsForConversations(
    $conversationIds: [String!]!
    $viewStatus: NotificationViewStatus!
  ) {
    updateParticipantNotificationsForConversations(
      conversationIds: $conversationIds
      viewStatus: $viewStatus
    )
  }
`;
