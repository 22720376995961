// @ts-nocheck
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
};

export enum AifResponseRule {
  AcknowledgeAll = 'ACKNOWLEDGE_ALL',
  ClarificationOnly = 'CLARIFICATION_ONLY'
}

export type ActionPlanItem = {
  __typename?: 'ActionPlanItem';
  _id: Scalars['String']['output'];
  assigneeIds: Array<Scalars['String']['output']>;
  assigneesOnItem: Array<BasePerson>;
  createdBy: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  name: Scalars['String']['output'];
  source: ActionPlanItemSource;
  tasks: Array<ActionPlanTask>;
  themeId?: Maybe<Scalars['String']['output']>;
};

export type ActionPlanItemInput = {
  assigneeIds: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  tasks: Array<ActionPlanTaskInput>;
  themeId?: InputMaybe<Scalars['String']['input']>;
};

export enum ActionPlanItemSource {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

export type ActionPlanTask = {
  __typename?: 'ActionPlanTask';
  _id: Scalars['String']['output'];
  assigneeIds: Array<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['Float']['output']>;
  isHiddenToPerson?: Maybe<Scalars['Boolean']['output']>;
  state: TaskState;
  text: Scalars['String']['output'];
};

export type ActionPlanTaskInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  assigneeIds: Array<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Float']['input']>;
  text: Scalars['String']['input'];
};

export type ActionablePhrase = {
  __typename?: 'ActionablePhrase';
  accepted?: Maybe<Scalars['Boolean']['output']>;
  end: Scalars['Float']['output'];
  sortOrder?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  start: Scalars['Float']['output'];
  text: Scalars['String']['output'];
  themeId?: Maybe<Scalars['String']['output']>;
};

export type ActionablePhraseInput = {
  accepted?: InputMaybe<Scalars['Boolean']['input']>;
  end: Scalars['Float']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  source: Scalars['String']['input'];
  start: Scalars['Float']['input'];
  text: Scalars['String']['input'];
  themeId?: InputMaybe<Scalars['String']['input']>;
};

export type ActionablePhraseStat = {
  __typename?: 'ActionablePhraseStat';
  conversationId: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type ActionablePhrases = {
  __typename?: 'ActionablePhrases';
  phrases: Array<ActionablePhrase>;
};

export type ActionablePhrasesPerTheme = {
  __typename?: 'ActionablePhrasesPerTheme';
  actionablePhraseStats: Array<ActionablePhraseStat>;
  themeId: Scalars['String']['output'];
};

export enum ActionsToInclude {
  All = 'ALL',
  Planned = 'PLANNED',
  Suggested = 'SUGGESTED'
}

export type AddManualAttributeResponse = {
  __typename?: 'AddManualAttributeResponse';
  attributes: Array<AttributeOptions>;
};

export type AddPrefixFromColumnTransformation = BaseTransformation & {
  __typename?: 'AddPrefixFromColumnTransformation';
  prefixColumn: PrefixColumn;
  type: Scalars['String']['output'];
};

export type AddPrefixTransformation = BaseTransformation & {
  __typename?: 'AddPrefixTransformation';
  prefix: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum AiCommentQuality {
  Good = 'GOOD',
  Poor = 'POOR'
}

export type AiFacilitatorConfiguration = {
  __typename?: 'AiFacilitatorConfiguration';
  aifResponseRule: AifResponseRule;
  isAIFacilitatorEnabled: Scalars['Boolean']['output'];
  responseDelayInHours: Scalars['Int']['output'];
};

export type AiFacilitatorResponse = {
  __typename?: 'AiFacilitatorResponse';
  nextAIFacilitatorResponseTime?: Maybe<Scalars['Float']['output']>;
};

export type AiSettings = {
  __typename?: 'AiSettings';
  generativeAiProvider: GenerativeAiProvider;
  termToMaskForAIOrgName: Array<Scalars['String']['output']>;
};

export type AiSettingsInput = {
  generativeAiProvider: GenerativeAiProvider;
  termToMaskForAIOrgName: Scalars['String']['input'];
};

export type ApplyRelationships = {
  __typename?: 'ApplyRelationships';
  hierarchical: Scalars['Boolean']['output'];
  nonHierarchical: Scalars['Boolean']['output'];
};

export type Attribute = {
  __typename?: 'Attribute';
  name: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type AttributeBreakdown = {
  __typename?: 'AttributeBreakdown';
  isEnps?: Maybe<Scalars['Boolean']['output']>;
  rows: Array<AttributeBreakdownRow>;
};

export type AttributeBreakdownRow = {
  __typename?: 'AttributeBreakdownRow';
  attributeValue: Scalars['String']['output'];
  averageRating: Scalars['Float']['output'];
  negative: Scalars['Float']['output'];
  neutral: Scalars['Float']['output'];
  positive: Scalars['Float']['output'];
};

export type AttributeFieldConfiguration = {
  __typename?: 'AttributeFieldConfiguration';
  field: CsvColumnConfiguration;
  name: Scalars['String']['output'];
};

export type AttributeInput = {
  name: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type AttributeOnPerson = {
  __typename?: 'AttributeOnPerson';
  name: Scalars['String']['output'];
  value: Scalars['JSON']['output'];
};

export type AttributeOptions = {
  __typename?: 'AttributeOptions';
  _id: Scalars['String']['output'];
  options: Array<Scalars['String']['output']>;
};

export enum AttributeType {
  All = 'ALL',
  Filterable = 'FILTERABLE',
  Manual = 'MANUAL'
}

export type Audience = {
  __typename?: 'Audience';
  attributes?: Maybe<Array<Attribute>>;
  audienceType: AudienceType;
  distributionSettings?: Maybe<DistributionSettings>;
  fileName?: Maybe<Scalars['String']['output']>;
  personIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type AudienceInput = {
  attributes?: InputMaybe<Array<AttributeInput>>;
  audienceType: AudienceType;
  distributionSettings?: InputMaybe<DistributionSettingsInput>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  personIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AudienceNudge = {
  __typename?: 'AudienceNudge';
  _id: Scalars['String']['output'];
  audience: Scalars['String']['output'];
  completedSends: Array<CompletedSend>;
  conversationSchemeId: Scalars['String']['output'];
  emailText: Scalars['String']['output'];
  scheduledSend?: Maybe<Scalars['Float']['output']>;
  sendSettings: ReminderSendSettings;
  sentAudience?: Maybe<Scalars['Int']['output']>;
  status: ReminderSendStatus;
  type: ReminderTypes;
};

export type AudienceNudgeConfig = {
  __typename?: 'AudienceNudgeConfig';
  reminders: Array<AudienceNudge>;
  weekday?: Maybe<Weekday>;
};

export type AudienceThemesReport = {
  __typename?: 'AudienceThemesReport';
  _id: Scalars['String']['output'];
  actionsToInclude: ActionsToInclude;
  completedSend?: Maybe<Scalars['Float']['output']>;
  conversationSchemeId?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  scheduledSend: Scalars['Float']['output'];
  sendFromPersonId: Scalars['String']['output'];
  status: ThemesReportStatus;
  themesToBreakdown: Array<ThemeBreakdown>;
};

export type AudienceThemesReportInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  actionsToInclude: ActionsToInclude;
  message: Scalars['String']['input'];
  scheduledSend: Scalars['Float']['input'];
  sendFromPersonId: Scalars['String']['input'];
  themesToBreakdown: Array<ThemeBreakdownInput>;
};

export enum AudienceType {
  Attribute = 'ATTRIBUTE',
  Csv = 'CSV',
  Distributed = 'DISTRIBUTED',
  EmailList = 'EMAIL_LIST',
  Everybody = 'EVERYBODY'
}

export type AuthResponse = {
  __typename?: 'AuthResponse';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type BaseActionablePhrase = {
  __typename?: 'BaseActionablePhrase';
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export type BaseAnswerResponse = {
  __typename?: 'BaseAnswerResponse';
  conversationSchemeWidgetCount: Scalars['Float']['output'];
  isCampaignClosed?: Maybe<Scalars['Boolean']['output']>;
  nextMessageBlocks: Array<BaseMessage>;
  showEarlyAIFTypingIndicator?: Maybe<Scalars['Boolean']['output']>;
};

export type BaseMessage = {
  _id: Scalars['ID']['output'];
  author?: Maybe<Person>;
  authorId: Scalars['String']['output'];
  reactions?: Maybe<Array<ReactionOnMessage>>;
  type: Scalars['String']['output'];
  upsertTimestamps: Array<Scalars['Float']['output']>;
};

export type BasePerson = {
  __typename?: 'BasePerson';
  _id: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  pipIntegrationPersonId?: Maybe<Scalars['String']['output']>;
};

export type BaseTransformation = {
  type: Scalars['String']['output'];
};

export enum BlockAdvanceCondition {
  Response = 'RESPONSE',
  Traversal = 'TRAVERSAL'
}

export type BreakdownPageInput = {
  actionsPanel: Array<Scalars['String']['input']>;
  deepDiveTable: Array<Scalars['String']['input']>;
  footer: Scalars['String']['input'];
  moreActionsButton?: InputMaybe<Scalars['String']['input']>;
  moreThemesButton?: InputMaybe<Scalars['String']['input']>;
  themeHeading: Scalars['String']['input'];
  themeSummary?: InputMaybe<Scalars['String']['input']>;
};

export type BulkReplyNoCommentsResponse = {
  __typename?: 'BulkReplyNoCommentsResponse';
  author?: Maybe<Person>;
  updateConversationIdsToMessageIds: Scalars['JSON']['output'];
  updateTimestamp: Scalars['Float']['output'];
};

export type BulkReplyPreviewResponse = {
  __typename?: 'BulkReplyPreviewResponse';
  campaignName: Scalars['String']['output'];
  noCommentConversationsCount: Scalars['Int']['output'];
  suggestions: Array<Scalars['String']['output']>;
};

export type Campaign = {
  __typename?: 'Campaign';
  _id: Scalars['ID']['output'];
  activeDaysAfterFinalSend?: Maybe<Scalars['Float']['output']>;
  aiFacilitatorConfiguration: AiFacilitatorConfiguration;
  applyRelationships: ApplyRelationships;
  audience: Audience;
  audienceNudges: AudienceNudgeConfig;
  campaignHash: Scalars['String']['output'];
  closeDate?: Maybe<Scalars['Float']['output']>;
  contactMethods: Array<Scalars['String']['output']>;
  conversationSchemes: Array<ConversationScheme>;
  conversationsFileName?: Maybe<Scalars['String']['output']>;
  defaultChatAppsText: Scalars['String']['output'];
  defaultSendTime: Scalars['Float']['output'];
  defaultSmsText: Scalars['String']['output'];
  emailSettings: EmailSettings;
  estimatedROI: Scalars['Int']['output'];
  finalSend: Scalars['Float']['output'];
  firstSend: Scalars['Float']['output'];
  goal: Scalars['String']['output'];
  isClosed?: Maybe<Scalars['Boolean']['output']>;
  isSending: Scalars['Boolean']['output'];
  leads?: Maybe<Array<LeadsAndSponsors>>;
  leadsRelationship?: Maybe<CampaignRelationship>;
  multiLanguageConversationsEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  planningBoardId: Scalars['ID']['output'];
  primaryLocale: Scalars['String']['output'];
  respondersRelationship?: Maybe<CampaignRelationship>;
  roiHypothesis: Scalars['String']['output'];
  sponsors?: Maybe<Array<LeadsAndSponsors>>;
  sponsorsRelationship?: Maybe<CampaignRelationship>;
  spreadByAttribute?: Maybe<Scalars['String']['output']>;
  status: CampaignStatus;
  timezone: Scalars['String']['output'];
  weeklySummary: WeeklySummary;
};

export type CampaignActionPlan = {
  __typename?: 'CampaignActionPlan';
  actionPlanItems: Array<ActionPlanItem>;
};

export type CampaignActionPlanAndSuggestions = {
  __typename?: 'CampaignActionPlanAndSuggestions';
  actionPlanItemSuggestions: Array<ActionPlanItem>;
  actionPlanItems: Array<ActionPlanItem>;
};

export type CampaignActionPlanExportResponse = {
  __typename?: 'CampaignActionPlanExportResponse';
  exportBase64String: Scalars['String']['output'];
};

export type CampaignActionPlanSuggestions = {
  __typename?: 'CampaignActionPlanSuggestions';
  actionPlanItemSuggestions: Array<ActionPlanItem>;
};

export type CampaignAttributeStats = {
  __typename?: 'CampaignAttributeStats';
  attributeBreakdown: AttributeBreakdown;
  ratingQuestions: Array<RatingQuestion>;
};

export type CampaignDeliveryTypeContent = {
  __typename?: 'CampaignDeliveryTypeContent';
  conversationSchemeId: Scalars['String']['output'];
};

export type CampaignDeliveryTypeContentInput = {
  conversationSchemeId: Scalars['String']['input'];
};

export type CampaignDeliveryTypeMetadataInput = {
  campaignId: Scalars['String']['input'];
  conversationSchemeId: Scalars['String']['input'];
  type: DeliveryType;
};

export type CampaignDesignerSummaryExportResponse = {
  __typename?: 'CampaignDesignerSummaryExportResponse';
  exportBase64String: Scalars['String']['output'];
};

export type CampaignDetails = {
  __typename?: 'CampaignDetails';
  _id: Scalars['String']['output'];
  aiFacilitatorConfiguration: AiFacilitatorConfiguration;
  applyRelationships: ApplyRelationships;
  audience: Audience;
  audienceNudgeReminders?: Maybe<Array<AudienceNudge>>;
  audienceThemesReport?: Maybe<Array<AudienceThemesReport>>;
  closeDate?: Maybe<Scalars['Float']['output']>;
  contactMethods: Array<ContactMethod>;
  conversationSchemes: Array<ConversationSchemeDetails>;
  defaultSendTime: Scalars['Float']['output'];
  emailSettings: ReminderEmailSettings;
  finalSend: Scalars['Float']['output'];
  firstSend: Scalars['Float']['output'];
  hasBeenPublished: Scalars['Boolean']['output'];
  hasLiveTestOrDemoData: Scalars['Boolean']['output'];
  impactArea?: Maybe<Scalars['String']['output']>;
  isClosed: Scalars['Boolean']['output'];
  leads?: Maybe<Array<LeadsAndSponsors>>;
  name: Scalars['String']['output'];
  nextScheduledSend?: Maybe<Scalars['Float']['output']>;
  respondersRelationship?: Maybe<CampaignRelationship>;
  sponsors?: Maybe<Array<LeadsAndSponsors>>;
  status: DisplayStatus;
  themes: Array<ThemeMetadata>;
  themesHash: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  topic?: Maybe<Scalars['String']['output']>;
};

export type CampaignEngagementHeatmap = {
  __typename?: 'CampaignEngagementHeatmap';
  audienceMembersContacted: Array<EngagementHeatmapRow>;
  uniqueParticipantsCommentedAfterReply: Array<EngagementHeatmapRow>;
  uniqueParticipantsWithAIReply: Array<EngagementHeatmapRow>;
  uniqueParticipantsWithAnActionAfterReply: Array<EngagementHeatmapRow>;
  uniqueParticipantsWithAtLeast1ActionableComment: Array<EngagementHeatmapRow>;
  uniqueParticipantsWithAtLeast1Comment: Array<EngagementHeatmapRow>;
  uniqueParticipantsWithAtLeast1Rating: Array<EngagementHeatmapRow>;
  uniqueParticipantsWithFacilitatorReply: Array<EngagementHeatmapRow>;
  uniqueParticipantsWithHumanFacilitatorReply: Array<EngagementHeatmapRow>;
};

export type CampaignEngagementStats = {
  __typename?: 'CampaignEngagementStats';
  audienceCount: Scalars['Float']['output'];
  uniqueParticipantsWithAtLeast1ActionableComment: Scalars['Float']['output'];
  uniqueParticipantsWithAtLeast1Comment: Scalars['Float']['output'];
  uniqueParticipantsWithAtLeast1Rating: Scalars['Float']['output'];
};

export type CampaignHashResponse = {
  __typename?: 'CampaignHashResponse';
  campaignHash: Scalars['String']['output'];
};

export type CampaignImpactMetrics = {
  __typename?: 'CampaignImpactMetrics';
  impactMetrics: Array<ImpactMetric>;
};

export type CampaignRelationship = Relationship & {
  __typename?: 'CampaignRelationship';
  _id: Scalars['String']['output'];
  archived: Scalars['Boolean']['output'];
  campaignId: Scalars['String']['output'];
  campaignRelationshipType: CampaignRelationshipType;
  isManual?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<RelationshipMetadata>;
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  type: Scalars['String']['output'];
  visibilityMap?: Maybe<Array<CampaignVisibilityMapEntry>>;
};

export type CampaignRelationshipInput = {
  _id: Scalars['String']['input'];
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  campaignId: Scalars['String']['input'];
  campaignRelationshipType: CampaignRelationshipType;
  isMainReportingLine?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  visibilityMap?: InputMaybe<Array<CampaignRelationshipVisibilityMapEntry>>;
};

export enum CampaignRelationshipType {
  ActionItemAssignee = 'ACTION_ITEM_ASSIGNEE',
  Lead = 'LEAD',
  Responder = 'RESPONDER',
  Sponsor = 'SPONSOR'
}

export type CampaignRelationshipVisibilityMapEntry = {
  audienceSelector: Scalars['String']['input'];
  responders?: InputMaybe<Array<PersonId>>;
};

export type CampaignStats = {
  __typename?: 'CampaignStats';
  participationStats: ParticipationStats;
  questionRows: Array<QuestionRow>;
  ratingSummary?: Maybe<RatingSummary>;
  schemeToFeaturedComments?: Maybe<Array<SchemeToFeaturedComments>>;
  themeStats: Array<ThemeStats>;
};

export enum CampaignStatus {
  Confirmed = 'CONFIRMED',
  Planning = 'PLANNING',
  Proposed = 'PROPOSED'
}

export type CampaignThemesExportResponse = {
  __typename?: 'CampaignThemesExportResponse';
  exportBase64String: Scalars['String']['output'];
};

export type CampaignValidationErrors = {
  __typename?: 'CampaignValidationErrors';
  audience?: Maybe<Scalars['String']['output']>;
  context?: Maybe<Array<Scalars['String']['output']>>;
  responder?: Maybe<Scalars['String']['output']>;
  schemes?: Maybe<Array<CampaignValidationSchemeErrors>>;
};

export type CampaignValidationSchemeErrors = {
  __typename?: 'CampaignValidationSchemeErrors';
  errors: Array<Scalars['String']['output']>;
  schemeId: Scalars['String']['output'];
};

export type CampaignVisibilityMapEntry = {
  __typename?: 'CampaignVisibilityMapEntry';
  audienceSelector: Scalars['String']['output'];
  responders?: Maybe<Array<Person>>;
};

export type CampaignsInTimeRangeResponse = {
  __typename?: 'CampaignsInTimeRangeResponse';
  campaigns: Array<Campaign>;
};

export type Category = {
  __typename?: 'Category';
  _id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId?: Maybe<Scalars['String']['output']>;
  spectrumHigh?: Maybe<Scalars['String']['output']>;
  spectrumLow?: Maybe<Scalars['String']['output']>;
  topics?: Maybe<Array<Topic>>;
};

export enum ClientType {
  Org = 'ORG',
  TeamMember = 'TEAM_MEMBER'
}

export type ColumnName = {
  __typename?: 'ColumnName';
  displayName: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum ColumnsType {
  Attribute = 'ATTRIBUTE',
  DirectReports = 'DIRECT_REPORTS',
  TeamsBelowMe = 'TEAMS_BELOW_ME'
}

export type CommentBlock = {
  __typename?: 'CommentBlock';
  _id: Scalars['ID']['output'];
  nextBlock?: Maybe<NextBlock>;
  text: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type CommentPhraseInput = {
  _id: Scalars['ID']['input'];
  arePhrasesReviewed: Scalars['Boolean']['input'];
  conversationId: Scalars['ID']['input'];
  latestUpsertTimestamp: Scalars['Float']['input'];
  phrases: Array<ActionablePhraseInput>;
};

export type CommentPhrases = {
  __typename?: 'CommentPhrases';
  _id: Scalars['ID']['output'];
  arePhrasesReviewed: Scalars['Boolean']['output'];
  commentText: Scalars['String']['output'];
  conversationId: Scalars['ID']['output'];
  latestUpsertTimestamp: Scalars['Float']['output'];
  personId: Scalars['String']['output'];
  phrases: Array<ActionablePhrase>;
  questionText: Scalars['String']['output'];
};

export type CompletedSend = {
  __typename?: 'CompletedSend';
  initiatedSend: Scalars['Float']['output'];
  scheduled: Scalars['Float']['output'];
};

export enum ContactMethod {
  Email = 'email',
  GoogleChat = 'googleChat',
  MetaWorkplace = 'metaWorkplace',
  MicrosoftTeams = 'microsoftTeams',
  SftpLink = 'sftpLink',
  Slack = 'slack',
  Sms = 'sms'
}

export type Conversation = {
  __typename?: 'Conversation';
  _id: Scalars['ID']['output'];
  aiFacilitatorResponse?: Maybe<AiFacilitatorResponse>;
  attributes: Array<AttributeOnPerson>;
  campaignId: Scalars['String']['output'];
  chatParticipationStatus: Scalars['String']['output'];
  containsTranslations?: Maybe<Scalars['Boolean']['output']>;
  conversationSchemeId: Scalars['String']['output'];
  conversationSchemeWidgetCount: Scalars['Float']['output'];
  creationTime: Scalars['Float']['output'];
  featuredCommentCandidates?: Maybe<Array<FeaturedCommentCandidate>>;
  hasActionablePhrases: Scalars['Boolean']['output'];
  hasOwnerInteractions: Scalars['Boolean']['output'];
  isCampaignClosed?: Maybe<Scalars['Boolean']['output']>;
  isStarred: Scalars['Boolean']['output'];
  lastSignificantUpdateTime: Scalars['Float']['output'];
  latestQuestionResponseState: LatestQuestionResponseState;
  leaderCommentCount: Scalars['Float']['output'];
  messages: Array<BaseMessage>;
  orgId: Scalars['String']['output'];
  owner: Person;
  ownerId: Scalars['String']['output'];
  ownerUnseenNotificationCount: Scalars['Float']['output'];
  pipId?: Maybe<Scalars['String']['output']>;
  publishedThemeIds: Array<Scalars['String']['output']>;
  relationships?: Maybe<Array<RelationshipVisibility>>;
  suggestedResponses?: Maybe<SuggestedResponses>;
};

export type ConversationBlock = CommentBlock | ConversationPromptBlock | FilteredSelfSelectBlock | RatingBlock | SelectBlock | SelfSelectBlock | SwitchBlock;

export type ConversationComment = BaseMessage & {
  __typename?: 'ConversationComment';
  _id: Scalars['ID']['output'];
  actionablePhrases?: Maybe<ActionablePhrases>;
  author?: Maybe<Person>;
  authorId: Scalars['String']['output'];
  conversationSchemeBlockId?: Maybe<Scalars['String']['output']>;
  hasAIMessageBeenFlagged?: Maybe<Scalars['Boolean']['output']>;
  hasAIMessageBeenReported?: Maybe<Scalars['Boolean']['output']>;
  image?: Maybe<Image>;
  isSkip?: Maybe<Scalars['Boolean']['output']>;
  reactions?: Maybe<Array<ReactionOnMessage>>;
  text?: Maybe<Scalars['String']['output']>;
  themesReportId?: Maybe<Scalars['String']['output']>;
  translatedText?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  upsertTimestamps: Array<Scalars['Float']['output']>;
};

export type ConversationCsvRow = {
  blockType: Scalars['String']['input'];
  category: Scalars['String']['input'];
  chatAppsText: Scalars['String']['input'];
  emailSubject: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  excludeAttributes: Scalars['String']['input'];
  includeAttributes: Scalars['String']['input'];
  ratingHighLegendLabel: Scalars['String']['input'];
  ratingLowLegendLabel: Scalars['String']['input'];
  selectValues: Scalars['String']['input'];
  selfSelectAttribute: Scalars['String']['input'];
  selfSelectExcludePeopleWithExistingData?: InputMaybe<Scalars['Boolean']['input']>;
  selfSelectFilteredBy: Scalars['String']['input'];
  selfSelectInputPlaceholderText?: InputMaybe<Scalars['String']['input']>;
  selfSelectOptionsLookup?: InputMaybe<Array<OptionsLookup>>;
  selfSelectShowInOrgUi?: InputMaybe<Scalars['Boolean']['input']>;
  smsText: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  text: Scalars['String']['input'];
  topic: Scalars['String']['input'];
};

export type ConversationFetchFilters = {
  attributeFilters: Scalars['JSON']['input'];
  conversationFilters: QuestionFilters;
  directReportingLine: Scalars['Boolean']['input'];
  personIdsToInclude: Array<Scalars['String']['input']>;
  themeFilters: Scalars['JSON']['input'];
};

export type ConversationPrompt = BaseMessage & {
  __typename?: 'ConversationPrompt';
  _id: Scalars['ID']['output'];
  author?: Maybe<Person>;
  authorId: Scalars['String']['output'];
  conversationSchemeBlockId?: Maybe<Scalars['String']['output']>;
  metadata: Metadata;
  reactions?: Maybe<Array<ReactionOnMessage>>;
  status: MessageStatus;
  type: Scalars['String']['output'];
  upsertTimestamps: Array<Scalars['Float']['output']>;
};

export type ConversationPromptBlock = {
  __typename?: 'ConversationPromptBlock';
  _id: Scalars['ID']['output'];
  metadata: Metadata;
  nextBlock?: Maybe<NextBlock>;
  type: Scalars['String']['output'];
};

export type ConversationScheme = {
  __typename?: 'ConversationScheme';
  _id: Scalars['String']['output'];
  chatAppsText?: Maybe<Scalars['String']['output']>;
  completedSends: Array<CompletedSend>;
  conversationBlocks: Array<ConversationBlock>;
  emailSubject?: Maybe<Scalars['String']['output']>;
  scheduledSends: Array<Scalars['Float']['output']>;
  smsText?: Maybe<Scalars['String']['output']>;
  status: ConversationSchemeSendStatus;
};

export type ConversationSchemeDetails = {
  __typename?: 'ConversationSchemeDetails';
  conversationSchemeId: Scalars['String']['output'];
  finalSend: Scalars['Float']['output'];
  firstSend: Scalars['Float']['output'];
  questions: Array<Question>;
  status: DisplayStatus;
};

export type ConversationSchemeParticipationStats = {
  __typename?: 'ConversationSchemeParticipationStats';
  actionablePhraseCounts: Array<TimestampedCount>;
  audienceCount: Scalars['Int']['output'];
  conversationSchemeId: Scalars['String']['output'];
  conversationStats: Array<TimestampedCount>;
  newParticipantCount: Scalars['Int']['output'];
  ownerCommentActivity: Array<TimestampedCount>;
  participantCount: Scalars['Int']['output'];
};

export type ConversationSchemeSendInfo = {
  __typename?: 'ConversationSchemeSendInfo';
  conversationSchemeId: Scalars['String']['output'];
  isLargeAudience: Scalars['Boolean']['output'];
  sendInfo: Array<SendInfo>;
};

export enum ConversationSchemeSendStatus {
  Completed = 'COMPLETED',
  ErrorMissedWindow = 'ERROR_MISSED_WINDOW',
  Scheduled = 'SCHEDULED'
}

export type ConversationSubscriptionResponse = {
  __typename?: 'ConversationSubscriptionResponse';
  conversation: Conversation;
  deletedMessages: Array<MessageDeletion>;
  newMessages: Array<BaseMessage>;
  shouldApplyChangeImmediately?: Maybe<Scalars['Boolean']['output']>;
  updatedMessages: Array<MessageUpdate>;
};

export type CreateOrUpdateNudgeReminderArgs = {
  __typename?: 'CreateOrUpdateNudgeReminderArgs';
  audience: NudgeAudienceType;
  campaignId: Scalars['String']['output'];
  chatAppsText: Scalars['String']['output'];
  conversationSchemeId: Scalars['String']['output'];
  defaultSubject: Scalars['String']['output'];
  emailText: Scalars['String']['output'];
  reminderId?: Maybe<Scalars['String']['output']>;
  scheduledSend: Scalars['Float']['output'];
  smsText: Scalars['String']['output'];
};

export type CsvColumnConfiguration = {
  __typename?: 'CsvColumnConfiguration';
  csvColumn: Scalars['String']['output'];
  fieldConstraints?: Maybe<FieldConstraints>;
  transformations?: Maybe<Array<BaseTransformation>>;
};

export type CsvIntegration = {
  __typename?: 'CsvIntegration';
  bucketDirectoryName: Scalars['String']['output'];
  fields: FieldToCsvColumnConfiguration;
  isEnabled: Scalars['Boolean']['output'];
};

export type Cursor = {
  __typename?: 'Cursor';
  idFrom: Scalars['ID']['output'];
  lastMessageFrom: Scalars['Float']['output'];
};

export type DeleteCampaignResponse = {
  __typename?: 'DeleteCampaignResponse';
  _id: Scalars['String']['output'];
};

export type DeleteCommentResponse = {
  __typename?: 'DeleteCommentResponse';
  isCampaignClosed?: Maybe<Scalars['Boolean']['output']>;
  messageId: Scalars['String']['output'];
};

export type DeleteNudgeReminderArgs = {
  __typename?: 'DeleteNudgeReminderArgs';
  campaignId: Scalars['String']['output'];
  reminderId: Scalars['String']['output'];
};

export type DeleteTemplateResponse = {
  __typename?: 'DeleteTemplateResponse';
  categories: Array<TemplateCategory>;
  templateId: Scalars['String']['output'];
};

export enum DeliveryStatus {
  FailedNonRetriable = 'FAILED_NON_RETRIABLE',
  FailedRetriable = 'FAILED_RETRIABLE',
  Queued = 'QUEUED',
  Sending = 'SENDING',
  Success = 'SUCCESS',
  ToBeSent = 'TO_BE_SENT',
  Unknown = 'UNKNOWN'
}

export type DeliveryStatusInfo = {
  __typename?: 'DeliveryStatusInfo';
  count: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  statusIsErrorCode: Scalars['Boolean']['output'];
};

export enum DeliveryType {
  AdHoc = 'AD_HOC',
  Campaign = 'CAMPAIGN',
  LaunchReminder = 'LAUNCH_REMINDER',
  Notification = 'NOTIFICATION',
  PasswordReset = 'PASSWORD_RESET',
  Reminder = 'REMINDER',
  SetupPassword = 'SETUP_PASSWORD'
}

export type DesignerFormBlockRow = {
  _id: Scalars['ID']['input'];
  category?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  selfSelectAttributeName?: InputMaybe<Scalars['String']['input']>;
  showInOrgUi?: InputMaybe<Scalars['Boolean']['input']>;
  skipIfPersonHasAttribute?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  topic?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type DesignerFormScheme = {
  _id: Scalars['ID']['input'];
  blocks: Array<DesignerFormBlockRow>;
  chatAppsText: Scalars['String']['input'];
  emailSubject: Scalars['String']['input'];
  smsText: Scalars['String']['input'];
};

export enum DisplayStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Proposed = 'PROPOSED',
  Upcoming = 'UPCOMING'
}

export type DistributedAudienceExportResponse = {
  __typename?: 'DistributedAudienceExportResponse';
  exportBase64String: Scalars['String']['output'];
};

export enum DistributionMethod {
  Evenly = 'EVENLY',
  Proportionately = 'PROPORTIONATELY'
}

export type DistributionSettings = {
  __typename?: 'DistributionSettings';
  audienceQuantity: Scalars['Int']['output'];
  distributionAttribute: Scalars['String']['output'];
  distributionMethod: DistributionMethod;
  favorLeastRecentlyAsked: Scalars['Boolean']['output'];
  targetAudienceAttributes: Array<Attribute>;
};

export type DistributionSettingsInput = {
  audienceQuantity: Scalars['Int']['input'];
  distributionAttribute: Scalars['String']['input'];
  distributionMethod: DistributionMethod;
  favorLeastRecentlyAsked: Scalars['Boolean']['input'];
  targetAudienceAttributes: Array<AttributeInput>;
};

export type DownloadImpactMetricDocumentPresignedUrl = {
  __typename?: 'DownloadImpactMetricDocumentPresignedUrl';
  fileName: Scalars['String']['output'];
  presignedUrl: Scalars['String']['output'];
};

export type DownloadPeopleDataPresignedUrl = {
  __typename?: 'DownloadPeopleDataPresignedUrl';
  fileName: Scalars['String']['output'];
  presignedUrl: Scalars['String']['output'];
};

export type EditCommentTextResponse = {
  __typename?: 'EditCommentTextResponse';
  isCampaignClosed?: Maybe<Scalars['Boolean']['output']>;
  messageId: Scalars['String']['output'];
};

export type EditPlanningBoardInput = {
  _id: Scalars['ID']['input'];
  defaultROI: Scalars['Int']['input'];
  multiLanguageConversationsEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  primaryLocale: Scalars['String']['input'];
};

export type EmailSettings = {
  __typename?: 'EmailSettings';
  defaultSubject: Scalars['String']['output'];
  fromEmail: Scalars['String']['output'];
  fromName: Scalars['String']['output'];
  logoImageUrl?: Maybe<Scalars['String']['output']>;
  templateName: Scalars['String']['output'];
};

export type EmailSettingsInput = {
  fromName?: InputMaybe<Scalars['String']['input']>;
  logoImage?: InputMaybe<LogoImageInput>;
  templateName?: InputMaybe<Scalars['String']['input']>;
};

export type EngagementHeatmapRow = {
  __typename?: 'EngagementHeatmapRow';
  distinctOwnerIds?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Error = {
  __typename?: 'Error';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
};

export type ErrorResponse = {
  __typename?: 'ErrorResponse';
  error?: Maybe<Scalars['String']['output']>;
};

export type FeaturedComment = {
  __typename?: 'FeaturedComment';
  conversationId: Scalars['String']['output'];
  messageId: Scalars['String']['output'];
  phraseIndexes: Array<BaseActionablePhrase>;
  text: Scalars['String']['output'];
  themes: Array<ThemeMetadata>;
};

export type FeaturedCommentCandidate = {
  __typename?: 'FeaturedCommentCandidate';
  isPreferred?: Maybe<Scalars['Boolean']['output']>;
  messageId: Scalars['String']['output'];
};

export type FieldConstraints = {
  __typename?: 'FieldConstraints';
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  isUnique?: Maybe<Scalars['Boolean']['output']>;
};

export type FieldToCsvColumnConfiguration = {
  __typename?: 'FieldToCsvColumnConfiguration';
  attributes?: Maybe<Array<AttributeFieldConfiguration>>;
  commentingDisabled?: Maybe<CsvColumnConfiguration>;
  customerEmployeeId?: Maybe<CsvColumnConfiguration>;
  email: CsvColumnConfiguration;
  familyName: CsvColumnConfiguration;
  givenName: CsvColumnConfiguration;
  integrationPersonId: CsvColumnConfiguration;
  metaWorkplaceEmail?: Maybe<CsvColumnConfiguration>;
  metaWorkplaceId?: Maybe<CsvColumnConfiguration>;
  microsoftDisplayName?: Maybe<CsvColumnConfiguration>;
  microsoftId?: Maybe<CsvColumnConfiguration>;
  microsoftManagerId?: Maybe<CsvColumnConfiguration>;
  microsoftTeamsEmail?: Maybe<CsvColumnConfiguration>;
  mobilePhone?: Maybe<CsvColumnConfiguration>;
  preferredContactMethod?: Maybe<CsvColumnConfiguration>;
  slackEmail?: Maybe<CsvColumnConfiguration>;
  slackId?: Maybe<CsvColumnConfiguration>;
};

export type Filter = {
  __typename?: 'Filter';
  displayName: Scalars['String']['output'];
  hideCategory?: Maybe<Scalars['Boolean']['output']>;
  options: Array<FilterOption>;
  value: Scalars['String']['output'];
};

export type FilterOption = {
  __typename?: 'FilterOption';
  conversationSchemeIds?: Maybe<Array<Scalars['String']['output']>>;
  displayName: Scalars['String']['output'];
  firstSendTime?: Maybe<Scalars['Float']['output']>;
  hideCategory?: Maybe<Scalars['Boolean']['output']>;
  questionText?: Maybe<Array<Scalars['String']['output']>>;
  type?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type FilteredSelfSelectAnswer = BaseMessage & {
  __typename?: 'FilteredSelfSelectAnswer';
  _id: Scalars['ID']['output'];
  author?: Maybe<Person>;
  authorId: Scalars['String']['output'];
  conversationSchemeBlockId?: Maybe<Scalars['String']['output']>;
  filterAttributeName: Scalars['String']['output'];
  inputPlaceholderText?: Maybe<Scalars['String']['output']>;
  metadata: Metadata;
  optionsLookup: Array<FilteredSelfSelectOptionsLookup>;
  reactions?: Maybe<Array<ReactionOnMessage>>;
  selectedValue?: Maybe<Scalars['String']['output']>;
  selfSelectAttributeName: Scalars['String']['output'];
  status: MessageStatus;
  type: Scalars['String']['output'];
  upsertTimestamps: Array<Scalars['Float']['output']>;
};

export type FilteredSelfSelectBlock = {
  __typename?: 'FilteredSelfSelectBlock';
  _id: Scalars['ID']['output'];
  filterAttributeName: Scalars['String']['output'];
  metadata: Metadata;
  nextBlock?: Maybe<NextBlock>;
  selfSelectAttributeName: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type FilteredSelfSelectOptionsLookup = {
  __typename?: 'FilteredSelfSelectOptionsLookup';
  filterAttributeValue: Scalars['String']['output'];
  options: Array<Scalars['String']['output']>;
  translatedOptions?: Maybe<Array<Scalars['String']['output']>>;
};

export type Filters = {
  __typename?: 'Filters';
  attributeFilters: Array<Filter>;
  conversationFilters: Array<Filter>;
  themeFilters: Array<Filter>;
};

export type FinancialImpactMetric = {
  __typename?: 'FinancialImpactMetric';
  _id: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  documents: Array<ImpactMetricDocument>;
  financialRoi: Scalars['Float']['output'];
  hash: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  roiAchievedDate?: Maybe<Scalars['Float']['output']>;
  type: ImpactMetricType;
};

export type FindAndReplaceTransformation = BaseTransformation & {
  __typename?: 'FindAndReplaceTransformation';
  find: Scalars['String']['output'];
  replaceWith: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum GenerativeAiProvider {
  Google = 'GOOGLE',
  None = 'NONE',
  Openai = 'OPENAI'
}

export type GenericTransformation = BaseTransformation & {
  __typename?: 'GenericTransformation';
  type: Scalars['String']['output'];
};

export type GetCampaignThemesResponse = {
  __typename?: 'GetCampaignThemesResponse';
  themeState: ThemeState;
  themesHash: Scalars['String']['output'];
};

export type GetSsoInfoResponse = {
  __typename?: 'GetSsoInfoResponse';
  msParameters?: Maybe<MsParameters>;
  oktaParameters?: Maybe<OktaParameters>;
  ssoEnabled: Scalars['Boolean']['output'];
  ssoProvider?: Maybe<SsoProvider>;
};

export type HierarchicalPersonRelationship = Relationship & {
  __typename?: 'HierarchicalPersonRelationship';
  _id: Scalars['String']['output'];
  archived: Scalars['Boolean']['output'];
  identifierAttribute?: Maybe<Scalars['String']['output']>;
  isMainReportingLine?: Maybe<Scalars['Boolean']['output']>;
  isManual?: Maybe<Scalars['Boolean']['output']>;
  leaderIdentifierAttribute?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<RelationshipMetadata>;
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Image = {
  __typename?: 'Image';
  dialog?: Maybe<ImageObject>;
  natural?: Maybe<ImageObject>;
};

export type ImageObject = {
  __typename?: 'ImageObject';
  fileName?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type ImpactMetric = FinancialImpactMetric | OtherImpactMetric;

export type ImpactMetricDocument = {
  __typename?: 'ImpactMetricDocument';
  _id: Scalars['String']['output'];
  createdAt: Scalars['Float']['output'];
  createdBy: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
};

export type ImpactMetricDocumentInput = {
  _id: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type ImpactMetricDocumentPresignedUrlInput = {
  fileName: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
};

export type ImpactMetricInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  documents: Array<ImpactMetricDocumentInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  roi: ImpactMetricRoiInput;
  roiAchievedDate?: InputMaybe<Scalars['Float']['input']>;
  type: ImpactMetricType;
};

export type ImpactMetricRoiInput = {
  financial?: InputMaybe<Scalars['Float']['input']>;
  other?: InputMaybe<Scalars['String']['input']>;
};

export enum ImpactMetricType {
  Financial = 'FINANCIAL',
  Other = 'OTHER'
}

export type InformLeadsAndSponsorsAudienceCountResponse = {
  __typename?: 'InformLeadsAndSponsorsAudienceCountResponse';
  leadsCount: Scalars['Float']['output'];
  sponsorsCount: Scalars['Float']['output'];
};

export type IntegrationInstall = {
  __typename?: 'IntegrationInstall';
  kind: Scalars['String']['output'];
  link: Scalars['String']['output'];
};

export enum LatestQuestionResponseState {
  PartiallyResponded = 'PARTIALLY_RESPONDED',
  Responded = 'RESPONDED',
  Unresponded = 'UNRESPONDED'
}

export type LeadsAndSponsors = {
  __typename?: 'LeadsAndSponsors';
  _id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type LegendLabels = {
  __typename?: 'LegendLabels';
  high: Scalars['String']['output'];
  low: Scalars['String']['output'];
  translatedHigh?: Maybe<Scalars['String']['output']>;
  translatedLow?: Maybe<Scalars['String']['output']>;
};

export type LiveTestAudienceInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type LoggedInUserResponse = {
  __typename?: 'LoggedInUserResponse';
  autoTranslateConversationsEnabled?: Maybe<Scalars['Boolean']['output']>;
  user: Person;
};

export type LogoImageInput = {
  content: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type MagicTokenAuthResponse = {
  __typename?: 'MagicTokenAuthResponse';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  requiresDeviceVerification: Scalars['Boolean']['output'];
};

export type ManyConversationsUpdateSubscriptionResponse = {
  __typename?: 'ManyConversationsUpdateSubscriptionResponse';
  updates: Array<ConversationSubscriptionResponse>;
};

export type MatchingPerson = {
  __typename?: 'MatchingPerson';
  _id: Scalars['ID']['output'];
  integrationPersonId: Scalars['String']['output'];
};

export type MessageDeletion = {
  __typename?: 'MessageDeletion';
  _id: Scalars['ID']['output'];
  authorId: Scalars['ID']['output'];
};

export enum MessageStatus {
  Answered = 'ANSWERED',
  Posed = 'POSED',
  Skipped = 'SKIPPED'
}

export type MessageUpdate = {
  __typename?: 'MessageUpdate';
  _id: Scalars['ID']['output'];
  authorId: Scalars['ID']['output'];
  updatedMessageProperties: UpdatedMessageProperties;
};

export type Metadata = {
  __typename?: 'Metadata';
  category: Scalars['String']['output'];
  categoryId: Scalars['String']['output'];
  questionText: Scalars['String']['output'];
  topic?: Maybe<Scalars['String']['output']>;
};

export type MicrosoftGroup = {
  __typename?: 'MicrosoftGroup';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type MicrosoftGroupResponse = {
  __typename?: 'MicrosoftGroupResponse';
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type MicrosoftPeopleSyncIntegration = {
  __typename?: 'MicrosoftPeopleSyncIntegration';
  isEnabled: Scalars['Boolean']['output'];
};

export type MsParameters = {
  __typename?: 'MsParameters';
  clientId: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
};

export type MultiPageReportInput = {
  campaignOverviewCanvas: Scalars['String']['input'];
  campaignTimelineCanvas: Scalars['String']['input'];
  pages: Array<Pages>;
};

export type MutateGroupVisibilityResponse = {
  __typename?: 'MutateGroupVisibilityResponse';
  relationship?: Maybe<NonHierarchicalPersonRelationship>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptActionItemSuggestion?: Maybe<CampaignActionPlanAndSuggestions>;
  addComment: BaseAnswerResponse;
  addDevice?: Maybe<Scalars['Boolean']['output']>;
  addManualAttribute: AddManualAttributeResponse;
  addToGoogleDrive: Scalars['String']['output'];
  answerFilteredSelfSelect: SelfSelectResponse;
  answerRating?: Maybe<BaseAnswerResponse>;
  answerRatingWidgetViaPixel?: Maybe<ErrorResponse>;
  answerSelectMany: BaseAnswerResponse;
  answerSelectOne: BaseAnswerResponse;
  answerSelfSelect: SelfSelectResponse;
  applyTemplateToCampaign: Campaign;
  authenticateMicrosoftViaCode?: Maybe<Scalars['Boolean']['output']>;
  autoTheme: Scalars['Boolean']['output'];
  bulkReplyNoComments: BulkReplyNoCommentsResponse;
  createActionPlanItem: CampaignActionPlan;
  createCampaign: Campaign;
  createImpactMetric: CampaignImpactMetrics;
  createOrUpdateNudgeReminder: CampaignDetails;
  createPlanningBoard: PlanningBoard;
  deleteActionPlanItem?: Maybe<CampaignActionPlan>;
  deleteCampaign: DeleteCampaignResponse;
  deleteCampaignThemesReportMessage: CampaignDetails;
  deleteComment: DeleteCommentResponse;
  deleteImpactMetric?: Maybe<CampaignImpactMetrics>;
  deleteLiveTestData: Scalars['Boolean']['output'];
  deleteNudgeReminder: CampaignDetails;
  deleteSampleData: CampaignHashResponse;
  deleteTemplate: DeleteTemplateResponse;
  editActionPlanItem: CampaignActionPlan;
  editCommentText: EditCommentTextResponse;
  editImpactMetric: CampaignImpactMetrics;
  editPlanningBoards: Array<PlanningBoard>;
  flagAIComment: Scalars['String']['output'];
  generateSampleData: CampaignHashResponse;
  generateThemeSnapshot: Scalars['Boolean']['output'];
  ignoreActionItemSuggestion?: Maybe<CampaignActionPlanSuggestions>;
  passwordAuth: AuthResponse;
  passwordReset?: Maybe<Scalars['Boolean']['output']>;
  publishThemes: PublishThemesResponse;
  refreshAuth: AuthResponse;
  reportAIComment: Scalars['String']['output'];
  saveGroupVisibilityRelationship: MutateGroupVisibilityResponse;
  sendLiveTest: Scalars['Boolean']['output'];
  setPassword?: Maybe<PersonResponse>;
  setReaction: SetReactionResponse;
  setResponderIsViewingConversation?: Maybe<Scalars['Boolean']['output']>;
  setResponderTyping?: Maybe<Scalars['Boolean']['output']>;
  setupMicrosoftIntegration?: Maybe<Scalars['Boolean']['output']>;
  signOut: Scalars['Boolean']['output'];
  skipConversationPrompt: BaseAnswerResponse;
  toggleConversationStarredState: Scalars['Boolean']['output'];
  toggleFeatureComment: ToggleFeatureCommentResponse;
  updateActionPlanOrder?: Maybe<CampaignActionPlan>;
  updateActionPlanTaskCompletion: ActionPlanTask;
  updateBoardCards: UpdateBoardCardsResponse;
  updateCampaignAudience: UpdateCampaignAudienceResponse;
  updateCampaignContext: Campaign;
  updateCampaignResponderSettings: Campaign;
  updateCampaignStatus: UpdateCampaignStatusResponse;
  updateCampaignTheme: UpdateCampaignThemeResponse;
  updateCampaignThemes: UpdateCampaignThemesResponse;
  updateCampaignThemesReportMessage: CampaignDetails;
  updateCampaignTiming: UpdateCampaignTimingResponse;
  updateCategoryTemplateOrder: TemplateCategory;
  updateCommentPhrases: Array<Scalars['String']['output']>;
  updateConversationSchemeSendTimes: UpdateConversationSchemeSendTimesResponse;
  updateDeliveriesToResend: UpdateDeliveriesToResendResponse;
  updateOrgSettings: UpdateOrgSettingsResponse;
  updateOwnerNotificationsForConversation?: Maybe<Scalars['Boolean']['output']>;
  updateParticipantNotificationsForConversations?: Maybe<Scalars['Boolean']['output']>;
  updatePeopleIntegrationSettings: UpdatePeopleIntegrationSettingsResponse;
  updatePersonPreferredLocale: Scalars['String']['output'];
  updateSendMethods: UpdateSendMethodsResponse;
  uploadCampaignConversationSchemesCsv: UploadCampaignConversationSchemesCsvResponse;
  upsertConversationSchemes: Campaign;
  upsertTemplate: UpsertTemplateResponse;
};


export type MutationAcceptActionItemSuggestionArgs = {
  actionPlanItemSuggestionId: Scalars['String']['input'];
  campaignId: Scalars['String']['input'];
};


export type MutationAddCommentArgs = {
  commentText?: InputMaybe<Scalars['String']['input']>;
  conversationId: Scalars['ID']['input'];
  imageHeight?: InputMaybe<Scalars['Float']['input']>;
  imageURI?: InputMaybe<Scalars['String']['input']>;
  imageWidth?: InputMaybe<Scalars['Float']['input']>;
  newMessageId: Scalars['ID']['input'];
};


export type MutationAddDeviceArgs = {
  deviceId: Scalars['String']['input'];
  tokenId: Scalars['String']['input'];
};


export type MutationAddManualAttributeArgs = {
  attributeName: Scalars['String']['input'];
};


export type MutationAddToGoogleDriveArgs = {
  campaignId: Scalars['ID']['input'];
};


export type MutationAnswerFilteredSelfSelectArgs = {
  conversationId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
  option: Scalars['String']['input'];
};


export type MutationAnswerRatingArgs = {
  conversationId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
  rating?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationAnswerRatingWidgetViaPixelArgs = {
  conversationId: Scalars['ID']['input'];
  deviceId?: InputMaybe<Scalars['String']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['ID']['input'];
  personTokenId: Scalars['String']['input'];
  rating?: InputMaybe<Scalars['Int']['input']>;
  ua?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAnswerSelectManyArgs = {
  conversationId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationAnswerSelectOneArgs = {
  conversationId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
  option?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAnswerSelfSelectArgs = {
  conversationId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
  option: Scalars['String']['input'];
};


export type MutationApplyTemplateToCampaignArgs = {
  campaignId: Scalars['String']['input'];
  templateId: Scalars['String']['input'];
};


export type MutationAuthenticateMicrosoftViaCodeArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAutoThemeArgs = {
  campaignId: Scalars['ID']['input'];
  command: Scalars['String']['input'];
};


export type MutationBulkReplyNoCommentsArgs = {
  bulkReplyText: Scalars['String']['input'];
  campaignId: Scalars['ID']['input'];
  conversationSchemeId: Scalars['ID']['input'];
};


export type MutationCreateActionPlanItemArgs = {
  actionPlanItem: ActionPlanItemInput;
  campaignId: Scalars['String']['input'];
};


export type MutationCreateCampaignArgs = {
  boardId: Scalars['ID']['input'];
  campaignStatus: CampaignStatus;
  endDateString: Scalars['String']['input'];
  startDateString: Scalars['String']['input'];
  templateId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateImpactMetricArgs = {
  campaignId: Scalars['String']['input'];
  impactMetricInput: ImpactMetricInput;
};


export type MutationCreateOrUpdateNudgeReminderArgs = {
  audience: NudgeAudienceType;
  campaignId: Scalars['String']['input'];
  chatAppsText: Scalars['String']['input'];
  conversationSchemeId: Scalars['String']['input'];
  defaultSubject: Scalars['String']['input'];
  emailText: Scalars['String']['input'];
  reminderId?: InputMaybe<Scalars['String']['input']>;
  scheduledSend: Scalars['Float']['input'];
  smsText: Scalars['String']['input'];
};


export type MutationCreatePlanningBoardArgs = {
  name: Scalars['String']['input'];
};


export type MutationDeleteActionPlanItemArgs = {
  actionPlanItemHash: Scalars['String']['input'];
  actionPlanItemId: Scalars['String']['input'];
  campaignId: Scalars['String']['input'];
};


export type MutationDeleteCampaignArgs = {
  _id: Scalars['String']['input'];
};


export type MutationDeleteCampaignThemesReportMessageArgs = {
  audienceThemesReportId: Scalars['String']['input'];
  campaignId: Scalars['String']['input'];
};


export type MutationDeleteCommentArgs = {
  conversationId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
};


export type MutationDeleteImpactMetricArgs = {
  campaignId: Scalars['String']['input'];
  impactMetricHash: Scalars['String']['input'];
  impactMetricId: Scalars['String']['input'];
};


export type MutationDeleteLiveTestDataArgs = {
  campaignId: Scalars['String']['input'];
};


export type MutationDeleteNudgeReminderArgs = {
  campaignId: Scalars['String']['input'];
  reminderId: Scalars['String']['input'];
};


export type MutationDeleteSampleDataArgs = {
  campaignId: Scalars['String']['input'];
};


export type MutationDeleteTemplateArgs = {
  templateId: Scalars['String']['input'];
};


export type MutationEditActionPlanItemArgs = {
  actionPlanItem: ActionPlanItemInput;
  actionPlanItemHash: Scalars['String']['input'];
  actionPlanItemId: Scalars['String']['input'];
  campaignId: Scalars['String']['input'];
};


export type MutationEditCommentTextArgs = {
  commentText: Scalars['String']['input'];
  conversationId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
};


export type MutationEditImpactMetricArgs = {
  campaignId: Scalars['String']['input'];
  impactMetricHash: Scalars['String']['input'];
  impactMetricId: Scalars['String']['input'];
  impactMetricInput: ImpactMetricInput;
};


export type MutationEditPlanningBoardsArgs = {
  input: Array<EditPlanningBoardInput>;
};


export type MutationFlagAiCommentArgs = {
  conversationId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  quality: AiCommentQuality;
};


export type MutationGenerateSampleDataArgs = {
  campaignId: Scalars['String']['input'];
  conversationSchemeId: Scalars['String']['input'];
  promptContext: Scalars['String']['input'];
};


export type MutationGenerateThemeSnapshotArgs = {
  campaignId: Scalars['ID']['input'];
};


export type MutationIgnoreActionItemSuggestionArgs = {
  actionPlanItemSuggestionId: Scalars['String']['input'];
  campaignId: Scalars['String']['input'];
};


export type MutationPasswordAuthArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPasswordResetArgs = {
  email: Scalars['String']['input'];
};


export type MutationPublishThemesArgs = {
  campaignId: Scalars['String']['input'];
};


export type MutationRefreshAuthArgs = {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationReportAiCommentArgs = {
  conversationId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
};


export type MutationSaveGroupVisibilityRelationshipArgs = {
  input: SaveGroupVisibilityRelationshipInput;
};


export type MutationSendLiveTestArgs = {
  campaignId: Scalars['String']['input'];
  conversationSchemeId: Scalars['String']['input'];
  testAudience: Array<LiveTestAudienceInput>;
};


export type MutationSetPasswordArgs = {
  password: Scalars['String']['input'];
  userToken: Scalars['String']['input'];
};


export type MutationSetReactionArgs = {
  conversationId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
  reactionType?: InputMaybe<ReactionType>;
};


export type MutationSetResponderIsViewingConversationArgs = {
  conversationId: Scalars['ID']['input'];
  responderName: Scalars['String']['input'];
};


export type MutationSetResponderTypingArgs = {
  conversationId: Scalars['ID']['input'];
  responderName: Scalars['String']['input'];
};


export type MutationSetupMicrosoftIntegrationArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSignOutArgs = {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSkipConversationPromptArgs = {
  conversationId: Scalars['ID']['input'];
  conversationPromptMessageId: Scalars['ID']['input'];
  newMessageId: Scalars['ID']['input'];
};


export type MutationToggleConversationStarredStateArgs = {
  conversationId: Scalars['ID']['input'];
  isStarred: Scalars['Boolean']['input'];
};


export type MutationToggleFeatureCommentArgs = {
  conversationId: Scalars['ID']['input'];
  makeFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  messageId: Scalars['ID']['input'];
};


export type MutationUpdateActionPlanOrderArgs = {
  campaignId: Scalars['String']['input'];
  newActionPlanItemOrder: Array<Scalars['String']['input']>;
  prevActionPlanItemOrder: Array<Scalars['String']['input']>;
};


export type MutationUpdateActionPlanTaskCompletionArgs = {
  actionPlanItemId: Scalars['String']['input'];
  campaignId: Scalars['String']['input'];
  taskCompletionState: UpdateActionPlanTaskInput;
};


export type MutationUpdateBoardCardsArgs = {
  boardId: Scalars['ID']['input'];
  campaignId: Scalars['ID']['input'];
  updatedCampaignStatus?: InputMaybe<CampaignStatus>;
  updatedFirstSendDateMillis?: InputMaybe<Scalars['Float']['input']>;
  updatedSortedCampaignIds: Array<Scalars['ID']['input']>;
  usersTimezone: Scalars['String']['input'];
};


export type MutationUpdateCampaignAudienceArgs = {
  attributes?: InputMaybe<Array<AttributeInput>>;
  audienceType: AudienceType;
  campaignHash: Scalars['String']['input'];
  campaignId: Scalars['String']['input'];
  distributionSettings?: InputMaybe<DistributionSettingsInput>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  personIds?: InputMaybe<Array<Scalars['String']['input']>>;
  spreadByAttribute?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCampaignContextArgs = {
  _id: Scalars['String']['input'];
  campaignHash: Scalars['String']['input'];
  estimatedROI: Scalars['Int']['input'];
  goal: Scalars['String']['input'];
  leadsRelationship?: InputMaybe<CampaignRelationshipInput>;
  multiLanguageConversationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  planningBoardId: Scalars['String']['input'];
  primaryLocale?: InputMaybe<Scalars['String']['input']>;
  roiHypothesis: Scalars['String']['input'];
  shouldNotifyLeadsAndSponsors: Scalars['Boolean']['input'];
  sponsorsRelationship?: InputMaybe<CampaignRelationshipInput>;
};


export type MutationUpdateCampaignResponderSettingsArgs = {
  _id: Scalars['String']['input'];
  aifResponseRule: Scalars['String']['input'];
  campaignHash: Scalars['String']['input'];
  hierarchical: Scalars['Boolean']['input'];
  isAIFacilitatorEnabled: Scalars['Boolean']['input'];
  nonHierarchical: Scalars['Boolean']['input'];
  relationship?: InputMaybe<CampaignRelationshipInput>;
  responseDelayInHours: Scalars['Int']['input'];
  shouldNotifyResponders: Scalars['Boolean']['input'];
  weekday: Weekday;
};


export type MutationUpdateCampaignStatusArgs = {
  campaignHash: Scalars['String']['input'];
  campaignId: Scalars['String']['input'];
  status: Scalars['String']['input'];
};


export type MutationUpdateCampaignThemeArgs = {
  campaignId: Scalars['String']['input'];
  themeId: Scalars['String']['input'];
  themeName: Scalars['String']['input'];
  themeSummary: Scalars['String']['input'];
  themesHash: Scalars['String']['input'];
};


export type MutationUpdateCampaignThemesArgs = {
  campaignId: Scalars['ID']['input'];
  campaignThemeState: ThemeStateInput;
  themesHash: Scalars['String']['input'];
};


export type MutationUpdateCampaignThemesReportMessageArgs = {
  audienceThemesReport: AudienceThemesReportInput;
  campaignId: Scalars['String']['input'];
  file?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCampaignTimingArgs = {
  _id: Scalars['String']['input'];
  activeDaysAfterFinalSend?: InputMaybe<Scalars['Float']['input']>;
  autoNudgeDay?: InputMaybe<Weekday>;
  campaignHash: Scalars['String']['input'];
  defaultSendTime: Scalars['Float']['input'];
  timezone: Scalars['String']['input'];
};


export type MutationUpdateCategoryTemplateOrderArgs = {
  categoryId: Scalars['String']['input'];
  templateIds: Array<Scalars['String']['input']>;
};


export type MutationUpdateCommentPhrasesArgs = {
  campaignId: Scalars['String']['input'];
  commentPhrases: Array<CommentPhraseInput>;
};


export type MutationUpdateConversationSchemeSendTimesArgs = {
  campaignHash: Scalars['String']['input'];
  campaignId: Scalars['String']['input'];
  conversationSchemeId: Scalars['String']['input'];
  endDateString: Scalars['String']['input'];
  startDateString: Scalars['String']['input'];
};


export type MutationUpdateDeliveriesToResendArgs = {
  campaignId: Scalars['String']['input'];
  deliveryContentSelector: CampaignDeliveryTypeContentInput;
  deliveryType: DeliveryType;
  errorCode: Scalars['String']['input'];
  resendAmount: ResendAmount;
  smsText: Scalars['String']['input'];
};


export type MutationUpdateOrgSettingsArgs = {
  aiSettings: AiSettingsInput;
  autoTranslateConversationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  contactMethods: Array<Scalars['String']['input']>;
  deviceRegistrationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  multiLanguageConversationsEnabled: Scalars['Boolean']['input'];
  permissions: OrgPermissionsInput;
  primaryLocale: Scalars['String']['input'];
};


export type MutationUpdateOwnerNotificationsForConversationArgs = {
  conversationId: Scalars['String']['input'];
  viewStatus: NotificationViewStatus;
};


export type MutationUpdateParticipantNotificationsForConversationsArgs = {
  conversationIds: Array<Scalars['String']['input']>;
  viewStatus: NotificationViewStatus;
};


export type MutationUpdatePeopleIntegrationSettingsArgs = {
  attributes: Array<OrgAttributeInput>;
  coreData: PeopleIntegrationSettingsInput;
};


export type MutationUpdatePersonPreferredLocaleArgs = {
  preferredLocale: Scalars['String']['input'];
};


export type MutationUpdateSendMethodsArgs = {
  _id: Scalars['String']['input'];
  campaignHash: Scalars['String']['input'];
  contactMethods: Array<Scalars['String']['input']>;
  emailSettings: EmailSettingsInput;
};


export type MutationUploadCampaignConversationSchemesCsvArgs = {
  campaignHash: Scalars['String']['input'];
  campaignId: Scalars['String']['input'];
  conversationCsvRows: Array<ConversationCsvRow>;
  fileName: Scalars['String']['input'];
};


export type MutationUpsertConversationSchemesArgs = {
  campaignHash: Scalars['String']['input'];
  campaignId: Scalars['String']['input'];
  formSchemes: Array<DesignerFormScheme>;
};


export type MutationUpsertTemplateArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  categories: Array<Scalars['String']['input']>;
  color: TemplateColor;
  conversationSchemes: Array<DesignerFormScheme>;
  goal: Scalars['String']['input'];
  industry: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type NextBlock = {
  __typename?: 'NextBlock';
  advanceOn: BlockAdvanceCondition;
  next: Scalars['String']['output'];
};

export type NextBlockWithAudience = {
  __typename?: 'NextBlockWithAudience';
  advanceOn: BlockAdvanceCondition;
  audience: Scalars['JSON']['output'];
  next: Scalars['String']['output'];
};

export type NonHierarchicalPersonRelationship = Relationship & {
  __typename?: 'NonHierarchicalPersonRelationship';
  _id: Scalars['String']['output'];
  archived: Scalars['Boolean']['output'];
  identifierAttribute?: Maybe<Scalars['String']['output']>;
  isManual?: Maybe<Scalars['Boolean']['output']>;
  leaderIdentifierAttribute?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<RelationshipMetadata>;
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  type: Scalars['String']['output'];
  visibilityMap?: Maybe<Array<VisibilityMapEntry>>;
};

export type NonHierarchicalPersonVisibilityMap = {
  __typename?: 'NonHierarchicalPersonVisibilityMap';
  identifierValue: Scalars['String']['output'];
  leaderValues: Array<Scalars['String']['output']>;
};

export type NonHierarchicalPersonVisibilityMapInput = {
  identifierValue: Scalars['String']['input'];
  leaderValues: Array<Scalars['String']['input']>;
};

export enum NotificationViewStatus {
  Clicked = 'CLICKED',
  Seen = 'SEEN',
  Unseen = 'UNSEEN'
}

export enum NudgeAudienceType {
  CampaignUnresponded = 'CAMPAIGN_UNRESPONDED',
  ConversationScheme = 'CONVERSATION_SCHEME'
}

export type OktaParameters = {
  __typename?: 'OktaParameters';
  clientId: Scalars['String']['output'];
  oktaDomain: Scalars['String']['output'];
};

export type OnePageReportInput = {
  campaignSummaryCanvas: Scalars['String']['input'];
  questionDataCanvas: Scalars['String']['input'];
};

export type Option = {
  __typename?: 'Option';
  count: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type OptionsLookup = {
  filterAttributeValue: Scalars['String']['input'];
  options: Array<Scalars['String']['input']>;
};

export type Org = {
  __typename?: 'Org';
  _id: Scalars['ID']['output'];
  industry: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type OrgAttribute = {
  __typename?: 'OrgAttribute';
  isFilterable: Scalars['Boolean']['output'];
  isVisible: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export type OrgAttributeInput = {
  csvColumn: Scalars['String']['input'];
  isFilterable: Scalars['Boolean']['input'];
  isManual: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type OrgFilteredAttributeValue = {
  __typename?: 'OrgFilteredAttributeValue';
  name: Scalars['String']['output'];
  options: Array<Scalars['String']['output']>;
};

export type OrgInfo = {
  __typename?: 'OrgInfo';
  country: Scalars['String']['output'];
  email: Scalars['String']['output'];
  industry: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
};

export type OrgIntegrations = {
  __typename?: 'OrgIntegrations';
  csv?: Maybe<CsvIntegration>;
  microsoftPeopleSync?: Maybe<MicrosoftPeopleSyncIntegration>;
};

export type OrgNotificationSettings = {
  __typename?: 'OrgNotificationSettings';
  timezone: Scalars['String']['output'];
};

export type OrgPermissions = {
  __typename?: 'OrgPermissions';
  admin?: Maybe<Scalars['Boolean']['output']>;
  calendar?: Maybe<Scalars['Boolean']['output']>;
  dashboard?: Maybe<Scalars['Boolean']['output']>;
  feed?: Maybe<Scalars['Boolean']['output']>;
  participation?: Maybe<Scalars['Boolean']['output']>;
  planningBoard?: Maybe<Scalars['Boolean']['output']>;
};

export type OrgPermissionsInput = {
  calendar: Scalars['Boolean']['input'];
  dashboard: Scalars['Boolean']['input'];
  feed: Scalars['Boolean']['input'];
  participation: Scalars['Boolean']['input'];
  planningBoard: Scalars['Boolean']['input'];
};

export type OrgResponderSettingsResponse = {
  __typename?: 'OrgResponderSettingsResponse';
  hasHierarchicalRelationship: Scalars['Boolean']['output'];
  hasNonHierarchicalRelationship: Scalars['Boolean']['output'];
  isResponderOnly: Scalars['Boolean']['output'];
};

export type OrgSettings = {
  __typename?: 'OrgSettings';
  aiSettings: AiSettings;
  attributes: Array<Maybe<OrgAttribute>>;
  autoTranslateConversationsEnabled?: Maybe<Scalars['Boolean']['output']>;
  contactMethods?: Maybe<Array<Scalars['String']['output']>>;
  deviceRegistrationEnabled?: Maybe<Scalars['Boolean']['output']>;
  integrations?: Maybe<OrgIntegrations>;
  multiLanguageConversationsEnabled?: Maybe<Scalars['Boolean']['output']>;
  notifications: OrgNotificationSettings;
  permissions?: Maybe<OrgPermissions>;
  primaryLocale: Scalars['String']['output'];
};

export type OtherImpactMetric = {
  __typename?: 'OtherImpactMetric';
  _id: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  documents: Array<ImpactMetricDocument>;
  hash: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  otherRoi: Scalars['String']['output'];
  roiAchievedDate?: Maybe<Scalars['Float']['output']>;
  type: ImpactMetricType;
};

export type Pip = {
  __typename?: 'PIP';
  integrationPersonId?: Maybe<Scalars['String']['output']>;
};

export type Pages = {
  canvases: Array<Scalars['String']['input']>;
};

export type ParticipantNotification = {
  __typename?: 'ParticipantNotification';
  _id: Scalars['ID']['output'];
  actionType: ParticipantNotificationType;
  actor: Person;
  campaignId: Scalars['String']['output'];
  content: ParticipantNotificationContent;
  conversationId: Scalars['ID']['output'];
  conversationOwner: Person;
  creationTime: Scalars['Float']['output'];
  mergedIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  orgId: Scalars['ID']['output'];
  viewStatus: NotificationViewStatus;
};

export type ParticipantNotificationContent = {
  __typename?: 'ParticipantNotificationContent';
  commentHasImage?: Maybe<Scalars['Boolean']['output']>;
  commentText?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  reactionType?: Maybe<ReactionType>;
  selectedValue?: Maybe<Scalars['String']['output']>;
  selectedValues?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum ParticipantNotificationType {
  Comment = 'COMMENT',
  ParticipantComment = 'PARTICIPANT_COMMENT',
  ParticipantReaction = 'PARTICIPANT_REACTION',
  Rating_11 = 'RATING_11',
  Rating_11AndComment = 'RATING_11_AND_COMMENT',
  RatingEnps = 'RATING_ENPS',
  RatingEnpsAndComment = 'RATING_ENPS_AND_COMMENT',
  RatingManaOra = 'RATING_MANA_ORA',
  RatingManaOraAndComment = 'RATING_MANA_ORA_AND_COMMENT',
  Reaction = 'REACTION',
  SelectMany = 'SELECT_MANY',
  SelectManyAndComment = 'SELECT_MANY_AND_COMMENT',
  SelectOne = 'SELECT_ONE',
  SelectOneAndComment = 'SELECT_ONE_AND_COMMENT'
}

export type ParticipationHeatmap = {
  __typename?: 'ParticipationHeatmap';
  columnNames?: Maybe<Array<ColumnName>>;
  rows: Array<RowStats>;
};

export type ParticipationStats = {
  __typename?: 'ParticipationStats';
  campaignAudienceCount: Scalars['Int']['output'];
  campaignParticipantCount: Scalars['Int']['output'];
  conversationSchemeParticipationStats: Array<ConversationSchemeParticipationStats>;
};

export type PeopleIntegrationCoreDataCsvColumns = {
  email: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  integrationPersonId: Scalars['String']['input'];
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  preferredContactMethod?: InputMaybe<Scalars['String']['input']>;
};

export type PeopleIntegrationCoreDataFieldTransformations = {
  mobilePhonePrefix?: InputMaybe<Scalars['String']['input']>;
};

export type PeopleIntegrationSettings = {
  __typename?: 'PeopleIntegrationSettings';
  email: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  integrationPersonId: Scalars['String']['output'];
  managerId?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  preferredContactMethod?: Maybe<Scalars['String']['output']>;
};

export type PeopleIntegrationSettingsInput = {
  csvColumns: PeopleIntegrationCoreDataCsvColumns;
  fieldTransformations: PeopleIntegrationCoreDataFieldTransformations;
};

export type Permissions = {
  __typename?: 'Permissions';
  admin?: Maybe<Scalars['Boolean']['output']>;
  calendar?: Maybe<Scalars['Boolean']['output']>;
  calendarEdit?: Maybe<Scalars['Boolean']['output']>;
  calendarEditElevated?: Maybe<Scalars['Boolean']['output']>;
  commentingDisabled?: Maybe<Scalars['Boolean']['output']>;
  dashboard?: Maybe<Scalars['Boolean']['output']>;
  feed?: Maybe<Scalars['Boolean']['output']>;
  galleryEdit?: Maybe<Scalars['Boolean']['output']>;
  isFromExtendedLogin?: Maybe<Scalars['Boolean']['output']>;
  participation?: Maybe<Scalars['Boolean']['output']>;
  planningBoard?: Maybe<Scalars['Boolean']['output']>;
  planningBoardEdit?: Maybe<Scalars['Boolean']['output']>;
  pulse?: Maybe<Scalars['Boolean']['output']>;
  recentConversationId?: Maybe<Scalars['String']['output']>;
  teamMemberUIToken?: Maybe<Scalars['String']['output']>;
  theming?: Maybe<Scalars['Boolean']['output']>;
};

export type PermissionsOnPerson = {
  __typename?: 'PermissionsOnPerson';
  admin?: Maybe<Scalars['Boolean']['output']>;
  calendar?: Maybe<Scalars['Boolean']['output']>;
  calendarEdit?: Maybe<Scalars['Boolean']['output']>;
  calendarEditElevated?: Maybe<Scalars['Boolean']['output']>;
  commentingDisabled?: Maybe<Scalars['Boolean']['output']>;
  dashboard?: Maybe<Scalars['Boolean']['output']>;
  feed?: Maybe<Scalars['Boolean']['output']>;
  galleryEdit?: Maybe<Scalars['Boolean']['output']>;
  participation?: Maybe<Scalars['Boolean']['output']>;
  planningBoard?: Maybe<Scalars['Boolean']['output']>;
  planningBoardEdit?: Maybe<Scalars['Boolean']['output']>;
  theming?: Maybe<Scalars['Boolean']['output']>;
};

export type Person = {
  __typename?: 'Person';
  _id: Scalars['ID']['output'];
  archivableAfter?: Maybe<Scalars['Float']['output']>;
  archived: Scalars['Boolean']['output'];
  attributes: Array<AttributeOnPerson>;
  avatar?: Maybe<PersonAvatar>;
  computedEmail?: Maybe<Scalars['Boolean']['output']>;
  customerEmployeeId?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  hasFullVisibility?: Maybe<Scalars['Boolean']['output']>;
  isFromAudienceUpload?: Maybe<Scalars['Boolean']['output']>;
  isJoyousAdmin?: Maybe<Scalars['Boolean']['output']>;
  lastConversationSent?: Maybe<Scalars['Float']['output']>;
  manager?: Maybe<Person>;
  microsoftDisplayName?: Maybe<Scalars['String']['output']>;
  microsoftId?: Maybe<Scalars['String']['output']>;
  microsoftManagerId?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  permissions?: Maybe<PermissionsOnPerson>;
  pip?: Maybe<Pip>;
  preferredLocale?: Maybe<Scalars['String']['output']>;
  relationships: Array<RelationshipVisibility>;
  teamMemberUIToken?: Maybe<Scalars['String']['output']>;
};

export type PersonAvatar = {
  __typename?: 'PersonAvatar';
  initials: Scalars['String']['output'];
};

export type PersonId = {
  _id: Scalars['String']['input'];
};

export type PersonInScope = {
  __typename?: 'PersonInScope';
  _id: Scalars['ID']['output'];
  isDirectReport?: Maybe<Scalars['Boolean']['output']>;
  managerIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PersonMatchesResponse = {
  __typename?: 'PersonMatchesResponse';
  archivedPersonIdentifiers: Array<Scalars['String']['output']>;
  matchingPersons: Array<MatchingPerson>;
  notInJoyousIdentifiers: Array<Scalars['String']['output']>;
};

export type PersonResponse = {
  __typename?: 'PersonResponse';
  errors?: Maybe<Array<Maybe<Error>>>;
  person?: Maybe<Person>;
};

export type PersonSettings = {
  __typename?: 'PersonSettings';
  permissions?: Maybe<Permissions>;
};

export type PersonsIngestionStatus = {
  __typename?: 'PersonsIngestionStatus';
  _id: Scalars['String']['output'];
  campaignId?: Maybe<Scalars['String']['output']>;
  client: Scalars['String']['output'];
  events: Array<PersonsIngestionStatusEvent>;
  fieldId: Scalars['String']['output'];
  fileUploadedTimestamp?: Maybe<Scalars['String']['output']>;
  initiatedTimestamp: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  retriesLeft: Scalars['Int']['output'];
  status: Scalars['String']['output'];
};

export type PersonsIngestionStatusEvent = {
  __typename?: 'PersonsIngestionStatusEvent';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  source: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type PlanningBoard = {
  __typename?: 'PlanningBoard';
  _id: Scalars['ID']['output'];
  createdByUserId: Scalars['ID']['output'];
  defaultROI: Scalars['Int']['output'];
  multiLanguageConversationsEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  primaryLocale: Scalars['String']['output'];
  sortedCampaignIds: Array<Scalars['String']['output']>;
};

export type PlanningBoardCampaign = {
  __typename?: 'PlanningBoardCampaign';
  _id: Scalars['ID']['output'];
  estimatedROI: Scalars['Int']['output'];
  firstSend: Scalars['Float']['output'];
  goal: Scalars['String']['output'];
  leads?: Maybe<Array<LeadsAndSponsors>>;
  leadsRelationship?: Maybe<CampaignRelationship>;
  name: Scalars['String']['output'];
  roiHypothesis: Scalars['String']['output'];
  sponsors?: Maybe<Array<LeadsAndSponsors>>;
  sponsorsRelationship?: Maybe<CampaignRelationship>;
  status: CampaignStatus;
  timezone: Scalars['String']['output'];
};

export type PlanningBoardCampaignsResponse = {
  __typename?: 'PlanningBoardCampaignsResponse';
  campaigns: Array<PlanningBoardCampaign>;
  planningBoardId: Scalars['ID']['output'];
};

export type PlanningBoardExportResponse = {
  __typename?: 'PlanningBoardExportResponse';
  exportBase64String: Scalars['String']['output'];
};

export type PrefixColumn = {
  __typename?: 'PrefixColumn';
  csvColumn: Scalars['String']['output'];
};

export enum PrintFileType {
  Pdf = 'PDF',
  Pptx = 'PPTX',
  Xlsx = 'XLSX'
}

export type PublishThemesResponse = {
  __typename?: 'PublishThemesResponse';
  campaignHash: Scalars['String']['output'];
  campaignId: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  actionablePhrasesAllThemes: Array<ActionablePhrasesPerTheme>;
  actionablePhrasesPerTheme: Array<ActionablePhrasesPerTheme>;
  attributes: Array<Maybe<AttributeOptions>>;
  bulkReplyPreview: BulkReplyPreviewResponse;
  campaign?: Maybe<Campaign>;
  campaignActionPlan: CampaignActionPlan;
  campaignActionPlanExport: CampaignActionPlanExportResponse;
  campaignActionPlanSuggestions: CampaignActionPlanSuggestions;
  campaignAttributeStats: CampaignAttributeStats;
  campaignAudienceCount?: Maybe<Scalars['Float']['output']>;
  campaignDesignerSummaryExport: CampaignDesignerSummaryExportResponse;
  campaignDetails: CampaignDetails;
  campaignEngagementHeatmap: CampaignEngagementHeatmap;
  campaignEngagementStats: CampaignEngagementStats;
  campaignImpactMetrics: CampaignImpactMetrics;
  campaignRelationships: Array<Maybe<Relationship>>;
  campaignStats: CampaignStats;
  campaignThemesExport: CampaignThemesExportResponse;
  campaignsInTimeRange: CampaignsInTimeRangeResponse;
  campaignsTimezones: Array<Scalars['String']['output']>;
  categories: Array<Category>;
  conversationList: Array<Conversation>;
  conversationSchemeSendInfo: ConversationSchemeSendInfo;
  conversationWithParticipants?: Maybe<Conversation>;
  distributedAudienceExport: DistributedAudienceExportResponse;
  downloadImpactMetricDocumentPresignedUrl: DownloadImpactMetricDocumentPresignedUrl;
  downloadPeopleDataPresignedUrl: DownloadPeopleDataPresignedUrl;
  earliestCalendarDate: Scalars['Float']['output'];
  extendedSignIn?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Filters>;
  getCampaignThemes: GetCampaignThemesResponse;
  getCommentPhrases: Array<CommentPhrases>;
  getGroupVisibilityRelationship?: Maybe<NonHierarchicalPersonRelationship>;
  getNudgeAudienceCount: Scalars['Int']['output'];
  getOrgAttributeValues: Array<Scalars['String']['output']>;
  getOrgFilteredAttributeValues: Array<OrgFilteredAttributeValue>;
  getSsoInfo?: Maybe<GetSsoInfoResponse>;
  informLeadsAndSponsorsAudienceCount: InformLeadsAndSponsorsAudienceCountResponse;
  informRespondersAudienceCount: Scalars['Float']['output'];
  latestIntegrationStatus?: Maybe<PersonsIngestionStatus>;
  leaderNotifications?: Maybe<Array<ParticipantNotification>>;
  loggedInUser: LoggedInUserResponse;
  magicTokenAuth: MagicTokenAuthResponse;
  microsoftSso: AuthResponse;
  mostRecentPeopleDataFile?: Maybe<Scalars['String']['output']>;
  oktaSso: AuthResponse;
  org: Org;
  orgIntegrationInstall: Array<Maybe<IntegrationInstall>>;
  orgResponderSettings: OrgResponderSettingsResponse;
  participationHeatmap: ParticipationHeatmap;
  personMatches: PersonMatchesResponse;
  personSearch: Array<Person>;
  persons: Array<Person>;
  personsAttributeNames: Array<Scalars['String']['output']>;
  personsAttributeValues: Array<Scalars['String']['output']>;
  personsInScope?: Maybe<Array<Maybe<PersonInScope>>>;
  planningBoardCampaigns: PlanningBoardCampaignsResponse;
  planningBoardExport: PlanningBoardExportResponse;
  planningBoards: Array<PlanningBoard>;
  relationships: Array<Relationship>;
  reportExport: ReportExportResponse;
  reportingStats: ReportingStatsResponse;
  resendSmsStatus: ResendSmsStatusResponse;
  settings?: Maybe<Settings>;
  teamMemberConversations?: Maybe<TeamMemberConversationsResponse>;
  template?: Maybe<Template>;
  templateCategories: Array<TemplateCategory>;
  templates: Array<Template>;
  themesReportDownload: ThemesReportDownloadResponse;
  timelineCampaigns: TimelineCampaignsResponse;
  translate: Array<Scalars['String']['output']>;
  uploadImpactMetricDocumentsPresignedUrl: Array<UploadImpactMetricDocumentsPresignedUrlResponse>;
  uploadPeopleDataPresignedUrl: Scalars['String']['output'];
  verifyDevice: VerifyDeviceResponse;
  verifyMicrosoftGroup: MicrosoftGroupResponse;
  visibleConversationCountsByFilter: VisibleConversationCountsByFilterResponse;
  visibleConversations: VisibleConversationsResponse;
};


export type QueryActionablePhrasesAllThemesArgs = {
  filters?: InputMaybe<ConversationFetchFilters>;
};


export type QueryActionablePhrasesPerThemeArgs = {
  filters?: InputMaybe<ConversationFetchFilters>;
  themeIds: Array<Scalars['String']['input']>;
};


export type QueryAttributesArgs = {
  type?: InputMaybe<AttributeType>;
};


export type QueryBulkReplyPreviewArgs = {
  campaignId: Scalars['ID']['input'];
  conversationSchemeId: Scalars['ID']['input'];
};


export type QueryCampaignArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryCampaignActionPlanArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryCampaignActionPlanExportArgs = {
  campaignDetailsCanvas?: InputMaybe<Scalars['String']['input']>;
  campaignId: Scalars['String']['input'];
  canvasPages?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  fileType: PrintFileType;
  title: Scalars['String']['input'];
};


export type QueryCampaignActionPlanSuggestionsArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryCampaignAttributeStatsArgs = {
  attribute: Scalars['String']['input'];
  filters?: InputMaybe<ConversationFetchFilters>;
};


export type QueryCampaignAudienceCountArgs = {
  attributes?: InputMaybe<Array<AttributeInput>>;
  audienceType: AudienceType;
};


export type QueryCampaignDesignerSummaryExportArgs = {
  campaignDetailsCanvas: Scalars['String']['input'];
  designerCanvasPages: Array<Array<Scalars['String']['input']>>;
  fileType: PrintFileType;
  title: Scalars['String']['input'];
};


export type QueryCampaignDetailsArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryCampaignEngagementHeatmapArgs = {
  attribute: Scalars['String']['input'];
  campaignId: Scalars['String']['input'];
  filters?: InputMaybe<ConversationFetchFilters>;
};


export type QueryCampaignEngagementStatsArgs = {
  campaignId: Scalars['String']['input'];
  filters?: InputMaybe<ConversationFetchFilters>;
};


export type QueryCampaignImpactMetricsArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryCampaignRelationshipsArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryCampaignStatsArgs = {
  filters?: InputMaybe<ConversationFetchFilters>;
  timezone: Scalars['String']['input'];
};


export type QueryCampaignThemesExportArgs = {
  breakdownPages?: InputMaybe<Array<BreakdownPageInput>>;
  fileType: PrintFileType;
  linkToActions: Scalars['String']['input'];
  linkToThemes: Scalars['String']['input'];
  logoImageUrl?: InputMaybe<Scalars['String']['input']>;
  summaryPage: SummaryPageInput;
  title: Scalars['String']['input'];
};


export type QueryCampaignsInTimeRangeArgs = {
  endDate: Scalars['Float']['input'];
  startDate: Scalars['Float']['input'];
};


export type QueryConversationListArgs = {
  client: ClientType;
  conversationIds: Array<Scalars['ID']['input']>;
};


export type QueryConversationSchemeSendInfoArgs = {
  campaignId: Scalars['String']['input'];
  conversationSchemeId: Scalars['String']['input'];
};


export type QueryConversationWithParticipantsArgs = {
  _id: Scalars['ID']['input'];
  client: ClientType;
};


export type QueryDistributedAudienceExportArgs = {
  audience: AudienceInput;
  campaignId: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};


export type QueryDownloadImpactMetricDocumentPresignedUrlArgs = {
  campaignId: Scalars['String']['input'];
  documentUploadId: Scalars['String']['input'];
  impactMetricHash: Scalars['String']['input'];
  impactMetricId: Scalars['String']['input'];
};


export type QueryExtendedSignInArgs = {
  extendedIdentityEmail?: InputMaybe<Scalars['String']['input']>;
  extendedSignInAccessToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCampaignThemesArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryGetCommentPhrasesArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryGetGroupVisibilityRelationshipArgs = {
  attributeName: Scalars['String']['input'];
};


export type QueryGetNudgeAudienceCountArgs = {
  audience: NudgeAudienceType;
  campaignId: Scalars['String']['input'];
  conversationSchemeId: Scalars['String']['input'];
};


export type QueryGetOrgAttributeValuesArgs = {
  attributeName: Scalars['String']['input'];
};


export type QueryGetOrgFilteredAttributeValuesArgs = {
  attributeName: Scalars['String']['input'];
  filteredAttributeName: Scalars['String']['input'];
};


export type QueryGetSsoInfoArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInformLeadsAndSponsorsAudienceCountArgs = {
  campaignId: Scalars['String']['input'];
  leadsRelationship?: InputMaybe<CampaignRelationshipInput>;
  sponsorsRelationship?: InputMaybe<CampaignRelationshipInput>;
};


export type QueryInformRespondersAudienceCountArgs = {
  campaignId: Scalars['String']['input'];
  directResponderRelationship?: InputMaybe<CampaignRelationshipInput>;
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  nonHierarchical?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryLatestIntegrationStatusArgs = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  fileUploadedTimestamp?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryMagicTokenAuthArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMicrosoftSsoArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOktaSsoArgs = {
  idToken?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryParticipationHeatmapArgs = {
  attribute?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ConversationFetchFilters>;
  type: ColumnsType;
};


export type QueryPersonMatchesArgs = {
  areIdentifiersEmails?: InputMaybe<Scalars['Boolean']['input']>;
  personIdentifiers: Array<Scalars['String']['input']>;
};


export type QueryPersonSearchArgs = {
  personId?: InputMaybe<Scalars['String']['input']>;
  searchString: Scalars['String']['input'];
};


export type QueryPersonsArgs = {
  _ids: Array<Scalars['ID']['input']>;
};


export type QueryPersonsAttributeValuesArgs = {
  attributeName: Scalars['String']['input'];
};


export type QueryPlanningBoardCampaignsArgs = {
  planningBoardId: Scalars['ID']['input'];
};


export type QueryPlanningBoardExportArgs = {
  boardContentPages: Array<Array<Array<Scalars['String']['input']>>>;
  exportHeader: Scalars['String']['input'];
  fileType: PrintFileType;
  quarterHeaders: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};


export type QueryRelationshipsArgs = {
  _ids: Array<Scalars['String']['input']>;
};


export type QueryReportExportArgs = {
  reportSpecificInput: ReportSpecificInput;
  title: Scalars['String']['input'];
};


export type QueryReportingStatsArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryResendSmsStatusArgs = {
  deliveryTypeMetadata: CampaignDeliveryTypeMetadataInput;
};


export type QueryTeamMemberConversationsArgs = {
  conversationIdFrom?: InputMaybe<Scalars['ID']['input']>;
  conversationTimeFrom?: InputMaybe<Scalars['Float']['input']>;
  selectedConversationId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryTemplateArgs = {
  templateId: Scalars['ID']['input'];
};


export type QueryThemesReportDownloadArgs = {
  campaignId: Scalars['ID']['input'];
  conversationId?: InputMaybe<Scalars['ID']['input']>;
  themesReportId: Scalars['ID']['input'];
};


export type QueryTimelineCampaignsArgs = {
  selectedPlanningBoardId: Scalars['String']['input'];
};


export type QueryTranslateArgs = {
  texts: Array<Scalars['String']['input']>;
};


export type QueryUploadImpactMetricDocumentsPresignedUrlArgs = {
  campaignId: Scalars['String']['input'];
  documents: Array<ImpactMetricDocumentPresignedUrlInput>;
};


export type QueryUploadPeopleDataPresignedUrlArgs = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
  fileUploadedTimestamp?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryVerifyDeviceArgs = {
  deviceId: Scalars['String']['input'];
  tokenId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVerifyMicrosoftGroupArgs = {
  groupObjectId: Scalars['String']['input'];
};


export type QueryVisibleConversationCountsByFilterArgs = {
  filters?: InputMaybe<ConversationFetchFilters>;
};


export type QueryVisibleConversationsArgs = {
  filterKey?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ConversationFetchFilters>;
  idFrom?: InputMaybe<Scalars['ID']['input']>;
  lastMessageFrom?: InputMaybe<Scalars['Float']['input']>;
};

export type Question = {
  __typename?: 'Question';
  category: Scalars['String']['output'];
  conversationSchemeBlockId: Scalars['String']['output'];
  questionText: Scalars['String']['output'];
  topic?: Maybe<Scalars['String']['output']>;
  type: QuestionType;
};

export type QuestionFilters = {
  campaign?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  conversation?: InputMaybe<Array<Scalars['String']['input']>>;
  conversationSchemeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  conversationStarters?: InputMaybe<Array<Scalars['String']['input']>>;
  questionText?: InputMaybe<Array<Scalars['String']['input']>>;
  sentiment?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QuestionRow = RatingRow | SelectRow;

export enum QuestionType {
  ConversationPrompt = 'CONVERSATION_PROMPT',
  Rating_11 = 'RATING_11',
  RatingEnps = 'RATING_ENPS',
  RatingManaOra = 'RATING_MANA_ORA',
  SelectMany = 'SELECT_MANY',
  SelectOne = 'SELECT_ONE'
}

export type RatingAnswer = BaseMessage & {
  __typename?: 'RatingAnswer';
  _id: Scalars['ID']['output'];
  author?: Maybe<Person>;
  authorId: Scalars['String']['output'];
  conversationSchemeBlockId?: Maybe<Scalars['String']['output']>;
  legendLabels: LegendLabels;
  metadata: Metadata;
  ratingValue?: Maybe<RatingAnswerValue>;
  reactions?: Maybe<Array<ReactionOnMessage>>;
  status: MessageStatus;
  type: Scalars['String']['output'];
  upsertTimestamps: Array<Scalars['Float']['output']>;
};

export type RatingAnswerValue = {
  __typename?: 'RatingAnswerValue';
  rating: Scalars['Int']['output'];
  sentiment: Sentiment;
};

export type RatingBlock = {
  __typename?: 'RatingBlock';
  _id: Scalars['ID']['output'];
  legendLabels: LegendLabels;
  metadata: Metadata;
  nextBlock?: Maybe<NextBlock>;
  type: Scalars['String']['output'];
};

export type RatingQuestion = {
  __typename?: 'RatingQuestion';
  attributeBreakdown: AttributeBreakdown;
  conversationSchemeBlockId: Scalars['String']['output'];
};

export type RatingRow = {
  __typename?: 'RatingRow';
  conversationSchemeBlockId: Scalars['String']['output'];
  ratingSummary: RatingSummary;
  type: QuestionType;
};

export type RatingSummary = {
  __typename?: 'RatingSummary';
  answered: Scalars['Int']['output'];
  averageRating: Scalars['Float']['output'];
  isEnps?: Maybe<Scalars['Boolean']['output']>;
  negative: Scalars['Int']['output'];
  neutral: Scalars['Int']['output'];
  positive: Scalars['Int']['output'];
};

export type ReactionOnMessage = {
  __typename?: 'ReactionOnMessage';
  person: Person;
  personId: Scalars['String']['output'];
  type: ReactionType;
};

export enum ReactionType {
  ClappingHands = 'clapping_hands',
  GrinningSquintingFace = 'grinning_squinting_face',
  LightBulb = 'light_bulb',
  RedHeart = 'red_heart',
  SlightlySmilingFace = 'slightly_smiling_face',
  ThumbsDown = 'thumbs_down',
  ThumbsUp = 'thumbs_up'
}

export type Relationship = {
  _id: Scalars['String']['output'];
  archived: Scalars['Boolean']['output'];
  isManual?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<RelationshipMetadata>;
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type RelationshipMetadata = {
  __typename?: 'RelationshipMetadata';
  affectedUserCount: Scalars['Int']['output'];
};

export enum RelationshipType {
  CampaignRelationship = 'CampaignRelationship',
  HierarchicalPersonRelationship = 'HierarchicalPersonRelationship',
  NonHierarchicalPersonRelationship = 'NonHierarchicalPersonRelationship'
}

export type RelationshipVisibility = {
  __typename?: 'RelationshipVisibility';
  campaignId?: Maybe<Scalars['String']['output']>;
  isHierarchy?: Maybe<Scalars['Boolean']['output']>;
  isLeader?: Maybe<Scalars['Boolean']['output']>;
  relationship: Relationship;
  relationshipId: Scalars['String']['output'];
  type: Scalars['String']['output'];
  visibility: Array<Scalars['String']['output']>;
  visibilityPersons: Array<Person>;
};

export type ReminderEmailSettings = {
  __typename?: 'ReminderEmailSettings';
  defaultSubject: Scalars['String']['output'];
  fromEmail: Scalars['String']['output'];
  fromName: Scalars['String']['output'];
  logoImageUrl?: Maybe<Scalars['String']['output']>;
};

export type ReminderSendSettings = {
  __typename?: 'ReminderSendSettings';
  chatAppsText?: Maybe<Scalars['String']['output']>;
  emailSettings: ReminderEmailSettings;
  smsText?: Maybe<Scalars['String']['output']>;
};

export enum ReminderSendStatus {
  Completed = 'COMPLETED',
  ErrorMissedWindow = 'ERROR_MISSED_WINDOW',
  ErrorMultipleScheduled = 'ERROR_MULTIPLE_SCHEDULED',
  Scheduled = 'SCHEDULED'
}

export enum ReminderTypes {
  AudienceNudge = 'AUDIENCE_NUDGE',
  InformResponder = 'INFORM_RESPONDER'
}

export type ReportExportResponse = {
  __typename?: 'ReportExportResponse';
  reportBase64String: Scalars['String']['output'];
};

export type ReportSpecificInput = {
  multiPageReportInput?: InputMaybe<MultiPageReportInput>;
  onePageReportInput?: InputMaybe<OnePageReportInput>;
  reportType: ReportType;
  sourceReportInput?: InputMaybe<SourceReportInput>;
};

export enum ReportType {
  MultiPagePdf = 'MULTI_PAGE_PDF',
  MultiPagePptx = 'MULTI_PAGE_PPTX',
  OnePagePdf = 'ONE_PAGE_PDF',
  OnePagePptx = 'ONE_PAGE_PPTX',
  SourceData = 'SOURCE_DATA'
}

export type ReportingStatsResponse = {
  __typename?: 'ReportingStatsResponse';
  ownerCommentsCount: Scalars['Int']['output'];
  reviewedPhrasesCount: Scalars['Int']['output'];
  themesCount: Scalars['Int']['output'];
};

export enum ResendAmount {
  AllRemaining = 'ALL_REMAINING',
  One = 'ONE',
  Thirty = 'THIRTY'
}

export type ResendLog = {
  __typename?: 'ResendLog';
  errorCode: Scalars['String']['output'];
  initiatedTimestamp: Scalars['Float']['output'];
  resendAmount: Scalars['String']['output'];
  statuses: Array<StatusInfo>;
  template: Scalars['String']['output'];
};

export type ResendSmsStatusResponse = {
  __typename?: 'ResendSmsStatusResponse';
  overall: Array<StatusInfoWithTemplate>;
  pollingTimestamp: Scalars['Float']['output'];
  resendLog: Array<ResendLog>;
};

export type ResendStats = {
  __typename?: 'ResendStats';
  failed: Array<Scalars['String']['output']>;
  failedResendable: Array<Scalars['String']['output']>;
  queued: Array<Scalars['String']['output']>;
  success: Array<Scalars['String']['output']>;
};

export type ResponderIsTypingResponse = {
  __typename?: 'ResponderIsTypingResponse';
  conversationId: Scalars['ID']['output'];
  responderId: Scalars['ID']['output'];
  responderName: Scalars['String']['output'];
};

export type ResponderIsViewingConversationResponse = {
  __typename?: 'ResponderIsViewingConversationResponse';
  conversationId: Scalars['ID']['output'];
  responderId: Scalars['ID']['output'];
  responderName: Scalars['String']['output'];
};

export type RowStats = {
  __typename?: 'RowStats';
  columns: Scalars['JSON']['output'];
  count: Scalars['Int']['output'];
  orgCount: Scalars['Int']['output'];
  orgTotalCount?: Maybe<Scalars['Int']['output']>;
  rowType: RowType;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum RowType {
  AnsweredTeamMember = 'ANSWERED_TEAM_MEMBER',
  Chats = 'CHATS',
  ChatsResponders = 'CHATS_RESPONDERS',
  ChatsTeamMember = 'CHATS_TEAM_MEMBER',
  CommentedTeamMember = 'COMMENTED_TEAM_MEMBER',
  ConversationsStarted = 'CONVERSATIONS_STARTED'
}

export type SaveGroupVisibilityRelationshipInput = {
  attributeName: Scalars['String']['input'];
  relationshipId?: InputMaybe<Scalars['String']['input']>;
  visibilityMapInput: Array<NonHierarchicalPersonVisibilityMapInput>;
};

export type SchemeToFeaturedComments = {
  __typename?: 'SchemeToFeaturedComments';
  conversationSchemeId: Scalars['String']['output'];
  featuredComments: Array<FeaturedComment>;
};

export type SelectBlock = {
  __typename?: 'SelectBlock';
  _id: Scalars['ID']['output'];
  metadata: Metadata;
  nextBlock?: Maybe<NextBlock>;
  options: Array<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type SelectManyAnswer = BaseMessage & {
  __typename?: 'SelectManyAnswer';
  _id: Scalars['ID']['output'];
  author?: Maybe<Person>;
  authorId: Scalars['String']['output'];
  conversationSchemeBlockId?: Maybe<Scalars['String']['output']>;
  metadata: Metadata;
  options: Array<Scalars['String']['output']>;
  reactions?: Maybe<Array<ReactionOnMessage>>;
  selectedValues?: Maybe<Array<Scalars['String']['output']>>;
  status: MessageStatus;
  translatedOptions?: Maybe<Array<Scalars['String']['output']>>;
  type: Scalars['String']['output'];
  upsertTimestamps: Array<Scalars['Float']['output']>;
};

export type SelectOneAnswer = BaseMessage & {
  __typename?: 'SelectOneAnswer';
  _id: Scalars['ID']['output'];
  author?: Maybe<Person>;
  authorId: Scalars['String']['output'];
  conversationSchemeBlockId?: Maybe<Scalars['String']['output']>;
  metadata: Metadata;
  options: Array<Scalars['String']['output']>;
  reactions?: Maybe<Array<ReactionOnMessage>>;
  selectedValue?: Maybe<Scalars['String']['output']>;
  status: MessageStatus;
  translatedOptions?: Maybe<Array<Scalars['String']['output']>>;
  type: Scalars['String']['output'];
  upsertTimestamps: Array<Scalars['Float']['output']>;
};

export type SelectRow = {
  __typename?: 'SelectRow';
  conversationSchemeBlockId: Scalars['String']['output'];
  options: Array<Option>;
  type: QuestionType;
};

export type SelfSelectAnswer = BaseMessage & {
  __typename?: 'SelfSelectAnswer';
  _id: Scalars['ID']['output'];
  author?: Maybe<Person>;
  authorId: Scalars['String']['output'];
  conversationSchemeBlockId?: Maybe<Scalars['String']['output']>;
  inputPlaceholderText?: Maybe<Scalars['String']['output']>;
  isHiddenInOrgUi?: Maybe<Scalars['Boolean']['output']>;
  metadata: Metadata;
  options: Array<Scalars['String']['output']>;
  reactions?: Maybe<Array<ReactionOnMessage>>;
  selectedValue?: Maybe<Scalars['String']['output']>;
  selfSelectAttributeName: Scalars['String']['output'];
  status: MessageStatus;
  translatedOptions?: Maybe<Array<Scalars['String']['output']>>;
  type: Scalars['String']['output'];
  upsertTimestamps: Array<Scalars['Float']['output']>;
};

export type SelfSelectBlock = {
  __typename?: 'SelfSelectBlock';
  _id: Scalars['ID']['output'];
  isHiddenInOrgUi?: Maybe<Scalars['Boolean']['output']>;
  metadata: Metadata;
  nextBlock?: Maybe<NextBlock>;
  options: Array<Scalars['String']['output']>;
  selfSelectAttributeName: Scalars['String']['output'];
  skipIfPersonHasAttribute?: Maybe<Scalars['Boolean']['output']>;
  type: Scalars['String']['output'];
};

export type SelfSelectResponse = {
  __typename?: 'SelfSelectResponse';
  conversationSchemeWidgetCount: Scalars['Float']['output'];
  isCampaignClosed?: Maybe<Scalars['Boolean']['output']>;
  nextMessageBlocks: Array<BaseMessage>;
  updatedAttributes: Array<AttributeOnPerson>;
};

export type SendInfo = {
  __typename?: 'SendInfo';
  initiatedSend?: Maybe<Scalars['Float']['output']>;
  scheduled: Scalars['Float']['output'];
  sendsByDeliveryMethod?: Maybe<Array<SendsByDeliveryMethod>>;
};

export type SendsByDeliveryMethod = {
  __typename?: 'SendsByDeliveryMethod';
  count: Scalars['Int']['output'];
  deliveryMethod: Scalars['String']['output'];
  deliveryStatusInfo?: Maybe<Array<DeliveryStatusInfo>>;
};

export enum Sentiment {
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE'
}

export type SetReactionResponse = {
  __typename?: 'SetReactionResponse';
  isCampaignClosed?: Maybe<Scalars['Boolean']['output']>;
  messageId: Scalars['String']['output'];
};

export type Settings = {
  __typename?: 'Settings';
  orgSettings?: Maybe<OrgSettings>;
  userSettings?: Maybe<PersonSettings>;
};

export type SourceReportInput = {
  campaignId: Scalars['String']['input'];
  selectedAttribute?: InputMaybe<Scalars['String']['input']>;
};

export enum SsoProvider {
  MicrosoftAuthorizationCode = 'MICROSOFT_AUTHORIZATION_CODE',
  MicrosoftImplicitGrant = 'MICROSOFT_IMPLICIT_GRANT',
  Okta = 'OKTA'
}

export type StatusInfo = {
  __typename?: 'StatusInfo';
  count: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  statusIsErrorCode: Scalars['Boolean']['output'];
};

export type StatusInfoWithTemplate = {
  __typename?: 'StatusInfoWithTemplate';
  count: Scalars['Int']['output'];
  latestPayloadTemplate?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  statusIsErrorCode: Scalars['Boolean']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  conversationUpdated: ConversationSubscriptionResponse;
  manyConversationsUpdated: ManyConversationsUpdateSubscriptionResponse;
  responderIsTyping: ResponderIsTypingResponse;
  responderIsViewingConversation: ResponderIsViewingConversationResponse;
};


export type SubscriptionConversationUpdatedArgs = {
  client: ClientType;
};


export type SubscriptionManyConversationsUpdatedArgs = {
  client: ClientType;
};


export type SubscriptionResponderIsTypingArgs = {
  subscribedConversationId: Scalars['ID']['input'];
};


export type SubscriptionResponderIsViewingConversationArgs = {
  subscribedConversationId: Scalars['ID']['input'];
};

export type SuggestedResponses = {
  __typename?: 'SuggestedResponses';
  lastUpdateTime: Scalars['Float']['output'];
  suggestions: Array<Scalars['String']['output']>;
};

export type SummaryPageInput = {
  actionPlan: Array<Scalars['String']['input']>;
  campaignDetails: Scalars['String']['input'];
  campaignThemes: Array<Scalars['String']['input']>;
  engagement: Scalars['String']['input'];
  footer: Scalars['String']['input'];
  moreActionsButton?: InputMaybe<Scalars['String']['input']>;
  moreThemesButton?: InputMaybe<Scalars['String']['input']>;
};

export type SwitchBlock = {
  __typename?: 'SwitchBlock';
  _id: Scalars['ID']['output'];
  nextBlocks: Array<NextBlockWithAudience>;
  type: Scalars['String']['output'];
};

export enum TaskState {
  Completed = 'COMPLETED',
  Ignored = 'IGNORED',
  Pending = 'PENDING',
  Suggested = 'SUGGESTED'
}

export type TeamMemberConversationsResponse = {
  __typename?: 'TeamMemberConversationsResponse';
  conversations: Array<Conversation>;
  hasMoreToLoad: Scalars['Boolean']['output'];
};

export type Template = {
  __typename?: 'Template';
  _id: Scalars['String']['output'];
  categories: Array<Scalars['String']['output']>;
  color: TemplateColor;
  conversationSchemes: Array<TemplateScheme>;
  creationTime: Scalars['Float']['output'];
  goal: Scalars['String']['output'];
  industry: Array<Scalars['String']['output']>;
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
};

export type TemplateCategory = {
  __typename?: 'TemplateCategory';
  _id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sortPosition: Scalars['Float']['output'];
  sortedTemplateIds: Array<Scalars['String']['output']>;
};

export enum TemplateColor {
  Blue = 'Blue',
  Gray = 'Gray',
  Orange = 'Orange',
  Pink = 'Pink',
  Purple = 'Purple',
  Yellow = 'Yellow'
}

export type TemplateScheme = {
  __typename?: 'TemplateScheme';
  _id: Scalars['String']['output'];
  chatAppsText: Scalars['String']['output'];
  conversationBlocks: Array<ConversationBlock>;
  emailSubject: Scalars['String']['output'];
  smsText: Scalars['String']['output'];
};

export type Theme = {
  __typename?: 'Theme';
  _id: Scalars['String']['output'];
  createdBy: ThemeSource;
  isNewTheme?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  namedBy: ThemeSource;
};

export type ThemeBreakdown = {
  __typename?: 'ThemeBreakdown';
  _id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  participantCount: Scalars['Int']['output'];
};

export type ThemeBreakdownInput = {
  _id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  participantCount: Scalars['Int']['input'];
};

export type ThemeData = {
  __typename?: 'ThemeData';
  _id: Scalars['String']['output'];
  participantCount: Scalars['Int']['output'];
  type: ThemeStatsType;
};

export type ThemeGroup = {
  __typename?: 'ThemeGroup';
  _id: Scalars['String']['output'];
  createdBy: ThemeSource;
  name: Scalars['String']['output'];
  namedBy: ThemeSource;
  themeIds: Array<Scalars['String']['output']>;
};

export type ThemeGroupData = {
  __typename?: 'ThemeGroupData';
  _id: Scalars['String']['output'];
  participantCount: Scalars['Int']['output'];
  themes: Array<ThemeData>;
  type: ThemeStatsType;
};

export type ThemeGroupInput = {
  _id: Scalars['String']['input'];
  createdBy: ThemeSource;
  name: Scalars['String']['input'];
  namedBy: ThemeSource;
  themeIds: Array<Scalars['String']['input']>;
};

export type ThemeInput = {
  _id: Scalars['String']['input'];
  createdBy: ThemeSource;
  isNewTheme?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  namedBy: ThemeSource;
};

export type ThemeMetadata = {
  __typename?: 'ThemeMetadata';
  _id: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  name: Scalars['String']['output'];
  namedBy: Scalars['String']['output'];
  summary?: Maybe<ThemeSummary>;
};

export enum ThemeSource {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

export type ThemeState = {
  __typename?: 'ThemeState';
  groups: Array<ThemeGroup>;
  themes: Array<Theme>;
};

export type ThemeStateInput = {
  groups: Array<ThemeGroupInput>;
  themes: Array<ThemeInput>;
};

export type ThemeStats = ThemeData | ThemeGroupData;

export enum ThemeStatsType {
  Group = 'GROUP',
  Theme = 'THEME'
}

export type ThemeSummary = {
  __typename?: 'ThemeSummary';
  source: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type ThemesReportDownloadResponse = {
  __typename?: 'ThemesReportDownloadResponse';
  base64String: Scalars['String']['output'];
};

export enum ThemesReportStatus {
  Completed = 'COMPLETED',
  Scheduled = 'SCHEDULED'
}

export type TimelineCampaign = {
  __typename?: 'TimelineCampaign';
  _id: Scalars['String']['output'];
  finalSend: Scalars['Float']['output'];
  firstSend: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  planningBoardId: Scalars['String']['output'];
};

export type TimelineCampaignsResponse = {
  __typename?: 'TimelineCampaignsResponse';
  defaultCampaignId?: Maybe<Scalars['String']['output']>;
  timelineCampaigns: Array<TimelineCampaign>;
};

export type TimestampedCount = {
  __typename?: 'TimestampedCount';
  count: Scalars['Int']['output'];
  date: Scalars['String']['output'];
};

export type ToggleFeatureCommentResponse = {
  __typename?: 'ToggleFeatureCommentResponse';
  isFeatured?: Maybe<Scalars['Boolean']['output']>;
  messageId: Scalars['String']['output'];
};

export type Topic = {
  __typename?: 'Topic';
  name?: Maybe<Scalars['String']['output']>;
  spectrumHigh?: Maybe<Scalars['String']['output']>;
  spectrumLow?: Maybe<Scalars['String']['output']>;
};

export type UpdateActionPlanTaskInput = {
  _id: Scalars['String']['input'];
  state: TaskState;
};

export type UpdateBoardCardsResponse = {
  __typename?: 'UpdateBoardCardsResponse';
  boardId: Scalars['ID']['output'];
  campaignId: Scalars['ID']['output'];
  updatedCampaignStatus?: Maybe<CampaignStatus>;
  updatedFirstSendDateMillis?: Maybe<Scalars['Float']['output']>;
  updatedSortedCampaignIds: Array<Scalars['ID']['output']>;
};

export type UpdateCampaignAudienceResponse = {
  __typename?: 'UpdateCampaignAudienceResponse';
  attributes?: Maybe<Array<Attribute>>;
  audienceType: AudienceType;
  campaignHash: Scalars['String']['output'];
  campaignId: Scalars['String']['output'];
  distributionSettings?: Maybe<DistributionSettings>;
  fileName?: Maybe<Scalars['String']['output']>;
  personIds?: Maybe<Array<Scalars['String']['output']>>;
  spreadByAttribute?: Maybe<Scalars['String']['output']>;
};

export type UpdateCampaignStatusResponse = {
  __typename?: 'UpdateCampaignStatusResponse';
  campaignHash: Scalars['String']['output'];
  campaignId: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type UpdateCampaignThemeResponse = {
  __typename?: 'UpdateCampaignThemeResponse';
  themeId: Scalars['String']['output'];
  themeName: Scalars['String']['output'];
  themeSummary?: Maybe<ThemeSummary>;
  themesHash: Scalars['String']['output'];
};

export type UpdateCampaignThemesResponse = {
  __typename?: 'UpdateCampaignThemesResponse';
  campaignId: Scalars['ID']['output'];
  themesHash: Scalars['String']['output'];
};

export type UpdateCampaignTimingResponse = {
  __typename?: 'UpdateCampaignTimingResponse';
  _id: Scalars['String']['output'];
  activeDaysAfterFinalSend?: Maybe<Scalars['Float']['output']>;
  audienceNudges: AudienceNudgeConfig;
  campaignHash: Scalars['String']['output'];
  closeDate?: Maybe<Scalars['Float']['output']>;
  conversationSchemes: Array<ConversationScheme>;
  defaultSendTime: Scalars['Float']['output'];
  finalSend: Scalars['Float']['output'];
  firstSend: Scalars['Float']['output'];
  timezone: Scalars['String']['output'];
};

export type UpdateConversationSchemeSendTimesResponse = {
  __typename?: 'UpdateConversationSchemeSendTimesResponse';
  campaignHash: Scalars['String']['output'];
  closeDate?: Maybe<Scalars['Float']['output']>;
  finalSend: Scalars['Float']['output'];
  firstSend: Scalars['Float']['output'];
  nextScheduledSend?: Maybe<Scalars['Float']['output']>;
  noChangeToSends?: Maybe<Scalars['Boolean']['output']>;
  scheduledSends: Array<Scalars['Float']['output']>;
};

export type UpdateDeliveriesToResendArgs = {
  __typename?: 'UpdateDeliveriesToResendArgs';
  campaignId: Scalars['String']['output'];
  deliveryContentSelector: CampaignDeliveryTypeContent;
  deliveryType: DeliveryType;
  errorCode: Scalars['String']['output'];
  resendAmount: ResendAmount;
  smsText: Scalars['String']['output'];
};

export type UpdateDeliveriesToResendResponse = {
  __typename?: 'UpdateDeliveriesToResendResponse';
  updatedTimestamp: Scalars['Float']['output'];
};

export type UpdateOrgSettingsResponse = {
  __typename?: 'UpdateOrgSettingsResponse';
  aiSettings: AiSettings;
  autoTranslateConversationsEnabled?: Maybe<Scalars['Boolean']['output']>;
  contactMethods: Array<Scalars['String']['output']>;
  deviceRegistrationEnabled?: Maybe<Scalars['Boolean']['output']>;
  multiLanguageConversationsEnabled: Scalars['Boolean']['output'];
  permissions: OrgPermissions;
  primaryLocale: Scalars['String']['output'];
};

export type UpdatePeopleIntegrationSettingsResponse = {
  __typename?: 'UpdatePeopleIntegrationSettingsResponse';
  attributes: Array<OrgAttribute>;
  integrations: OrgIntegrations;
};

export type UpdateSendMethodsResponse = {
  __typename?: 'UpdateSendMethodsResponse';
  _id: Scalars['String']['output'];
  campaignHash: Scalars['String']['output'];
  contactMethods: Array<Scalars['String']['output']>;
  emailSettings: EmailSettings;
};

export type UpdatedMessageProperties = {
  __typename?: 'UpdatedMessageProperties';
  actionablePhrases?: Maybe<ActionablePhrases>;
  conversationSchemeBlockId?: Maybe<Scalars['String']['output']>;
  hasAIMessageBeenFlagged?: Maybe<Scalars['Boolean']['output']>;
  hasAIMessageBeenReported?: Maybe<Scalars['Boolean']['output']>;
  image?: Maybe<Image>;
  isSkip?: Maybe<Scalars['Boolean']['output']>;
  ratingValue?: Maybe<RatingAnswerValue>;
  reactions?: Maybe<Array<ReactionOnMessage>>;
  selectedValue?: Maybe<Scalars['String']['output']>;
  selectedValues?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<MessageStatus>;
  text?: Maybe<Scalars['String']['output']>;
  themesReportId?: Maybe<Scalars['String']['output']>;
  translatedText?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  upsertTimestamps: Array<Scalars['Float']['output']>;
};

export type UploadCampaignConversationSchemesCsvResponse = {
  __typename?: 'UploadCampaignConversationSchemesCsvResponse';
  campaignHash: Scalars['String']['output'];
  campaignId: Scalars['String']['output'];
  conversationSchemes: Array<ConversationScheme>;
  fileName: Scalars['String']['output'];
  finalSend: Scalars['Float']['output'];
};

export type UploadImpactMetricDocumentsPresignedUrlResponse = {
  __typename?: 'UploadImpactMetricDocumentsPresignedUrlResponse';
  documentId: Scalars['String']['output'];
  fields: Scalars['JSON']['output'];
  url: Scalars['String']['output'];
};

export type UpsertTemplateResponse = {
  __typename?: 'UpsertTemplateResponse';
  categories: Array<TemplateCategory>;
  template: Template;
};

export type VerifyDeviceResponse = {
  __typename?: 'VerifyDeviceResponse';
  accessToken: Scalars['String']['output'];
  hasAddedNewDevice: Scalars['Boolean']['output'];
  refreshToken: Scalars['String']['output'];
};

export type VisibilityMapEntry = {
  __typename?: 'VisibilityMapEntry';
  affectedUsers?: Maybe<Array<Person>>;
  identifierValue?: Maybe<Scalars['String']['output']>;
  leaderPersons?: Maybe<Array<Person>>;
  leaderValues?: Maybe<Array<Scalars['String']['output']>>;
};

export type VisibleConversationCountsByFilterResponse = {
  __typename?: 'VisibleConversationCountsByFilterResponse';
  allConversations: Scalars['Float']['output'];
  allNewReplies: Scalars['Float']['output'];
  myConversations: Scalars['Float']['output'];
  myNewReplies: Scalars['Float']['output'];
  newFeedback: Scalars['Float']['output'];
  newPartialAnswers: Scalars['Float']['output'];
};

export type VisibleConversationsResponse = {
  __typename?: 'VisibleConversationsResponse';
  conversations: Array<Conversation>;
  filterKey?: Maybe<Scalars['String']['output']>;
  hasMoreToLoad: Scalars['Boolean']['output'];
  nextCursor?: Maybe<Cursor>;
};

export enum Weekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type WeeklySummary = {
  __typename?: 'WeeklySummary';
  weekday: Weekday;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;

export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<RefType extends Record<string, unknown>> = {
  ConversationBlock: ( CommentBlock ) | ( ConversationPromptBlock ) | ( FilteredSelfSelectBlock ) | ( RatingBlock ) | ( SelectBlock ) | ( SelfSelectBlock ) | ( SwitchBlock );
  ImpactMetric: ( FinancialImpactMetric ) | ( OtherImpactMetric );
  QuestionRow: ( RatingRow ) | ( SelectRow );
  ThemeStats: ( ThemeData ) | ( ThemeGroupData );
};

/** Mapping of interface types */
export type ResolversInterfaceTypes<RefType extends Record<string, unknown>> = {
  BaseMessage: ( ConversationComment ) | ( ConversationPrompt ) | ( FilteredSelfSelectAnswer ) | ( RatingAnswer ) | ( SelectManyAnswer ) | ( SelectOneAnswer ) | ( SelfSelectAnswer );
  BaseTransformation: ( AddPrefixFromColumnTransformation ) | ( AddPrefixTransformation ) | ( FindAndReplaceTransformation ) | ( GenericTransformation );
  Relationship: ( CampaignRelationship ) | ( HierarchicalPersonRelationship ) | ( NonHierarchicalPersonRelationship );
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AIFResponseRule: AifResponseRule;
  ActionPlanItem: ResolverTypeWrapper<ActionPlanItem>;
  ActionPlanItemInput: ActionPlanItemInput;
  ActionPlanItemSource: ActionPlanItemSource;
  ActionPlanTask: ResolverTypeWrapper<ActionPlanTask>;
  ActionPlanTaskInput: ActionPlanTaskInput;
  ActionablePhrase: ResolverTypeWrapper<ActionablePhrase>;
  ActionablePhraseInput: ActionablePhraseInput;
  ActionablePhraseStat: ResolverTypeWrapper<ActionablePhraseStat>;
  ActionablePhrases: ResolverTypeWrapper<ActionablePhrases>;
  ActionablePhrasesPerTheme: ResolverTypeWrapper<ActionablePhrasesPerTheme>;
  ActionsToInclude: ActionsToInclude;
  AddManualAttributeResponse: ResolverTypeWrapper<AddManualAttributeResponse>;
  AddPrefixFromColumnTransformation: ResolverTypeWrapper<AddPrefixFromColumnTransformation>;
  AddPrefixTransformation: ResolverTypeWrapper<AddPrefixTransformation>;
  AiCommentQuality: AiCommentQuality;
  AiFacilitatorConfiguration: ResolverTypeWrapper<AiFacilitatorConfiguration>;
  AiFacilitatorResponse: ResolverTypeWrapper<AiFacilitatorResponse>;
  AiSettings: ResolverTypeWrapper<AiSettings>;
  AiSettingsInput: AiSettingsInput;
  ApplyRelationships: ResolverTypeWrapper<ApplyRelationships>;
  Attribute: ResolverTypeWrapper<Attribute>;
  AttributeBreakdown: ResolverTypeWrapper<AttributeBreakdown>;
  AttributeBreakdownRow: ResolverTypeWrapper<AttributeBreakdownRow>;
  AttributeFieldConfiguration: ResolverTypeWrapper<AttributeFieldConfiguration>;
  AttributeInput: AttributeInput;
  AttributeOnPerson: ResolverTypeWrapper<AttributeOnPerson>;
  AttributeOptions: ResolverTypeWrapper<AttributeOptions>;
  AttributeType: AttributeType;
  Audience: ResolverTypeWrapper<Audience>;
  AudienceInput: AudienceInput;
  AudienceNudge: ResolverTypeWrapper<AudienceNudge>;
  AudienceNudgeConfig: ResolverTypeWrapper<AudienceNudgeConfig>;
  AudienceThemesReport: ResolverTypeWrapper<AudienceThemesReport>;
  AudienceThemesReportInput: AudienceThemesReportInput;
  AudienceType: AudienceType;
  AuthResponse: ResolverTypeWrapper<AuthResponse>;
  BaseActionablePhrase: ResolverTypeWrapper<BaseActionablePhrase>;
  BaseAnswerResponse: ResolverTypeWrapper<BaseAnswerResponse>;
  BaseMessage: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['BaseMessage']>;
  BasePerson: ResolverTypeWrapper<BasePerson>;
  BaseTransformation: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['BaseTransformation']>;
  BlockAdvanceCondition: BlockAdvanceCondition;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  BreakdownPageInput: BreakdownPageInput;
  BulkReplyNoCommentsResponse: ResolverTypeWrapper<BulkReplyNoCommentsResponse>;
  BulkReplyPreviewResponse: ResolverTypeWrapper<BulkReplyPreviewResponse>;
  Campaign: ResolverTypeWrapper<Campaign>;
  CampaignActionPlan: ResolverTypeWrapper<CampaignActionPlan>;
  CampaignActionPlanAndSuggestions: ResolverTypeWrapper<CampaignActionPlanAndSuggestions>;
  CampaignActionPlanExportResponse: ResolverTypeWrapper<CampaignActionPlanExportResponse>;
  CampaignActionPlanSuggestions: ResolverTypeWrapper<CampaignActionPlanSuggestions>;
  CampaignAttributeStats: ResolverTypeWrapper<CampaignAttributeStats>;
  CampaignDeliveryTypeContent: ResolverTypeWrapper<CampaignDeliveryTypeContent>;
  CampaignDeliveryTypeContentInput: CampaignDeliveryTypeContentInput;
  CampaignDeliveryTypeMetadataInput: CampaignDeliveryTypeMetadataInput;
  CampaignDesignerSummaryExportResponse: ResolverTypeWrapper<CampaignDesignerSummaryExportResponse>;
  CampaignDetails: ResolverTypeWrapper<CampaignDetails>;
  CampaignEngagementHeatmap: ResolverTypeWrapper<CampaignEngagementHeatmap>;
  CampaignEngagementStats: ResolverTypeWrapper<CampaignEngagementStats>;
  CampaignHashResponse: ResolverTypeWrapper<CampaignHashResponse>;
  CampaignImpactMetrics: ResolverTypeWrapper<Omit<CampaignImpactMetrics, 'impactMetrics'> & { impactMetrics: Array<ResolversTypes['ImpactMetric']> }>;
  CampaignRelationship: ResolverTypeWrapper<CampaignRelationship>;
  CampaignRelationshipInput: CampaignRelationshipInput;
  CampaignRelationshipType: CampaignRelationshipType;
  CampaignRelationshipVisibilityMapEntry: CampaignRelationshipVisibilityMapEntry;
  CampaignStats: ResolverTypeWrapper<Omit<CampaignStats, 'questionRows' | 'themeStats'> & { questionRows: Array<ResolversTypes['QuestionRow']>, themeStats: Array<ResolversTypes['ThemeStats']> }>;
  CampaignStatus: CampaignStatus;
  CampaignThemesExportResponse: ResolverTypeWrapper<CampaignThemesExportResponse>;
  CampaignValidationErrors: ResolverTypeWrapper<CampaignValidationErrors>;
  CampaignValidationSchemeErrors: ResolverTypeWrapper<CampaignValidationSchemeErrors>;
  CampaignVisibilityMapEntry: ResolverTypeWrapper<CampaignVisibilityMapEntry>;
  CampaignsInTimeRangeResponse: ResolverTypeWrapper<CampaignsInTimeRangeResponse>;
  Category: ResolverTypeWrapper<Category>;
  ClientType: ClientType;
  ColumnName: ResolverTypeWrapper<ColumnName>;
  ColumnsType: ColumnsType;
  CommentBlock: ResolverTypeWrapper<CommentBlock>;
  CommentPhraseInput: CommentPhraseInput;
  CommentPhrases: ResolverTypeWrapper<CommentPhrases>;
  CompletedSend: ResolverTypeWrapper<CompletedSend>;
  ContactMethod: ContactMethod;
  Conversation: ResolverTypeWrapper<Conversation>;
  ConversationBlock: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['ConversationBlock']>;
  ConversationComment: ResolverTypeWrapper<ConversationComment>;
  ConversationCsvRow: ConversationCsvRow;
  ConversationFetchFilters: ConversationFetchFilters;
  ConversationPrompt: ResolverTypeWrapper<ConversationPrompt>;
  ConversationPromptBlock: ResolverTypeWrapper<ConversationPromptBlock>;
  ConversationScheme: ResolverTypeWrapper<Omit<ConversationScheme, 'conversationBlocks'> & { conversationBlocks: Array<ResolversTypes['ConversationBlock']> }>;
  ConversationSchemeDetails: ResolverTypeWrapper<ConversationSchemeDetails>;
  ConversationSchemeParticipationStats: ResolverTypeWrapper<ConversationSchemeParticipationStats>;
  ConversationSchemeSendInfo: ResolverTypeWrapper<ConversationSchemeSendInfo>;
  ConversationSchemeSendStatus: ConversationSchemeSendStatus;
  ConversationSubscriptionResponse: ResolverTypeWrapper<ConversationSubscriptionResponse>;
  CreateOrUpdateNudgeReminderArgs: ResolverTypeWrapper<CreateOrUpdateNudgeReminderArgs>;
  CsvColumnConfiguration: ResolverTypeWrapper<CsvColumnConfiguration>;
  CsvIntegration: ResolverTypeWrapper<CsvIntegration>;
  Cursor: ResolverTypeWrapper<Cursor>;
  DeleteCampaignResponse: ResolverTypeWrapper<DeleteCampaignResponse>;
  DeleteCommentResponse: ResolverTypeWrapper<DeleteCommentResponse>;
  DeleteNudgeReminderArgs: ResolverTypeWrapper<DeleteNudgeReminderArgs>;
  DeleteTemplateResponse: ResolverTypeWrapper<DeleteTemplateResponse>;
  DeliveryStatus: DeliveryStatus;
  DeliveryStatusInfo: ResolverTypeWrapper<DeliveryStatusInfo>;
  DeliveryType: DeliveryType;
  DesignerFormBlockRow: DesignerFormBlockRow;
  DesignerFormScheme: DesignerFormScheme;
  DisplayStatus: DisplayStatus;
  DistributedAudienceExportResponse: ResolverTypeWrapper<DistributedAudienceExportResponse>;
  DistributionMethod: DistributionMethod;
  DistributionSettings: ResolverTypeWrapper<DistributionSettings>;
  DistributionSettingsInput: DistributionSettingsInput;
  DownloadImpactMetricDocumentPresignedUrl: ResolverTypeWrapper<DownloadImpactMetricDocumentPresignedUrl>;
  DownloadPeopleDataPresignedUrl: ResolverTypeWrapper<DownloadPeopleDataPresignedUrl>;
  EditCommentTextResponse: ResolverTypeWrapper<EditCommentTextResponse>;
  EditPlanningBoardInput: EditPlanningBoardInput;
  EmailSettings: ResolverTypeWrapper<EmailSettings>;
  EmailSettingsInput: EmailSettingsInput;
  EngagementHeatmapRow: ResolverTypeWrapper<EngagementHeatmapRow>;
  Error: ResolverTypeWrapper<Error>;
  ErrorResponse: ResolverTypeWrapper<ErrorResponse>;
  FeaturedComment: ResolverTypeWrapper<FeaturedComment>;
  FeaturedCommentCandidate: ResolverTypeWrapper<FeaturedCommentCandidate>;
  FieldConstraints: ResolverTypeWrapper<FieldConstraints>;
  FieldToCsvColumnConfiguration: ResolverTypeWrapper<FieldToCsvColumnConfiguration>;
  Filter: ResolverTypeWrapper<Filter>;
  FilterOption: ResolverTypeWrapper<FilterOption>;
  FilteredSelfSelectAnswer: ResolverTypeWrapper<FilteredSelfSelectAnswer>;
  FilteredSelfSelectBlock: ResolverTypeWrapper<FilteredSelfSelectBlock>;
  FilteredSelfSelectOptionsLookup: ResolverTypeWrapper<FilteredSelfSelectOptionsLookup>;
  Filters: ResolverTypeWrapper<Filters>;
  FinancialImpactMetric: ResolverTypeWrapper<FinancialImpactMetric>;
  FindAndReplaceTransformation: ResolverTypeWrapper<FindAndReplaceTransformation>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  GenerativeAiProvider: GenerativeAiProvider;
  GenericTransformation: ResolverTypeWrapper<GenericTransformation>;
  GetCampaignThemesResponse: ResolverTypeWrapper<GetCampaignThemesResponse>;
  GetSsoInfoResponse: ResolverTypeWrapper<GetSsoInfoResponse>;
  HierarchicalPersonRelationship: ResolverTypeWrapper<HierarchicalPersonRelationship>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  Image: ResolverTypeWrapper<Image>;
  ImageObject: ResolverTypeWrapper<ImageObject>;
  ImpactMetric: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['ImpactMetric']>;
  ImpactMetricDocument: ResolverTypeWrapper<ImpactMetricDocument>;
  ImpactMetricDocumentInput: ImpactMetricDocumentInput;
  ImpactMetricDocumentPresignedUrlInput: ImpactMetricDocumentPresignedUrlInput;
  ImpactMetricInput: ImpactMetricInput;
  ImpactMetricRoiInput: ImpactMetricRoiInput;
  ImpactMetricType: ImpactMetricType;
  InformLeadsAndSponsorsAudienceCountResponse: ResolverTypeWrapper<InformLeadsAndSponsorsAudienceCountResponse>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  IntegrationInstall: ResolverTypeWrapper<IntegrationInstall>;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  LatestQuestionResponseState: LatestQuestionResponseState;
  LeadsAndSponsors: ResolverTypeWrapper<LeadsAndSponsors>;
  LegendLabels: ResolverTypeWrapper<LegendLabels>;
  LiveTestAudienceInput: LiveTestAudienceInput;
  LoggedInUserResponse: ResolverTypeWrapper<LoggedInUserResponse>;
  LogoImageInput: LogoImageInput;
  MagicTokenAuthResponse: ResolverTypeWrapper<MagicTokenAuthResponse>;
  ManyConversationsUpdateSubscriptionResponse: ResolverTypeWrapper<ManyConversationsUpdateSubscriptionResponse>;
  MatchingPerson: ResolverTypeWrapper<MatchingPerson>;
  MessageDeletion: ResolverTypeWrapper<MessageDeletion>;
  MessageStatus: MessageStatus;
  MessageUpdate: ResolverTypeWrapper<MessageUpdate>;
  Metadata: ResolverTypeWrapper<Metadata>;
  MicrosoftGroup: ResolverTypeWrapper<MicrosoftGroup>;
  MicrosoftGroupResponse: ResolverTypeWrapper<MicrosoftGroupResponse>;
  MicrosoftPeopleSyncIntegration: ResolverTypeWrapper<MicrosoftPeopleSyncIntegration>;
  MsParameters: ResolverTypeWrapper<MsParameters>;
  MultiPageReportInput: MultiPageReportInput;
  MutateGroupVisibilityResponse: ResolverTypeWrapper<MutateGroupVisibilityResponse>;
  Mutation: ResolverTypeWrapper<{}>;
  NextBlock: ResolverTypeWrapper<NextBlock>;
  NextBlockWithAudience: ResolverTypeWrapper<NextBlockWithAudience>;
  NonHierarchicalPersonRelationship: ResolverTypeWrapper<NonHierarchicalPersonRelationship>;
  NonHierarchicalPersonVisibilityMap: ResolverTypeWrapper<NonHierarchicalPersonVisibilityMap>;
  NonHierarchicalPersonVisibilityMapInput: NonHierarchicalPersonVisibilityMapInput;
  NotificationViewStatus: NotificationViewStatus;
  NudgeAudienceType: NudgeAudienceType;
  OktaParameters: ResolverTypeWrapper<OktaParameters>;
  OnePageReportInput: OnePageReportInput;
  Option: ResolverTypeWrapper<Option>;
  OptionsLookup: OptionsLookup;
  Org: ResolverTypeWrapper<Org>;
  OrgAttribute: ResolverTypeWrapper<OrgAttribute>;
  OrgAttributeInput: OrgAttributeInput;
  OrgFilteredAttributeValue: ResolverTypeWrapper<OrgFilteredAttributeValue>;
  OrgInfo: ResolverTypeWrapper<OrgInfo>;
  OrgIntegrations: ResolverTypeWrapper<OrgIntegrations>;
  OrgNotificationSettings: ResolverTypeWrapper<OrgNotificationSettings>;
  OrgPermissions: ResolverTypeWrapper<OrgPermissions>;
  OrgPermissionsInput: OrgPermissionsInput;
  OrgResponderSettingsResponse: ResolverTypeWrapper<OrgResponderSettingsResponse>;
  OrgSettings: ResolverTypeWrapper<OrgSettings>;
  OtherImpactMetric: ResolverTypeWrapper<OtherImpactMetric>;
  PIP: ResolverTypeWrapper<Pip>;
  Pages: Pages;
  ParticipantNotification: ResolverTypeWrapper<ParticipantNotification>;
  ParticipantNotificationContent: ResolverTypeWrapper<ParticipantNotificationContent>;
  ParticipantNotificationType: ParticipantNotificationType;
  ParticipationHeatmap: ResolverTypeWrapper<ParticipationHeatmap>;
  ParticipationStats: ResolverTypeWrapper<ParticipationStats>;
  PeopleIntegrationCoreDataCsvColumns: PeopleIntegrationCoreDataCsvColumns;
  PeopleIntegrationCoreDataFieldTransformations: PeopleIntegrationCoreDataFieldTransformations;
  PeopleIntegrationSettings: ResolverTypeWrapper<PeopleIntegrationSettings>;
  PeopleIntegrationSettingsInput: PeopleIntegrationSettingsInput;
  Permissions: ResolverTypeWrapper<Permissions>;
  PermissionsOnPerson: ResolverTypeWrapper<PermissionsOnPerson>;
  Person: ResolverTypeWrapper<Person>;
  PersonAvatar: ResolverTypeWrapper<PersonAvatar>;
  PersonId: PersonId;
  PersonInScope: ResolverTypeWrapper<PersonInScope>;
  PersonMatchesResponse: ResolverTypeWrapper<PersonMatchesResponse>;
  PersonResponse: ResolverTypeWrapper<PersonResponse>;
  PersonSettings: ResolverTypeWrapper<PersonSettings>;
  PersonsIngestionStatus: ResolverTypeWrapper<PersonsIngestionStatus>;
  PersonsIngestionStatusEvent: ResolverTypeWrapper<PersonsIngestionStatusEvent>;
  PlanningBoard: ResolverTypeWrapper<PlanningBoard>;
  PlanningBoardCampaign: ResolverTypeWrapper<PlanningBoardCampaign>;
  PlanningBoardCampaignsResponse: ResolverTypeWrapper<PlanningBoardCampaignsResponse>;
  PlanningBoardExportResponse: ResolverTypeWrapper<PlanningBoardExportResponse>;
  PrefixColumn: ResolverTypeWrapper<PrefixColumn>;
  PrintFileType: PrintFileType;
  PublishThemesResponse: ResolverTypeWrapper<PublishThemesResponse>;
  Query: ResolverTypeWrapper<{}>;
  Question: ResolverTypeWrapper<Question>;
  QuestionFilters: QuestionFilters;
  QuestionRow: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['QuestionRow']>;
  QuestionType: QuestionType;
  RatingAnswer: ResolverTypeWrapper<RatingAnswer>;
  RatingAnswerValue: ResolverTypeWrapper<RatingAnswerValue>;
  RatingBlock: ResolverTypeWrapper<RatingBlock>;
  RatingQuestion: ResolverTypeWrapper<RatingQuestion>;
  RatingRow: ResolverTypeWrapper<RatingRow>;
  RatingSummary: ResolverTypeWrapper<RatingSummary>;
  ReactionOnMessage: ResolverTypeWrapper<ReactionOnMessage>;
  ReactionType: ReactionType;
  Relationship: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Relationship']>;
  RelationshipMetadata: ResolverTypeWrapper<RelationshipMetadata>;
  RelationshipType: RelationshipType;
  RelationshipVisibility: ResolverTypeWrapper<RelationshipVisibility>;
  ReminderEmailSettings: ResolverTypeWrapper<ReminderEmailSettings>;
  ReminderSendSettings: ResolverTypeWrapper<ReminderSendSettings>;
  ReminderSendStatus: ReminderSendStatus;
  ReminderTypes: ReminderTypes;
  ReportExportResponse: ResolverTypeWrapper<ReportExportResponse>;
  ReportSpecificInput: ReportSpecificInput;
  ReportType: ReportType;
  ReportingStatsResponse: ResolverTypeWrapper<ReportingStatsResponse>;
  ResendAmount: ResendAmount;
  ResendLog: ResolverTypeWrapper<ResendLog>;
  ResendSmsStatusResponse: ResolverTypeWrapper<ResendSmsStatusResponse>;
  ResendStats: ResolverTypeWrapper<ResendStats>;
  ResponderIsTypingResponse: ResolverTypeWrapper<ResponderIsTypingResponse>;
  ResponderIsViewingConversationResponse: ResolverTypeWrapper<ResponderIsViewingConversationResponse>;
  RowStats: ResolverTypeWrapper<RowStats>;
  RowType: RowType;
  SaveGroupVisibilityRelationshipInput: SaveGroupVisibilityRelationshipInput;
  SchemeToFeaturedComments: ResolverTypeWrapper<SchemeToFeaturedComments>;
  SelectBlock: ResolverTypeWrapper<SelectBlock>;
  SelectManyAnswer: ResolverTypeWrapper<SelectManyAnswer>;
  SelectOneAnswer: ResolverTypeWrapper<SelectOneAnswer>;
  SelectRow: ResolverTypeWrapper<SelectRow>;
  SelfSelectAnswer: ResolverTypeWrapper<SelfSelectAnswer>;
  SelfSelectBlock: ResolverTypeWrapper<SelfSelectBlock>;
  SelfSelectResponse: ResolverTypeWrapper<SelfSelectResponse>;
  SendInfo: ResolverTypeWrapper<SendInfo>;
  SendsByDeliveryMethod: ResolverTypeWrapper<SendsByDeliveryMethod>;
  Sentiment: Sentiment;
  SetReactionResponse: ResolverTypeWrapper<SetReactionResponse>;
  Settings: ResolverTypeWrapper<Settings>;
  SourceReportInput: SourceReportInput;
  SsoProvider: SsoProvider;
  StatusInfo: ResolverTypeWrapper<StatusInfo>;
  StatusInfoWithTemplate: ResolverTypeWrapper<StatusInfoWithTemplate>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Subscription: ResolverTypeWrapper<{}>;
  SuggestedResponses: ResolverTypeWrapper<SuggestedResponses>;
  SummaryPageInput: SummaryPageInput;
  SwitchBlock: ResolverTypeWrapper<SwitchBlock>;
  TaskState: TaskState;
  TeamMemberConversationsResponse: ResolverTypeWrapper<TeamMemberConversationsResponse>;
  Template: ResolverTypeWrapper<Template>;
  TemplateCategory: ResolverTypeWrapper<TemplateCategory>;
  TemplateColor: TemplateColor;
  TemplateScheme: ResolverTypeWrapper<Omit<TemplateScheme, 'conversationBlocks'> & { conversationBlocks: Array<ResolversTypes['ConversationBlock']> }>;
  Theme: ResolverTypeWrapper<Theme>;
  ThemeBreakdown: ResolverTypeWrapper<ThemeBreakdown>;
  ThemeBreakdownInput: ThemeBreakdownInput;
  ThemeData: ResolverTypeWrapper<ThemeData>;
  ThemeGroup: ResolverTypeWrapper<ThemeGroup>;
  ThemeGroupData: ResolverTypeWrapper<ThemeGroupData>;
  ThemeGroupInput: ThemeGroupInput;
  ThemeInput: ThemeInput;
  ThemeMetadata: ResolverTypeWrapper<ThemeMetadata>;
  ThemeSource: ThemeSource;
  ThemeState: ResolverTypeWrapper<ThemeState>;
  ThemeStateInput: ThemeStateInput;
  ThemeStats: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['ThemeStats']>;
  ThemeStatsType: ThemeStatsType;
  ThemeSummary: ResolverTypeWrapper<ThemeSummary>;
  ThemesReportDownloadResponse: ResolverTypeWrapper<ThemesReportDownloadResponse>;
  ThemesReportStatus: ThemesReportStatus;
  TimelineCampaign: ResolverTypeWrapper<TimelineCampaign>;
  TimelineCampaignsResponse: ResolverTypeWrapper<TimelineCampaignsResponse>;
  TimestampedCount: ResolverTypeWrapper<TimestampedCount>;
  ToggleFeatureCommentResponse: ResolverTypeWrapper<ToggleFeatureCommentResponse>;
  Topic: ResolverTypeWrapper<Topic>;
  UpdateActionPlanTaskInput: UpdateActionPlanTaskInput;
  UpdateBoardCardsResponse: ResolverTypeWrapper<UpdateBoardCardsResponse>;
  UpdateCampaignAudienceResponse: ResolverTypeWrapper<UpdateCampaignAudienceResponse>;
  UpdateCampaignStatusResponse: ResolverTypeWrapper<UpdateCampaignStatusResponse>;
  UpdateCampaignThemeResponse: ResolverTypeWrapper<UpdateCampaignThemeResponse>;
  UpdateCampaignThemesResponse: ResolverTypeWrapper<UpdateCampaignThemesResponse>;
  UpdateCampaignTimingResponse: ResolverTypeWrapper<UpdateCampaignTimingResponse>;
  UpdateConversationSchemeSendTimesResponse: ResolverTypeWrapper<UpdateConversationSchemeSendTimesResponse>;
  UpdateDeliveriesToResendArgs: ResolverTypeWrapper<UpdateDeliveriesToResendArgs>;
  UpdateDeliveriesToResendResponse: ResolverTypeWrapper<UpdateDeliveriesToResendResponse>;
  UpdateOrgSettingsResponse: ResolverTypeWrapper<UpdateOrgSettingsResponse>;
  UpdatePeopleIntegrationSettingsResponse: ResolverTypeWrapper<UpdatePeopleIntegrationSettingsResponse>;
  UpdateSendMethodsResponse: ResolverTypeWrapper<UpdateSendMethodsResponse>;
  UpdatedMessageProperties: ResolverTypeWrapper<UpdatedMessageProperties>;
  UploadCampaignConversationSchemesCsvResponse: ResolverTypeWrapper<UploadCampaignConversationSchemesCsvResponse>;
  UploadImpactMetricDocumentsPresignedUrlResponse: ResolverTypeWrapper<UploadImpactMetricDocumentsPresignedUrlResponse>;
  UpsertTemplateResponse: ResolverTypeWrapper<UpsertTemplateResponse>;
  VerifyDeviceResponse: ResolverTypeWrapper<VerifyDeviceResponse>;
  VisibilityMapEntry: ResolverTypeWrapper<VisibilityMapEntry>;
  VisibleConversationCountsByFilterResponse: ResolverTypeWrapper<VisibleConversationCountsByFilterResponse>;
  VisibleConversationsResponse: ResolverTypeWrapper<VisibleConversationsResponse>;
  Weekday: Weekday;
  WeeklySummary: ResolverTypeWrapper<WeeklySummary>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ActionPlanItem: ActionPlanItem;
  ActionPlanItemInput: ActionPlanItemInput;
  ActionPlanTask: ActionPlanTask;
  ActionPlanTaskInput: ActionPlanTaskInput;
  ActionablePhrase: ActionablePhrase;
  ActionablePhraseInput: ActionablePhraseInput;
  ActionablePhraseStat: ActionablePhraseStat;
  ActionablePhrases: ActionablePhrases;
  ActionablePhrasesPerTheme: ActionablePhrasesPerTheme;
  AddManualAttributeResponse: AddManualAttributeResponse;
  AddPrefixFromColumnTransformation: AddPrefixFromColumnTransformation;
  AddPrefixTransformation: AddPrefixTransformation;
  AiFacilitatorConfiguration: AiFacilitatorConfiguration;
  AiFacilitatorResponse: AiFacilitatorResponse;
  AiSettings: AiSettings;
  AiSettingsInput: AiSettingsInput;
  ApplyRelationships: ApplyRelationships;
  Attribute: Attribute;
  AttributeBreakdown: AttributeBreakdown;
  AttributeBreakdownRow: AttributeBreakdownRow;
  AttributeFieldConfiguration: AttributeFieldConfiguration;
  AttributeInput: AttributeInput;
  AttributeOnPerson: AttributeOnPerson;
  AttributeOptions: AttributeOptions;
  Audience: Audience;
  AudienceInput: AudienceInput;
  AudienceNudge: AudienceNudge;
  AudienceNudgeConfig: AudienceNudgeConfig;
  AudienceThemesReport: AudienceThemesReport;
  AudienceThemesReportInput: AudienceThemesReportInput;
  AuthResponse: AuthResponse;
  BaseActionablePhrase: BaseActionablePhrase;
  BaseAnswerResponse: BaseAnswerResponse;
  BaseMessage: ResolversInterfaceTypes<ResolversParentTypes>['BaseMessage'];
  BasePerson: BasePerson;
  BaseTransformation: ResolversInterfaceTypes<ResolversParentTypes>['BaseTransformation'];
  Boolean: Scalars['Boolean']['output'];
  BreakdownPageInput: BreakdownPageInput;
  BulkReplyNoCommentsResponse: BulkReplyNoCommentsResponse;
  BulkReplyPreviewResponse: BulkReplyPreviewResponse;
  Campaign: Campaign;
  CampaignActionPlan: CampaignActionPlan;
  CampaignActionPlanAndSuggestions: CampaignActionPlanAndSuggestions;
  CampaignActionPlanExportResponse: CampaignActionPlanExportResponse;
  CampaignActionPlanSuggestions: CampaignActionPlanSuggestions;
  CampaignAttributeStats: CampaignAttributeStats;
  CampaignDeliveryTypeContent: CampaignDeliveryTypeContent;
  CampaignDeliveryTypeContentInput: CampaignDeliveryTypeContentInput;
  CampaignDeliveryTypeMetadataInput: CampaignDeliveryTypeMetadataInput;
  CampaignDesignerSummaryExportResponse: CampaignDesignerSummaryExportResponse;
  CampaignDetails: CampaignDetails;
  CampaignEngagementHeatmap: CampaignEngagementHeatmap;
  CampaignEngagementStats: CampaignEngagementStats;
  CampaignHashResponse: CampaignHashResponse;
  CampaignImpactMetrics: Omit<CampaignImpactMetrics, 'impactMetrics'> & { impactMetrics: Array<ResolversParentTypes['ImpactMetric']> };
  CampaignRelationship: CampaignRelationship;
  CampaignRelationshipInput: CampaignRelationshipInput;
  CampaignRelationshipVisibilityMapEntry: CampaignRelationshipVisibilityMapEntry;
  CampaignStats: Omit<CampaignStats, 'questionRows' | 'themeStats'> & { questionRows: Array<ResolversParentTypes['QuestionRow']>, themeStats: Array<ResolversParentTypes['ThemeStats']> };
  CampaignThemesExportResponse: CampaignThemesExportResponse;
  CampaignValidationErrors: CampaignValidationErrors;
  CampaignValidationSchemeErrors: CampaignValidationSchemeErrors;
  CampaignVisibilityMapEntry: CampaignVisibilityMapEntry;
  CampaignsInTimeRangeResponse: CampaignsInTimeRangeResponse;
  Category: Category;
  ColumnName: ColumnName;
  CommentBlock: CommentBlock;
  CommentPhraseInput: CommentPhraseInput;
  CommentPhrases: CommentPhrases;
  CompletedSend: CompletedSend;
  Conversation: Conversation;
  ConversationBlock: ResolversUnionTypes<ResolversParentTypes>['ConversationBlock'];
  ConversationComment: ConversationComment;
  ConversationCsvRow: ConversationCsvRow;
  ConversationFetchFilters: ConversationFetchFilters;
  ConversationPrompt: ConversationPrompt;
  ConversationPromptBlock: ConversationPromptBlock;
  ConversationScheme: Omit<ConversationScheme, 'conversationBlocks'> & { conversationBlocks: Array<ResolversParentTypes['ConversationBlock']> };
  ConversationSchemeDetails: ConversationSchemeDetails;
  ConversationSchemeParticipationStats: ConversationSchemeParticipationStats;
  ConversationSchemeSendInfo: ConversationSchemeSendInfo;
  ConversationSubscriptionResponse: ConversationSubscriptionResponse;
  CreateOrUpdateNudgeReminderArgs: CreateOrUpdateNudgeReminderArgs;
  CsvColumnConfiguration: CsvColumnConfiguration;
  CsvIntegration: CsvIntegration;
  Cursor: Cursor;
  DeleteCampaignResponse: DeleteCampaignResponse;
  DeleteCommentResponse: DeleteCommentResponse;
  DeleteNudgeReminderArgs: DeleteNudgeReminderArgs;
  DeleteTemplateResponse: DeleteTemplateResponse;
  DeliveryStatusInfo: DeliveryStatusInfo;
  DesignerFormBlockRow: DesignerFormBlockRow;
  DesignerFormScheme: DesignerFormScheme;
  DistributedAudienceExportResponse: DistributedAudienceExportResponse;
  DistributionSettings: DistributionSettings;
  DistributionSettingsInput: DistributionSettingsInput;
  DownloadImpactMetricDocumentPresignedUrl: DownloadImpactMetricDocumentPresignedUrl;
  DownloadPeopleDataPresignedUrl: DownloadPeopleDataPresignedUrl;
  EditCommentTextResponse: EditCommentTextResponse;
  EditPlanningBoardInput: EditPlanningBoardInput;
  EmailSettings: EmailSettings;
  EmailSettingsInput: EmailSettingsInput;
  EngagementHeatmapRow: EngagementHeatmapRow;
  Error: Error;
  ErrorResponse: ErrorResponse;
  FeaturedComment: FeaturedComment;
  FeaturedCommentCandidate: FeaturedCommentCandidate;
  FieldConstraints: FieldConstraints;
  FieldToCsvColumnConfiguration: FieldToCsvColumnConfiguration;
  Filter: Filter;
  FilterOption: FilterOption;
  FilteredSelfSelectAnswer: FilteredSelfSelectAnswer;
  FilteredSelfSelectBlock: FilteredSelfSelectBlock;
  FilteredSelfSelectOptionsLookup: FilteredSelfSelectOptionsLookup;
  Filters: Filters;
  FinancialImpactMetric: FinancialImpactMetric;
  FindAndReplaceTransformation: FindAndReplaceTransformation;
  Float: Scalars['Float']['output'];
  GenericTransformation: GenericTransformation;
  GetCampaignThemesResponse: GetCampaignThemesResponse;
  GetSsoInfoResponse: GetSsoInfoResponse;
  HierarchicalPersonRelationship: HierarchicalPersonRelationship;
  ID: Scalars['ID']['output'];
  Image: Image;
  ImageObject: ImageObject;
  ImpactMetric: ResolversUnionTypes<ResolversParentTypes>['ImpactMetric'];
  ImpactMetricDocument: ImpactMetricDocument;
  ImpactMetricDocumentInput: ImpactMetricDocumentInput;
  ImpactMetricDocumentPresignedUrlInput: ImpactMetricDocumentPresignedUrlInput;
  ImpactMetricInput: ImpactMetricInput;
  ImpactMetricRoiInput: ImpactMetricRoiInput;
  InformLeadsAndSponsorsAudienceCountResponse: InformLeadsAndSponsorsAudienceCountResponse;
  Int: Scalars['Int']['output'];
  IntegrationInstall: IntegrationInstall;
  JSON: Scalars['JSON']['output'];
  LeadsAndSponsors: LeadsAndSponsors;
  LegendLabels: LegendLabels;
  LiveTestAudienceInput: LiveTestAudienceInput;
  LoggedInUserResponse: LoggedInUserResponse;
  LogoImageInput: LogoImageInput;
  MagicTokenAuthResponse: MagicTokenAuthResponse;
  ManyConversationsUpdateSubscriptionResponse: ManyConversationsUpdateSubscriptionResponse;
  MatchingPerson: MatchingPerson;
  MessageDeletion: MessageDeletion;
  MessageUpdate: MessageUpdate;
  Metadata: Metadata;
  MicrosoftGroup: MicrosoftGroup;
  MicrosoftGroupResponse: MicrosoftGroupResponse;
  MicrosoftPeopleSyncIntegration: MicrosoftPeopleSyncIntegration;
  MsParameters: MsParameters;
  MultiPageReportInput: MultiPageReportInput;
  MutateGroupVisibilityResponse: MutateGroupVisibilityResponse;
  Mutation: {};
  NextBlock: NextBlock;
  NextBlockWithAudience: NextBlockWithAudience;
  NonHierarchicalPersonRelationship: NonHierarchicalPersonRelationship;
  NonHierarchicalPersonVisibilityMap: NonHierarchicalPersonVisibilityMap;
  NonHierarchicalPersonVisibilityMapInput: NonHierarchicalPersonVisibilityMapInput;
  OktaParameters: OktaParameters;
  OnePageReportInput: OnePageReportInput;
  Option: Option;
  OptionsLookup: OptionsLookup;
  Org: Org;
  OrgAttribute: OrgAttribute;
  OrgAttributeInput: OrgAttributeInput;
  OrgFilteredAttributeValue: OrgFilteredAttributeValue;
  OrgInfo: OrgInfo;
  OrgIntegrations: OrgIntegrations;
  OrgNotificationSettings: OrgNotificationSettings;
  OrgPermissions: OrgPermissions;
  OrgPermissionsInput: OrgPermissionsInput;
  OrgResponderSettingsResponse: OrgResponderSettingsResponse;
  OrgSettings: OrgSettings;
  OtherImpactMetric: OtherImpactMetric;
  PIP: Pip;
  Pages: Pages;
  ParticipantNotification: ParticipantNotification;
  ParticipantNotificationContent: ParticipantNotificationContent;
  ParticipationHeatmap: ParticipationHeatmap;
  ParticipationStats: ParticipationStats;
  PeopleIntegrationCoreDataCsvColumns: PeopleIntegrationCoreDataCsvColumns;
  PeopleIntegrationCoreDataFieldTransformations: PeopleIntegrationCoreDataFieldTransformations;
  PeopleIntegrationSettings: PeopleIntegrationSettings;
  PeopleIntegrationSettingsInput: PeopleIntegrationSettingsInput;
  Permissions: Permissions;
  PermissionsOnPerson: PermissionsOnPerson;
  Person: Person;
  PersonAvatar: PersonAvatar;
  PersonId: PersonId;
  PersonInScope: PersonInScope;
  PersonMatchesResponse: PersonMatchesResponse;
  PersonResponse: PersonResponse;
  PersonSettings: PersonSettings;
  PersonsIngestionStatus: PersonsIngestionStatus;
  PersonsIngestionStatusEvent: PersonsIngestionStatusEvent;
  PlanningBoard: PlanningBoard;
  PlanningBoardCampaign: PlanningBoardCampaign;
  PlanningBoardCampaignsResponse: PlanningBoardCampaignsResponse;
  PlanningBoardExportResponse: PlanningBoardExportResponse;
  PrefixColumn: PrefixColumn;
  PublishThemesResponse: PublishThemesResponse;
  Query: {};
  Question: Question;
  QuestionFilters: QuestionFilters;
  QuestionRow: ResolversUnionTypes<ResolversParentTypes>['QuestionRow'];
  RatingAnswer: RatingAnswer;
  RatingAnswerValue: RatingAnswerValue;
  RatingBlock: RatingBlock;
  RatingQuestion: RatingQuestion;
  RatingRow: RatingRow;
  RatingSummary: RatingSummary;
  ReactionOnMessage: ReactionOnMessage;
  Relationship: ResolversInterfaceTypes<ResolversParentTypes>['Relationship'];
  RelationshipMetadata: RelationshipMetadata;
  RelationshipVisibility: RelationshipVisibility;
  ReminderEmailSettings: ReminderEmailSettings;
  ReminderSendSettings: ReminderSendSettings;
  ReportExportResponse: ReportExportResponse;
  ReportSpecificInput: ReportSpecificInput;
  ReportingStatsResponse: ReportingStatsResponse;
  ResendLog: ResendLog;
  ResendSmsStatusResponse: ResendSmsStatusResponse;
  ResendStats: ResendStats;
  ResponderIsTypingResponse: ResponderIsTypingResponse;
  ResponderIsViewingConversationResponse: ResponderIsViewingConversationResponse;
  RowStats: RowStats;
  SaveGroupVisibilityRelationshipInput: SaveGroupVisibilityRelationshipInput;
  SchemeToFeaturedComments: SchemeToFeaturedComments;
  SelectBlock: SelectBlock;
  SelectManyAnswer: SelectManyAnswer;
  SelectOneAnswer: SelectOneAnswer;
  SelectRow: SelectRow;
  SelfSelectAnswer: SelfSelectAnswer;
  SelfSelectBlock: SelfSelectBlock;
  SelfSelectResponse: SelfSelectResponse;
  SendInfo: SendInfo;
  SendsByDeliveryMethod: SendsByDeliveryMethod;
  SetReactionResponse: SetReactionResponse;
  Settings: Settings;
  SourceReportInput: SourceReportInput;
  StatusInfo: StatusInfo;
  StatusInfoWithTemplate: StatusInfoWithTemplate;
  String: Scalars['String']['output'];
  Subscription: {};
  SuggestedResponses: SuggestedResponses;
  SummaryPageInput: SummaryPageInput;
  SwitchBlock: SwitchBlock;
  TeamMemberConversationsResponse: TeamMemberConversationsResponse;
  Template: Template;
  TemplateCategory: TemplateCategory;
  TemplateScheme: Omit<TemplateScheme, 'conversationBlocks'> & { conversationBlocks: Array<ResolversParentTypes['ConversationBlock']> };
  Theme: Theme;
  ThemeBreakdown: ThemeBreakdown;
  ThemeBreakdownInput: ThemeBreakdownInput;
  ThemeData: ThemeData;
  ThemeGroup: ThemeGroup;
  ThemeGroupData: ThemeGroupData;
  ThemeGroupInput: ThemeGroupInput;
  ThemeInput: ThemeInput;
  ThemeMetadata: ThemeMetadata;
  ThemeState: ThemeState;
  ThemeStateInput: ThemeStateInput;
  ThemeStats: ResolversUnionTypes<ResolversParentTypes>['ThemeStats'];
  ThemeSummary: ThemeSummary;
  ThemesReportDownloadResponse: ThemesReportDownloadResponse;
  TimelineCampaign: TimelineCampaign;
  TimelineCampaignsResponse: TimelineCampaignsResponse;
  TimestampedCount: TimestampedCount;
  ToggleFeatureCommentResponse: ToggleFeatureCommentResponse;
  Topic: Topic;
  UpdateActionPlanTaskInput: UpdateActionPlanTaskInput;
  UpdateBoardCardsResponse: UpdateBoardCardsResponse;
  UpdateCampaignAudienceResponse: UpdateCampaignAudienceResponse;
  UpdateCampaignStatusResponse: UpdateCampaignStatusResponse;
  UpdateCampaignThemeResponse: UpdateCampaignThemeResponse;
  UpdateCampaignThemesResponse: UpdateCampaignThemesResponse;
  UpdateCampaignTimingResponse: UpdateCampaignTimingResponse;
  UpdateConversationSchemeSendTimesResponse: UpdateConversationSchemeSendTimesResponse;
  UpdateDeliveriesToResendArgs: UpdateDeliveriesToResendArgs;
  UpdateDeliveriesToResendResponse: UpdateDeliveriesToResendResponse;
  UpdateOrgSettingsResponse: UpdateOrgSettingsResponse;
  UpdatePeopleIntegrationSettingsResponse: UpdatePeopleIntegrationSettingsResponse;
  UpdateSendMethodsResponse: UpdateSendMethodsResponse;
  UpdatedMessageProperties: UpdatedMessageProperties;
  UploadCampaignConversationSchemesCsvResponse: UploadCampaignConversationSchemesCsvResponse;
  UploadImpactMetricDocumentsPresignedUrlResponse: UploadImpactMetricDocumentsPresignedUrlResponse;
  UpsertTemplateResponse: UpsertTemplateResponse;
  VerifyDeviceResponse: VerifyDeviceResponse;
  VisibilityMapEntry: VisibilityMapEntry;
  VisibleConversationCountsByFilterResponse: VisibleConversationCountsByFilterResponse;
  VisibleConversationsResponse: VisibleConversationsResponse;
  WeeklySummary: WeeklySummary;
};

export type ActionPlanItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionPlanItem'] = ResolversParentTypes['ActionPlanItem']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assigneeIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  assigneesOnItem?: Resolver<Array<ResolversTypes['BasePerson']>, ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['ActionPlanItemSource'], ParentType, ContextType>;
  tasks?: Resolver<Array<ResolversTypes['ActionPlanTask']>, ParentType, ContextType>;
  themeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActionPlanTaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionPlanTask'] = ResolversParentTypes['ActionPlanTask']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assigneeIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  isHiddenToPerson?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['TaskState'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActionablePhraseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionablePhrase'] = ResolversParentTypes['ActionablePhrase']> = {
  accepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  end?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  themeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActionablePhraseStatResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionablePhraseStat'] = ResolversParentTypes['ActionablePhraseStat']> = {
  conversationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActionablePhrasesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionablePhrases'] = ResolversParentTypes['ActionablePhrases']> = {
  phrases?: Resolver<Array<ResolversTypes['ActionablePhrase']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActionablePhrasesPerThemeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionablePhrasesPerTheme'] = ResolversParentTypes['ActionablePhrasesPerTheme']> = {
  actionablePhraseStats?: Resolver<Array<ResolversTypes['ActionablePhraseStat']>, ParentType, ContextType>;
  themeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddManualAttributeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddManualAttributeResponse'] = ResolversParentTypes['AddManualAttributeResponse']> = {
  attributes?: Resolver<Array<ResolversTypes['AttributeOptions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddPrefixFromColumnTransformationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddPrefixFromColumnTransformation'] = ResolversParentTypes['AddPrefixFromColumnTransformation']> = {
  prefixColumn?: Resolver<ResolversTypes['PrefixColumn'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddPrefixTransformationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddPrefixTransformation'] = ResolversParentTypes['AddPrefixTransformation']> = {
  prefix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AiFacilitatorConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AiFacilitatorConfiguration'] = ResolversParentTypes['AiFacilitatorConfiguration']> = {
  aifResponseRule?: Resolver<ResolversTypes['AIFResponseRule'], ParentType, ContextType>;
  isAIFacilitatorEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  responseDelayInHours?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AiFacilitatorResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AiFacilitatorResponse'] = ResolversParentTypes['AiFacilitatorResponse']> = {
  nextAIFacilitatorResponseTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AiSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AiSettings'] = ResolversParentTypes['AiSettings']> = {
  generativeAiProvider?: Resolver<ResolversTypes['GenerativeAiProvider'], ParentType, ContextType>;
  termToMaskForAIOrgName?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplyRelationshipsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyRelationships'] = ResolversParentTypes['ApplyRelationships']> = {
  hierarchical?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  nonHierarchical?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttributeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attribute'] = ResolversParentTypes['Attribute']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  values?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttributeBreakdownResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttributeBreakdown'] = ResolversParentTypes['AttributeBreakdown']> = {
  isEnps?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['AttributeBreakdownRow']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttributeBreakdownRowResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttributeBreakdownRow'] = ResolversParentTypes['AttributeBreakdownRow']> = {
  attributeValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  averageRating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  negative?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  neutral?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  positive?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttributeFieldConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttributeFieldConfiguration'] = ResolversParentTypes['AttributeFieldConfiguration']> = {
  field?: Resolver<ResolversTypes['CsvColumnConfiguration'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttributeOnPersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttributeOnPerson'] = ResolversParentTypes['AttributeOnPerson']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttributeOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttributeOptions'] = ResolversParentTypes['AttributeOptions']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AudienceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Audience'] = ResolversParentTypes['Audience']> = {
  attributes?: Resolver<Maybe<Array<ResolversTypes['Attribute']>>, ParentType, ContextType>;
  audienceType?: Resolver<ResolversTypes['AudienceType'], ParentType, ContextType>;
  distributionSettings?: Resolver<Maybe<ResolversTypes['DistributionSettings']>, ParentType, ContextType>;
  fileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AudienceNudgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AudienceNudge'] = ResolversParentTypes['AudienceNudge']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  audience?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completedSends?: Resolver<Array<ResolversTypes['CompletedSend']>, ParentType, ContextType>;
  conversationSchemeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scheduledSend?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sendSettings?: Resolver<ResolversTypes['ReminderSendSettings'], ParentType, ContextType>;
  sentAudience?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ReminderSendStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ReminderTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AudienceNudgeConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['AudienceNudgeConfig'] = ResolversParentTypes['AudienceNudgeConfig']> = {
  reminders?: Resolver<Array<ResolversTypes['AudienceNudge']>, ParentType, ContextType>;
  weekday?: Resolver<Maybe<ResolversTypes['Weekday']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AudienceThemesReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['AudienceThemesReport'] = ResolversParentTypes['AudienceThemesReport']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  actionsToInclude?: Resolver<ResolversTypes['ActionsToInclude'], ParentType, ContextType>;
  completedSend?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  conversationSchemeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scheduledSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sendFromPersonId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ThemesReportStatus'], ParentType, ContextType>;
  themesToBreakdown?: Resolver<Array<ResolversTypes['ThemeBreakdown']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthResponse'] = ResolversParentTypes['AuthResponse']> = {
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseActionablePhraseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseActionablePhrase'] = ResolversParentTypes['BaseActionablePhrase']> = {
  end?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseAnswerResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseAnswerResponse'] = ResolversParentTypes['BaseAnswerResponse']> = {
  conversationSchemeWidgetCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isCampaignClosed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  nextMessageBlocks?: Resolver<Array<ResolversTypes['BaseMessage']>, ParentType, ContextType>;
  showEarlyAIFTypingIndicator?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseMessage'] = ResolversParentTypes['BaseMessage']> = {
  __resolveType: TypeResolveFn<'ConversationComment' | 'ConversationPrompt' | 'FilteredSelfSelectAnswer' | 'RatingAnswer' | 'SelectManyAnswer' | 'SelectOneAnswer' | 'SelfSelectAnswer', ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reactions?: Resolver<Maybe<Array<ResolversTypes['ReactionOnMessage']>>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  upsertTimestamps?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
};

export type BasePersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['BasePerson'] = ResolversParentTypes['BasePerson']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  familyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  givenName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pipIntegrationPersonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseTransformationResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseTransformation'] = ResolversParentTypes['BaseTransformation']> = {
  __resolveType: TypeResolveFn<'AddPrefixFromColumnTransformation' | 'AddPrefixTransformation' | 'FindAndReplaceTransformation' | 'GenericTransformation', ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type BulkReplyNoCommentsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BulkReplyNoCommentsResponse'] = ResolversParentTypes['BulkReplyNoCommentsResponse']> = {
  author?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  updateConversationIdsToMessageIds?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  updateTimestamp?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BulkReplyPreviewResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BulkReplyPreviewResponse'] = ResolversParentTypes['BulkReplyPreviewResponse']> = {
  campaignName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  noCommentConversationsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  suggestions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignResolvers<ContextType = any, ParentType extends ResolversParentTypes['Campaign'] = ResolversParentTypes['Campaign']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  activeDaysAfterFinalSend?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  aiFacilitatorConfiguration?: Resolver<ResolversTypes['AiFacilitatorConfiguration'], ParentType, ContextType>;
  applyRelationships?: Resolver<ResolversTypes['ApplyRelationships'], ParentType, ContextType>;
  audience?: Resolver<ResolversTypes['Audience'], ParentType, ContextType>;
  audienceNudges?: Resolver<ResolversTypes['AudienceNudgeConfig'], ParentType, ContextType>;
  campaignHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  closeDate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contactMethods?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  conversationSchemes?: Resolver<Array<ResolversTypes['ConversationScheme']>, ParentType, ContextType>;
  conversationsFileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultChatAppsText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  defaultSendTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  defaultSmsText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailSettings?: Resolver<ResolversTypes['EmailSettings'], ParentType, ContextType>;
  estimatedROI?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  finalSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  goal?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isClosed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isSending?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  leads?: Resolver<Maybe<Array<ResolversTypes['LeadsAndSponsors']>>, ParentType, ContextType>;
  leadsRelationship?: Resolver<Maybe<ResolversTypes['CampaignRelationship']>, ParentType, ContextType>;
  multiLanguageConversationsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  planningBoardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  primaryLocale?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  respondersRelationship?: Resolver<Maybe<ResolversTypes['CampaignRelationship']>, ParentType, ContextType>;
  roiHypothesis?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sponsors?: Resolver<Maybe<Array<ResolversTypes['LeadsAndSponsors']>>, ParentType, ContextType>;
  sponsorsRelationship?: Resolver<Maybe<ResolversTypes['CampaignRelationship']>, ParentType, ContextType>;
  spreadByAttribute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CampaignStatus'], ParentType, ContextType>;
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  weeklySummary?: Resolver<ResolversTypes['WeeklySummary'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignActionPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignActionPlan'] = ResolversParentTypes['CampaignActionPlan']> = {
  actionPlanItems?: Resolver<Array<ResolversTypes['ActionPlanItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignActionPlanAndSuggestionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignActionPlanAndSuggestions'] = ResolversParentTypes['CampaignActionPlanAndSuggestions']> = {
  actionPlanItemSuggestions?: Resolver<Array<ResolversTypes['ActionPlanItem']>, ParentType, ContextType>;
  actionPlanItems?: Resolver<Array<ResolversTypes['ActionPlanItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignActionPlanExportResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignActionPlanExportResponse'] = ResolversParentTypes['CampaignActionPlanExportResponse']> = {
  exportBase64String?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignActionPlanSuggestionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignActionPlanSuggestions'] = ResolversParentTypes['CampaignActionPlanSuggestions']> = {
  actionPlanItemSuggestions?: Resolver<Array<ResolversTypes['ActionPlanItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignAttributeStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignAttributeStats'] = ResolversParentTypes['CampaignAttributeStats']> = {
  attributeBreakdown?: Resolver<ResolversTypes['AttributeBreakdown'], ParentType, ContextType>;
  ratingQuestions?: Resolver<Array<ResolversTypes['RatingQuestion']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignDeliveryTypeContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignDeliveryTypeContent'] = ResolversParentTypes['CampaignDeliveryTypeContent']> = {
  conversationSchemeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignDesignerSummaryExportResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignDesignerSummaryExportResponse'] = ResolversParentTypes['CampaignDesignerSummaryExportResponse']> = {
  exportBase64String?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignDetails'] = ResolversParentTypes['CampaignDetails']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  aiFacilitatorConfiguration?: Resolver<ResolversTypes['AiFacilitatorConfiguration'], ParentType, ContextType>;
  applyRelationships?: Resolver<ResolversTypes['ApplyRelationships'], ParentType, ContextType>;
  audience?: Resolver<ResolversTypes['Audience'], ParentType, ContextType>;
  audienceNudgeReminders?: Resolver<Maybe<Array<ResolversTypes['AudienceNudge']>>, ParentType, ContextType>;
  audienceThemesReport?: Resolver<Maybe<Array<ResolversTypes['AudienceThemesReport']>>, ParentType, ContextType>;
  closeDate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contactMethods?: Resolver<Array<ResolversTypes['ContactMethod']>, ParentType, ContextType>;
  conversationSchemes?: Resolver<Array<ResolversTypes['ConversationSchemeDetails']>, ParentType, ContextType>;
  defaultSendTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  emailSettings?: Resolver<ResolversTypes['ReminderEmailSettings'], ParentType, ContextType>;
  finalSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  hasBeenPublished?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasLiveTestOrDemoData?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  impactArea?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isClosed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  leads?: Resolver<Maybe<Array<ResolversTypes['LeadsAndSponsors']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextScheduledSend?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  respondersRelationship?: Resolver<Maybe<ResolversTypes['CampaignRelationship']>, ParentType, ContextType>;
  sponsors?: Resolver<Maybe<Array<ResolversTypes['LeadsAndSponsors']>>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DisplayStatus'], ParentType, ContextType>;
  themes?: Resolver<Array<ResolversTypes['ThemeMetadata']>, ParentType, ContextType>;
  themesHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignEngagementHeatmapResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignEngagementHeatmap'] = ResolversParentTypes['CampaignEngagementHeatmap']> = {
  audienceMembersContacted?: Resolver<Array<ResolversTypes['EngagementHeatmapRow']>, ParentType, ContextType>;
  uniqueParticipantsCommentedAfterReply?: Resolver<Array<ResolversTypes['EngagementHeatmapRow']>, ParentType, ContextType>;
  uniqueParticipantsWithAIReply?: Resolver<Array<ResolversTypes['EngagementHeatmapRow']>, ParentType, ContextType>;
  uniqueParticipantsWithAnActionAfterReply?: Resolver<Array<ResolversTypes['EngagementHeatmapRow']>, ParentType, ContextType>;
  uniqueParticipantsWithAtLeast1ActionableComment?: Resolver<Array<ResolversTypes['EngagementHeatmapRow']>, ParentType, ContextType>;
  uniqueParticipantsWithAtLeast1Comment?: Resolver<Array<ResolversTypes['EngagementHeatmapRow']>, ParentType, ContextType>;
  uniqueParticipantsWithAtLeast1Rating?: Resolver<Array<ResolversTypes['EngagementHeatmapRow']>, ParentType, ContextType>;
  uniqueParticipantsWithFacilitatorReply?: Resolver<Array<ResolversTypes['EngagementHeatmapRow']>, ParentType, ContextType>;
  uniqueParticipantsWithHumanFacilitatorReply?: Resolver<Array<ResolversTypes['EngagementHeatmapRow']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignEngagementStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignEngagementStats'] = ResolversParentTypes['CampaignEngagementStats']> = {
  audienceCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  uniqueParticipantsWithAtLeast1ActionableComment?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  uniqueParticipantsWithAtLeast1Comment?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  uniqueParticipantsWithAtLeast1Rating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignHashResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignHashResponse'] = ResolversParentTypes['CampaignHashResponse']> = {
  campaignHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignImpactMetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignImpactMetrics'] = ResolversParentTypes['CampaignImpactMetrics']> = {
  impactMetrics?: Resolver<Array<ResolversTypes['ImpactMetric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignRelationshipResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignRelationship'] = ResolversParentTypes['CampaignRelationship']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  campaignId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  campaignRelationshipType?: Resolver<ResolversTypes['CampaignRelationshipType'], ParentType, ContextType>;
  isManual?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['RelationshipMetadata']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visibilityMap?: Resolver<Maybe<Array<ResolversTypes['CampaignVisibilityMapEntry']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignStats'] = ResolversParentTypes['CampaignStats']> = {
  participationStats?: Resolver<ResolversTypes['ParticipationStats'], ParentType, ContextType>;
  questionRows?: Resolver<Array<ResolversTypes['QuestionRow']>, ParentType, ContextType>;
  ratingSummary?: Resolver<Maybe<ResolversTypes['RatingSummary']>, ParentType, ContextType>;
  schemeToFeaturedComments?: Resolver<Maybe<Array<ResolversTypes['SchemeToFeaturedComments']>>, ParentType, ContextType>;
  themeStats?: Resolver<Array<ResolversTypes['ThemeStats']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignThemesExportResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignThemesExportResponse'] = ResolversParentTypes['CampaignThemesExportResponse']> = {
  exportBase64String?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignValidationErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignValidationErrors'] = ResolversParentTypes['CampaignValidationErrors']> = {
  audience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  context?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  responder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schemes?: Resolver<Maybe<Array<ResolversTypes['CampaignValidationSchemeErrors']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignValidationSchemeErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignValidationSchemeErrors'] = ResolversParentTypes['CampaignValidationSchemeErrors']> = {
  errors?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  schemeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignVisibilityMapEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignVisibilityMapEntry'] = ResolversParentTypes['CampaignVisibilityMapEntry']> = {
  audienceSelector?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  responders?: Resolver<Maybe<Array<ResolversTypes['Person']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampaignsInTimeRangeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignsInTimeRangeResponse'] = ResolversParentTypes['CampaignsInTimeRangeResponse']> = {
  campaigns?: Resolver<Array<ResolversTypes['Campaign']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  spectrumHigh?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  spectrumLow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topics?: Resolver<Maybe<Array<ResolversTypes['Topic']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColumnNameResolvers<ContextType = any, ParentType extends ResolversParentTypes['ColumnName'] = ResolversParentTypes['ColumnName']> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentBlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommentBlock'] = ResolversParentTypes['CommentBlock']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  nextBlock?: Resolver<Maybe<ResolversTypes['NextBlock']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentPhrasesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommentPhrases'] = ResolversParentTypes['CommentPhrases']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  arePhrasesReviewed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  commentText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  latestUpsertTimestamp?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  personId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phrases?: Resolver<Array<ResolversTypes['ActionablePhrase']>, ParentType, ContextType>;
  questionText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompletedSendResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompletedSend'] = ResolversParentTypes['CompletedSend']> = {
  initiatedSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  scheduled?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Conversation'] = ResolversParentTypes['Conversation']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  aiFacilitatorResponse?: Resolver<Maybe<ResolversTypes['AiFacilitatorResponse']>, ParentType, ContextType>;
  attributes?: Resolver<Array<ResolversTypes['AttributeOnPerson']>, ParentType, ContextType>;
  campaignId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  chatParticipationStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  containsTranslations?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  conversationSchemeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversationSchemeWidgetCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  creationTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  featuredCommentCandidates?: Resolver<Maybe<Array<ResolversTypes['FeaturedCommentCandidate']>>, ParentType, ContextType>;
  hasActionablePhrases?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasOwnerInteractions?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isCampaignClosed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isStarred?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastSignificantUpdateTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  latestQuestionResponseState?: Resolver<ResolversTypes['LatestQuestionResponseState'], ParentType, ContextType>;
  leaderCommentCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  messages?: Resolver<Array<ResolversTypes['BaseMessage']>, ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['Person'], ParentType, ContextType>;
  ownerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerUnseenNotificationCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pipId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedThemeIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  relationships?: Resolver<Maybe<Array<ResolversTypes['RelationshipVisibility']>>, ParentType, ContextType>;
  suggestedResponses?: Resolver<Maybe<ResolversTypes['SuggestedResponses']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationBlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationBlock'] = ResolversParentTypes['ConversationBlock']> = {
  __resolveType: TypeResolveFn<'CommentBlock' | 'ConversationPromptBlock' | 'FilteredSelfSelectBlock' | 'RatingBlock' | 'SelectBlock' | 'SelfSelectBlock' | 'SwitchBlock', ParentType, ContextType>;
};

export type ConversationCommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationComment'] = ResolversParentTypes['ConversationComment']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  actionablePhrases?: Resolver<Maybe<ResolversTypes['ActionablePhrases']>, ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversationSchemeBlockId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasAIMessageBeenFlagged?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasAIMessageBeenReported?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  isSkip?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  reactions?: Resolver<Maybe<Array<ResolversTypes['ReactionOnMessage']>>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  themesReportId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  translatedText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  upsertTimestamps?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationPromptResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationPrompt'] = ResolversParentTypes['ConversationPrompt']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversationSchemeBlockId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['Metadata'], ParentType, ContextType>;
  reactions?: Resolver<Maybe<Array<ResolversTypes['ReactionOnMessage']>>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MessageStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  upsertTimestamps?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationPromptBlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationPromptBlock'] = ResolversParentTypes['ConversationPromptBlock']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['Metadata'], ParentType, ContextType>;
  nextBlock?: Resolver<Maybe<ResolversTypes['NextBlock']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationSchemeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationScheme'] = ResolversParentTypes['ConversationScheme']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  chatAppsText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completedSends?: Resolver<Array<ResolversTypes['CompletedSend']>, ParentType, ContextType>;
  conversationBlocks?: Resolver<Array<ResolversTypes['ConversationBlock']>, ParentType, ContextType>;
  emailSubject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduledSends?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  smsText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ConversationSchemeSendStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationSchemeDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationSchemeDetails'] = ResolversParentTypes['ConversationSchemeDetails']> = {
  conversationSchemeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  finalSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  questions?: Resolver<Array<ResolversTypes['Question']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DisplayStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationSchemeParticipationStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationSchemeParticipationStats'] = ResolversParentTypes['ConversationSchemeParticipationStats']> = {
  actionablePhraseCounts?: Resolver<Array<ResolversTypes['TimestampedCount']>, ParentType, ContextType>;
  audienceCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversationSchemeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversationStats?: Resolver<Array<ResolversTypes['TimestampedCount']>, ParentType, ContextType>;
  newParticipantCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ownerCommentActivity?: Resolver<Array<ResolversTypes['TimestampedCount']>, ParentType, ContextType>;
  participantCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationSchemeSendInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationSchemeSendInfo'] = ResolversParentTypes['ConversationSchemeSendInfo']> = {
  conversationSchemeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isLargeAudience?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sendInfo?: Resolver<Array<ResolversTypes['SendInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationSubscriptionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationSubscriptionResponse'] = ResolversParentTypes['ConversationSubscriptionResponse']> = {
  conversation?: Resolver<ResolversTypes['Conversation'], ParentType, ContextType>;
  deletedMessages?: Resolver<Array<ResolversTypes['MessageDeletion']>, ParentType, ContextType>;
  newMessages?: Resolver<Array<ResolversTypes['BaseMessage']>, ParentType, ContextType>;
  shouldApplyChangeImmediately?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updatedMessages?: Resolver<Array<ResolversTypes['MessageUpdate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOrUpdateNudgeReminderArgsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateOrUpdateNudgeReminderArgs'] = ResolversParentTypes['CreateOrUpdateNudgeReminderArgs']> = {
  audience?: Resolver<ResolversTypes['NudgeAudienceType'], ParentType, ContextType>;
  campaignId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  chatAppsText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversationSchemeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  defaultSubject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reminderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduledSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  smsText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CsvColumnConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CsvColumnConfiguration'] = ResolversParentTypes['CsvColumnConfiguration']> = {
  csvColumn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fieldConstraints?: Resolver<Maybe<ResolversTypes['FieldConstraints']>, ParentType, ContextType>;
  transformations?: Resolver<Maybe<Array<ResolversTypes['BaseTransformation']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CsvIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CsvIntegration'] = ResolversParentTypes['CsvIntegration']> = {
  bucketDirectoryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fields?: Resolver<ResolversTypes['FieldToCsvColumnConfiguration'], ParentType, ContextType>;
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CursorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Cursor'] = ResolversParentTypes['Cursor']> = {
  idFrom?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastMessageFrom?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteCampaignResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteCampaignResponse'] = ResolversParentTypes['DeleteCampaignResponse']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteCommentResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteCommentResponse'] = ResolversParentTypes['DeleteCommentResponse']> = {
  isCampaignClosed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  messageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteNudgeReminderArgsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteNudgeReminderArgs'] = ResolversParentTypes['DeleteNudgeReminderArgs']> = {
  campaignId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reminderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTemplateResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteTemplateResponse'] = ResolversParentTypes['DeleteTemplateResponse']> = {
  categories?: Resolver<Array<ResolversTypes['TemplateCategory']>, ParentType, ContextType>;
  templateId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryStatusInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryStatusInfo'] = ResolversParentTypes['DeliveryStatusInfo']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statusIsErrorCode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistributedAudienceExportResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DistributedAudienceExportResponse'] = ResolversParentTypes['DistributedAudienceExportResponse']> = {
  exportBase64String?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistributionSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DistributionSettings'] = ResolversParentTypes['DistributionSettings']> = {
  audienceQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distributionAttribute?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  distributionMethod?: Resolver<ResolversTypes['DistributionMethod'], ParentType, ContextType>;
  favorLeastRecentlyAsked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  targetAudienceAttributes?: Resolver<Array<ResolversTypes['Attribute']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DownloadImpactMetricDocumentPresignedUrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['DownloadImpactMetricDocumentPresignedUrl'] = ResolversParentTypes['DownloadImpactMetricDocumentPresignedUrl']> = {
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  presignedUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DownloadPeopleDataPresignedUrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['DownloadPeopleDataPresignedUrl'] = ResolversParentTypes['DownloadPeopleDataPresignedUrl']> = {
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  presignedUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditCommentTextResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditCommentTextResponse'] = ResolversParentTypes['EditCommentTextResponse']> = {
  isCampaignClosed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  messageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailSettings'] = ResolversParentTypes['EmailSettings']> = {
  defaultSubject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fromEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fromName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logoImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  templateName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EngagementHeatmapRowResolvers<ContextType = any, ParentType extends ResolversParentTypes['EngagementHeatmapRow'] = ResolversParentTypes['EngagementHeatmapRow']> = {
  distinctOwnerIds?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payload?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrorResponse'] = ResolversParentTypes['ErrorResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturedCommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeaturedComment'] = ResolversParentTypes['FeaturedComment']> = {
  conversationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  messageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phraseIndexes?: Resolver<Array<ResolversTypes['BaseActionablePhrase']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  themes?: Resolver<Array<ResolversTypes['ThemeMetadata']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturedCommentCandidateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeaturedCommentCandidate'] = ResolversParentTypes['FeaturedCommentCandidate']> = {
  isPreferred?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  messageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldConstraintsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldConstraints'] = ResolversParentTypes['FieldConstraints']> = {
  isRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isUnique?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldToCsvColumnConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldToCsvColumnConfiguration'] = ResolversParentTypes['FieldToCsvColumnConfiguration']> = {
  attributes?: Resolver<Maybe<Array<ResolversTypes['AttributeFieldConfiguration']>>, ParentType, ContextType>;
  commentingDisabled?: Resolver<Maybe<ResolversTypes['CsvColumnConfiguration']>, ParentType, ContextType>;
  customerEmployeeId?: Resolver<Maybe<ResolversTypes['CsvColumnConfiguration']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['CsvColumnConfiguration'], ParentType, ContextType>;
  familyName?: Resolver<ResolversTypes['CsvColumnConfiguration'], ParentType, ContextType>;
  givenName?: Resolver<ResolversTypes['CsvColumnConfiguration'], ParentType, ContextType>;
  integrationPersonId?: Resolver<ResolversTypes['CsvColumnConfiguration'], ParentType, ContextType>;
  metaWorkplaceEmail?: Resolver<Maybe<ResolversTypes['CsvColumnConfiguration']>, ParentType, ContextType>;
  metaWorkplaceId?: Resolver<Maybe<ResolversTypes['CsvColumnConfiguration']>, ParentType, ContextType>;
  microsoftDisplayName?: Resolver<Maybe<ResolversTypes['CsvColumnConfiguration']>, ParentType, ContextType>;
  microsoftId?: Resolver<Maybe<ResolversTypes['CsvColumnConfiguration']>, ParentType, ContextType>;
  microsoftManagerId?: Resolver<Maybe<ResolversTypes['CsvColumnConfiguration']>, ParentType, ContextType>;
  microsoftTeamsEmail?: Resolver<Maybe<ResolversTypes['CsvColumnConfiguration']>, ParentType, ContextType>;
  mobilePhone?: Resolver<Maybe<ResolversTypes['CsvColumnConfiguration']>, ParentType, ContextType>;
  preferredContactMethod?: Resolver<Maybe<ResolversTypes['CsvColumnConfiguration']>, ParentType, ContextType>;
  slackEmail?: Resolver<Maybe<ResolversTypes['CsvColumnConfiguration']>, ParentType, ContextType>;
  slackId?: Resolver<Maybe<ResolversTypes['CsvColumnConfiguration']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['Filter'] = ResolversParentTypes['Filter']> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hideCategory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['FilterOption']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilterOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FilterOption'] = ResolversParentTypes['FilterOption']> = {
  conversationSchemeIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstSendTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hideCategory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  questionText?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilteredSelfSelectAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['FilteredSelfSelectAnswer'] = ResolversParentTypes['FilteredSelfSelectAnswer']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversationSchemeBlockId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filterAttributeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inputPlaceholderText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['Metadata'], ParentType, ContextType>;
  optionsLookup?: Resolver<Array<ResolversTypes['FilteredSelfSelectOptionsLookup']>, ParentType, ContextType>;
  reactions?: Resolver<Maybe<Array<ResolversTypes['ReactionOnMessage']>>, ParentType, ContextType>;
  selectedValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  selfSelectAttributeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MessageStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  upsertTimestamps?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilteredSelfSelectBlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['FilteredSelfSelectBlock'] = ResolversParentTypes['FilteredSelfSelectBlock']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  filterAttributeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['Metadata'], ParentType, ContextType>;
  nextBlock?: Resolver<Maybe<ResolversTypes['NextBlock']>, ParentType, ContextType>;
  selfSelectAttributeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilteredSelfSelectOptionsLookupResolvers<ContextType = any, ParentType extends ResolversParentTypes['FilteredSelfSelectOptionsLookup'] = ResolversParentTypes['FilteredSelfSelectOptionsLookup']> = {
  filterAttributeValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  translatedOptions?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['Filters'] = ResolversParentTypes['Filters']> = {
  attributeFilters?: Resolver<Array<ResolversTypes['Filter']>, ParentType, ContextType>;
  conversationFilters?: Resolver<Array<ResolversTypes['Filter']>, ParentType, ContextType>;
  themeFilters?: Resolver<Array<ResolversTypes['Filter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FinancialImpactMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['FinancialImpactMetric'] = ResolversParentTypes['FinancialImpactMetric']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Array<ResolversTypes['ImpactMetricDocument']>, ParentType, ContextType>;
  financialRoi?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roiAchievedDate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ImpactMetricType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FindAndReplaceTransformationResolvers<ContextType = any, ParentType extends ResolversParentTypes['FindAndReplaceTransformation'] = ResolversParentTypes['FindAndReplaceTransformation']> = {
  find?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  replaceWith?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenericTransformationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenericTransformation'] = ResolversParentTypes['GenericTransformation']> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetCampaignThemesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetCampaignThemesResponse'] = ResolversParentTypes['GetCampaignThemesResponse']> = {
  themeState?: Resolver<ResolversTypes['ThemeState'], ParentType, ContextType>;
  themesHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetSsoInfoResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetSsoInfoResponse'] = ResolversParentTypes['GetSsoInfoResponse']> = {
  msParameters?: Resolver<Maybe<ResolversTypes['MsParameters']>, ParentType, ContextType>;
  oktaParameters?: Resolver<Maybe<ResolversTypes['OktaParameters']>, ParentType, ContextType>;
  ssoEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ssoProvider?: Resolver<Maybe<ResolversTypes['SsoProvider']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HierarchicalPersonRelationshipResolvers<ContextType = any, ParentType extends ResolversParentTypes['HierarchicalPersonRelationship'] = ResolversParentTypes['HierarchicalPersonRelationship']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  identifierAttribute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isMainReportingLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isManual?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  leaderIdentifierAttribute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['RelationshipMetadata']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']> = {
  dialog?: Resolver<Maybe<ResolversTypes['ImageObject']>, ParentType, ContextType>;
  natural?: Resolver<Maybe<ResolversTypes['ImageObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageObjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImageObject'] = ResolversParentTypes['ImageObject']> = {
  fileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImpactMetric'] = ResolversParentTypes['ImpactMetric']> = {
  __resolveType: TypeResolveFn<'FinancialImpactMetric' | 'OtherImpactMetric', ParentType, ContextType>;
};

export type ImpactMetricDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImpactMetricDocument'] = ResolversParentTypes['ImpactMetricDocument']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InformLeadsAndSponsorsAudienceCountResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InformLeadsAndSponsorsAudienceCountResponse'] = ResolversParentTypes['InformLeadsAndSponsorsAudienceCountResponse']> = {
  leadsCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sponsorsCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationInstallResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationInstall'] = ResolversParentTypes['IntegrationInstall']> = {
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type LeadsAndSponsorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['LeadsAndSponsors'] = ResolversParentTypes['LeadsAndSponsors']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegendLabelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['LegendLabels'] = ResolversParentTypes['LegendLabels']> = {
  high?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  low?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  translatedHigh?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  translatedLow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoggedInUserResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoggedInUserResponse'] = ResolversParentTypes['LoggedInUserResponse']> = {
  autoTranslateConversationsEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Person'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MagicTokenAuthResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MagicTokenAuthResponse'] = ResolversParentTypes['MagicTokenAuthResponse']> = {
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requiresDeviceVerification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManyConversationsUpdateSubscriptionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManyConversationsUpdateSubscriptionResponse'] = ResolversParentTypes['ManyConversationsUpdateSubscriptionResponse']> = {
  updates?: Resolver<Array<ResolversTypes['ConversationSubscriptionResponse']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MatchingPersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['MatchingPerson'] = ResolversParentTypes['MatchingPerson']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integrationPersonId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeletionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageDeletion'] = ResolversParentTypes['MessageDeletion']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageUpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageUpdate'] = ResolversParentTypes['MessageUpdate']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedMessageProperties?: Resolver<ResolversTypes['UpdatedMessageProperties'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['Metadata'] = ResolversParentTypes['Metadata']> = {
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  categoryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questionText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MicrosoftGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['MicrosoftGroup'] = ResolversParentTypes['MicrosoftGroup']> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MicrosoftGroupResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MicrosoftGroupResponse'] = ResolversParentTypes['MicrosoftGroupResponse']> = {
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MicrosoftPeopleSyncIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['MicrosoftPeopleSyncIntegration'] = ResolversParentTypes['MicrosoftPeopleSyncIntegration']> = {
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MsParametersResolvers<ContextType = any, ParentType extends ResolversParentTypes['MsParameters'] = ResolversParentTypes['MsParameters']> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutateGroupVisibilityResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutateGroupVisibilityResponse'] = ResolversParentTypes['MutateGroupVisibilityResponse']> = {
  relationship?: Resolver<Maybe<ResolversTypes['NonHierarchicalPersonRelationship']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  acceptActionItemSuggestion?: Resolver<Maybe<ResolversTypes['CampaignActionPlanAndSuggestions']>, ParentType, ContextType, RequireFields<MutationAcceptActionItemSuggestionArgs, 'actionPlanItemSuggestionId' | 'campaignId'>>;
  addComment?: Resolver<ResolversTypes['BaseAnswerResponse'], ParentType, ContextType, RequireFields<MutationAddCommentArgs, 'conversationId' | 'newMessageId'>>;
  addDevice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationAddDeviceArgs, 'deviceId' | 'tokenId'>>;
  addManualAttribute?: Resolver<ResolversTypes['AddManualAttributeResponse'], ParentType, ContextType, RequireFields<MutationAddManualAttributeArgs, 'attributeName'>>;
  addToGoogleDrive?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationAddToGoogleDriveArgs, 'campaignId'>>;
  answerFilteredSelfSelect?: Resolver<ResolversTypes['SelfSelectResponse'], ParentType, ContextType, RequireFields<MutationAnswerFilteredSelfSelectArgs, 'conversationId' | 'messageId' | 'option'>>;
  answerRating?: Resolver<Maybe<ResolversTypes['BaseAnswerResponse']>, ParentType, ContextType, RequireFields<MutationAnswerRatingArgs, 'conversationId' | 'messageId'>>;
  answerRatingWidgetViaPixel?: Resolver<Maybe<ResolversTypes['ErrorResponse']>, ParentType, ContextType, RequireFields<MutationAnswerRatingWidgetViaPixelArgs, 'conversationId' | 'messageId' | 'personTokenId'>>;
  answerSelectMany?: Resolver<ResolversTypes['BaseAnswerResponse'], ParentType, ContextType, RequireFields<MutationAnswerSelectManyArgs, 'conversationId' | 'messageId'>>;
  answerSelectOne?: Resolver<ResolversTypes['BaseAnswerResponse'], ParentType, ContextType, RequireFields<MutationAnswerSelectOneArgs, 'conversationId' | 'messageId'>>;
  answerSelfSelect?: Resolver<ResolversTypes['SelfSelectResponse'], ParentType, ContextType, RequireFields<MutationAnswerSelfSelectArgs, 'conversationId' | 'messageId' | 'option'>>;
  applyTemplateToCampaign?: Resolver<ResolversTypes['Campaign'], ParentType, ContextType, RequireFields<MutationApplyTemplateToCampaignArgs, 'campaignId' | 'templateId'>>;
  authenticateMicrosoftViaCode?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationAuthenticateMicrosoftViaCodeArgs>>;
  autoTheme?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAutoThemeArgs, 'campaignId' | 'command'>>;
  bulkReplyNoComments?: Resolver<ResolversTypes['BulkReplyNoCommentsResponse'], ParentType, ContextType, RequireFields<MutationBulkReplyNoCommentsArgs, 'bulkReplyText' | 'campaignId' | 'conversationSchemeId'>>;
  createActionPlanItem?: Resolver<ResolversTypes['CampaignActionPlan'], ParentType, ContextType, RequireFields<MutationCreateActionPlanItemArgs, 'actionPlanItem' | 'campaignId'>>;
  createCampaign?: Resolver<ResolversTypes['Campaign'], ParentType, ContextType, RequireFields<MutationCreateCampaignArgs, 'boardId' | 'campaignStatus' | 'endDateString' | 'startDateString'>>;
  createImpactMetric?: Resolver<ResolversTypes['CampaignImpactMetrics'], ParentType, ContextType, RequireFields<MutationCreateImpactMetricArgs, 'campaignId' | 'impactMetricInput'>>;
  createOrUpdateNudgeReminder?: Resolver<ResolversTypes['CampaignDetails'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateNudgeReminderArgs, 'audience' | 'campaignId' | 'chatAppsText' | 'conversationSchemeId' | 'defaultSubject' | 'emailText' | 'scheduledSend' | 'smsText'>>;
  createPlanningBoard?: Resolver<ResolversTypes['PlanningBoard'], ParentType, ContextType, RequireFields<MutationCreatePlanningBoardArgs, 'name'>>;
  deleteActionPlanItem?: Resolver<Maybe<ResolversTypes['CampaignActionPlan']>, ParentType, ContextType, RequireFields<MutationDeleteActionPlanItemArgs, 'actionPlanItemHash' | 'actionPlanItemId' | 'campaignId'>>;
  deleteCampaign?: Resolver<ResolversTypes['DeleteCampaignResponse'], ParentType, ContextType, RequireFields<MutationDeleteCampaignArgs, '_id'>>;
  deleteCampaignThemesReportMessage?: Resolver<ResolversTypes['CampaignDetails'], ParentType, ContextType, RequireFields<MutationDeleteCampaignThemesReportMessageArgs, 'audienceThemesReportId' | 'campaignId'>>;
  deleteComment?: Resolver<ResolversTypes['DeleteCommentResponse'], ParentType, ContextType, RequireFields<MutationDeleteCommentArgs, 'conversationId' | 'messageId'>>;
  deleteImpactMetric?: Resolver<Maybe<ResolversTypes['CampaignImpactMetrics']>, ParentType, ContextType, RequireFields<MutationDeleteImpactMetricArgs, 'campaignId' | 'impactMetricHash' | 'impactMetricId'>>;
  deleteLiveTestData?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteLiveTestDataArgs, 'campaignId'>>;
  deleteNudgeReminder?: Resolver<ResolversTypes['CampaignDetails'], ParentType, ContextType, RequireFields<MutationDeleteNudgeReminderArgs, 'campaignId' | 'reminderId'>>;
  deleteSampleData?: Resolver<ResolversTypes['CampaignHashResponse'], ParentType, ContextType, RequireFields<MutationDeleteSampleDataArgs, 'campaignId'>>;
  deleteTemplate?: Resolver<ResolversTypes['DeleteTemplateResponse'], ParentType, ContextType, RequireFields<MutationDeleteTemplateArgs, 'templateId'>>;
  editActionPlanItem?: Resolver<ResolversTypes['CampaignActionPlan'], ParentType, ContextType, RequireFields<MutationEditActionPlanItemArgs, 'actionPlanItem' | 'actionPlanItemHash' | 'actionPlanItemId' | 'campaignId'>>;
  editCommentText?: Resolver<ResolversTypes['EditCommentTextResponse'], ParentType, ContextType, RequireFields<MutationEditCommentTextArgs, 'commentText' | 'conversationId' | 'messageId'>>;
  editImpactMetric?: Resolver<ResolversTypes['CampaignImpactMetrics'], ParentType, ContextType, RequireFields<MutationEditImpactMetricArgs, 'campaignId' | 'impactMetricHash' | 'impactMetricId' | 'impactMetricInput'>>;
  editPlanningBoards?: Resolver<Array<ResolversTypes['PlanningBoard']>, ParentType, ContextType, RequireFields<MutationEditPlanningBoardsArgs, 'input'>>;
  flagAIComment?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationFlagAiCommentArgs, 'conversationId' | 'messageId' | 'quality'>>;
  generateSampleData?: Resolver<ResolversTypes['CampaignHashResponse'], ParentType, ContextType, RequireFields<MutationGenerateSampleDataArgs, 'campaignId' | 'conversationSchemeId' | 'promptContext'>>;
  generateThemeSnapshot?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationGenerateThemeSnapshotArgs, 'campaignId'>>;
  ignoreActionItemSuggestion?: Resolver<Maybe<ResolversTypes['CampaignActionPlanSuggestions']>, ParentType, ContextType, RequireFields<MutationIgnoreActionItemSuggestionArgs, 'actionPlanItemSuggestionId' | 'campaignId'>>;
  passwordAuth?: Resolver<ResolversTypes['AuthResponse'], ParentType, ContextType, Partial<MutationPasswordAuthArgs>>;
  passwordReset?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationPasswordResetArgs, 'email'>>;
  publishThemes?: Resolver<ResolversTypes['PublishThemesResponse'], ParentType, ContextType, RequireFields<MutationPublishThemesArgs, 'campaignId'>>;
  refreshAuth?: Resolver<ResolversTypes['AuthResponse'], ParentType, ContextType, Partial<MutationRefreshAuthArgs>>;
  reportAIComment?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationReportAiCommentArgs, 'conversationId' | 'messageId'>>;
  saveGroupVisibilityRelationship?: Resolver<ResolversTypes['MutateGroupVisibilityResponse'], ParentType, ContextType, RequireFields<MutationSaveGroupVisibilityRelationshipArgs, 'input'>>;
  sendLiveTest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendLiveTestArgs, 'campaignId' | 'conversationSchemeId' | 'testAudience'>>;
  setPassword?: Resolver<Maybe<ResolversTypes['PersonResponse']>, ParentType, ContextType, RequireFields<MutationSetPasswordArgs, 'password' | 'userToken'>>;
  setReaction?: Resolver<ResolversTypes['SetReactionResponse'], ParentType, ContextType, RequireFields<MutationSetReactionArgs, 'conversationId' | 'messageId'>>;
  setResponderIsViewingConversation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetResponderIsViewingConversationArgs, 'conversationId' | 'responderName'>>;
  setResponderTyping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetResponderTypingArgs, 'conversationId' | 'responderName'>>;
  setupMicrosoftIntegration?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationSetupMicrosoftIntegrationArgs>>;
  signOut?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, Partial<MutationSignOutArgs>>;
  skipConversationPrompt?: Resolver<ResolversTypes['BaseAnswerResponse'], ParentType, ContextType, RequireFields<MutationSkipConversationPromptArgs, 'conversationId' | 'conversationPromptMessageId' | 'newMessageId'>>;
  toggleConversationStarredState?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationToggleConversationStarredStateArgs, 'conversationId' | 'isStarred'>>;
  toggleFeatureComment?: Resolver<ResolversTypes['ToggleFeatureCommentResponse'], ParentType, ContextType, RequireFields<MutationToggleFeatureCommentArgs, 'conversationId' | 'messageId'>>;
  updateActionPlanOrder?: Resolver<Maybe<ResolversTypes['CampaignActionPlan']>, ParentType, ContextType, RequireFields<MutationUpdateActionPlanOrderArgs, 'campaignId' | 'newActionPlanItemOrder' | 'prevActionPlanItemOrder'>>;
  updateActionPlanTaskCompletion?: Resolver<ResolversTypes['ActionPlanTask'], ParentType, ContextType, RequireFields<MutationUpdateActionPlanTaskCompletionArgs, 'actionPlanItemId' | 'campaignId' | 'taskCompletionState'>>;
  updateBoardCards?: Resolver<ResolversTypes['UpdateBoardCardsResponse'], ParentType, ContextType, RequireFields<MutationUpdateBoardCardsArgs, 'boardId' | 'campaignId' | 'updatedSortedCampaignIds' | 'usersTimezone'>>;
  updateCampaignAudience?: Resolver<ResolversTypes['UpdateCampaignAudienceResponse'], ParentType, ContextType, RequireFields<MutationUpdateCampaignAudienceArgs, 'audienceType' | 'campaignHash' | 'campaignId'>>;
  updateCampaignContext?: Resolver<ResolversTypes['Campaign'], ParentType, ContextType, RequireFields<MutationUpdateCampaignContextArgs, '_id' | 'campaignHash' | 'estimatedROI' | 'goal' | 'name' | 'planningBoardId' | 'roiHypothesis' | 'shouldNotifyLeadsAndSponsors'>>;
  updateCampaignResponderSettings?: Resolver<ResolversTypes['Campaign'], ParentType, ContextType, RequireFields<MutationUpdateCampaignResponderSettingsArgs, '_id' | 'aifResponseRule' | 'campaignHash' | 'hierarchical' | 'isAIFacilitatorEnabled' | 'nonHierarchical' | 'responseDelayInHours' | 'shouldNotifyResponders' | 'weekday'>>;
  updateCampaignStatus?: Resolver<ResolversTypes['UpdateCampaignStatusResponse'], ParentType, ContextType, RequireFields<MutationUpdateCampaignStatusArgs, 'campaignHash' | 'campaignId' | 'status'>>;
  updateCampaignTheme?: Resolver<ResolversTypes['UpdateCampaignThemeResponse'], ParentType, ContextType, RequireFields<MutationUpdateCampaignThemeArgs, 'campaignId' | 'themeId' | 'themeName' | 'themeSummary' | 'themesHash'>>;
  updateCampaignThemes?: Resolver<ResolversTypes['UpdateCampaignThemesResponse'], ParentType, ContextType, RequireFields<MutationUpdateCampaignThemesArgs, 'campaignId' | 'campaignThemeState' | 'themesHash'>>;
  updateCampaignThemesReportMessage?: Resolver<ResolversTypes['CampaignDetails'], ParentType, ContextType, RequireFields<MutationUpdateCampaignThemesReportMessageArgs, 'audienceThemesReport' | 'campaignId'>>;
  updateCampaignTiming?: Resolver<ResolversTypes['UpdateCampaignTimingResponse'], ParentType, ContextType, RequireFields<MutationUpdateCampaignTimingArgs, '_id' | 'campaignHash' | 'defaultSendTime' | 'timezone'>>;
  updateCategoryTemplateOrder?: Resolver<ResolversTypes['TemplateCategory'], ParentType, ContextType, RequireFields<MutationUpdateCategoryTemplateOrderArgs, 'categoryId' | 'templateIds'>>;
  updateCommentPhrases?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationUpdateCommentPhrasesArgs, 'campaignId' | 'commentPhrases'>>;
  updateConversationSchemeSendTimes?: Resolver<ResolversTypes['UpdateConversationSchemeSendTimesResponse'], ParentType, ContextType, RequireFields<MutationUpdateConversationSchemeSendTimesArgs, 'campaignHash' | 'campaignId' | 'conversationSchemeId' | 'endDateString' | 'startDateString'>>;
  updateDeliveriesToResend?: Resolver<ResolversTypes['UpdateDeliveriesToResendResponse'], ParentType, ContextType, RequireFields<MutationUpdateDeliveriesToResendArgs, 'campaignId' | 'deliveryContentSelector' | 'deliveryType' | 'errorCode' | 'resendAmount' | 'smsText'>>;
  updateOrgSettings?: Resolver<ResolversTypes['UpdateOrgSettingsResponse'], ParentType, ContextType, RequireFields<MutationUpdateOrgSettingsArgs, 'aiSettings' | 'contactMethods' | 'multiLanguageConversationsEnabled' | 'permissions' | 'primaryLocale'>>;
  updateOwnerNotificationsForConversation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateOwnerNotificationsForConversationArgs, 'conversationId' | 'viewStatus'>>;
  updateParticipantNotificationsForConversations?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateParticipantNotificationsForConversationsArgs, 'conversationIds' | 'viewStatus'>>;
  updatePeopleIntegrationSettings?: Resolver<ResolversTypes['UpdatePeopleIntegrationSettingsResponse'], ParentType, ContextType, RequireFields<MutationUpdatePeopleIntegrationSettingsArgs, 'attributes' | 'coreData'>>;
  updatePersonPreferredLocale?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationUpdatePersonPreferredLocaleArgs, 'preferredLocale'>>;
  updateSendMethods?: Resolver<ResolversTypes['UpdateSendMethodsResponse'], ParentType, ContextType, RequireFields<MutationUpdateSendMethodsArgs, '_id' | 'campaignHash' | 'contactMethods' | 'emailSettings'>>;
  uploadCampaignConversationSchemesCsv?: Resolver<ResolversTypes['UploadCampaignConversationSchemesCsvResponse'], ParentType, ContextType, RequireFields<MutationUploadCampaignConversationSchemesCsvArgs, 'campaignHash' | 'campaignId' | 'conversationCsvRows' | 'fileName'>>;
  upsertConversationSchemes?: Resolver<ResolversTypes['Campaign'], ParentType, ContextType, RequireFields<MutationUpsertConversationSchemesArgs, 'campaignHash' | 'campaignId' | 'formSchemes'>>;
  upsertTemplate?: Resolver<ResolversTypes['UpsertTemplateResponse'], ParentType, ContextType, RequireFields<MutationUpsertTemplateArgs, 'categories' | 'color' | 'conversationSchemes' | 'goal' | 'industry' | 'name'>>;
};

export type NextBlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['NextBlock'] = ResolversParentTypes['NextBlock']> = {
  advanceOn?: Resolver<ResolversTypes['BlockAdvanceCondition'], ParentType, ContextType>;
  next?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NextBlockWithAudienceResolvers<ContextType = any, ParentType extends ResolversParentTypes['NextBlockWithAudience'] = ResolversParentTypes['NextBlockWithAudience']> = {
  advanceOn?: Resolver<ResolversTypes['BlockAdvanceCondition'], ParentType, ContextType>;
  audience?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  next?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NonHierarchicalPersonRelationshipResolvers<ContextType = any, ParentType extends ResolversParentTypes['NonHierarchicalPersonRelationship'] = ResolversParentTypes['NonHierarchicalPersonRelationship']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  identifierAttribute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isManual?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  leaderIdentifierAttribute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['RelationshipMetadata']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visibilityMap?: Resolver<Maybe<Array<ResolversTypes['VisibilityMapEntry']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NonHierarchicalPersonVisibilityMapResolvers<ContextType = any, ParentType extends ResolversParentTypes['NonHierarchicalPersonVisibilityMap'] = ResolversParentTypes['NonHierarchicalPersonVisibilityMap']> = {
  identifierValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leaderValues?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OktaParametersResolvers<ContextType = any, ParentType extends ResolversParentTypes['OktaParameters'] = ResolversParentTypes['OktaParameters']> = {
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  oktaDomain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Option'] = ResolversParentTypes['Option']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgResolvers<ContextType = any, ParentType extends ResolversParentTypes['Org'] = ResolversParentTypes['Org']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  industry?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgAttributeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrgAttribute'] = ResolversParentTypes['OrgAttribute']> = {
  isFilterable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isVisible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgFilteredAttributeValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrgFilteredAttributeValue'] = ResolversParentTypes['OrgFilteredAttributeValue']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrgInfo'] = ResolversParentTypes['OrgInfo']> = {
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  industry?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgIntegrationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrgIntegrations'] = ResolversParentTypes['OrgIntegrations']> = {
  csv?: Resolver<Maybe<ResolversTypes['CsvIntegration']>, ParentType, ContextType>;
  microsoftPeopleSync?: Resolver<Maybe<ResolversTypes['MicrosoftPeopleSyncIntegration']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgNotificationSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrgNotificationSettings'] = ResolversParentTypes['OrgNotificationSettings']> = {
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgPermissionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrgPermissions'] = ResolversParentTypes['OrgPermissions']> = {
  admin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  calendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dashboard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  feed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  participation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  planningBoard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgResponderSettingsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrgResponderSettingsResponse'] = ResolversParentTypes['OrgResponderSettingsResponse']> = {
  hasHierarchicalRelationship?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasNonHierarchicalRelationship?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isResponderOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrgSettings'] = ResolversParentTypes['OrgSettings']> = {
  aiSettings?: Resolver<ResolversTypes['AiSettings'], ParentType, ContextType>;
  attributes?: Resolver<Array<Maybe<ResolversTypes['OrgAttribute']>>, ParentType, ContextType>;
  autoTranslateConversationsEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  contactMethods?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  deviceRegistrationEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  integrations?: Resolver<Maybe<ResolversTypes['OrgIntegrations']>, ParentType, ContextType>;
  multiLanguageConversationsEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  notifications?: Resolver<ResolversTypes['OrgNotificationSettings'], ParentType, ContextType>;
  permissions?: Resolver<Maybe<ResolversTypes['OrgPermissions']>, ParentType, ContextType>;
  primaryLocale?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OtherImpactMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['OtherImpactMetric'] = ResolversParentTypes['OtherImpactMetric']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Array<ResolversTypes['ImpactMetricDocument']>, ParentType, ContextType>;
  hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  otherRoi?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roiAchievedDate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ImpactMetricType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PipResolvers<ContextType = any, ParentType extends ResolversParentTypes['PIP'] = ResolversParentTypes['PIP']> = {
  integrationPersonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParticipantNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParticipantNotification'] = ResolversParentTypes['ParticipantNotification']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  actionType?: Resolver<ResolversTypes['ParticipantNotificationType'], ParentType, ContextType>;
  actor?: Resolver<ResolversTypes['Person'], ParentType, ContextType>;
  campaignId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['ParticipantNotificationContent'], ParentType, ContextType>;
  conversationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  conversationOwner?: Resolver<ResolversTypes['Person'], ParentType, ContextType>;
  creationTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  mergedIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  viewStatus?: Resolver<ResolversTypes['NotificationViewStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParticipantNotificationContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParticipantNotificationContent'] = ResolversParentTypes['ParticipantNotificationContent']> = {
  commentHasImage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  commentText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reactionType?: Resolver<Maybe<ResolversTypes['ReactionType']>, ParentType, ContextType>;
  selectedValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  selectedValues?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParticipationHeatmapResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParticipationHeatmap'] = ResolversParentTypes['ParticipationHeatmap']> = {
  columnNames?: Resolver<Maybe<Array<ResolversTypes['ColumnName']>>, ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['RowStats']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParticipationStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParticipationStats'] = ResolversParentTypes['ParticipationStats']> = {
  campaignAudienceCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  campaignParticipantCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversationSchemeParticipationStats?: Resolver<Array<ResolversTypes['ConversationSchemeParticipationStats']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PeopleIntegrationSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PeopleIntegrationSettings'] = ResolversParentTypes['PeopleIntegrationSettings']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  familyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  givenName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  integrationPersonId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  managerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobilePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredContactMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Permissions'] = ResolversParentTypes['Permissions']> = {
  admin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  calendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  calendarEdit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  calendarEditElevated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  commentingDisabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dashboard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  feed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  galleryEdit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isFromExtendedLogin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  participation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  planningBoard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  planningBoardEdit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pulse?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  recentConversationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamMemberUIToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  theming?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionsOnPersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['PermissionsOnPerson'] = ResolversParentTypes['PermissionsOnPerson']> = {
  admin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  calendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  calendarEdit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  calendarEditElevated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  commentingDisabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dashboard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  feed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  galleryEdit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  participation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  planningBoard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  planningBoardEdit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  theming?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['Person'] = ResolversParentTypes['Person']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  archivableAfter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  attributes?: Resolver<Array<ResolversTypes['AttributeOnPerson']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['PersonAvatar']>, ParentType, ContextType>;
  computedEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  customerEmployeeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  familyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  givenName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasFullVisibility?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isFromAudienceUpload?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isJoyousAdmin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastConversationSent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manager?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  microsoftDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoftId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoftManagerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobilePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  permissions?: Resolver<Maybe<ResolversTypes['PermissionsOnPerson']>, ParentType, ContextType>;
  pip?: Resolver<Maybe<ResolversTypes['PIP']>, ParentType, ContextType>;
  preferredLocale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  relationships?: Resolver<Array<ResolversTypes['RelationshipVisibility']>, ParentType, ContextType>;
  teamMemberUIToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonAvatarResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonAvatar'] = ResolversParentTypes['PersonAvatar']> = {
  initials?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonInScopeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonInScope'] = ResolversParentTypes['PersonInScope']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isDirectReport?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  managerIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonMatchesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonMatchesResponse'] = ResolversParentTypes['PersonMatchesResponse']> = {
  archivedPersonIdentifiers?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  matchingPersons?: Resolver<Array<ResolversTypes['MatchingPerson']>, ParentType, ContextType>;
  notInJoyousIdentifiers?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonResponse'] = ResolversParentTypes['PersonResponse']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['Error']>>>, ParentType, ContextType>;
  person?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonSettings'] = ResolversParentTypes['PersonSettings']> = {
  permissions?: Resolver<Maybe<ResolversTypes['Permissions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonsIngestionStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonsIngestionStatus'] = ResolversParentTypes['PersonsIngestionStatus']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  campaignId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  events?: Resolver<Array<ResolversTypes['PersonsIngestionStatusEvent']>, ParentType, ContextType>;
  fieldId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileUploadedTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  initiatedTimestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  retriesLeft?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonsIngestionStatusEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonsIngestionStatusEvent'] = ResolversParentTypes['PersonsIngestionStatusEvent']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanningBoardResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanningBoard'] = ResolversParentTypes['PlanningBoard']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdByUserId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  defaultROI?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  multiLanguageConversationsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  primaryLocale?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sortedCampaignIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanningBoardCampaignResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanningBoardCampaign'] = ResolversParentTypes['PlanningBoardCampaign']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  estimatedROI?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  firstSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  goal?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leads?: Resolver<Maybe<Array<ResolversTypes['LeadsAndSponsors']>>, ParentType, ContextType>;
  leadsRelationship?: Resolver<Maybe<ResolversTypes['CampaignRelationship']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roiHypothesis?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sponsors?: Resolver<Maybe<Array<ResolversTypes['LeadsAndSponsors']>>, ParentType, ContextType>;
  sponsorsRelationship?: Resolver<Maybe<ResolversTypes['CampaignRelationship']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CampaignStatus'], ParentType, ContextType>;
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanningBoardCampaignsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanningBoardCampaignsResponse'] = ResolversParentTypes['PlanningBoardCampaignsResponse']> = {
  campaigns?: Resolver<Array<ResolversTypes['PlanningBoardCampaign']>, ParentType, ContextType>;
  planningBoardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanningBoardExportResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanningBoardExportResponse'] = ResolversParentTypes['PlanningBoardExportResponse']> = {
  exportBase64String?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrefixColumnResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrefixColumn'] = ResolversParentTypes['PrefixColumn']> = {
  csvColumn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublishThemesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublishThemesResponse'] = ResolversParentTypes['PublishThemesResponse']> = {
  campaignHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  campaignId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  actionablePhrasesAllThemes?: Resolver<Array<ResolversTypes['ActionablePhrasesPerTheme']>, ParentType, ContextType, Partial<QueryActionablePhrasesAllThemesArgs>>;
  actionablePhrasesPerTheme?: Resolver<Array<ResolversTypes['ActionablePhrasesPerTheme']>, ParentType, ContextType, RequireFields<QueryActionablePhrasesPerThemeArgs, 'themeIds'>>;
  attributes?: Resolver<Array<Maybe<ResolversTypes['AttributeOptions']>>, ParentType, ContextType, Partial<QueryAttributesArgs>>;
  bulkReplyPreview?: Resolver<ResolversTypes['BulkReplyPreviewResponse'], ParentType, ContextType, RequireFields<QueryBulkReplyPreviewArgs, 'campaignId' | 'conversationSchemeId'>>;
  campaign?: Resolver<Maybe<ResolversTypes['Campaign']>, ParentType, ContextType, RequireFields<QueryCampaignArgs, 'campaignId'>>;
  campaignActionPlan?: Resolver<ResolversTypes['CampaignActionPlan'], ParentType, ContextType, RequireFields<QueryCampaignActionPlanArgs, 'campaignId'>>;
  campaignActionPlanExport?: Resolver<ResolversTypes['CampaignActionPlanExportResponse'], ParentType, ContextType, RequireFields<QueryCampaignActionPlanExportArgs, 'campaignId' | 'fileType' | 'title'>>;
  campaignActionPlanSuggestions?: Resolver<ResolversTypes['CampaignActionPlanSuggestions'], ParentType, ContextType, RequireFields<QueryCampaignActionPlanSuggestionsArgs, 'campaignId'>>;
  campaignAttributeStats?: Resolver<ResolversTypes['CampaignAttributeStats'], ParentType, ContextType, RequireFields<QueryCampaignAttributeStatsArgs, 'attribute'>>;
  campaignAudienceCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType, RequireFields<QueryCampaignAudienceCountArgs, 'audienceType'>>;
  campaignDesignerSummaryExport?: Resolver<ResolversTypes['CampaignDesignerSummaryExportResponse'], ParentType, ContextType, RequireFields<QueryCampaignDesignerSummaryExportArgs, 'campaignDetailsCanvas' | 'designerCanvasPages' | 'fileType' | 'title'>>;
  campaignDetails?: Resolver<ResolversTypes['CampaignDetails'], ParentType, ContextType, RequireFields<QueryCampaignDetailsArgs, 'campaignId'>>;
  campaignEngagementHeatmap?: Resolver<ResolversTypes['CampaignEngagementHeatmap'], ParentType, ContextType, RequireFields<QueryCampaignEngagementHeatmapArgs, 'attribute' | 'campaignId'>>;
  campaignEngagementStats?: Resolver<ResolversTypes['CampaignEngagementStats'], ParentType, ContextType, RequireFields<QueryCampaignEngagementStatsArgs, 'campaignId'>>;
  campaignImpactMetrics?: Resolver<ResolversTypes['CampaignImpactMetrics'], ParentType, ContextType, RequireFields<QueryCampaignImpactMetricsArgs, 'campaignId'>>;
  campaignRelationships?: Resolver<Array<Maybe<ResolversTypes['Relationship']>>, ParentType, ContextType, RequireFields<QueryCampaignRelationshipsArgs, 'campaignId'>>;
  campaignStats?: Resolver<ResolversTypes['CampaignStats'], ParentType, ContextType, RequireFields<QueryCampaignStatsArgs, 'timezone'>>;
  campaignThemesExport?: Resolver<ResolversTypes['CampaignThemesExportResponse'], ParentType, ContextType, RequireFields<QueryCampaignThemesExportArgs, 'fileType' | 'linkToActions' | 'linkToThemes' | 'summaryPage' | 'title'>>;
  campaignsInTimeRange?: Resolver<ResolversTypes['CampaignsInTimeRangeResponse'], ParentType, ContextType, RequireFields<QueryCampaignsInTimeRangeArgs, 'endDate' | 'startDate'>>;
  campaignsTimezones?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  categories?: Resolver<Array<ResolversTypes['Category']>, ParentType, ContextType>;
  conversationList?: Resolver<Array<ResolversTypes['Conversation']>, ParentType, ContextType, RequireFields<QueryConversationListArgs, 'client' | 'conversationIds'>>;
  conversationSchemeSendInfo?: Resolver<ResolversTypes['ConversationSchemeSendInfo'], ParentType, ContextType, RequireFields<QueryConversationSchemeSendInfoArgs, 'campaignId' | 'conversationSchemeId'>>;
  conversationWithParticipants?: Resolver<Maybe<ResolversTypes['Conversation']>, ParentType, ContextType, RequireFields<QueryConversationWithParticipantsArgs, '_id' | 'client'>>;
  distributedAudienceExport?: Resolver<ResolversTypes['DistributedAudienceExportResponse'], ParentType, ContextType, RequireFields<QueryDistributedAudienceExportArgs, 'audience' | 'campaignId' | 'timezone'>>;
  downloadImpactMetricDocumentPresignedUrl?: Resolver<ResolversTypes['DownloadImpactMetricDocumentPresignedUrl'], ParentType, ContextType, RequireFields<QueryDownloadImpactMetricDocumentPresignedUrlArgs, 'campaignId' | 'documentUploadId' | 'impactMetricHash' | 'impactMetricId'>>;
  downloadPeopleDataPresignedUrl?: Resolver<ResolversTypes['DownloadPeopleDataPresignedUrl'], ParentType, ContextType>;
  earliestCalendarDate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  extendedSignIn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, Partial<QueryExtendedSignInArgs>>;
  filters?: Resolver<Maybe<ResolversTypes['Filters']>, ParentType, ContextType>;
  getCampaignThemes?: Resolver<ResolversTypes['GetCampaignThemesResponse'], ParentType, ContextType, RequireFields<QueryGetCampaignThemesArgs, 'campaignId'>>;
  getCommentPhrases?: Resolver<Array<ResolversTypes['CommentPhrases']>, ParentType, ContextType, RequireFields<QueryGetCommentPhrasesArgs, 'campaignId'>>;
  getGroupVisibilityRelationship?: Resolver<Maybe<ResolversTypes['NonHierarchicalPersonRelationship']>, ParentType, ContextType, RequireFields<QueryGetGroupVisibilityRelationshipArgs, 'attributeName'>>;
  getNudgeAudienceCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryGetNudgeAudienceCountArgs, 'audience' | 'campaignId' | 'conversationSchemeId'>>;
  getOrgAttributeValues?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryGetOrgAttributeValuesArgs, 'attributeName'>>;
  getOrgFilteredAttributeValues?: Resolver<Array<ResolversTypes['OrgFilteredAttributeValue']>, ParentType, ContextType, RequireFields<QueryGetOrgFilteredAttributeValuesArgs, 'attributeName' | 'filteredAttributeName'>>;
  getSsoInfo?: Resolver<Maybe<ResolversTypes['GetSsoInfoResponse']>, ParentType, ContextType, Partial<QueryGetSsoInfoArgs>>;
  informLeadsAndSponsorsAudienceCount?: Resolver<ResolversTypes['InformLeadsAndSponsorsAudienceCountResponse'], ParentType, ContextType, RequireFields<QueryInformLeadsAndSponsorsAudienceCountArgs, 'campaignId'>>;
  informRespondersAudienceCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryInformRespondersAudienceCountArgs, 'campaignId'>>;
  latestIntegrationStatus?: Resolver<Maybe<ResolversTypes['PersonsIngestionStatus']>, ParentType, ContextType, Partial<QueryLatestIntegrationStatusArgs>>;
  leaderNotifications?: Resolver<Maybe<Array<ResolversTypes['ParticipantNotification']>>, ParentType, ContextType>;
  loggedInUser?: Resolver<ResolversTypes['LoggedInUserResponse'], ParentType, ContextType>;
  magicTokenAuth?: Resolver<ResolversTypes['MagicTokenAuthResponse'], ParentType, ContextType, Partial<QueryMagicTokenAuthArgs>>;
  microsoftSso?: Resolver<ResolversTypes['AuthResponse'], ParentType, ContextType, Partial<QueryMicrosoftSsoArgs>>;
  mostRecentPeopleDataFile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oktaSso?: Resolver<ResolversTypes['AuthResponse'], ParentType, ContextType, Partial<QueryOktaSsoArgs>>;
  org?: Resolver<ResolversTypes['Org'], ParentType, ContextType>;
  orgIntegrationInstall?: Resolver<Array<Maybe<ResolversTypes['IntegrationInstall']>>, ParentType, ContextType>;
  orgResponderSettings?: Resolver<ResolversTypes['OrgResponderSettingsResponse'], ParentType, ContextType>;
  participationHeatmap?: Resolver<ResolversTypes['ParticipationHeatmap'], ParentType, ContextType, RequireFields<QueryParticipationHeatmapArgs, 'type'>>;
  personMatches?: Resolver<ResolversTypes['PersonMatchesResponse'], ParentType, ContextType, RequireFields<QueryPersonMatchesArgs, 'personIdentifiers'>>;
  personSearch?: Resolver<Array<ResolversTypes['Person']>, ParentType, ContextType, RequireFields<QueryPersonSearchArgs, 'searchString'>>;
  persons?: Resolver<Array<ResolversTypes['Person']>, ParentType, ContextType, RequireFields<QueryPersonsArgs, '_ids'>>;
  personsAttributeNames?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  personsAttributeValues?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryPersonsAttributeValuesArgs, 'attributeName'>>;
  personsInScope?: Resolver<Maybe<Array<Maybe<ResolversTypes['PersonInScope']>>>, ParentType, ContextType>;
  planningBoardCampaigns?: Resolver<ResolversTypes['PlanningBoardCampaignsResponse'], ParentType, ContextType, RequireFields<QueryPlanningBoardCampaignsArgs, 'planningBoardId'>>;
  planningBoardExport?: Resolver<ResolversTypes['PlanningBoardExportResponse'], ParentType, ContextType, RequireFields<QueryPlanningBoardExportArgs, 'boardContentPages' | 'exportHeader' | 'fileType' | 'quarterHeaders' | 'title'>>;
  planningBoards?: Resolver<Array<ResolversTypes['PlanningBoard']>, ParentType, ContextType>;
  relationships?: Resolver<Array<ResolversTypes['Relationship']>, ParentType, ContextType, RequireFields<QueryRelationshipsArgs, '_ids'>>;
  reportExport?: Resolver<ResolversTypes['ReportExportResponse'], ParentType, ContextType, RequireFields<QueryReportExportArgs, 'reportSpecificInput' | 'title'>>;
  reportingStats?: Resolver<ResolversTypes['ReportingStatsResponse'], ParentType, ContextType, RequireFields<QueryReportingStatsArgs, 'campaignId'>>;
  resendSmsStatus?: Resolver<ResolversTypes['ResendSmsStatusResponse'], ParentType, ContextType, RequireFields<QueryResendSmsStatusArgs, 'deliveryTypeMetadata'>>;
  settings?: Resolver<Maybe<ResolversTypes['Settings']>, ParentType, ContextType>;
  teamMemberConversations?: Resolver<Maybe<ResolversTypes['TeamMemberConversationsResponse']>, ParentType, ContextType, Partial<QueryTeamMemberConversationsArgs>>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType, RequireFields<QueryTemplateArgs, 'templateId'>>;
  templateCategories?: Resolver<Array<ResolversTypes['TemplateCategory']>, ParentType, ContextType>;
  templates?: Resolver<Array<ResolversTypes['Template']>, ParentType, ContextType>;
  themesReportDownload?: Resolver<ResolversTypes['ThemesReportDownloadResponse'], ParentType, ContextType, RequireFields<QueryThemesReportDownloadArgs, 'campaignId' | 'themesReportId'>>;
  timelineCampaigns?: Resolver<ResolversTypes['TimelineCampaignsResponse'], ParentType, ContextType, RequireFields<QueryTimelineCampaignsArgs, 'selectedPlanningBoardId'>>;
  translate?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryTranslateArgs, 'texts'>>;
  uploadImpactMetricDocumentsPresignedUrl?: Resolver<Array<ResolversTypes['UploadImpactMetricDocumentsPresignedUrlResponse']>, ParentType, ContextType, RequireFields<QueryUploadImpactMetricDocumentsPresignedUrlArgs, 'campaignId' | 'documents'>>;
  uploadPeopleDataPresignedUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryUploadPeopleDataPresignedUrlArgs, 'fileName'>>;
  verifyDevice?: Resolver<ResolversTypes['VerifyDeviceResponse'], ParentType, ContextType, RequireFields<QueryVerifyDeviceArgs, 'deviceId'>>;
  verifyMicrosoftGroup?: Resolver<ResolversTypes['MicrosoftGroupResponse'], ParentType, ContextType, RequireFields<QueryVerifyMicrosoftGroupArgs, 'groupObjectId'>>;
  visibleConversationCountsByFilter?: Resolver<ResolversTypes['VisibleConversationCountsByFilterResponse'], ParentType, ContextType, Partial<QueryVisibleConversationCountsByFilterArgs>>;
  visibleConversations?: Resolver<ResolversTypes['VisibleConversationsResponse'], ParentType, ContextType, Partial<QueryVisibleConversationsArgs>>;
};

export type QuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Question'] = ResolversParentTypes['Question']> = {
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversationSchemeBlockId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questionText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['QuestionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionRowResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionRow'] = ResolversParentTypes['QuestionRow']> = {
  __resolveType: TypeResolveFn<'RatingRow' | 'SelectRow', ParentType, ContextType>;
};

export type RatingAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['RatingAnswer'] = ResolversParentTypes['RatingAnswer']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversationSchemeBlockId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  legendLabels?: Resolver<ResolversTypes['LegendLabels'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['Metadata'], ParentType, ContextType>;
  ratingValue?: Resolver<Maybe<ResolversTypes['RatingAnswerValue']>, ParentType, ContextType>;
  reactions?: Resolver<Maybe<Array<ResolversTypes['ReactionOnMessage']>>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MessageStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  upsertTimestamps?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RatingAnswerValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['RatingAnswerValue'] = ResolversParentTypes['RatingAnswerValue']> = {
  rating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sentiment?: Resolver<ResolversTypes['Sentiment'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RatingBlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['RatingBlock'] = ResolversParentTypes['RatingBlock']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  legendLabels?: Resolver<ResolversTypes['LegendLabels'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['Metadata'], ParentType, ContextType>;
  nextBlock?: Resolver<Maybe<ResolversTypes['NextBlock']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RatingQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RatingQuestion'] = ResolversParentTypes['RatingQuestion']> = {
  attributeBreakdown?: Resolver<ResolversTypes['AttributeBreakdown'], ParentType, ContextType>;
  conversationSchemeBlockId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RatingRowResolvers<ContextType = any, ParentType extends ResolversParentTypes['RatingRow'] = ResolversParentTypes['RatingRow']> = {
  conversationSchemeBlockId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ratingSummary?: Resolver<ResolversTypes['RatingSummary'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['QuestionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RatingSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['RatingSummary'] = ResolversParentTypes['RatingSummary']> = {
  answered?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  averageRating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isEnps?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  negative?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  neutral?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  positive?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReactionOnMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReactionOnMessage'] = ResolversParentTypes['ReactionOnMessage']> = {
  person?: Resolver<ResolversTypes['Person'], ParentType, ContextType>;
  personId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ReactionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelationshipResolvers<ContextType = any, ParentType extends ResolversParentTypes['Relationship'] = ResolversParentTypes['Relationship']> = {
  __resolveType: TypeResolveFn<'CampaignRelationship' | 'HierarchicalPersonRelationship' | 'NonHierarchicalPersonRelationship', ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isManual?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['RelationshipMetadata']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type RelationshipMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['RelationshipMetadata'] = ResolversParentTypes['RelationshipMetadata']> = {
  affectedUserCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelationshipVisibilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['RelationshipVisibility'] = ResolversParentTypes['RelationshipVisibility']> = {
  campaignId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isHierarchy?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isLeader?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  relationship?: Resolver<ResolversTypes['Relationship'], ParentType, ContextType>;
  relationshipId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visibility?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  visibilityPersons?: Resolver<Array<ResolversTypes['Person']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReminderEmailSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReminderEmailSettings'] = ResolversParentTypes['ReminderEmailSettings']> = {
  defaultSubject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fromEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fromName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logoImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReminderSendSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReminderSendSettings'] = ResolversParentTypes['ReminderSendSettings']> = {
  chatAppsText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailSettings?: Resolver<ResolversTypes['ReminderEmailSettings'], ParentType, ContextType>;
  smsText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportExportResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportExportResponse'] = ResolversParentTypes['ReportExportResponse']> = {
  reportBase64String?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportingStatsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportingStatsResponse'] = ResolversParentTypes['ReportingStatsResponse']> = {
  ownerCommentsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  reviewedPhrasesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  themesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResendLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResendLog'] = ResolversParentTypes['ResendLog']> = {
  errorCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  initiatedTimestamp?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  resendAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statuses?: Resolver<Array<ResolversTypes['StatusInfo']>, ParentType, ContextType>;
  template?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResendSmsStatusResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResendSmsStatusResponse'] = ResolversParentTypes['ResendSmsStatusResponse']> = {
  overall?: Resolver<Array<ResolversTypes['StatusInfoWithTemplate']>, ParentType, ContextType>;
  pollingTimestamp?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  resendLog?: Resolver<Array<ResolversTypes['ResendLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResendStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResendStats'] = ResolversParentTypes['ResendStats']> = {
  failed?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  failedResendable?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  queued?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResponderIsTypingResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResponderIsTypingResponse'] = ResolversParentTypes['ResponderIsTypingResponse']> = {
  conversationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  responderId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  responderName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResponderIsViewingConversationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResponderIsViewingConversationResponse'] = ResolversParentTypes['ResponderIsViewingConversationResponse']> = {
  conversationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  responderId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  responderName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RowStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RowStats'] = ResolversParentTypes['RowStats']> = {
  columns?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  orgCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  orgTotalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rowType?: Resolver<ResolversTypes['RowType'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchemeToFeaturedCommentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SchemeToFeaturedComments'] = ResolversParentTypes['SchemeToFeaturedComments']> = {
  conversationSchemeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  featuredComments?: Resolver<Array<ResolversTypes['FeaturedComment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SelectBlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['SelectBlock'] = ResolversParentTypes['SelectBlock']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['Metadata'], ParentType, ContextType>;
  nextBlock?: Resolver<Maybe<ResolversTypes['NextBlock']>, ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SelectManyAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['SelectManyAnswer'] = ResolversParentTypes['SelectManyAnswer']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversationSchemeBlockId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['Metadata'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  reactions?: Resolver<Maybe<Array<ResolversTypes['ReactionOnMessage']>>, ParentType, ContextType>;
  selectedValues?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MessageStatus'], ParentType, ContextType>;
  translatedOptions?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  upsertTimestamps?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SelectOneAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['SelectOneAnswer'] = ResolversParentTypes['SelectOneAnswer']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversationSchemeBlockId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['Metadata'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  reactions?: Resolver<Maybe<Array<ResolversTypes['ReactionOnMessage']>>, ParentType, ContextType>;
  selectedValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MessageStatus'], ParentType, ContextType>;
  translatedOptions?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  upsertTimestamps?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SelectRowResolvers<ContextType = any, ParentType extends ResolversParentTypes['SelectRow'] = ResolversParentTypes['SelectRow']> = {
  conversationSchemeBlockId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['Option']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['QuestionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SelfSelectAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['SelfSelectAnswer'] = ResolversParentTypes['SelfSelectAnswer']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversationSchemeBlockId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inputPlaceholderText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isHiddenInOrgUi?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['Metadata'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  reactions?: Resolver<Maybe<Array<ResolversTypes['ReactionOnMessage']>>, ParentType, ContextType>;
  selectedValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  selfSelectAttributeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MessageStatus'], ParentType, ContextType>;
  translatedOptions?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  upsertTimestamps?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SelfSelectBlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['SelfSelectBlock'] = ResolversParentTypes['SelfSelectBlock']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isHiddenInOrgUi?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['Metadata'], ParentType, ContextType>;
  nextBlock?: Resolver<Maybe<ResolversTypes['NextBlock']>, ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  selfSelectAttributeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  skipIfPersonHasAttribute?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SelfSelectResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SelfSelectResponse'] = ResolversParentTypes['SelfSelectResponse']> = {
  conversationSchemeWidgetCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isCampaignClosed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  nextMessageBlocks?: Resolver<Array<ResolversTypes['BaseMessage']>, ParentType, ContextType>;
  updatedAttributes?: Resolver<Array<ResolversTypes['AttributeOnPerson']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendInfo'] = ResolversParentTypes['SendInfo']> = {
  initiatedSend?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  scheduled?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sendsByDeliveryMethod?: Resolver<Maybe<Array<ResolversTypes['SendsByDeliveryMethod']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendsByDeliveryMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendsByDeliveryMethod'] = ResolversParentTypes['SendsByDeliveryMethod']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deliveryMethod?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deliveryStatusInfo?: Resolver<Maybe<Array<ResolversTypes['DeliveryStatusInfo']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetReactionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetReactionResponse'] = ResolversParentTypes['SetReactionResponse']> = {
  isCampaignClosed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  messageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Settings'] = ResolversParentTypes['Settings']> = {
  orgSettings?: Resolver<Maybe<ResolversTypes['OrgSettings']>, ParentType, ContextType>;
  userSettings?: Resolver<Maybe<ResolversTypes['PersonSettings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatusInfo'] = ResolversParentTypes['StatusInfo']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statusIsErrorCode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusInfoWithTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatusInfoWithTemplate'] = ResolversParentTypes['StatusInfoWithTemplate']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  latestPayloadTemplate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statusIsErrorCode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  conversationUpdated?: SubscriptionResolver<ResolversTypes['ConversationSubscriptionResponse'], "conversationUpdated", ParentType, ContextType, RequireFields<SubscriptionConversationUpdatedArgs, 'client'>>;
  manyConversationsUpdated?: SubscriptionResolver<ResolversTypes['ManyConversationsUpdateSubscriptionResponse'], "manyConversationsUpdated", ParentType, ContextType, RequireFields<SubscriptionManyConversationsUpdatedArgs, 'client'>>;
  responderIsTyping?: SubscriptionResolver<ResolversTypes['ResponderIsTypingResponse'], "responderIsTyping", ParentType, ContextType, RequireFields<SubscriptionResponderIsTypingArgs, 'subscribedConversationId'>>;
  responderIsViewingConversation?: SubscriptionResolver<ResolversTypes['ResponderIsViewingConversationResponse'], "responderIsViewingConversation", ParentType, ContextType, RequireFields<SubscriptionResponderIsViewingConversationArgs, 'subscribedConversationId'>>;
};

export type SuggestedResponsesResolvers<ContextType = any, ParentType extends ResolversParentTypes['SuggestedResponses'] = ResolversParentTypes['SuggestedResponses']> = {
  lastUpdateTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  suggestions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwitchBlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['SwitchBlock'] = ResolversParentTypes['SwitchBlock']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  nextBlocks?: Resolver<Array<ResolversTypes['NextBlockWithAudience']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamMemberConversationsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamMemberConversationsResponse'] = ResolversParentTypes['TeamMemberConversationsResponse']> = {
  conversations?: Resolver<Array<ResolversTypes['Conversation']>, ParentType, ContextType>;
  hasMoreToLoad?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Template'] = ResolversParentTypes['Template']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  categories?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  color?: Resolver<ResolversTypes['TemplateColor'], ParentType, ContextType>;
  conversationSchemes?: Resolver<Array<ResolversTypes['TemplateScheme']>, ParentType, ContextType>;
  creationTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  goal?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  industry?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateCategory'] = ResolversParentTypes['TemplateCategory']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sortPosition?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sortedTemplateIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateSchemeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateScheme'] = ResolversParentTypes['TemplateScheme']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  chatAppsText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversationBlocks?: Resolver<Array<ResolversTypes['ConversationBlock']>, ParentType, ContextType>;
  emailSubject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  smsText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Theme'] = ResolversParentTypes['Theme']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['ThemeSource'], ParentType, ContextType>;
  isNewTheme?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  namedBy?: Resolver<ResolversTypes['ThemeSource'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeBreakdownResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThemeBreakdown'] = ResolversParentTypes['ThemeBreakdown']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  participantCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThemeData'] = ResolversParentTypes['ThemeData']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  participantCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ThemeStatsType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThemeGroup'] = ResolversParentTypes['ThemeGroup']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['ThemeSource'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  namedBy?: Resolver<ResolversTypes['ThemeSource'], ParentType, ContextType>;
  themeIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeGroupDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThemeGroupData'] = ResolversParentTypes['ThemeGroupData']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  participantCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  themes?: Resolver<Array<ResolversTypes['ThemeData']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ThemeStatsType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThemeMetadata'] = ResolversParentTypes['ThemeMetadata']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  namedBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['ThemeSummary']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThemeState'] = ResolversParentTypes['ThemeState']> = {
  groups?: Resolver<Array<ResolversTypes['ThemeGroup']>, ParentType, ContextType>;
  themes?: Resolver<Array<ResolversTypes['Theme']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThemeStats'] = ResolversParentTypes['ThemeStats']> = {
  __resolveType: TypeResolveFn<'ThemeData' | 'ThemeGroupData', ParentType, ContextType>;
};

export type ThemeSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThemeSummary'] = ResolversParentTypes['ThemeSummary']> = {
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemesReportDownloadResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThemesReportDownloadResponse'] = ResolversParentTypes['ThemesReportDownloadResponse']> = {
  base64String?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimelineCampaignResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimelineCampaign'] = ResolversParentTypes['TimelineCampaign']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  finalSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  planningBoardId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimelineCampaignsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimelineCampaignsResponse'] = ResolversParentTypes['TimelineCampaignsResponse']> = {
  defaultCampaignId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timelineCampaigns?: Resolver<Array<ResolversTypes['TimelineCampaign']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimestampedCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimestampedCount'] = ResolversParentTypes['TimestampedCount']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToggleFeatureCommentResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ToggleFeatureCommentResponse'] = ResolversParentTypes['ToggleFeatureCommentResponse']> = {
  isFeatured?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  messageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopicResolvers<ContextType = any, ParentType extends ResolversParentTypes['Topic'] = ResolversParentTypes['Topic']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  spectrumHigh?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  spectrumLow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateBoardCardsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateBoardCardsResponse'] = ResolversParentTypes['UpdateBoardCardsResponse']> = {
  boardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  campaignId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedCampaignStatus?: Resolver<Maybe<ResolversTypes['CampaignStatus']>, ParentType, ContextType>;
  updatedFirstSendDateMillis?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updatedSortedCampaignIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateCampaignAudienceResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCampaignAudienceResponse'] = ResolversParentTypes['UpdateCampaignAudienceResponse']> = {
  attributes?: Resolver<Maybe<Array<ResolversTypes['Attribute']>>, ParentType, ContextType>;
  audienceType?: Resolver<ResolversTypes['AudienceType'], ParentType, ContextType>;
  campaignHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  campaignId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  distributionSettings?: Resolver<Maybe<ResolversTypes['DistributionSettings']>, ParentType, ContextType>;
  fileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  spreadByAttribute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateCampaignStatusResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCampaignStatusResponse'] = ResolversParentTypes['UpdateCampaignStatusResponse']> = {
  campaignHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  campaignId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateCampaignThemeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCampaignThemeResponse'] = ResolversParentTypes['UpdateCampaignThemeResponse']> = {
  themeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  themeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  themeSummary?: Resolver<Maybe<ResolversTypes['ThemeSummary']>, ParentType, ContextType>;
  themesHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateCampaignThemesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCampaignThemesResponse'] = ResolversParentTypes['UpdateCampaignThemesResponse']> = {
  campaignId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  themesHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateCampaignTimingResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCampaignTimingResponse'] = ResolversParentTypes['UpdateCampaignTimingResponse']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  activeDaysAfterFinalSend?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  audienceNudges?: Resolver<ResolversTypes['AudienceNudgeConfig'], ParentType, ContextType>;
  campaignHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  closeDate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  conversationSchemes?: Resolver<Array<ResolversTypes['ConversationScheme']>, ParentType, ContextType>;
  defaultSendTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  finalSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateConversationSchemeSendTimesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateConversationSchemeSendTimesResponse'] = ResolversParentTypes['UpdateConversationSchemeSendTimesResponse']> = {
  campaignHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  closeDate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  finalSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  nextScheduledSend?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  noChangeToSends?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  scheduledSends?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDeliveriesToResendArgsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateDeliveriesToResendArgs'] = ResolversParentTypes['UpdateDeliveriesToResendArgs']> = {
  campaignId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deliveryContentSelector?: Resolver<ResolversTypes['CampaignDeliveryTypeContent'], ParentType, ContextType>;
  deliveryType?: Resolver<ResolversTypes['DeliveryType'], ParentType, ContextType>;
  errorCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resendAmount?: Resolver<ResolversTypes['ResendAmount'], ParentType, ContextType>;
  smsText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDeliveriesToResendResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateDeliveriesToResendResponse'] = ResolversParentTypes['UpdateDeliveriesToResendResponse']> = {
  updatedTimestamp?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOrgSettingsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateOrgSettingsResponse'] = ResolversParentTypes['UpdateOrgSettingsResponse']> = {
  aiSettings?: Resolver<ResolversTypes['AiSettings'], ParentType, ContextType>;
  autoTranslateConversationsEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  contactMethods?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  deviceRegistrationEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  multiLanguageConversationsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  permissions?: Resolver<ResolversTypes['OrgPermissions'], ParentType, ContextType>;
  primaryLocale?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdatePeopleIntegrationSettingsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdatePeopleIntegrationSettingsResponse'] = ResolversParentTypes['UpdatePeopleIntegrationSettingsResponse']> = {
  attributes?: Resolver<Array<ResolversTypes['OrgAttribute']>, ParentType, ContextType>;
  integrations?: Resolver<ResolversTypes['OrgIntegrations'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateSendMethodsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateSendMethodsResponse'] = ResolversParentTypes['UpdateSendMethodsResponse']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  campaignHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contactMethods?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  emailSettings?: Resolver<ResolversTypes['EmailSettings'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdatedMessagePropertiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdatedMessageProperties'] = ResolversParentTypes['UpdatedMessageProperties']> = {
  actionablePhrases?: Resolver<Maybe<ResolversTypes['ActionablePhrases']>, ParentType, ContextType>;
  conversationSchemeBlockId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasAIMessageBeenFlagged?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasAIMessageBeenReported?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  isSkip?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ratingValue?: Resolver<Maybe<ResolversTypes['RatingAnswerValue']>, ParentType, ContextType>;
  reactions?: Resolver<Maybe<Array<ResolversTypes['ReactionOnMessage']>>, ParentType, ContextType>;
  selectedValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  selectedValues?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['MessageStatus']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  themesReportId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  translatedText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  upsertTimestamps?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UploadCampaignConversationSchemesCsvResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadCampaignConversationSchemesCsvResponse'] = ResolversParentTypes['UploadCampaignConversationSchemesCsvResponse']> = {
  campaignHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  campaignId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversationSchemes?: Resolver<Array<ResolversTypes['ConversationScheme']>, ParentType, ContextType>;
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  finalSend?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UploadImpactMetricDocumentsPresignedUrlResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadImpactMetricDocumentsPresignedUrlResponse'] = ResolversParentTypes['UploadImpactMetricDocumentsPresignedUrlResponse']> = {
  documentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fields?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpsertTemplateResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpsertTemplateResponse'] = ResolversParentTypes['UpsertTemplateResponse']> = {
  categories?: Resolver<Array<ResolversTypes['TemplateCategory']>, ParentType, ContextType>;
  template?: Resolver<ResolversTypes['Template'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerifyDeviceResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VerifyDeviceResponse'] = ResolversParentTypes['VerifyDeviceResponse']> = {
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasAddedNewDevice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisibilityMapEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisibilityMapEntry'] = ResolversParentTypes['VisibilityMapEntry']> = {
  affectedUsers?: Resolver<Maybe<Array<ResolversTypes['Person']>>, ParentType, ContextType>;
  identifierValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leaderPersons?: Resolver<Maybe<Array<ResolversTypes['Person']>>, ParentType, ContextType>;
  leaderValues?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisibleConversationCountsByFilterResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisibleConversationCountsByFilterResponse'] = ResolversParentTypes['VisibleConversationCountsByFilterResponse']> = {
  allConversations?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  allNewReplies?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  myConversations?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  myNewReplies?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  newFeedback?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  newPartialAnswers?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisibleConversationsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisibleConversationsResponse'] = ResolversParentTypes['VisibleConversationsResponse']> = {
  conversations?: Resolver<Array<ResolversTypes['Conversation']>, ParentType, ContextType>;
  filterKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasMoreToLoad?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  nextCursor?: Resolver<Maybe<ResolversTypes['Cursor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WeeklySummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeeklySummary'] = ResolversParentTypes['WeeklySummary']> = {
  weekday?: Resolver<ResolversTypes['Weekday'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  ActionPlanItem?: ActionPlanItemResolvers<ContextType>;
  ActionPlanTask?: ActionPlanTaskResolvers<ContextType>;
  ActionablePhrase?: ActionablePhraseResolvers<ContextType>;
  ActionablePhraseStat?: ActionablePhraseStatResolvers<ContextType>;
  ActionablePhrases?: ActionablePhrasesResolvers<ContextType>;
  ActionablePhrasesPerTheme?: ActionablePhrasesPerThemeResolvers<ContextType>;
  AddManualAttributeResponse?: AddManualAttributeResponseResolvers<ContextType>;
  AddPrefixFromColumnTransformation?: AddPrefixFromColumnTransformationResolvers<ContextType>;
  AddPrefixTransformation?: AddPrefixTransformationResolvers<ContextType>;
  AiFacilitatorConfiguration?: AiFacilitatorConfigurationResolvers<ContextType>;
  AiFacilitatorResponse?: AiFacilitatorResponseResolvers<ContextType>;
  AiSettings?: AiSettingsResolvers<ContextType>;
  ApplyRelationships?: ApplyRelationshipsResolvers<ContextType>;
  Attribute?: AttributeResolvers<ContextType>;
  AttributeBreakdown?: AttributeBreakdownResolvers<ContextType>;
  AttributeBreakdownRow?: AttributeBreakdownRowResolvers<ContextType>;
  AttributeFieldConfiguration?: AttributeFieldConfigurationResolvers<ContextType>;
  AttributeOnPerson?: AttributeOnPersonResolvers<ContextType>;
  AttributeOptions?: AttributeOptionsResolvers<ContextType>;
  Audience?: AudienceResolvers<ContextType>;
  AudienceNudge?: AudienceNudgeResolvers<ContextType>;
  AudienceNudgeConfig?: AudienceNudgeConfigResolvers<ContextType>;
  AudienceThemesReport?: AudienceThemesReportResolvers<ContextType>;
  AuthResponse?: AuthResponseResolvers<ContextType>;
  BaseActionablePhrase?: BaseActionablePhraseResolvers<ContextType>;
  BaseAnswerResponse?: BaseAnswerResponseResolvers<ContextType>;
  BaseMessage?: BaseMessageResolvers<ContextType>;
  BasePerson?: BasePersonResolvers<ContextType>;
  BaseTransformation?: BaseTransformationResolvers<ContextType>;
  BulkReplyNoCommentsResponse?: BulkReplyNoCommentsResponseResolvers<ContextType>;
  BulkReplyPreviewResponse?: BulkReplyPreviewResponseResolvers<ContextType>;
  Campaign?: CampaignResolvers<ContextType>;
  CampaignActionPlan?: CampaignActionPlanResolvers<ContextType>;
  CampaignActionPlanAndSuggestions?: CampaignActionPlanAndSuggestionsResolvers<ContextType>;
  CampaignActionPlanExportResponse?: CampaignActionPlanExportResponseResolvers<ContextType>;
  CampaignActionPlanSuggestions?: CampaignActionPlanSuggestionsResolvers<ContextType>;
  CampaignAttributeStats?: CampaignAttributeStatsResolvers<ContextType>;
  CampaignDeliveryTypeContent?: CampaignDeliveryTypeContentResolvers<ContextType>;
  CampaignDesignerSummaryExportResponse?: CampaignDesignerSummaryExportResponseResolvers<ContextType>;
  CampaignDetails?: CampaignDetailsResolvers<ContextType>;
  CampaignEngagementHeatmap?: CampaignEngagementHeatmapResolvers<ContextType>;
  CampaignEngagementStats?: CampaignEngagementStatsResolvers<ContextType>;
  CampaignHashResponse?: CampaignHashResponseResolvers<ContextType>;
  CampaignImpactMetrics?: CampaignImpactMetricsResolvers<ContextType>;
  CampaignRelationship?: CampaignRelationshipResolvers<ContextType>;
  CampaignStats?: CampaignStatsResolvers<ContextType>;
  CampaignThemesExportResponse?: CampaignThemesExportResponseResolvers<ContextType>;
  CampaignValidationErrors?: CampaignValidationErrorsResolvers<ContextType>;
  CampaignValidationSchemeErrors?: CampaignValidationSchemeErrorsResolvers<ContextType>;
  CampaignVisibilityMapEntry?: CampaignVisibilityMapEntryResolvers<ContextType>;
  CampaignsInTimeRangeResponse?: CampaignsInTimeRangeResponseResolvers<ContextType>;
  Category?: CategoryResolvers<ContextType>;
  ColumnName?: ColumnNameResolvers<ContextType>;
  CommentBlock?: CommentBlockResolvers<ContextType>;
  CommentPhrases?: CommentPhrasesResolvers<ContextType>;
  CompletedSend?: CompletedSendResolvers<ContextType>;
  Conversation?: ConversationResolvers<ContextType>;
  ConversationBlock?: ConversationBlockResolvers<ContextType>;
  ConversationComment?: ConversationCommentResolvers<ContextType>;
  ConversationPrompt?: ConversationPromptResolvers<ContextType>;
  ConversationPromptBlock?: ConversationPromptBlockResolvers<ContextType>;
  ConversationScheme?: ConversationSchemeResolvers<ContextType>;
  ConversationSchemeDetails?: ConversationSchemeDetailsResolvers<ContextType>;
  ConversationSchemeParticipationStats?: ConversationSchemeParticipationStatsResolvers<ContextType>;
  ConversationSchemeSendInfo?: ConversationSchemeSendInfoResolvers<ContextType>;
  ConversationSubscriptionResponse?: ConversationSubscriptionResponseResolvers<ContextType>;
  CreateOrUpdateNudgeReminderArgs?: CreateOrUpdateNudgeReminderArgsResolvers<ContextType>;
  CsvColumnConfiguration?: CsvColumnConfigurationResolvers<ContextType>;
  CsvIntegration?: CsvIntegrationResolvers<ContextType>;
  Cursor?: CursorResolvers<ContextType>;
  DeleteCampaignResponse?: DeleteCampaignResponseResolvers<ContextType>;
  DeleteCommentResponse?: DeleteCommentResponseResolvers<ContextType>;
  DeleteNudgeReminderArgs?: DeleteNudgeReminderArgsResolvers<ContextType>;
  DeleteTemplateResponse?: DeleteTemplateResponseResolvers<ContextType>;
  DeliveryStatusInfo?: DeliveryStatusInfoResolvers<ContextType>;
  DistributedAudienceExportResponse?: DistributedAudienceExportResponseResolvers<ContextType>;
  DistributionSettings?: DistributionSettingsResolvers<ContextType>;
  DownloadImpactMetricDocumentPresignedUrl?: DownloadImpactMetricDocumentPresignedUrlResolvers<ContextType>;
  DownloadPeopleDataPresignedUrl?: DownloadPeopleDataPresignedUrlResolvers<ContextType>;
  EditCommentTextResponse?: EditCommentTextResponseResolvers<ContextType>;
  EmailSettings?: EmailSettingsResolvers<ContextType>;
  EngagementHeatmapRow?: EngagementHeatmapRowResolvers<ContextType>;
  Error?: ErrorResolvers<ContextType>;
  ErrorResponse?: ErrorResponseResolvers<ContextType>;
  FeaturedComment?: FeaturedCommentResolvers<ContextType>;
  FeaturedCommentCandidate?: FeaturedCommentCandidateResolvers<ContextType>;
  FieldConstraints?: FieldConstraintsResolvers<ContextType>;
  FieldToCsvColumnConfiguration?: FieldToCsvColumnConfigurationResolvers<ContextType>;
  Filter?: FilterResolvers<ContextType>;
  FilterOption?: FilterOptionResolvers<ContextType>;
  FilteredSelfSelectAnswer?: FilteredSelfSelectAnswerResolvers<ContextType>;
  FilteredSelfSelectBlock?: FilteredSelfSelectBlockResolvers<ContextType>;
  FilteredSelfSelectOptionsLookup?: FilteredSelfSelectOptionsLookupResolvers<ContextType>;
  Filters?: FiltersResolvers<ContextType>;
  FinancialImpactMetric?: FinancialImpactMetricResolvers<ContextType>;
  FindAndReplaceTransformation?: FindAndReplaceTransformationResolvers<ContextType>;
  GenericTransformation?: GenericTransformationResolvers<ContextType>;
  GetCampaignThemesResponse?: GetCampaignThemesResponseResolvers<ContextType>;
  GetSsoInfoResponse?: GetSsoInfoResponseResolvers<ContextType>;
  HierarchicalPersonRelationship?: HierarchicalPersonRelationshipResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  ImageObject?: ImageObjectResolvers<ContextType>;
  ImpactMetric?: ImpactMetricResolvers<ContextType>;
  ImpactMetricDocument?: ImpactMetricDocumentResolvers<ContextType>;
  InformLeadsAndSponsorsAudienceCountResponse?: InformLeadsAndSponsorsAudienceCountResponseResolvers<ContextType>;
  IntegrationInstall?: IntegrationInstallResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  LeadsAndSponsors?: LeadsAndSponsorsResolvers<ContextType>;
  LegendLabels?: LegendLabelsResolvers<ContextType>;
  LoggedInUserResponse?: LoggedInUserResponseResolvers<ContextType>;
  MagicTokenAuthResponse?: MagicTokenAuthResponseResolvers<ContextType>;
  ManyConversationsUpdateSubscriptionResponse?: ManyConversationsUpdateSubscriptionResponseResolvers<ContextType>;
  MatchingPerson?: MatchingPersonResolvers<ContextType>;
  MessageDeletion?: MessageDeletionResolvers<ContextType>;
  MessageUpdate?: MessageUpdateResolvers<ContextType>;
  Metadata?: MetadataResolvers<ContextType>;
  MicrosoftGroup?: MicrosoftGroupResolvers<ContextType>;
  MicrosoftGroupResponse?: MicrosoftGroupResponseResolvers<ContextType>;
  MicrosoftPeopleSyncIntegration?: MicrosoftPeopleSyncIntegrationResolvers<ContextType>;
  MsParameters?: MsParametersResolvers<ContextType>;
  MutateGroupVisibilityResponse?: MutateGroupVisibilityResponseResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NextBlock?: NextBlockResolvers<ContextType>;
  NextBlockWithAudience?: NextBlockWithAudienceResolvers<ContextType>;
  NonHierarchicalPersonRelationship?: NonHierarchicalPersonRelationshipResolvers<ContextType>;
  NonHierarchicalPersonVisibilityMap?: NonHierarchicalPersonVisibilityMapResolvers<ContextType>;
  OktaParameters?: OktaParametersResolvers<ContextType>;
  Option?: OptionResolvers<ContextType>;
  Org?: OrgResolvers<ContextType>;
  OrgAttribute?: OrgAttributeResolvers<ContextType>;
  OrgFilteredAttributeValue?: OrgFilteredAttributeValueResolvers<ContextType>;
  OrgInfo?: OrgInfoResolvers<ContextType>;
  OrgIntegrations?: OrgIntegrationsResolvers<ContextType>;
  OrgNotificationSettings?: OrgNotificationSettingsResolvers<ContextType>;
  OrgPermissions?: OrgPermissionsResolvers<ContextType>;
  OrgResponderSettingsResponse?: OrgResponderSettingsResponseResolvers<ContextType>;
  OrgSettings?: OrgSettingsResolvers<ContextType>;
  OtherImpactMetric?: OtherImpactMetricResolvers<ContextType>;
  PIP?: PipResolvers<ContextType>;
  ParticipantNotification?: ParticipantNotificationResolvers<ContextType>;
  ParticipantNotificationContent?: ParticipantNotificationContentResolvers<ContextType>;
  ParticipationHeatmap?: ParticipationHeatmapResolvers<ContextType>;
  ParticipationStats?: ParticipationStatsResolvers<ContextType>;
  PeopleIntegrationSettings?: PeopleIntegrationSettingsResolvers<ContextType>;
  Permissions?: PermissionsResolvers<ContextType>;
  PermissionsOnPerson?: PermissionsOnPersonResolvers<ContextType>;
  Person?: PersonResolvers<ContextType>;
  PersonAvatar?: PersonAvatarResolvers<ContextType>;
  PersonInScope?: PersonInScopeResolvers<ContextType>;
  PersonMatchesResponse?: PersonMatchesResponseResolvers<ContextType>;
  PersonResponse?: PersonResponseResolvers<ContextType>;
  PersonSettings?: PersonSettingsResolvers<ContextType>;
  PersonsIngestionStatus?: PersonsIngestionStatusResolvers<ContextType>;
  PersonsIngestionStatusEvent?: PersonsIngestionStatusEventResolvers<ContextType>;
  PlanningBoard?: PlanningBoardResolvers<ContextType>;
  PlanningBoardCampaign?: PlanningBoardCampaignResolvers<ContextType>;
  PlanningBoardCampaignsResponse?: PlanningBoardCampaignsResponseResolvers<ContextType>;
  PlanningBoardExportResponse?: PlanningBoardExportResponseResolvers<ContextType>;
  PrefixColumn?: PrefixColumnResolvers<ContextType>;
  PublishThemesResponse?: PublishThemesResponseResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Question?: QuestionResolvers<ContextType>;
  QuestionRow?: QuestionRowResolvers<ContextType>;
  RatingAnswer?: RatingAnswerResolvers<ContextType>;
  RatingAnswerValue?: RatingAnswerValueResolvers<ContextType>;
  RatingBlock?: RatingBlockResolvers<ContextType>;
  RatingQuestion?: RatingQuestionResolvers<ContextType>;
  RatingRow?: RatingRowResolvers<ContextType>;
  RatingSummary?: RatingSummaryResolvers<ContextType>;
  ReactionOnMessage?: ReactionOnMessageResolvers<ContextType>;
  Relationship?: RelationshipResolvers<ContextType>;
  RelationshipMetadata?: RelationshipMetadataResolvers<ContextType>;
  RelationshipVisibility?: RelationshipVisibilityResolvers<ContextType>;
  ReminderEmailSettings?: ReminderEmailSettingsResolvers<ContextType>;
  ReminderSendSettings?: ReminderSendSettingsResolvers<ContextType>;
  ReportExportResponse?: ReportExportResponseResolvers<ContextType>;
  ReportingStatsResponse?: ReportingStatsResponseResolvers<ContextType>;
  ResendLog?: ResendLogResolvers<ContextType>;
  ResendSmsStatusResponse?: ResendSmsStatusResponseResolvers<ContextType>;
  ResendStats?: ResendStatsResolvers<ContextType>;
  ResponderIsTypingResponse?: ResponderIsTypingResponseResolvers<ContextType>;
  ResponderIsViewingConversationResponse?: ResponderIsViewingConversationResponseResolvers<ContextType>;
  RowStats?: RowStatsResolvers<ContextType>;
  SchemeToFeaturedComments?: SchemeToFeaturedCommentsResolvers<ContextType>;
  SelectBlock?: SelectBlockResolvers<ContextType>;
  SelectManyAnswer?: SelectManyAnswerResolvers<ContextType>;
  SelectOneAnswer?: SelectOneAnswerResolvers<ContextType>;
  SelectRow?: SelectRowResolvers<ContextType>;
  SelfSelectAnswer?: SelfSelectAnswerResolvers<ContextType>;
  SelfSelectBlock?: SelfSelectBlockResolvers<ContextType>;
  SelfSelectResponse?: SelfSelectResponseResolvers<ContextType>;
  SendInfo?: SendInfoResolvers<ContextType>;
  SendsByDeliveryMethod?: SendsByDeliveryMethodResolvers<ContextType>;
  SetReactionResponse?: SetReactionResponseResolvers<ContextType>;
  Settings?: SettingsResolvers<ContextType>;
  StatusInfo?: StatusInfoResolvers<ContextType>;
  StatusInfoWithTemplate?: StatusInfoWithTemplateResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SuggestedResponses?: SuggestedResponsesResolvers<ContextType>;
  SwitchBlock?: SwitchBlockResolvers<ContextType>;
  TeamMemberConversationsResponse?: TeamMemberConversationsResponseResolvers<ContextType>;
  Template?: TemplateResolvers<ContextType>;
  TemplateCategory?: TemplateCategoryResolvers<ContextType>;
  TemplateScheme?: TemplateSchemeResolvers<ContextType>;
  Theme?: ThemeResolvers<ContextType>;
  ThemeBreakdown?: ThemeBreakdownResolvers<ContextType>;
  ThemeData?: ThemeDataResolvers<ContextType>;
  ThemeGroup?: ThemeGroupResolvers<ContextType>;
  ThemeGroupData?: ThemeGroupDataResolvers<ContextType>;
  ThemeMetadata?: ThemeMetadataResolvers<ContextType>;
  ThemeState?: ThemeStateResolvers<ContextType>;
  ThemeStats?: ThemeStatsResolvers<ContextType>;
  ThemeSummary?: ThemeSummaryResolvers<ContextType>;
  ThemesReportDownloadResponse?: ThemesReportDownloadResponseResolvers<ContextType>;
  TimelineCampaign?: TimelineCampaignResolvers<ContextType>;
  TimelineCampaignsResponse?: TimelineCampaignsResponseResolvers<ContextType>;
  TimestampedCount?: TimestampedCountResolvers<ContextType>;
  ToggleFeatureCommentResponse?: ToggleFeatureCommentResponseResolvers<ContextType>;
  Topic?: TopicResolvers<ContextType>;
  UpdateBoardCardsResponse?: UpdateBoardCardsResponseResolvers<ContextType>;
  UpdateCampaignAudienceResponse?: UpdateCampaignAudienceResponseResolvers<ContextType>;
  UpdateCampaignStatusResponse?: UpdateCampaignStatusResponseResolvers<ContextType>;
  UpdateCampaignThemeResponse?: UpdateCampaignThemeResponseResolvers<ContextType>;
  UpdateCampaignThemesResponse?: UpdateCampaignThemesResponseResolvers<ContextType>;
  UpdateCampaignTimingResponse?: UpdateCampaignTimingResponseResolvers<ContextType>;
  UpdateConversationSchemeSendTimesResponse?: UpdateConversationSchemeSendTimesResponseResolvers<ContextType>;
  UpdateDeliveriesToResendArgs?: UpdateDeliveriesToResendArgsResolvers<ContextType>;
  UpdateDeliveriesToResendResponse?: UpdateDeliveriesToResendResponseResolvers<ContextType>;
  UpdateOrgSettingsResponse?: UpdateOrgSettingsResponseResolvers<ContextType>;
  UpdatePeopleIntegrationSettingsResponse?: UpdatePeopleIntegrationSettingsResponseResolvers<ContextType>;
  UpdateSendMethodsResponse?: UpdateSendMethodsResponseResolvers<ContextType>;
  UpdatedMessageProperties?: UpdatedMessagePropertiesResolvers<ContextType>;
  UploadCampaignConversationSchemesCsvResponse?: UploadCampaignConversationSchemesCsvResponseResolvers<ContextType>;
  UploadImpactMetricDocumentsPresignedUrlResponse?: UploadImpactMetricDocumentsPresignedUrlResponseResolvers<ContextType>;
  UpsertTemplateResponse?: UpsertTemplateResponseResolvers<ContextType>;
  VerifyDeviceResponse?: VerifyDeviceResponseResolvers<ContextType>;
  VisibilityMapEntry?: VisibilityMapEntryResolvers<ContextType>;
  VisibleConversationCountsByFilterResponse?: VisibleConversationCountsByFilterResponseResolvers<ContextType>;
  VisibleConversationsResponse?: VisibleConversationsResponseResolvers<ContextType>;
  WeeklySummary?: WeeklySummaryResolvers<ContextType>;
};

