import { touchRippleClasses } from '@mui/material/ButtonBase';
import dividerClasses from '@mui/material/Divider/dividerClasses';
import MuiMenu, { menuClasses, type MenuProps as MuiMenuProps } from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { styled, theme } from '@shared/frontend/theme';

import { type IconSize } from './styled-icon';
import { getTypographyStyles } from './typography';

export type MenuProps = Omit<MuiMenuProps, 'open'>;
const iconSize: IconSize = '1.8rem';

export const StyledMenu = styled(MuiMenu)`
  .${menuClasses.paper} {
    border: ${theme.border};
    border-radius: ${theme.borderRadius.sm};
    box-shadow: ${theme.shadows.medium};
  }

  .${menuClasses.list} {
    padding: ${theme.spacing.xs} 0;
  }

  && {
    .${dividerClasses.root} {
      margin: ${theme.spacing.xs};
    }
  }
`;

export const StyledMenuItem = styled(MuiMenuItem)`
  && {
    ${getTypographyStyles({ $variant: 'BodyUi' })}

    min-height: 0;
    display: flex;
    column-gap: ${theme.spacing.s};
    padding: 0.8rem ${theme.spacing.s};
    color: ${theme.text.primary};

    &:focus-visible,
    &:hover {
      background-color: ${theme.color.ducksBlue[100]};
    }

    .${touchRippleClasses.child} {
      background-color: ${theme.color.ducksBlue[300]};
    }

    svg {
      height: ${iconSize};
      width: ${iconSize};
    }
  }
`;
