import { Button } from '@shared/frontend/components/base/button';
import { BodyUi } from '@shared/frontend/components/base/typography';
import { styled, theme } from '@shared/frontend/theme';
import React from 'react';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${theme.spacing.xs};
  padding: ${theme.spacing.m};
`;

const Actions = styled('div')`
  display: flex;
  gap: ${theme.spacing.xs};
`;

type Props = {
  handleMainAction: () => void;
  handleCancel: () => void;
  confirmationMessage: string;
  mainActionText: string;
};

export const ConfirmationContent = ({
  handleMainAction,
  handleCancel,
  confirmationMessage,
  mainActionText,
}: Props) => {
  return (
    <Wrapper data-cy="confirmation-content">
      <BodyUi>{confirmationMessage}</BodyUi>
      <Actions>
        <Button onClick={handleCancel} $variant="ghost" $colorScheme="ducksBlue">
          Cancel
        </Button>
        <Button onClick={() => handleMainAction()} $variant="filled" $colorScheme="microPigPink">
          {mainActionText}
        </Button>
      </Actions>
    </Wrapper>
  );
};
