import { NetworkStatus, useQuery } from '@apollo/client';
import { CircularProgress } from '@components/base/progress';
import { styled } from '@frontend/theme';
import { isHandledNetworkError } from '@shared/graphql/apollo-client-helpers';
import { sortConversationsByLastSignificantUpdateTime } from '@shared/graphql/conversation-utils';
import { Navigate, useParams } from '@tanstack/react-router';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  TEAM_MEMBER_CONVERSATIONS,
  type TeamMemberConversationsResponse,
} from '../team-member-conversations-query';
import { ListContent } from './list-content';
import { updateQuery } from './utils';

const ProgressWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const List = memo(() => {
  const { selectedConversationId } = useParams({
    from: '/AppLayout/conversations/$selectedConversationId',
  });
  const [lastFetchedIndex, setLastFetchedIndex] = useState<number | null>(null);
  const [scrollSelectedConversationIntoView, setScrollSelectedConversationIntoView] =
    useState(true);
  const firstItemFromFetchRef = useRef<HTMLAnchorElement | null>(null);

  const {
    data: teamMemberResponse,
    loading,
    error,
    fetchMore,
    networkStatus,
  } = useQuery<TeamMemberConversationsResponse>(TEAM_MEMBER_CONVERSATIONS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      selectedConversationId,
    },
  });

  // enabling scrolling to the selected conversation if it was disabled after clicking the show older conversations button
  useEffect(() => {
    if (!scrollSelectedConversationIntoView) {
      setScrollSelectedConversationIntoView(true);
    }
    // eslint-disable-next-line
  }, [selectedConversationId]);

  const sortedConversations = useMemo(
    () =>
      sortConversationsByLastSignificantUpdateTime(
        teamMemberResponse?.pageData.conversations ?? [],
      ),
    [teamMemberResponse?.pageData.conversations],
  );

  const fetchMoreConversations = useCallback(async () => {
    // to observe rendering of older conversations when the show older conversations button gets clicked
    setScrollSelectedConversationIntoView(false);

    const lastConversation = sortedConversations[sortedConversations.length - 1];
    const variables = {
      conversationTimeFrom: lastConversation.lastSignificantUpdateTime,
      conversationIdFrom: lastConversation._id,
    };

    setLastFetchedIndex(sortedConversations.length - 1);

    await fetchMore({ variables, updateQuery });

    // This will allow the tab indexing to continue from the last conversation of the previous fetch
    // rather than focusing straight back to the 'Show older conversations'
    if (firstItemFromFetchRef.current) {
      firstItemFromFetchRef.current.setAttribute('tabIndex', '-1');
      firstItemFromFetchRef.current.focus();
    }
  }, [fetchMore, sortedConversations]);

  // We don't want to show this loading indicator when the loading is triggered by the fetch more
  if (loading && networkStatus !== NetworkStatus.fetchMore) {
    return (
      <ProgressWrapper>
        <CircularProgress size="4rem" />
      </ProgressWrapper>
    );
  }

  if (error || !teamMemberResponse?.pageData.conversations.length) {
    const { networkError } = error ?? {};

    if (networkError && isHandledNetworkError(networkError)) {
      return (
        <ProgressWrapper>
          <CircularProgress size="4rem" />
        </ProgressWrapper>
      );
    }

    return <Navigate to="/error" />;
  }

  return (
    <ListContent
      conversations={sortedConversations}
      fetchMoreConversations={fetchMoreConversations}
      hasMoreToLoad={teamMemberResponse.pageData.hasMoreToLoad}
      isFetchingMore={networkStatus === NetworkStatus.fetchMore}
      scrollSelectedConversationIntoView={scrollSelectedConversationIntoView}
      selectedConversationId={selectedConversationId}
      oldLastFetchedIndex={lastFetchedIndex}
      firstItemFromFetchRef={firstItemFromFetchRef}
    />
  );
});
