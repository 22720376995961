import { styled, theme } from '@frontend/theme';
import React, { Dispatch, SetStateAction } from 'react';

import { ListHeader } from '../../page-layout/header/list-header';
import { List } from './list';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.ducksBlue[25]};
  height: 100%;
  overflow: hidden;
  border-right: 0.1rem solid ${theme.color.ducksBlue[25]};
`;

type Props = {
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
};

export const ListContainer = ({ setIsDrawerOpen }: Props) => {
  return (
    <Wrapper>
      <ListHeader setIsDrawerOpen={setIsDrawerOpen} />
      <List />
    </Wrapper>
  );
};
