import {
  IconButton as MuiIconButton,
  type IconButtonProps as MuiIconButtonProps,
} from '@mui/material';
import { type ThemeColorScheme, styled, theme } from '@shared/frontend/theme';
import React, { type ForwardedRef } from 'react';

import type { IconName } from '../icons';
import { type IconSize, StyledIcon } from './styled-icon';

type ColorScheme = Extract<
  ThemeColorScheme,
  'ducksBlue' | 'kereruPurple' | 'microPigPink' | 'katipoBlack' | 'happyYellow' | 'joyousDogOrange'
>;

type Props = {
  iconName: IconName;
  $size?: IconSize;
  $colorScheme?: ColorScheme;
} & Omit<MuiIconButtonProps, 'size'>;

export const IconButton = styled(
  React.forwardRef(
    (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { iconName, $size = '2.8rem', children, $colorScheme, ...restOfProps }: Props,
      ref: ForwardedRef<HTMLButtonElement>,
    ) => (
      <MuiIconButton {...restOfProps} ref={ref}>
        <StyledIcon iconName={iconName} $size={$size} $customColor="inherit" />
        {children}
      </MuiIconButton>
    ),
  ),
)`
  && {
    padding: 0.2rem;

    ${({ $colorScheme = 'ducksBlue' }) => `
      color: ${theme.color[$colorScheme][$colorScheme === 'happyYellow' ? 600 : 400]};
      &:hover {
        background-color: ${theme.color[$colorScheme][100]};
      }
    `}

    &:disabled {
      color: ${theme.color.katipoBlack[300]};
    }
  }
`;
