import { type AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { BodyUi } from '@shared/frontend/components/base/typography';
import { styled, theme } from '@shared/frontend/theme';
import React, { type HTMLAttributes } from 'react';

import { StyledIcon } from '../base/styled-icon';

const Wrapper = styled('li')`
  &&& {
    width: 100%;
    position: relative;
    padding: 0.8rem ${theme.spacing.s};
    column-gap: 1rem;
  }
`;

export type Option<T = unknown> = {
  optionData: T;
  key: string;
  label: string;
  chipLabel?: string;
  group?: string;
};

export const DropdownOption = <T extends string | Option>(
  props: HTMLAttributes<HTMLLIElement>,
  option: T,
) => {
  if (typeof option === 'string') {
    return (
      <Wrapper key={option} {...props} data-cy="dropdown-option">
        <BodyUi>{option}</BodyUi>
      </Wrapper>
    );
  }
  return (
    <Wrapper key={option.key} {...props} data-cy="dropdown-option">
      <BodyUi>{option.label}</BodyUi>
    </Wrapper>
  );
};

export const DropdownOptionMulti = <T extends string | Option>(
  props: HTMLAttributes<HTMLLIElement>,
  option: T,
  state: AutocompleteRenderOptionState,
) => {
  if (typeof option === 'string') {
    return (
      <Wrapper key={option} {...props} data-cy="dropdown-option">
        <BodyUi>{option}</BodyUi>
      </Wrapper>
    );
  }
  return (
    <Wrapper key={option.key} {...props} data-cy="dropdown-option">
      {state.selected && <StyledIcon iconName="Check" $size="1.8rem" />}
      <BodyUi>{option.label}</BodyUi>
    </Wrapper>
  );
};
