import { IconButton } from '@components/base/icon-button';
import { H1 } from '@components/base/typography';
import JoybowLogoImage from '@frontend/assets/joybow-logo.png';
import { styled, theme } from '@frontend/theme';
import { useIsExtraSmallResolution } from '@frontend/hooks/use-resolution';
import React, { Dispatch, SetStateAction } from 'react';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.s};
  height: 5.6rem;
  min-height: 5.6rem;
  width: 100%;
  align-items: center;
  padding: 0 ${theme.spacing.m};
  background: ${theme.color.white};
`;

const JoybowLogo = styled('img')`
  height: 3rem;
  margin-left: auto;
`;

type ConversationsHeaderProps = {
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
};

export const ListHeader = ({ setIsDrawerOpen }: ConversationsHeaderProps) => {
  const isMobileResolution = useIsExtraSmallResolution();

  return (
    <Wrapper>
      <IconButton
        iconName="Menu"
        $size="2.4rem"
        aria-label="menu"
        data-cy="menu-button"
        data-dd-action-name="menu-button"
        onClick={() => setIsDrawerOpen(true)}
      />
      <H1 $fontWeight="medium" $customColor={theme.text.primary}>
        Conversations
      </H1>
      {isMobileResolution && <JoybowLogo src={JoybowLogoImage} alt="logo" />}
    </Wrapper>
  );
};
