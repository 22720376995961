import { makeVar } from '@apollo/client';
import { DependencyList, useEffect } from 'react';

export enum MessageStatus {
  Sending = 'SENDING',
  Sent = 'SENT',
  Error = 'ERROR',
}
export type MessagesInFlight = {
  [messageId: string]: MessageStatus;
};

// conversation detail message menu states
export const imageUrlOfImageModalStateVar = makeVar<string | null>(null);
export const messagesBeingSentStateVar = makeVar<MessagesInFlight>({});

export const updateMessageBeingSentState = (messageId: string, status: MessageStatus) => {
  const currentState = messagesBeingSentStateVar();
  messagesBeingSentStateVar({ ...currentState, [messageId]: status });
};

export const useResetAllLocalMessageStates = (deps?: DependencyList) => {
  useEffect(() => {
    imageUrlOfImageModalStateVar(null);
    messagesBeingSentStateVar({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

// conversations state
export const initialLoadConversationIdVar = makeVar<string | null>(null);

// conversation input draft messages
export const draftMessageMapVar = makeVar<{ [key: string]: string }>({});
export const setDraftMessageMap = (newDraftMessage: { [key: string]: string }) =>
  draftMessageMapVar({ ...draftMessageMapVar(), ...newDraftMessage });
