/* eslint-disable no-console */
import { datadogRum } from '@datadog/browser-rum';

const BASE_FUNCTION_PATH = 'team-member-ui.src.utils.team-member-ui-local-storage-manager';

// If users disable cookies in chrome it also disables local storage by causing property access to throw
// We catch those thrown errors and fall back to an in-memory object map to allow the site to still function. At least until they refresh the page.
export class TeamMemberUiLocalStorageManager {
  private memoryStorage: Record<string, string | undefined>;

  constructor() {
    this.memoryStorage = {};
  }

  getItem(key: string): string | undefined {
    const functionPath = `${BASE_FUNCTION_PATH}.getItem`;

    // We need to give priority to local storage to support multiple tabs with the same refresh token.
    // If the token gets refreshed in one tab, all the other tabs need to stop using the original refresh token (which has been rotated).
    // Otherwise, if other tabs try to refresh tokens with the old value we will invalidate all the user sessions, logging out the user every time a reused refresh token is detected.
    // When we detect token reuse we invalidate all sessions, as indicated by oAuth recommended practices. See: https://auth0.com/docs/secure/tokens/refresh-tokens/refresh-token-rotation#automatic-reuse-detection
    // At the same time we also need to handle the strange case we are seeing in production where local storage values return undefined sometimes, even seconds after writing the values.

    const memoryStorageItem = this.memoryStorage[key];
    let localStorageItem: string | undefined;

    try {
      localStorageItem = localStorage.getItem(key) ?? undefined;
    } catch (error) {
      const message = 'Error attempting to get item from local storage.';
      datadogRum.addAction(message, { functionPath, key, error });
      console.warn(message);
    }

    if (!localStorageItem) {
      // If local storage value is undefined we might be facing the weird issue where local storage values disappear (get removed).
      // We return whatever we had in memory which should be the last known value.
      // The only potential valid case is that the user had actually logged out in another tab but our system will handle that well when a refresh auth is attempted.
      const message = 'Returning memory storage item because local storage item is not valid.';
      datadogRum.addAction(message, {
        functionPath,
        key,
        isMemoryValueDefined: !!memoryStorageItem, // Checking for null, undefined and empty string
        isLocalStorageNull: localStorageItem === null,
        isLocalStorageUndefined: localStorageItem === undefined,
        isLocalStorageEmptyString: localStorageItem === '',
      });
      console.warn(message, {
        functionPath,
        key,
        isMemoryValueDefined: !!memoryStorageItem,
        isLocalStorageNull: localStorageItem === null,
        isLocalStorageUndefined: localStorageItem === undefined,
        isLocalStorageEmptyString: localStorageItem === '',
      });
      return memoryStorageItem;
    }

    if (memoryStorageItem !== undefined && localStorageItem !== memoryStorageItem) {
      const message =
        'Memory storage item and local storage item are out of sync. This could be related to the user having other tabs open.';
      datadogRum.addAction(message, { functionPath, key });
      console.warn(message, { functionPath, key });
      this.memoryStorage[key] = localStorageItem;
    }

    return localStorageItem;
  }

  setItem(key: string, value: string) {
    const functionPath = `${BASE_FUNCTION_PATH}.setItem`;

    datadogRum.addAction('Set value in local storage', {
      functionPath,
      key,
      isValueDefined: !!value, // Checking for null, undefined and empty string
    });

    this.memoryStorage[key] = value;

    try {
      localStorage.setItem(key, value);
    } catch (e) {
      const message = 'Error attempting to set item to local storage.';
      datadogRum.addAction(message, { functionPath, key });
      console.warn(message);
    }
  }

  removeItem(key: string) {
    const functionPath = `${BASE_FUNCTION_PATH}.removeItem`;

    datadogRum.addAction('Remove value from local storage', { functionPath, key });

    delete this.memoryStorage[key];

    try {
      localStorage.removeItem(key);
    } catch (e) {
      const message = 'Error attempting to remove item from local storage.';
      datadogRum.addAction(message, { functionPath, key });
      console.warn(message);
    }
  }
}
