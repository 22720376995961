import { BodyConversation } from '@shared/frontend/components/base/typography';
import { CommentMessageBubble } from '@shared/frontend/components/conversation/message/comment/comment-message-bubble';
import { type ColorOptions } from '@shared/frontend/components/conversation/message/types';
import { type BorderRadii } from '@shared/frontend/components/message-bubble';
import React, { type MouseEventHandler } from 'react';

import { StaticTranslate } from '../../translate';

type Props = {
  isHighlighted: boolean;
  colorOptions: ColorOptions;
  onClick?: MouseEventHandler<HTMLElement>;
  borderRadii?: BorderRadii;
};

export const SkippedMessage = ({ isHighlighted, onClick, borderRadii, colorOptions }: Props) => (
  <CommentMessageBubble
    onClick={onClick}
    tabIndex={0}
    $isHighlighted={isHighlighted}
    $borderRadii={borderRadii}
    $colorOptions={colorOptions}
  >
    <BodyConversation $fontStyle="italic" aria-label="Skipped rating question">
      <StaticTranslate originalText="Skipped question" />
    </BodyConversation>
  </CommentMessageBubble>
);
