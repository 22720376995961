import createSvgIcon from '@mui/material/utils/createSvgIcon';
import React from 'react';

export const JoyousLogoSquare = createSvgIcon(
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="10" fill="url(#paint0_linear_4124_928)" />
    <path
      d="M13.1261 30.3251C13.144 29.1757 13.5778 28.1663 14.1728 27.2134C14.8516 26.1265 15.7455 25.229 16.6932 24.386C17.4171 23.7419 18.1769 23.1387 18.914 22.5099C18.9926 22.4429 19.0657 22.3294 19.078 22.2291C19.2871 20.5672 19.4891 18.9048 19.687 17.2417C19.849 15.8805 19.9923 14.5169 20.1691 13.1578C20.2417 12.5987 20.5653 12.1913 21.1368 12.095C21.5434 12.0264 21.9688 12.0168 22.3802 12.0489C22.7857 12.0806 22.982 12.3316 22.9681 12.7447C22.9517 13.2296 22.9194 13.7168 22.8523 14.1969C22.5542 16.3221 22.241 18.4448 21.9337 20.5688C21.6352 22.6298 21.2449 24.6723 20.6583 26.672C20.2313 28.1278 19.7504 29.5647 18.9419 30.8625C18.4719 31.6173 17.8956 32.2774 17.0672 32.6476C16.1174 33.0723 15.1517 33.112 14.2454 32.5465C13.4421 32.0452 13.1081 31.2656 13.1257 30.3251H13.1261ZM18.5748 25.1347C18.5465 25.1183 18.5181 25.1014 18.4902 25.085C17.9463 25.6324 17.3864 26.1646 16.8632 26.7309C16.2698 27.373 15.7914 28.0977 15.4933 28.9291C15.3317 29.3794 15.2522 29.8435 15.3783 30.3163C15.4845 30.7153 15.8616 30.8806 16.2315 30.6973C16.3457 30.6407 16.4714 30.5682 16.5412 30.4675C16.8325 30.0476 17.149 29.6361 17.3748 29.1809C17.8673 28.1899 18.1239 27.1139 18.3781 26.0419C18.4495 25.7411 18.5098 25.4371 18.5748 25.1347Z"
      fill="white"
    />
    <path
      d="M22.1934 6.875C22.5349 6.96179 22.8936 7.00781 23.2142 7.14384C23.8872 7.42963 24.172 8.08881 24.0246 8.89694C23.8538 9.83263 23.1668 10.4083 22.1227 10.4632C21.835 10.4781 21.5389 10.464 21.2561 10.4107C20.2143 10.2145 19.9355 9.36923 20.0118 8.57886C20.1014 7.65205 20.9857 7.00942 21.9182 6.93878C22.0049 6.93232 22.0921 6.93797 22.1793 6.93797C22.1841 6.91698 22.1885 6.89599 22.1934 6.875Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4124_928"
        x1="-4.32"
        y1="-4.6"
        x2="53.92"
        y2="49.88"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.04" stopColor="#FCB316" />
        <stop offset="0.07" stopColor="#FBAE16" />
        <stop offset="0.11" stopColor="#F9A118" />
        <stop offset="0.15" stopColor="#F68A1B" />
        <stop offset="0.18" stopColor="#F26B20" />
        <stop offset="0.2" stopColor="#F15E22" />
        <stop offset="0.35" stopColor="#F21196" />
        <stop offset="0.474119" stopColor="#C430AE" />
        <stop offset="0.57" stopColor="#9650C8" />
        <stop offset="0.59" stopColor="#895BC9" />
        <stop offset="0.65" stopColor="#697BCD" />
        <stop offset="0.71" stopColor="#35ADD2" />
        <stop offset="0.73" stopColor="#23C0D5" />
        <stop offset="0.79" stopColor="#22B7D1" />
        <stop offset="0.89" stopColor="#1F9EC8" />
        <stop offset="1" stopColor="#1F78BA" />
      </linearGradient>
    </defs>
  </svg>,
  'JoyousLogoSquareIcon',
);
