import MuiChip, { type ChipProps, chipClasses } from '@mui/material/Chip';
import svgIconClasses from '@mui/material/SvgIcon/svgIconClasses';
import { type ThemeColorScheme, styled, theme, transientOptions } from '@shared/frontend/theme';
import React from 'react';

import { colorWithOpacity } from '../../theme/color';
import { type IconSize } from './styled-icon';
import { Caption, getTypographyStyles } from './typography';

const iconSize: IconSize = '1.8rem';

type Variant = 'filled' | 'outlined' | 'dark';
export type ColorScheme = Exclude<ThemeColorScheme, 'joyousDogOrange'>;
type ColorMap = Record<
  ColorScheme,
  Record<
    Variant,
    {
      bgColor: string;
      borderColor: string;
      textColor: string;
      iconColor: string;
      dismissIconColor: string;
    }
  >
>;

const colorMap: ColorMap = {
  ducksBlue: {
    filled: {
      bgColor: theme.color.ducksBlue[50],
      borderColor: 'transparent',
      textColor: theme.text.primary,
      iconColor: theme.color.ducksBlue[400],
      dismissIconColor: theme.color.ducksBlue[300],
    },
    outlined: {
      bgColor: theme.color.white,
      borderColor: theme.color.ducksBlue[300],
      textColor: theme.color.ducksBlue[500],
      iconColor: theme.color.ducksBlue[400],
      dismissIconColor: theme.color.ducksBlue[300],
    },
    dark: {
      bgColor: theme.color.ducksBlue[500],
      borderColor: 'transparent',
      textColor: theme.color.white,
      iconColor: theme.color.white,
      dismissIconColor: theme.color.white,
    },
  },
  kereruPurple: {
    filled: {
      bgColor: theme.color.kereruPurple[50],
      borderColor: 'transparent',
      textColor: theme.text.primary,
      iconColor: theme.color.kereruPurple[400],
      dismissIconColor: theme.color.kereruPurple[300],
    },
    outlined: {
      bgColor: theme.color.white,
      borderColor: theme.color.kereruPurple[300],
      textColor: theme.color.kereruPurple[500],
      iconColor: theme.color.kereruPurple[400],
      dismissIconColor: theme.color.kereruPurple[300],
    },
    dark: {
      bgColor: theme.color.kereruPurple[500],
      borderColor: 'transparent',
      textColor: theme.color.white,
      iconColor: theme.color.white,
      dismissIconColor: theme.color.white,
    },
  },
  microPigPink: {
    filled: {
      bgColor: theme.color.microPigPink[50],
      borderColor: 'transparent',
      textColor: theme.text.primary,
      iconColor: theme.color.microPigPink[400],
      dismissIconColor: theme.color.microPigPink[200],
    },
    outlined: {
      bgColor: theme.color.white,
      borderColor: theme.color.microPigPink[300],
      textColor: theme.color.microPigPink[300],
      iconColor: theme.color.microPigPink[400],
      dismissIconColor: theme.color.microPigPink[200],
    },
    dark: {
      bgColor: theme.color.microPigPink[500],
      borderColor: 'transparent',
      textColor: theme.color.white,
      iconColor: theme.color.white,
      dismissIconColor: theme.color.white,
    },
  },
  happyYellow: {
    filled: {
      bgColor: theme.color.happyYellow[50],
      borderColor: 'transparent',
      textColor: theme.text.primary,
      iconColor: theme.color.happyYellow[400],
      dismissIconColor: theme.color.happyYellow[300],
    },
    outlined: {
      bgColor: theme.color.white,
      borderColor: theme.color.happyYellow[300],
      textColor: theme.color.happyYellow[500],
      iconColor: theme.color.happyYellow[400],
      dismissIconColor: theme.color.happyYellow[300],
    },
    dark: {
      bgColor: theme.color.happyYellow[500],
      borderColor: 'transparent',
      textColor: theme.color.white,
      iconColor: theme.color.white,
      dismissIconColor: theme.color.white,
    },
  },
  joyousBlue: {
    filled: {
      bgColor: theme.color.joyousBlue[50],
      borderColor: 'transparent',
      textColor: theme.text.primary,
      iconColor: theme.color.joyousBlue[400],
      dismissIconColor: theme.color.joyousBlue[300],
    },
    outlined: {
      bgColor: theme.color.white,
      borderColor: theme.color.joyousBlue[500],
      textColor: theme.color.joyousBlue[500],
      iconColor: theme.color.joyousBlue[400],
      dismissIconColor: theme.color.joyousBlue[300],
    },
    dark: {
      bgColor: theme.color.joyousBlue[500],
      borderColor: 'transparent',
      textColor: theme.color.white,
      iconColor: theme.color.white,
      dismissIconColor: theme.color.white,
    },
  },
  katipoBlack: {
    filled: {
      bgColor: theme.color.katipoBlack[50],
      borderColor: 'transparent',
      textColor: theme.text.primary,
      iconColor: theme.color.katipoBlack[400],
      dismissIconColor: theme.color.katipoBlack[300],
    },
    outlined: {
      bgColor: theme.color.white,
      borderColor: theme.color.katipoBlack[300],
      textColor: theme.color.katipoBlack[300],
      iconColor: theme.color.katipoBlack[400],
      dismissIconColor: theme.color.katipoBlack[200],
    },
    dark: {
      bgColor: theme.color.katipoBlack[500],
      borderColor: 'transparent',
      textColor: theme.color.white,
      iconColor: theme.color.white,
      dismissIconColor: theme.color.white,
    },
  },
};

const getHoverBgColor = ($colorScheme: ColorScheme, $variant: Variant) => {
  if ($variant === 'outlined') {
    return colorWithOpacity(colorMap[$colorScheme].outlined.borderColor, 0.1);
  }

  return `color-mix(in srgb, ${colorMap[$colorScheme][$variant].bgColor}, black 10%)`;
};

type Props = {
  $variant: Variant;
  $colorScheme: ColorScheme;
  $disabled?: boolean;
  $maxWidth?: string;
  action?: {
    onClick: ChipProps['onDelete'];
    icon: ChipProps['deleteIcon'];
  };
} & ChipProps;

export const Chip = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ action, $disabled, $colorScheme, $variant, $maxWidth, ...props }: Props) => (
    <MuiChip
      deleteIcon={action?.icon}
      onDelete={action?.onClick}
      title={props.label ? `${props.label}` : undefined}
      aria-disabled={$disabled}
      {...props}
    />
  ),
)<Props>`
  && {
    height: 2.8rem;
    opacity: ${({ $disabled }) => ($disabled ? '0.4' : '1')};

    .${chipClasses.label} {
      ${getTypographyStyles({ $variant: 'Caption', $fontWeight: 'medium', $noWrap: true })}
      padding-left: ${theme.spacing.s};
      padding-right: ${theme.spacing.s};
      ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth};` : '')}
    }

    ${({ $variant, $colorScheme, onClick }) => `
      background-color: ${colorMap[$colorScheme][$variant].bgColor};
      border: 0.1rem solid ${colorMap[$colorScheme][$variant].borderColor};
      color: ${colorMap[$colorScheme][$variant].textColor};

      ${
        onClick
          ? `
          &:hover {
            background-color: ${getHoverBgColor($colorScheme, $variant)};
          }
        `
          : ''
      }

      &.${chipClasses.focusVisible} {
        background-color: ${getHoverBgColor($colorScheme, $variant)};
      }

      .${svgIconClasses.root} {
        width: ${iconSize};
        height: ${iconSize};
        color: inherit;
      }

      && .${chipClasses.icon} {
        color: ${colorMap[$colorScheme][$variant].iconColor};
      }

      && .${chipClasses.deleteIcon} {
        color: ${colorMap[$colorScheme][$variant].dismissIconColor};

        &:hover {
          color: color-mix(in srgb, ${colorMap[$colorScheme][$variant].iconColor}, black 10%)
        }
      }
    `}
  }
`;

type ThemingChipProps = {
  $variant: Variant;
  $colorScheme: ColorScheme;
  $disabled?: boolean;
} & React.ClassAttributes<HTMLSpanElement> &
  React.HTMLAttributes<HTMLSpanElement>;

export const ThemingChip = styled(
  (props: ThemingChipProps) => <Caption $fontWeight="medium" {...props} />,
  transientOptions,
)<ThemingChipProps>`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.xs};
  border-radius: ${theme.borderRadius.lg};
  padding: ${theme.spacing.xs} ${theme.spacing.s};
  opacity: ${({ $disabled }) => ($disabled ? '0.4' : '1')};

  ${({ $variant, $colorScheme }) => `
    background-color: ${colorMap[$colorScheme][$variant].bgColor};
    border: 0.1rem solid ${colorMap[$colorScheme][$variant].borderColor};
    color: ${colorMap[$colorScheme][$variant].textColor};

    svg {
      color: ${colorMap[$colorScheme][$variant].iconColor};
      height: ${iconSize};
      width: ${iconSize};
    }
  `}
`;
