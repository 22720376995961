import { type IconName, Icons } from '@shared/frontend/components/icons';
import { type ThemeColorScheme, styled, theme, transientOptions } from '@shared/frontend/theme';
import React from 'react';

type IconProps = React.ComponentProps<(typeof Icons)[IconName]>;
export type IconSize =
  | '1.0rem'
  | '1.4rem'
  | '1.6rem'
  | '1.8rem'
  | '2.0rem'
  | '2.2rem'
  | '2.4rem'
  | '2.8rem'
  | '3.0rem'
  | '3.2rem'
  | '3.6rem'
  | '4.8rem'
  | '7.5rem'
  | '15.0rem';

export type Props = {
  iconName: IconName;
  $size: IconSize;
  /** Easy helper to use the 400 variant of one of our supported color schemes */
  $colorScheme?: ThemeColorScheme | 'gradient';
  /** Manual color string */
  $customColor?: string;
} & Omit<IconProps, 'color' | 'size'>;

export const StyledIcon = styled(({ iconName, ...restOfProps }: Props) => {
  const Icon = Icons[iconName];
  return <Icon {...restOfProps} />;
}, transientOptions)`
  &&&& {
    height: ${({ $size }) => $size};
    width: ${({ $size }) => $size};

    ${({ $colorScheme = 'ducksBlue' }) => {
      switch ($colorScheme) {
        case undefined:
          return '';
        case 'gradient':
          return `
                          fill: url(#linearColors);
                          path { fill: url(#linearColors); }
                        `;
        case 'happyYellow':
          return `color: ${theme.color[$colorScheme][600]};`;

        default:
          // Handle any other color scheme
          return `color: ${theme.color[$colorScheme][400]};`;
      }
    }}

    ${({ $customColor }) => ($customColor ? `color: ${$customColor};` : '')}
  }
`;
