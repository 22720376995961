import { gql } from '@apollo/client';

import messageFields from './message-fields';
import { authorFields } from './person-fields';

const conversationUpdatedFields = gql`
  fragment conversationUpdatedFields on ConversationSubscriptionResponse {
    conversation {
      _id
      lastSignificantUpdateTime
      hasOwnerInteractions
      ownerUnseenNotificationCount
      chatParticipationStatus
      isStarred
      hasActionablePhrases
      suggestedResponses {
        suggestions
        lastUpdateTime
      }
      aiFacilitatorResponse {
        nextAIFacilitatorResponseTime
      }
      containsTranslations
    }
    newMessages {
      ...messageFields
      author {
        ...authorFields
      }
    }
    updatedMessages {
      _id
      authorId
      updatedMessageProperties {
        text
        translatedText
        actionablePhrases {
          phrases {
            start
            end
            text
          }
        }
        reactions {
          personId
          type
          person {
            name
          }
        }
        upsertTimestamps
        conversationSchemeBlockId
        isSkip
        status
        ratingValue {
          rating
          sentiment
        }
        selectedValue
        selectedValues
        hasAIMessageBeenReported
        hasAIMessageBeenFlagged
        themesReportId
      }
    }
    deletedMessages {
      _id
      authorId
    }
    shouldApplyChangeImmediately
  }
  ${messageFields}
  ${authorFields}
`;

export default conversationUpdatedFields;
