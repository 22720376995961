import { LoggedInUserContext } from '@components/logged-in-user-context';
import { datadogRum } from '@datadog/browser-rum';
import { useIsExtraSmallResolution } from '@frontend/hooks/use-resolution';
import { styled, theme } from '@frontend/theme';
import { useQueryLoggedInUser } from '@shared/frontend/hooks/use-query-logged-in-user';
import { parseQueryResponse } from '@shared/graphql/parse-query-response';
import { Navigate, useParams } from '@tanstack/react-router';
import React, { useState } from 'react';

import { tokenManager } from '../../utils/token-manager';
import { Conversations } from '../conversations/conversations';
import { ListContainer } from '../conversations/list/list-container';
import { useSubscribeToUpdates } from '../conversations/use-subscribe-to-updates';
import { Drawer } from './drawer';

const BASE_FUNCTION_PATH = 'team-member-ui.src.features.page-layout.page-with-conversations';

// This is to prevent the header/footer content being covered by
// the browser navigation tools in iOS Safari
const MobileWrapper = styled('div')`
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
`;

const DesktopWrapper = styled('div')`
  display: grid;
  height: 100%;

  @media (min-width: ${theme.breakpointPixels.sm}) {
    grid-template-columns: 45% 1fr;
  }
  @media (min-width: ${theme.breakpointPixels.md}) {
    grid-template-columns: 33% 1fr;
  }
`;

type ViewProps = {
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DesktopView = ({ setIsDrawerOpen }: ViewProps) => {
  return (
    <DesktopWrapper>
      <ListContainer setIsDrawerOpen={setIsDrawerOpen} />
      <Conversations />
    </DesktopWrapper>
  );
};

const MobileView = ({ setIsDrawerOpen }: ViewProps) => {
  const { selectedConversationId } = useParams({
    from: '/AppLayout/conversations/$selectedConversationId',
  });

  return (
    <MobileWrapper>
      {!selectedConversationId ? (
        <ListContainer setIsDrawerOpen={setIsDrawerOpen} />
      ) : (
        <Conversations />
      )}
    </MobileWrapper>
  );
};

// route that redirects to /error if not authenticated, otherwise the passed in component
export const PageWithConversations = () => {
  const functionPath = `${BASE_FUNCTION_PATH}.PageWithConversations`;
  const currentUserAccessToken = tokenManager.getAccessTokenPayload();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMobileResolution = useIsExtraSmallResolution();
  const [loggedInUserData, loggedInUserFallback] = parseQueryResponse(
    useQueryLoggedInUser({
      skip: !currentUserAccessToken,
    }),
  );

  useSubscribeToUpdates();

  if (!currentUserAccessToken) {
    datadogRum.addError('No user access token.', { functionPath });
    tokenManager.clear();
    datadogRum.clearUser();
    return <Navigate to="/error/authentication" />;
  }

  if (loggedInUserFallback) {
    return loggedInUserFallback;
  }

  return (
    <LoggedInUserContext.Provider value={loggedInUserData?.loggedInUser}>
      {isMobileResolution ? (
        <MobileView setIsDrawerOpen={setIsDrawerOpen} />
      ) : (
        <DesktopView setIsDrawerOpen={setIsDrawerOpen} />
      )}
      <Drawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
    </LoggedInUserContext.Provider>
  );
};
