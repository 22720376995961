import { useConversationContext } from '@shared/frontend/components/conversation/conversation-context';
import { useMessageProperties } from '@shared/frontend/components/conversation/message/use-message-properties';
import { Icons } from '@shared/frontend/components/icons';
import { MenuItem } from '@shared/frontend/components/menu';
import React from 'react';

import { useToggleFeatureCommentMutation } from './use-toggle-feature-comment-mutation';

type Props = {
  handleMenuClose: (event: React.MouseEvent<HTMLElement>) => void;
};

export const FeatureCommentMenuItem = ({ handleMenuClose }: Props) => {
  const [toggleFeatureMessage] = useToggleFeatureCommentMutation();

  const { conversation } = useConversationContext();
  const { message } = useMessageProperties();

  const isPreferredFeatured = !!conversation.featuredCommentCandidates?.find(
    (candidate) => candidate.messageId === message._id,
  )?.isPreferred;

  return (
    <MenuItem
      onClick={(event) => {
        event.stopPropagation();
        handleMenuClose(event);
        toggleFeatureMessage(conversation, message._id, isPreferredFeatured);
      }}
      tabIndex={0}
      data-cy="message-feature-button"
    >
      <Icons.Bookmark />
      {isPreferredFeatured ? 'Remove featured comment' : 'Make featured comment'}
    </MenuItem>
  );
};
