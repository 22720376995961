import { BodyConversation } from '@components/base/typography';
import { styled, theme } from '@frontend/theme';
import React from 'react';

type ContainerProps = {
  $hasExtraHorizontalPadding: boolean;
};

const Container = styled('div')<ContainerProps>`
  height: 2rem;
  min-width: 2rem;
  padding: ${({ $hasExtraHorizontalPadding }) =>
    $hasExtraHorizontalPadding ? `${theme.spacing.xxs} ${theme.spacing.xs}` : theme.spacing.xxs};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: ${theme.borderRadius.md};
  background-color: ${theme.secondary};
`;

type Props = {
  notificationCount: number;
};

export const NotificationBubble = ({ notificationCount }: Props) => (
  <Container $hasExtraHorizontalPadding={notificationCount > 9}>
    <BodyConversation
      data-cy="notification-count"
      $fontWeight="medium"
      $customColor={theme.color.white}
    >
      {notificationCount}
    </BodyConversation>
  </Container>
);
