import { HttpLink } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { type ApolloRegionModule } from '@shared/graphql/apollo-region-module';
import { type TokenManager } from '@shared/utils/token-manager';

export const getVersionLink = (buildVersion: string | undefined) =>
  setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...(buildVersion ? { 'client-version': buildVersion } : {}),
    },
  }));

export const getAuthLink = (tokenManager: TokenManager) =>
  setContext((_, { headers: baseHeaders }) => {
    const joyousJwt = tokenManager.getAccessTokenJwtFromStorage();

    if (joyousJwt) {
      return {
        headers: {
          ...baseHeaders,
          authorization: `Bearer ${joyousJwt}`,
        },
      };
    }

    return { headers: baseHeaders };
  });

export const getHttpLinks = (apolloRegionModuleInstance: ApolloRegionModule) => {
  const customFetch = (_uri: string, options: { body: string }) =>
    fetch(apolloRegionModuleInstance.getApolloUri(), options);

  const basicHttpLink = new HttpLink({ fetch: customFetch });
  const batchHttpLink = new BatchHttpLink({ fetch: customFetch });

  return { basicHttpLink, batchHttpLink };
};
