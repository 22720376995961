import backdropClasses from '@mui/material/Backdrop/backdropClasses';
import touchRippleClasses from '@mui/material/ButtonBase/touchRippleClasses';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import paperClasses from '@mui/material/Paper/paperClasses';

import { styled, theme } from '../theme';

export const Drawer = styled(MuiDrawer)<DrawerProps>`
  && {
    .${paperClasses.root} {
      padding: ${theme.spacing.s};
      background-color: ${theme.color.white};
      display: flex;
      flex-direction: column;
      border-radius: 0 ${theme.borderRadius.sm} ${theme.borderRadius.sm} 0;
      box-shadow: ${theme.shadows.heavy};

      .${touchRippleClasses.child} {
        background-color: ${theme.color.ducksBlue[300]};
      }
    }

    .${backdropClasses.root} {
      background-color: transparent;
    }
  }
`;
