import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { Global } from '@emotion/react';
import { getTypographyStyles } from '@shared/frontend/components/base/typography';
import { css, theme } from '@shared/frontend/theme';
import React from 'react';

const globalStyles = css`
  html {
    font-size: 62.5%;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    height: 100vh;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  strong,
  b {
    font-weight: 700;
  }

  body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;

    ${getTypographyStyles({ $variant: 'BodyUi', $customColor: theme.text.primary })};
  }

  @media print {
    body {
      background-color: #fff;
    }
  }

  body::backdrop {
    background-color: #eceff1;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  :focus-visible {
    outline: ${theme.color.ducksBlue[300]} auto 0.1rem;
  }

  #hubspot-messages-iframe-container {
    transform: translate(10rem);

    .hs-shadow-container {
      + iframe {
        visibility: hidden !important;
        opacity: 0;
        transform: translate(-10rem, calc(100%)) scale(0);
        transform-origin: bottom right;
        transition:
          opacity 0.3s ease,
          visibility 0.3s ease,
          transform 0.3s ease;
      }

      &.active + iframe {
        visibility: visible !important;
        opacity: 1;
        transform: translate(-10rem, 0) scale(1);
        transition:
          opacity 0.3s ease,
          visibility 0.3s ease,
          transform 0.3s ease;
      }
    }
  }
`;

export const ReactContext = ({
  apolloClient,
  children,
}: {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  children: React.ReactNode;
}) => (
  <>
    <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    <Global styles={globalStyles} />
  </>
);
