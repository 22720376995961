import createSvgIcon from '@mui/material/utils/createSvgIcon';
import React from 'react';

export const HideTimelineIcon = createSvgIcon(
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.75 3.25C2.75 2.5625 2.99479 1.97396 3.48438 1.48438L2.125 0.125L0.25 2L2.75 4.5V3.25Z"
      fill="currentColor"
    />
    <path d="M10.25 10.75H12.75L5.25 3.25V7L10.25 12V10.75Z" fill="currentColor" />
    <path d="M15.25 13.25H11.5L14 15.75L19 20.75H22.75L15.25 13.25Z" fill="currentColor" />
    <path
      d="M24.5156 22.5156C24.026 23.0052 23.4375 23.25 22.75 23.25H21.5L24 25.75L25.875 23.875L24.5156 22.5156Z"
      fill="currentColor"
    />
    <path
      d="M14 18.25V15.75H6.5V18.25H14ZM10.25 13.25H11.5H15.25L12.75 10.75H10.25V12V13.25ZM14 8.25H21.5V5.75H14V8.25ZM5.25 23.25C4.5625 23.25 3.97396 23.0052 3.48438 22.5156C2.99479 22.026 2.75 21.4375 2.75 20.75V4.5V3.25C2.75 2.5625 2.99479 1.97396 3.48438 1.48438L5.25 3.25V7V20.75H19H22.75L24.5156 22.5156C24.026 23.0052 23.4375 23.25 22.75 23.25H21.5H5.25ZM25.25 3.25V20.125L22.75 17.625V3.25H8.375L5.875 0.75H14.3125H22.75C23.4375 0.75 24.026 0.994792 24.5156 1.48438C25.0052 1.97396 25.25 2.5625 25.25 3.25Z"
      fill="currentColor"
    />
  </svg>,
  'HideTimelineIcon',
);
