import { StatusMessage } from '@components/status-message';
import { styled, theme } from '@frontend/theme';
import { useParams } from '@tanstack/react-router';
import React from 'react';

import { ConversationHeader } from '../page-layout/header/conversation-header';
import { DetailsContainer } from './details/details-container';

const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const StyledStatusMessage = styled(StatusMessage)`
  background-color: ${theme.color.white};
`;

export const Conversations = () => {
  const { selectedConversationId } = useParams({
    from: '/AppLayout/conversations/$selectedConversationId',
  });

  return (
    <Container>
      <ConversationHeader />
      {selectedConversationId ? (
        <DetailsContainer selectedConversationId={selectedConversationId} />
      ) : (
        <StyledStatusMessage variant="noSelectedConversationTeamMember" />
      )}
    </Container>
  );
};
