import { backdropClasses as muiBackdropClasses } from '@mui/material/Backdrop';
import MuiDialog, {
  type DialogProps,
  dialogClasses as muiDialogClasses,
} from '@mui/material/Dialog';
import { IconButton } from '@shared/frontend/components/base/icon-button';
import { BodyUi, getTypographyStyles } from '@shared/frontend/components/base/typography';
import { type ThemeColorScheme, styled, theme } from '@shared/frontend/theme';
import React from 'react';

import { StyledIcon } from '../base/styled-icon';
import { type IconName } from '../icons';
import { FooterButton, type FooterButtonProps } from './footer-button';

export type DialogColorScheme = Extract<ThemeColorScheme, 'ducksBlue' | 'kereruPurple'>;

type ColorSchemeProps = { $colorScheme: DialogColorScheme };

type BaseDialogProps = {
  open: boolean;
  onClose?: (event?: object, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  onExited?: () => void;
} & Omit<DialogProps, 'title' | 'color'>;

export const Dialog = styled(
  ({ open, onClose, onExited, maxWidth, ...restOfProps }: BaseDialogProps) => (
    <MuiDialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      data-cy="dialog"
      TransitionProps={{
        onExited,
      }}
      {...restOfProps}
    />
  ),
)`
  .${muiDialogClasses.paper} {
    width: 100%;
    border-radius: ${theme.borderRadius.sm};
    padding: ${theme.spacing.l};
    box-shadow: none;
    background: ${theme.color.white};

    .${muiDialogClasses.root} {
      padding: 0 0 ${theme.spacing.l};
    }
  }

  .${muiBackdropClasses.root} {
    background: ${theme.backgroundDialog};
  }
`;

const UnstyledDialog = styled(Dialog)`
  .${muiDialogClasses.paper} {
    border-radius: 0;
    padding: 0;

    .${muiDialogClasses.root} {
      padding: 0;
    }
  }
`;

const DialogContent = styled('div')`
  ${getTypographyStyles({ $variant: 'BodyUi' })};

  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.l};
`;

const UnstyledDialogContent = styled(DialogContent)`
  height: 100%;
  row-gap: 0;
`;

export const FullScreenDialog = ({ children, open, ...restOfDialogProps }: BaseDialogProps) => {
  return (
    <UnstyledDialog open={open} fullScreen {...restOfDialogProps}>
      <UnstyledDialogContent>{children}</UnstyledDialogContent>
    </UnstyledDialog>
  );
};

const DialogHeader = styled('div')<ColorSchemeProps>`
  ${getTypographyStyles({ $variant: 'H1', $fontWeight: 'medium' })};
  color: ${({ $colorScheme }) => theme.color[$colorScheme][500]};
`;

const Row = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.xs};
`;

const DialogErrorWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.xs};
`;

export const DialogFooter = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing.s};
`;

const CloseButtonWrapper = styled('div')`
  margin-left: auto;
`;

type DialogInfoProps = {
  title: React.ReactNode;
  subTitle?: string;
  iconName?: IconName;
} & Partial<ColorSchemeProps> &
  BaseDialogProps;

export const DialogInfo = ({
  title,
  subTitle,
  children,
  $colorScheme = 'ducksBlue',
  open,
  onClose,
  iconName,
  ...restOfDialogProps
}: DialogInfoProps) => {
  return (
    <Dialog open={open} onClose={onClose} {...restOfDialogProps}>
      <DialogContent>
        <DialogHeader $colorScheme={$colorScheme}>
          <Row data-cy="dialog-title">
            {iconName && (
              <StyledIcon iconName={iconName} $size="2.4rem" $colorScheme={$colorScheme} />
            )}
            {title}
            <CloseButtonWrapper>
              <IconButton
                iconName="Close"
                $colorScheme={$colorScheme}
                onClick={onClose}
                data-cy="dialog-close-button"
              />
            </CloseButtonWrapper>
          </Row>
          {subTitle && (
            <Row>
              <BodyUi>{subTitle}</BodyUi>
            </Row>
          )}
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
};

type CustomFooterProps = {
  footer: React.ReactNode;
  primaryButtonProps?: never;
  secondaryButtonProps?: never;
};

type FooterProps = {
  footer?: never;
  primaryButtonProps: Omit<FooterButtonProps, '$colorScheme' | 'data-cy'>;
  secondaryButtonProps?: Partial<Omit<FooterButtonProps, '$colorScheme' | 'data-cy'>>;
};

type DialogActionableProps = (CustomFooterProps | FooterProps) & {
  errors?: string[];
} & DialogInfoProps;

export const DialogActionable = ({
  title,
  subTitle,
  children,
  $colorScheme = 'ducksBlue',
  open,
  onClose,
  footer,
  primaryButtonProps,
  secondaryButtonProps,
  errors = [],
  iconName,
  ...restOfDialogProps
}: DialogActionableProps) => {
  // FIXME: Figure out a way to cancel the confirmation action
  // const preventOutsideClose =
  //   primaryButtonProps.requireConfirmation ||
  //   secondaryButtonProps?.requireConfirmation ||
  //   primaryButtonProps.loading ||
  //   secondaryButtonProps?.loading;
  //   onClose={preventOutsideClose ? () => {} : onClose}

  return (
    <Dialog open={open} onClose={onClose} {...restOfDialogProps}>
      <DialogContent>
        <DialogHeader $colorScheme={$colorScheme}>
          <Row data-cy="dialog-title">
            {iconName && (
              <StyledIcon iconName={iconName} $size="2.4rem" $colorScheme={$colorScheme} />
            )}
            {title}
          </Row>
          {subTitle && (
            <Row>
              <BodyUi>{subTitle}</BodyUi>
            </Row>
          )}
        </DialogHeader>
        {children}
        {!!errors.filter(Boolean).length && (
          <DialogErrorWrapper>
            {errors.map((error, index) => (
              <BodyUi
                key={`${title}-error-${index}`}
                $customColor={theme.text.error}
                data-cy="dialog-error"
              >
                {error}
              </BodyUi>
            ))}
          </DialogErrorWrapper>
        )}
        {footer ? (
          footer
        ) : (
          <DialogFooter>
            <FooterButton
              {...secondaryButtonProps}
              $colorScheme={$colorScheme}
              text={secondaryButtonProps?.text ?? 'Cancel'}
              onClick={() => {
                if (secondaryButtonProps?.onClick) {
                  secondaryButtonProps.onClick();
                  return;
                }
                if (onClose) {
                  onClose();
                }
              }}
              data-cy="dialog-secondary-button"
            />
            <FooterButton
              {...primaryButtonProps}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={primaryButtonProps?.onClick ?? (() => {})}
              text={primaryButtonProps?.text ?? ''}
              $colorScheme={$colorScheme}
              data-cy="dialog-primary-button"
            />
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
};
