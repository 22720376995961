import { createTheme } from '@mui/material';

import { theme } from './index';

export const muiTheme = createTheme({
  typography: {
    fontFamily: theme.font.fontFamily,
  },
  palette: {
    primary: {
      main: theme.primary,
    },
    secondary: {
      main: theme.secondary,
    },
    error: {
      main: theme.error,
    },
  },
  components: {
    MuiSvgIcon: {
      defaultProps: {
        color: 'secondary',
      },
    },
  },
});
