import { useConversationContext } from '@shared/frontend/components/conversation/conversation-context';
import { useMessageProperties } from '@shared/frontend/components/conversation/message/use-message-properties';
import { useLoggedInUser } from '@shared/frontend/components/logged-in-user-context';
import { getIsOrgUI } from '@shared/graphql/app-state';
import {
  isGraphQLComment,
  isGraphQLFilteredSelfSelect,
  isGraphQLSelfSelect,
} from '@shared/graphql/message-utils';

type MenuItemOptionFlags = {
  hasEditButton: boolean;
  hasViewButton: boolean;
  hasDeleteButton: boolean;
  hasFeatureButton: boolean;
  hasAIMessageReport: boolean;
  hasAIMessageFlag: boolean;
  hasAIMessageInfo: boolean;
};

export const useMenuItemMap = (): MenuItemOptionFlags => {
  const loggedInUser = useLoggedInUser();

  const { message, isAuthorUser, isAuthorJoyousAI, isSkippedConversationPromptAnswer } =
    useMessageProperties();
  const isComment = isGraphQLComment(message);

  const { commentingDisabled, admin } = loggedInUser?.permissions ?? {};
  const { conversation } = useConversationContext();
  const { isCampaignClosed } = conversation;

  if (getIsOrgUI()) {
    return {
      // Should only be for comments on org-ui
      hasEditButton: !!(
        (isAuthorUser || isAuthorJoyousAI) &&
        !isCampaignClosed &&
        isComment &&
        !commentingDisabled
      ),
      hasViewButton: isComment && !!message.image,
      // Should only be for comments on org-ui
      hasDeleteButton: (isAuthorUser || isAuthorJoyousAI) && isComment,
      // Feature button is available for admins only, for all comments in org-ui that do not have images
      hasFeatureButton: !!(!isAuthorJoyousAI && admin && isComment && !message.image),
      // Should only be for comments from AI Facilitator and if it has not been reported
      hasAIMessageReport:
        isAuthorJoyousAI &&
        isComment &&
        !message.hasAIMessageBeenReported &&
        !loggedInUser?.isJoyousAdmin,
      // Should only be for comments from AI Facilitator and if it it's the joyous admin
      hasAIMessageFlag: !!(isAuthorJoyousAI && isComment && loggedInUser?.isJoyousAdmin),
      // should only be for AI Facilitator messages
      hasAIMessageInfo: isAuthorJoyousAI,
    };
  }

  return {
    hasEditButton: !!(
      isAuthorUser &&
      !isCampaignClosed &&
      (!isComment || message.text) &&
      !commentingDisabled
    ),
    hasViewButton: isComment && !!message.image,
    hasDeleteButton:
      isAuthorUser &&
      !isSkippedConversationPromptAnswer &&
      !isGraphQLSelfSelect(message) &&
      !isGraphQLFilteredSelfSelect(message),
    hasFeatureButton: false,
    hasAIMessageReport: isAuthorJoyousAI && isComment && !message.hasAIMessageBeenReported,
    hasAIMessageFlag: false,
    hasAIMessageInfo: isAuthorJoyousAI,
  };
};
