import { styled, theme, transientOptions } from '@shared/frontend/theme';

const { fontFamily, size, weight, style, letterSpacing, lineHeight } = theme.font;

const baseStyles = `
  font-family: ${fontFamily};
  margin: 0;
`;

const noWrapStyles = `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const h1Styles = `
  font-size: ${size.lg};
  font-weight: ${weight.regular};
  line-height: ${lineHeight.lg};
  letter-spacing: ${letterSpacing.md};
`;

const h1ResponsiveStyles = `
  @media (max-width: ${theme.breakpointPixels.sm}) {
    font-size: ${size.xl};
    line-height: ${lineHeight.xl};
  }
`;

const h2Styles = `
  font-size: ${size.md};
  font-weight: ${weight.regular};
  line-height: ${lineHeight.md};
  letter-spacing: ${letterSpacing.md};
`;

const h2ResponsiveStyles = `
  @media (max-width: ${theme.breakpointPixels.sm}) {
    font-size: ${size.lg};
    line-height: ${lineHeight.lg};
  }
`;

const bodyConversationStyles = `
  font-size: ${size.md};
  font-weight: ${weight.regular};
  line-height: ${lineHeight.lg};
  letter-spacing: ${letterSpacing.sm};
  word-break: normal;
  overflow-wrap: anywhere;
`;

const bodyConversationResponsiveStyles = `
  @media (max-width: ${theme.breakpointPixels.sm}) {
    font-size: ${size.lg};
    line-height: ${lineHeight.xl};
  }
`;

const bodyUiStyles = `
  font-size: ${size.sm};
  font-weight: ${weight.regular};
  line-height: ${lineHeight.md};
  letter-spacing: ${letterSpacing.sm};
`;

const bodyUiResponsiveStyles = `
  @media (max-width: ${theme.breakpointPixels.sm}) {
    font-size: ${size.md};
    line-height: ${lineHeight.lg};
  }
`;

const captionStyles = `
  font-size: ${size.xs};
  font-weight: ${weight.regular};
  line-height: ${lineHeight.sm};
  letter-spacing: ${letterSpacing.sm};

  @media (max-width: ${theme.breakpointPixels.sm}) {
    font-size: ${size.sm};
    line-height: ${lineHeight.md};
  }
`;

const captionResponsiveStyles = `
  @media (max-width: ${theme.breakpointPixels.sm}) {
    font-size: ${size.sm};
    line-height: ${lineHeight.md};
  }
`;

const getVariantStyles = (variant: TypographyVariant) => {
  switch (variant) {
    case 'H1':
      return h1Styles;

    case 'H2':
      return h2Styles;

    case 'BodyConversation':
      return bodyConversationStyles;

    case 'BodyUi':
      return bodyUiStyles;

    case 'Caption':
      return captionStyles;

    default:
      return ``;
  }
};

const getResponsiveVariantStyles = (variant: TypographyVariant) => {
  switch (variant) {
    case 'H1':
      return h1ResponsiveStyles;

    case 'H2':
      return h2ResponsiveStyles;

    case 'BodyConversation':
      return bodyConversationResponsiveStyles;

    case 'BodyUi':
      return bodyUiResponsiveStyles;

    case 'Caption':
      return captionResponsiveStyles;

    default:
      return ``;
  }
};

type TypographyVariant = 'H1' | 'H2' | 'BodyConversation' | 'BodyUi' | 'Caption';

type TypographyProps = {
  $variant: TypographyVariant;
  $noWrap?: boolean;
  $fontWeight?: keyof typeof weight;
  $fontStyle?: keyof typeof style;
  $customColor?: string; // This is to allow any custom overrides
  $useResponsiveStyles?: boolean;
};

export const getTypographyStyles = ({
  $variant,
  $noWrap,
  $fontWeight,
  $fontStyle,
  $customColor,
  $useResponsiveStyles = true,
}: TypographyProps) => `
  ${baseStyles}
  ${getVariantStyles($variant)}
  ${$useResponsiveStyles ? getResponsiveVariantStyles($variant) : ''}

  ${$noWrap ? noWrapStyles : ''}
  ${$fontWeight ? `font-weight: ${weight[$fontWeight]};` : ''}
  ${$fontStyle ? `font-style: ${style[$fontStyle]};` : ''}
  ${$customColor ? `color: ${$customColor};` : ''}
`;

export const H1 = styled('h1', transientOptions)<Omit<TypographyProps, '$variant'>>`
  ${({ $noWrap, $fontWeight, $fontStyle, $customColor, $useResponsiveStyles }) =>
    getTypographyStyles({
      $variant: 'H1',
      $noWrap,
      $fontWeight,
      $fontStyle,
      $customColor,
      $useResponsiveStyles,
    })}
`;

export const H2 = styled('h2', transientOptions)<Omit<TypographyProps, '$variant'>>`
  ${({ $noWrap, $fontWeight, $fontStyle, $customColor, $useResponsiveStyles }) =>
    getTypographyStyles({
      $variant: 'H2',
      $noWrap,
      $fontWeight,
      $fontStyle,
      $customColor,
      $useResponsiveStyles,
    })}
`;

export const BodyConversation = styled('p', transientOptions)<Omit<TypographyProps, '$variant'>>`
  ${({ $noWrap, $fontWeight, $fontStyle, $customColor, $useResponsiveStyles }) =>
    getTypographyStyles({
      $variant: 'BodyConversation',
      $noWrap,
      $fontWeight,
      $fontStyle,
      $customColor,
      $useResponsiveStyles,
    })}
`;

export const BodyUi = styled('p', transientOptions)<Omit<TypographyProps, '$variant'>>`
  ${({ $noWrap, $fontWeight, $fontStyle, $customColor, $useResponsiveStyles }) =>
    getTypographyStyles({
      $variant: 'BodyUi',
      $noWrap,
      $fontWeight,
      $fontStyle,
      $customColor,
      $useResponsiveStyles,
    })}
`;

export const Caption = styled('span', transientOptions)<Omit<TypographyProps, '$variant'>>`
  ${({ $noWrap, $fontWeight, $fontStyle, $customColor, $useResponsiveStyles }) =>
    getTypographyStyles({
      $variant: 'Caption',
      $noWrap,
      $fontWeight,
      $fontStyle,
      $customColor,
      $useResponsiveStyles,
    })}
`;

/**
 * Used to custom style specific text part of a typography
 * i.e. bolding a word or making a word italic
 */
export const Span = styled('span', transientOptions)<Omit<TypographyProps, '$variant'>>`
  ${({ $noWrap, $fontWeight, $fontStyle, $customColor }) => `
    display: inline-block;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    ${baseStyles}
    ${$noWrap ? noWrapStyles : ''}
    ${$fontWeight ? `font-weight: ${weight[$fontWeight]};` : ''}
    ${$fontStyle ? `font-style: ${style[$fontStyle]};` : ''}
    ${$customColor ? `color: ${$customColor};` : ''}
  `}
`;
