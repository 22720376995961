import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { styled, theme } from '@frontend/theme';
import { StyledIcon } from '@components/base/styled-icon';
import JoybowLogoImage from '@frontend/assets/joybow-logo.png';
import { BodyConversation, H1 } from '@components/base/typography';
import { Button } from '@components/base/button';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { CONTACT_METHOD_TO_DISPLAY, ContactMethod } from '@shared/utils/contact-method';

import { PageWrapper } from './authentication';
import {
  getDeviceId,
  MAGIC_TOKEN_LOCAL_STORAGE_KEY,
  magicTokenLocalStorage,
} from '../../utils/auth-utils';

const StyledPageWrapper = styled(PageWrapper)`
  background-color: ${theme.color.happyYellow[25]};
  padding: ${theme.spacing.l};
`;

const DeviceErrorWrapper = styled('div')`
  max-width: 70rem;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xl};
  padding: ${theme.spacing.l} ${theme.spacing.xl};
  background-color: ${theme.color.white};
  border-radius: ${theme.borderRadius.md};
  border: 0.1rem solid ${theme.color.happyYellow[100]};

  @media (min-width: ${theme.breakpointPixels.sm}) {
    flex-direction: row;
  }
`;

type DeviceErrorSectionProps = {
  $gap: keyof typeof theme.spacing;
  $alignItems?: 'center' | 'flex-start';
};

const DeviceErrorSection = styled('div')<DeviceErrorSectionProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => theme.spacing[$gap]};
  justify-content: center;
  ${({ $alignItems }) => ($alignItems ? `align-items: ${$alignItems};` : '')};
`;

const JoybowLogo = styled('img')`
  height: 4.2rem;
  width: 10rem;
`;

const StyledH1 = styled(H1)`
  font-weight: ${theme.font.weight.medium};
  font-size: ${theme.font.size.xxl};
  line-height: ${theme.font.lineHeight.xl};
`;

const StyledButton = styled(Button)`
  align-self: center;

  @media (min-width: ${theme.breakpointPixels.sm}) {
    align-self: flex-start;
  }

  && {
    background-color: ${theme.color.happyYellow[500]};
    color: ${theme.color.katipoBlack[900]};
    font-size: 1.4rem;
  }
`;

const ADD_DEVICE = gql`
  mutation addDevice($tokenId: String!, $deviceId: String!) {
    addDevice(tokenId: $tokenId, deviceId: $deviceId)
  }
`;

const getContactMethodToDisplay = (contactMethod?: ContactMethod) => {
  if (!contactMethod) return 'contact method';
  if (contactMethod === ContactMethod.Email) return 'email address';
  if (contactMethod === ContactMethod.Sms) return 'phone number';
  return `${CONTACT_METHOD_TO_DISPLAY[contactMethod]} user`;
};

export const DeviceVerification = () => {
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [addDevice, { loading }] = useMutation(ADD_DEVICE);
  const navigate = useNavigate();
  const { contactMethod } = useSearch({ strict: false });

  const contactMethodToDisplay = getContactMethodToDisplay(contactMethod as ContactMethod);

  const sendVerificationLink = async () => {
    const deviceId = await getDeviceId();
    const magicToken = magicTokenLocalStorage.getItem(MAGIC_TOKEN_LOCAL_STORAGE_KEY);

    if (!magicToken) {
      await navigate({ to: '/error/authentication' });
      return;
    }

    await addDevice({ variables: { tokenId: magicToken, deviceId } });
    setIsLinkSent(true);
  };

  return (
    <StyledPageWrapper>
      <DeviceErrorWrapper>
        <DeviceErrorSection $gap="s" $alignItems="center">
          <StyledIcon
            iconName="GppMaybeOutlined"
            $size="15.0rem"
            $customColor={theme.color.happyYellow[500]}
          />
          <JoybowLogo src={JoybowLogoImage} alt="Joyous logo" />
        </DeviceErrorSection>

        <DeviceErrorSection $gap="l">
          <StyledH1>We noticed you&apos;re using a new device.</StyledH1>
          <BodyConversation>
            To keep your account secure, please verify your identity.
            <br />
            <br />
            We&apos;ll send a verification link to the same {contactMethodToDisplay} we used
            previously.
          </BodyConversation>
          <StyledButton
            $variant="filled"
            $colorScheme="happyYellow"
            $loading={loading}
            disabled={loading}
            onClick={sendVerificationLink}
          >
            {isLinkSent ? 'Resend' : 'Send'} verification link
          </StyledButton>
        </DeviceErrorSection>
      </DeviceErrorWrapper>
    </StyledPageWrapper>
  );
};
