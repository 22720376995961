import MuiButton, { type ButtonProps as MuiButtonProps } from '@mui/material/Button';
import MuiButtonBase, { buttonBaseClasses, type ButtonBaseProps } from '@mui/material/ButtonBase';
import { styled, theme, type ThemeColorScheme, transientOptions } from '@shared/frontend/theme';
import React, { type ComponentProps, type ForwardedRef } from 'react';

import { type IconName, Icons } from '../icons';
import { CircularProgress } from './progress';
import { type IconSize, StyledIcon } from './styled-icon';
import { getTypographyStyles } from './typography';

type ButtonColorScheme = Extract<
  ThemeColorScheme,
  'ducksBlue' | 'kereruPurple' | 'microPigPink' | 'katipoBlack' | 'happyYellow' | 'joyousDogOrange'
>;

const iconSize: IconSize = '1.8rem';

export const colorMap: Record<
  ButtonColorScheme,
  {
    textColor: string;
    iconColor: string;
    hoverBgColor: string;
    filledOutlineColor: string;
    filledBgColor: string;
  }
> = {
  ducksBlue: {
    textColor: theme.color.ducksBlue[500],
    iconColor: theme.color.ducksBlue[400],
    hoverBgColor: theme.color.ducksBlue[100],
    filledOutlineColor: theme.color.ducksBlue[200],
    filledBgColor: theme.color.ducksBlue[50],
  },
  kereruPurple: {
    textColor: theme.color.kereruPurple[500],
    iconColor: theme.color.kereruPurple[400],
    hoverBgColor: theme.color.kereruPurple[100],
    filledOutlineColor: theme.color.kereruPurple[200],
    filledBgColor: theme.color.kereruPurple[50],
  },
  microPigPink: {
    textColor: theme.color.microPigPink[500],
    iconColor: theme.color.microPigPink[400],
    hoverBgColor: theme.color.microPigPink[100],
    filledOutlineColor: theme.color.microPigPink[200],
    filledBgColor: theme.color.microPigPink[50],
  },
  happyYellow: {
    textColor: theme.color.happyYellow[700],
    iconColor: theme.color.happyYellow[600],
    hoverBgColor: theme.color.happyYellow[100],
    filledOutlineColor: theme.color.happyYellow[300],
    filledBgColor: theme.color.happyYellow[50],
  },
  katipoBlack: {
    textColor: theme.color.katipoBlack[500],
    iconColor: theme.color.katipoBlack[400],
    hoverBgColor: theme.color.katipoBlack[100],
    filledOutlineColor: theme.color.katipoBlack[200],
    filledBgColor: theme.color.katipoBlack[50],
  },
  joyousDogOrange: {
    textColor: theme.color.joyousDogOrange[500],
    iconColor: theme.color.joyousDogOrange[400],
    hoverBgColor: theme.color.joyousDogOrange[100],
    filledOutlineColor: theme.color.joyousDogOrange[200],
    filledBgColor: theme.color.joyousDogOrange[50],
  },
};

export type ButtonProps = {
  $variant: 'ghost' | 'filled';
  $colorScheme: ButtonColorScheme;
  $iconColorScheme?: ComponentProps<typeof StyledIcon>['$colorScheme'];
  $loading?: boolean;
  $leftIcon?: IconName;
  $rightIcon?: IconName;
} & Omit<MuiButtonProps, 'color' | 'variant'>;

export const Button = styled(
  ({
    $variant,
    $colorScheme,
    $loading,
    $leftIcon,
    $rightIcon,
    $iconColorScheme,
    children,
    ...restOfProps
  }: ButtonProps) => {
    if ($loading)
      return (
        <MuiButton disabled {...restOfProps}>
          <CircularProgress
            $colorScheme={$variant === 'filled' ? 'katipoBlack' : $colorScheme}
            size="1.8rem"
          />
        </MuiButton>
      );

    const LeftIcon = $leftIcon && Icons[$leftIcon];
    const RightIcon = $rightIcon && Icons[$rightIcon];

    return (
      <MuiButton {...restOfProps}>
        {LeftIcon && (
          <StyledIcon
            iconName={$leftIcon}
            className="leftIcon"
            $size="1.8rem"
            $colorScheme={$iconColorScheme ?? $colorScheme}
          />
        )}
        {children}
        {RightIcon && (
          <StyledIcon
            iconName={$rightIcon}
            className="rightIcon"
            $size="1.8rem"
            $colorScheme={$iconColorScheme ?? $colorScheme}
          />
        )}
      </MuiButton>
    );
  },
)<ButtonProps>`
  && {
    ${getTypographyStyles({ $variant: 'BodyUi' })};

    text-transform: none;
    min-height: 2.8rem;
    max-height: fit-content;
    padding: 0 ${theme.spacing.s};
    border-radius: ${theme.borderRadius[50]};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing.xs};
    color: ${({ $colorScheme }) => colorMap[$colorScheme].textColor};

    svg {
      color: ${({ $colorScheme }) => colorMap[$colorScheme].textColor};
      height: ${iconSize};
      width: ${iconSize};
    }

    .leftIcon {
      margin-left: -${theme.spacing.xxs};
    }

    .rightIcon {
      margin-right: -${theme.spacing.xxs};
    }

    ${({ $variant, $colorScheme }) => {
      switch ($variant) {
        case 'filled':
          return `
            background-color:  ${colorMap[$colorScheme].filledBgColor};
            border: 0.1rem solid ${colorMap[$colorScheme].filledOutlineColor};
            `;
        case 'ghost':
          return `
            background-color: transparent;
            border: 0.1rem solid transparent;
            `;
        default:
          return '';
      }
    }}

    &:hover {
      background-color: ${({ $colorScheme }) => colorMap[$colorScheme].hoverBgColor};
    }

    &&:disabled {
      color: ${theme.color.katipoBlack[200]};

      ${({ $variant }) =>
        $variant === 'filled' &&
        `
        background-color: ${theme.color.katipoBlack[25]};
        border-color: ${theme.color.katipoBlack[50]};
        `}

      svg {
        color: ${theme.color.katipoBlack[100]};
      }
    }
  }
`;

export const UnstyledButton = styled(
  React.forwardRef((props: ButtonBaseProps, ref: ForwardedRef<HTMLButtonElement>) => (
    <MuiButtonBase ref={ref} focusRipple {...props} />
  )),
)`
  && {
    ${({ disableRipple }) =>
      disableRipple &&
      `
        &:focus-visible {
          outline: ${theme.color.ducksBlue[300]} auto 0.1rem;
        }
    `}
  }
`;

type CircularArrowButtonProps = {
  direction: 'left' | 'right';
} & MuiButtonProps;

export const CircularArrowButton = styled(
  ({ direction, ...restOfProps }: CircularArrowButtonProps) => (
    <MuiButton {...restOfProps}>
      <StyledIcon iconName={direction === 'left' ? 'ArrowBack' : 'ArrowForward'} $size="3.6rem" />
    </MuiButton>
  ),
  transientOptions,
)<CircularArrowButtonProps>`
  &&.${buttonBaseClasses.root} {
    z-index: 4;
    min-width: 4.8rem;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    box-shadow: ${theme.shadows.light};
    background-color: ${theme.color.white};
    ${({ disabled }) => (disabled ? 'visibility: hidden;' : '')}
  }
`;
