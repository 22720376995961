import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const useIsExtraSmallResolution = () =>
  useMediaQuery(useTheme().breakpoints.down('sm'), { noSsr: true }); // 600px

export const useIsSmallResolution = () =>
  useMediaQuery(useTheme().breakpoints.down('md'), { noSsr: true }); // 900px

export const useIsMediumResolution = () =>
  useMediaQuery(useTheme().breakpoints.down('lg'), { noSsr: true }); // 1200px

export const useIsLargeResolution = () =>
  useMediaQuery(useTheme().breakpoints.down('xl'), { noSsr: true }); // 1536px
