import { DateTime, type DateTimeOptions } from 'luxon';

import { roundAccuratelyToOneDecimalPoint } from './math';

const DATE_FORMAT = 'EEEE, dd MMMM yyyy';

export const formatNumberToLocaleString = (numberToFormat: number) =>
  numberToFormat.toLocaleString();

export const formatNumberToOneDecimalPoint = (value: number) => {
  const roundedValue = roundAccuratelyToOneDecimalPoint(value);
  return roundedValue.toFixed(1);
};

export const getMongoClusterName = (mongoURL: string) =>
  /(?:@)(.*?)(?=\.)/.exec(mongoURL)?.[1] || 'local';

export const formatFullName = (givenName: string, familyName: string): string => {
  if (!familyName) return givenName;
  return `${givenName} ${familyName}`;
};

export const getFormattedDate = (date: number, timeZone?: DateTimeOptions['zone']) => {
  return DateTime.fromMillis(date, { zone: timeZone }).toFormat(DATE_FORMAT);
};
