export const JOYOUS_ERROR_NAME = 'JoyousError';

export class JoyousError extends Error {
  constructor(message: string) {
    super(message);

    // Maintains proper stack trace for where our error was thrown
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, JoyousError);
    }

    this.name = JOYOUS_ERROR_NAME;
  }

  static isJoyousError(error: unknown): error is JoyousError {
    return error instanceof JoyousError;
  }
}
