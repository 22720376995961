import {
  getApiEndpoint,
  getLocalEndpoint,
  getSubscriptionEndpoint,
} from '../utils/multi-region-utils';

export type ApolloRegionModule = {
  getApolloUri: () => string;
  getSubscriptionUri: () => string;
  setUris: (userLocation?: string | undefined) => void;
};

const apolloRegionModule = (
  joyousEnv: string,
  localApolloUri: string,
  localSubscriptionUri: string,
  crossDevice: boolean,
  localIp: string,
  provider: string,
): ApolloRegionModule => {
  let apolloUri: string;
  let subscriptionUri: string;

  const setUris = (userLocation?: string) => {
    apolloUri =
      joyousEnv === 'local'
        ? getLocalEndpoint(localApolloUri, !!crossDevice, localIp)
        : getApiEndpoint(userLocation, joyousEnv, provider);

    subscriptionUri =
      joyousEnv === 'local'
        ? getLocalEndpoint(localSubscriptionUri, !!crossDevice, localIp)
        : getSubscriptionEndpoint(userLocation, joyousEnv, provider);
  };

  // Set up initial URIs with default region
  setUris();

  return {
    getApolloUri: () => apolloUri,
    getSubscriptionUri: () => subscriptionUri,
    setUris,
  };
};

export default apolloRegionModule;
