import { CircularProgress } from '@components/base/progress';
import { StatusMessage } from '@components/status-message';
import { datadogRum } from '@datadog/browser-rum';
import { useNavigate } from '@tanstack/react-router';
import React, { useEffect, useState } from 'react';

import { PageWrapper } from './features/authentication/authentication';
import { DeviceVerification } from './features/authentication/device-verification';
import { useMagicTokenAuth } from './features/authentication/use-magic-token-auth';
import { MAGIC_TOKEN_LOCAL_STORAGE_KEY, magicTokenLocalStorage } from './utils/auth-utils';

export enum ErrorType {
  Maintenance = 'MAINTENANCE',
  TooManyRequests = 'TOO_MANY_REQUESTS',
  Authentication = 'AUTHENTICATION',
  Device = 'DEVICE',
}

type Props = {
  errorType?: ErrorType;
};

const BASE_FUNCTION_PATH = 'team-member-ui.src.error';

const TeamMemberAuthenticationError = () => {
  const functionPath = `${BASE_FUNCTION_PATH}.TeamMemberAuthenticationError`;

  const magicToken = magicTokenLocalStorage.getItem(MAGIC_TOKEN_LOCAL_STORAGE_KEY);

  useEffect(() => {
    datadogRum.addAction('Team member authentication error.', {
      functionPath,
      magicTokenPresent: !!magicToken,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const [isUnrecoverable, setIsUnrecoverable] = useState(false);

  useMagicTokenAuth({
    magicToken: isUnrecoverable ? undefined : magicToken,
    onSuccess: (hasAddedNewDevice?: boolean) => {
      datadogRum.addError(
        "Authentication was successfully recovered via locally stored magic token. This shouldn't be necessary.",
        { functionPath },
      ); // This is an error because it means that something went wrong and brought us here.
      return navigate({
        to: '/conversations/$selectedConversationId',
        params: { selectedConversationId: 'latest' },
        search: hasAddedNewDevice ? { newDevice: hasAddedNewDevice } : {},
      });
    },
    onError: () => {
      datadogRum.addAction('Could not recover from authentication error.', { functionPath });
      setIsUnrecoverable(true);
      // Return promise to match property signature
      return Promise.resolve();
    },
  });

  if (isUnrecoverable) {
    return <StatusMessage variant="authentication" />;
  }

  return (
    <PageWrapper>
      <CircularProgress size="4rem" />
    </PageWrapper>
  );
};

export const Error = ({ errorType }: Props) => {
  if (errorType === ErrorType.Authentication) {
    return <TeamMemberAuthenticationError />;
  }

  if (errorType === ErrorType.Device) {
    return <DeviceVerification />;
  }

  if (errorType === ErrorType.TooManyRequests) {
    return <StatusMessage variant="tooManyRequests" />;
  }

  if (errorType === ErrorType.Maintenance) {
    return <StatusMessage variant="maintenance" />;
  }

  return <StatusMessage variant="error" />;
};
