/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createRouter } from '@tanstack/react-router';
import React from 'react';

import { Error } from '../error';
import { AppLayoutTree } from './routes';
import { rootRoute } from './root-route';

// Structure of the Joyous Team-Member-UI Application
export const routeTree = rootRoute.addChildren([AppLayoutTree]);

export const router = createRouter({
  routeTree,
  defaultErrorComponent: () => <Error />,
});

/* We override the type definition of the Router to link it with our own instance.
 * This enables us to use the router with type-safety everywhere in the app.
 */
declare module '@tanstack/react-router' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Register {
    router: typeof router;
  }
}
