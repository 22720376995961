import { gql } from '@apollo/client';

import messageFields from './message-fields';

// only fields of conversation document, nothing resolved via graphql walk
const conversationFields = gql`
  fragment conversationFields on Conversation {
    _id
    orgId
    hasOwnerInteractions
    hasActionablePhrases
    isStarred
    lastSignificantUpdateTime
    leaderCommentCount
    campaignId
    creationTime
    latestQuestionResponseState
    ownerUnseenNotificationCount
    chatParticipationStatus
    ownerId
    messages {
      ...messageFields
    }
    conversationSchemeWidgetCount
    relationships {
      type
      visibility
    }
    attributes {
      name
      value
    }
    isStarred
    isCampaignClosed
    publishedThemeIds
    suggestedResponses {
      suggestions
      lastUpdateTime
    }
    aiFacilitatorResponse {
      nextAIFacilitatorResponseTime
    }
    containsTranslations
  }
  ${messageFields}
`;

export default conversationFields;
