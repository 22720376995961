import { styled, theme } from '@frontend/theme';
import { Outlet } from '@tanstack/react-router';
import React from 'react';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100vh;
  overflow: hidden;
  background-color: ${theme.color.white};
`;

export const App = () => {
  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  );
};
