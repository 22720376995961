// This is used for comments, ratings, select-many, and select-one widgets where borders can be highlighted.
export const getDataCyIdForBubbles = (
  isBorderHighlighted: boolean | undefined,
  isHighlighted: boolean | undefined,
): string => {
  if (isHighlighted) {
    return 'bubble-highlighted';
  }

  if (isBorderHighlighted) {
    return 'bubble-border-highlighted';
  }

  return '';
};
