import MuiAutocomplete, {
  autocompleteClasses,
  type AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';
import filledInputClasses from '@mui/material/FilledInput/filledInputClasses';
import MuiPopper from '@mui/material/Popper';
import { StyledIcon } from '@shared/frontend/components/base/styled-icon';
import { Icons } from '@shared/frontend/components/icons';
import { styled, theme } from '@shared/frontend/theme';
import { type Optional } from '@shared/types';
import React from 'react';

import { BodyUi } from '../base/typography';
import { DropdownOption, type Option } from './dropdown-option';
import { TextInput, type TextInputProps } from './text';

const Wrapper = styled('div')`
  position: relative;
  overflow: visible;
`;

const GroupHeader = styled('div')`
  height: 3.6rem;
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
`;

export type AutocompleteOption = string | Option;

type AutocompleteProps = MuiAutocompleteProps<AutocompleteOption, boolean, boolean, boolean>;

const StyledPaper = styled('div')`
  background: ${theme.color.white};
  overflow: hidden;
  border: ${theme.border};
  border-radius: ${theme.borderRadius.md};
  box-shadow: ${theme.shadows.light};
  z-index: ${theme.zIndex.max};

  &:empty {
    display: none;
  }
`;

const StyledListboxComponent = styled('ul')<React.HTMLAttributes<HTMLUListElement>>`
  && {
    background-color: ${theme.color.white};

    .${autocompleteClasses.option} {
      padding: 0;

      &.${autocompleteClasses.focused} {
        background-color: ${theme.color.ducksBlue[100]};
      }

      &[aria-selected='true'] {
        background-color: ${theme.color.ducksBlue[50]};
      }
    }
  }
`;

const StyledPopper = styled(MuiPopper)`
  && {
    z-index: ${theme.zIndex.max};
  }
`;

type Props = {
  label?: TextInputProps['label'];
  textInputProps?: Omit<TextInputProps, 'variant' | 'label'>;
} & Optional<AutocompleteProps, 'renderInput'>;

export const SingleSelectInput = styled(
  React.forwardRef<HTMLInputElement, Props>(
    ({ label, textInputProps, renderInput, disabled, ...props }, ref) => (
      <Wrapper>
        <MuiAutocomplete
          fullWidth
          openOnFocus
          blurOnSelect
          selectOnFocus={false}
          popupIcon={
            <StyledIcon
              iconName="ArrowDropDown"
              $size="2.4rem"
              $customColor={disabled ? theme.color.ducksBlue[100] : theme.secondary}
            />
          }
          clearIcon={<Icons.Clear />}
          PaperComponent={StyledPaper}
          ListboxComponent={StyledListboxComponent}
          renderInput={
            renderInput ??
            ((params) => <TextInput {...params} {...textInputProps} label={label} ref={ref} />)
          }
          renderOption={DropdownOption}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>
                <BodyUi $customColor={theme.text.muted}>{params.group.toUpperCase()}</BodyUi>
              </GroupHeader>
              {params.children}
            </li>
          )}
          PopperComponent={StyledPopper}
          disabled={disabled}
          {...props}
        />
      </Wrapper>
    ),
  ),
)<Props>`
  &&& {
    .${filledInputClasses.root} {
      background: ${theme.color.white};
      border: ${theme.border};
    }
  }
`;
