import { styled } from '@shared/frontend/theme';
import React from 'react';

// add http at front if no protocol supplied.
// eslint-disable-next-line no-useless-escape
const hasProtocol = (url: string) => /^(?:f|ht)tps?\:\/\//.test(url);
const clean = (url: string) => `${hasProtocol(url) ? '' : 'http://'}${url}`;

const openUrlOnEnter = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
  if (event.key === 'Enter') {
    event.currentTarget.click();
  }
};

const StyledLink = styled('a')<{ $color: string }>`
  text-decoration: underline;
  cursor: pointer;
  pointer-events: auto;
  word-break: break-all;
  color: ${({ $color }) => $color};
`;

const ClickableURL = ({
  url,
  raw,
  skipCleanUrl = false,
  isOnSameWindow = false,
  color = '',
}: {
  url: string;
  raw: string;
  skipCleanUrl?: boolean;
  isOnSameWindow?: boolean;
  color?: string;
}) => (
  <StyledLink
    href={skipCleanUrl ? url : clean(url)}
    onClick={(event) => event.stopPropagation()}
    onKeyDown={(event: React.KeyboardEvent<HTMLAnchorElement>) => openUrlOnEnter(event)}
    target={isOnSameWindow ? '' : '_blank'}
    rel="noopener noreferrer"
    $color={color}
  >
    {raw}
  </StyledLink>
);

export default ClickableURL;
