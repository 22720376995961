import createTheme from '@mui/material/styles/createTheme';

import { breakpointPixels } from './constants/breakpoint';
import { color } from './constants/color';
import { fontFamily, fontSize, fontWeight } from './constants/font-format';
import { spacing } from './constants/spacing';
import { zIndex } from './constants/z-index';

const primary = color.ducksBlue[500];
const secondary = color.ducksBlue[400];
const error = color.microPigPink[900];
const surface = color.white;

const header = {
  height: '5.6rem',
  heightPx: 56,
};

const button = {
  borderRadius: '2.4rem',
  primary: {
    color: color.white,
    background: primary,
    hover: {
      color: secondary,
      background: color.ducksBlue[100],
    },
  },
  secondary: {
    color: secondary,
    hoverBackground: color.ducksBlue[100],
    hover: {
      color: primary,
      background: color.ducksBlue[100],
    },
  },
  legacyGrey: {
    color: color.katipoBlack[700],
    hoverBackground: color.katipoBlack[100],
  },
  grey: {
    background: color.katipoBlack[100],
    color: color.katipoBlack[300],
    hoverBackground: color.katipoBlack[50],
  },
  disabled: {
    color: color.katipoBlack[200],
    background: color.katipoBlack[100],
  },
  error: {
    color: color.white,
    background: color.microPigPink[900],
    hover: {
      color: color.white,
      background: color.microPigPink[300],
    },
  },
};

const text = {
  primary: color.katipoBlack[700],
  secondary: color.katipoBlack[400],
  contrast: color.white,
  assistiveText: color.katipoBlack[300],
  error,
};

const theme = {
  breakpointPixels,
  color,
  button,
  text,
  fontFamily,
  fontSize,
  fontWeight,
  spacing,
  zIndex,
  iconSize: '2.4rem',
  primary,
  secondary,
  error,
  surface,
  header,
};

export const muiTheme = createTheme({
  typography: {
    fontFamily,
  },
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: error,
    },
  },
  components: {
    MuiSvgIcon: {
      defaultProps: {
        color: 'secondary',
      },
    },
  },
});

export type ThemeColor = keyof Pick<
  typeof color,
  | 'ducksBlue'
  | 'happyYellow'
  | 'joyousBlue'
  | 'joyousDogOrange'
  | 'katipoBlack'
  | 'kereruPurple'
  | 'microPigPink'
>;

export default theme;
