import { localesWithCurrency } from '@shared/utils/locale-utils';
import React, { useEffect, useState } from 'react';

import { useUpdatePersonPreferredLocale } from '../../hooks/use-update-person-preferred-locale';
import { styled, theme } from '../../theme';
import { Button } from '../base/button';
import { BodyUi, H2 } from '../base/typography';
import { DialogActionable } from '../dialog';
import { SingleSelectInput, type Option } from '../inputs';
import { useUserPreferredLocale } from '../logged-in-user-context';

const SectionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s};
`;

const LOCALE_OPTIONS: Option[] = localesWithCurrency
  // we only want to show English (US) and then all other languages. We do not want to show other English locales currently.
  .filter((locale) => locale.localeCode === 'en-US' || locale.language.code !== 'en')
  .sort((a, b) => {
    const languageSort = a.language.name.localeCompare(b.language.name);
    return languageSort === 0 ? a.country.name.localeCompare(b.country.name) : languageSort;
  })
  .map((locale) => ({
    key: locale.localeCode,
    label: `${locale.language.name} (${locale.country.name})`,
    optionData: {},
  }));

type Props = {
  open: boolean;
  onClose: () => void;
  showAdminUnsetOption?: boolean;
};

export const UserSettingsDialog = ({ open, onClose, showAdminUnsetOption }: Props) => {
  const userPreferredLocale = useUserPreferredLocale();
  const [preferredLanguage, setPreferredLanguage] = useState<Option | undefined>();
  const [updatePersonPreferredLocale, { loading, error }] = useUpdatePersonPreferredLocale({
    reloadPageOnSuccess: true,
  });

  useEffect(() => {
    const localeOption = LOCALE_OPTIONS.find((option) => option.key === userPreferredLocale);
    setPreferredLanguage(localeOption);
  }, [userPreferredLocale]);

  const onSave = async () => {
    if (preferredLanguage) {
      const res = await updatePersonPreferredLocale({
        variables: { preferredLocale: preferredLanguage.key },
      });
      if (!!res.data?.updatePersonPreferredLocale || res.data?.updatePersonPreferredLocale === '') {
        setPreferredLanguage(
          LOCALE_OPTIONS.find((option) => option.key === res.data?.updatePersonPreferredLocale),
        );
      }
    }
  };

  const onAdminUnset = async () => {
    await updatePersonPreferredLocale({
      variables: { preferredLocale: '' },
    });
    onClose();
  };

  const isDirty = preferredLanguage?.key !== userPreferredLocale;
  return (
    <DialogActionable
      open={open}
      title="Settings"
      onClose={onClose}
      primaryButtonProps={{
        text: 'Save',
        onClick: onSave,
        loading,
        disabled: !isDirty,
      }}
      errors={error ? [error.message] : []}
    >
      <SectionWrapper>
        <H2>Language</H2>
        <BodyUi>
          Conversations and notifications will be translated into your preferred language.
        </BodyUi>
        <SingleSelectInput
          label="Preferred language"
          data-cy="locale-select"
          getOptionLabel={(option) => (option as Option).label}
          disableClearable
          options={LOCALE_OPTIONS}
          isOptionEqualToValue={(option, value) =>
            (option as Option)?.key === (value as Option)?.key
          }
          value={preferredLanguage}
          onChange={(_e, value) => {
            setPreferredLanguage(value as Option);
          }}
        />
        {showAdminUnsetOption && (
          <Button
            $variant="filled"
            $colorScheme="microPigPink"
            $leftIcon="Delete"
            onClick={onAdminUnset}
          >
            Unset user preferred language
          </Button>
        )}
      </SectionWrapper>
    </DialogActionable>
  );
};
