export const fontSize = {
  xxs: '0.8rem',
  xs: '1rem',
  s: '1.2rem',
  m: '1.4rem', // default font size
  l: '1.6rem', // used for inputs to avoid zoom issue on apple
  xl: '1.8rem',
  xxl: '2.0rem',
} as const;

export const fontWeight = {
  regular: 400,
  medium: 500,
} as const;

export const fontFamily = 'Inter,Roboto, Helvetica, Arial, sans-serif';
