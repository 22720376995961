import loadImage from 'blueimp-load-image';

/**
 * Takes a file object and returns a promise of a dataURI string.
 * Scales down the image if it is not a gif.
 * @param  {object} file             An object containing the file path etc.
 * @param  {object} opts             Settings per blueimp-load-image, typically maxWidth, maxHeight
 * @param  {Number} jpgQuality=0.7   JPG quality, defaulting to 70%.
 * @return {Promise}                 Promise of a dataURL string.
 */
const convertFileToDataURI = (
  file: File,
  opts: { maxWidth: number; maxHeight: number },
  jpgQuality = 0.7,
): Promise<string> => {
  return new Promise((resolve) => {
    // downsampling of 0.5 significantly improves quality - may slow things down.
    const options = { orientation: true, canvas: true, downsamplingRatio: 0.5, ...opts };

    if (file.type === 'image/gif') {
      const fileReader = new FileReader();
      // Hook up on load end handler that runs once the FileReader has finished reading
      // the file into a dataURL
      fileReader.onloadend = () => {
        // TODO handle the return types properly
        resolve(fileReader.result as string);
      };
      // Start the file read
      fileReader.readAsDataURL(file);
    } else if (file.type === 'image/jpeg') {
      loadImage(
        file,
        (canvas) => resolve((canvas as HTMLCanvasElement).toDataURL('image/jpeg', jpgQuality)),
        options,
      );
    } else {
      loadImage(file, (canvas) => resolve((canvas as HTMLCanvasElement).toDataURL()), options);
    }
  });
};

export default convertFileToDataURI;
