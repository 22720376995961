import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';

export enum ContactMethod {
  Sms = 'sms',
  Email = 'email',
  SftpLink = 'sftpLink',
  MicrosoftTeams = 'microsoftTeams',
  MetaWorkplace = 'metaWorkplace',
  Slack = 'slack',
  GoogleChat = 'googleChat',
}

export type MergedChatAppsMethod = 'chatApps';

const VALID_CONTACT_METHODS: ContactMethod[] = [
  ContactMethod.Email,
  ContactMethod.Sms,
  ContactMethod.SftpLink,
  ContactMethod.MicrosoftTeams,
  ContactMethod.MetaWorkplace,
  ContactMethod.Slack,
  ContactMethod.GoogleChat,
];

export const CONTACT_METHOD_TO_DISPLAY: Record<ContactMethod, string> = {
  [ContactMethod.Email]: 'Email',
  [ContactMethod.Sms]: 'SMS',
  [ContactMethod.SftpLink]: 'SFTP',
  [ContactMethod.MicrosoftTeams]: 'Microsoft Teams',
  [ContactMethod.MetaWorkplace]: 'Meta Workplace',
  [ContactMethod.Slack]: 'Slack',
  [ContactMethod.GoogleChat]: 'Google Chat',
};

export const getValidContactMethod = (
  value: string,
): ContactMethod | null | MergedChatAppsMethod => {
  const lowerCaseValue = value.toLowerCase().trim();
  const validMergedContactMethod = lowerCaseValue === 'chatapps' ? 'chatApps' : null;

  return (
    (VALID_CONTACT_METHODS.find((method) => method.toLowerCase() === lowerCaseValue) ||
      validMergedContactMethod) ??
    null
  );
};

export const isValidContactMethod = (
  value: string | ContactMethod | null,
): value is ContactMethod => !!value && !!getValidContactMethod(value);

export const getEnabledContactMethods = (contactMethods: ContactMethod[]) => {
  return Object.values(ContactMethod).reduce<Record<ContactMethod, boolean>>(
    (memo, contactMethod) => {
      memo[contactMethod] = !!contactMethods.includes(contactMethod);

      return memo;
    },
    {
      [ContactMethod.Email]: false,
      [ContactMethod.Sms]: false,
      [ContactMethod.SftpLink]: false,
      [ContactMethod.MicrosoftTeams]: false,
      [ContactMethod.MetaWorkplace]: false,
      [ContactMethod.Slack]: false,
      [ContactMethod.GoogleChat]: false,
    },
  );
};

const PHONE_DENY_LIST = new Set(['+19999999999', '9999999999']);

// 28-08-24: This check is adapted from validator's regex for Albanian phone numbers.
// There is a bug currently in the library that prevents mobile phones with 7 digits after the prefix from being validated.
const isAlbanianMobilePhone = (mobilePhone: string) =>
  /^(?<country_code>\+355|0)(?<prefix>6[789])(?<number>\d{6,7})$/.test(mobilePhone);

export const nonChatAppContactMethodValidationMap: Record<
  ContactMethod.Sms | ContactMethod.Email,
  (value: string) => boolean
> = {
  [ContactMethod.Sms]: (mobilePhone: string) =>
    (isAlbanianMobilePhone(mobilePhone) ||
      // adding strictMode to check for the plus character (and valid country code) at the beginning of the phone number
      isMobilePhone(mobilePhone, 'any', { strictMode: true })) &&
    !PHONE_DENY_LIST.has(mobilePhone),

  [ContactMethod.Email]: (email: string) => isEmail(email),
};
