import { errorHandler, Heap } from '@shared/utils/heap-utils';

export const pushUnansweredConversationsButtonEvent = () => {
  try {
    const { heap } = (window as unknown) as { heap?: Heap };

    if (heap) {
      heap.track('Click - Respond to questions you missed');
    }
  } catch (error) {
    // We don't want any analytics error to affect the user
    errorHandler(error);
  }
};

export const pushGoToLeaderViewClick = (): void => {
  try {
    const { heap } = (window as unknown) as { heap?: Heap };
    if (heap) {
      heap.track('Click - Go To Leader View');
    }
  } catch (error) {
    // We don't want any analytics error to affect the user
    errorHandler(error);
  }
};
