import { styled, theme } from '@shared/frontend/theme';

type Props = {
  $isRight?: boolean;
};

export const WidgetMessageBubbleWrapper = styled('div')<Props>`
  display: flex;
  align-items: flex-end;
  flex-direction: ${({ $isRight }) => ($isRight ? 'row' : 'row-reverse')};
  gap: ${theme.spacing.xs};
`;
