import { type ContactMethod } from '@shared/utils/contact-method';
import { createRootRoute, Navigate, Outlet } from '@tanstack/react-router';
import React, { Suspense } from 'react';

type SearchParams = {
  /** Used to navigate to a specific conversation in notifications */
  c: string;
  contactMethod?: ContactMethod;
  newDevice?: string;
};

const TanStackRouterDevtools =
  process.env.BUILD_ROUTER_DEV_TOOLS === 'true'
    ? React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      )
    : () => null; // Render nothing in production

export const rootRoute = createRootRoute({
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    // validate and parse the search params into a typed state
    return {
      c: search.c as string,
      contactMethod: search.contactMethod as ContactMethod,
    };
  },
  component: () => {
    return (
      <>
        <Outlet />
        <Suspense>
          <TanStackRouterDevtools initialIsOpen={false} position="bottom-right" />
        </Suspense>
      </>
    );
  },
  // When the user tries to access a route that does not exist, we redirect them to the latest conversation
  notFoundComponent: () => {
    return (
      <Navigate
        to="/conversations/$selectedConversationId"
        params={{ selectedConversationId: 'latest' }}
      />
    );
  },
});
