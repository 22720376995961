import {
  type ApolloQueryResult,
  type ObservableQuery,
  type OperationVariables,
  type QueryResult,
} from '@apollo/client';
import { CircularProgress } from '@shared/frontend/components/base/progress';
import { Navigate } from '@tanstack/react-router';
import React from 'react';

import { isHandledNetworkError } from './apollo-client-helpers';

export { gql } from '@apollo/client';

export type QueryOperations<T> = {
  refetch?: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<T>>;
  fetchMore?: ObservableQuery['fetchMore'];
};
type Response<T> = [T, null, QueryOperations<T>];
type Skipped<T> = [null, null, QueryOperations<T>];
type Fallback<T> = [null, JSX.Element, QueryOperations<T>];

export const parseQueryResponse = <T,>(
  response: Partial<QueryResult<T>>,
): Response<T> | Fallback<T> | Skipped<T> => {
  const { data, loading, error, refetch, fetchMore } = response;

  if (loading) {
    return [null, <CircularProgress $isCentered />, { refetch }];
  }

  if (error) {
    const { networkError } = error;

    if (isHandledNetworkError(networkError)) {
      return [null, <CircularProgress $isCentered />, { refetch }];
    }

    return [null, <Navigate to="/error" />, { refetch }];
  }

  if (!data) {
    return [null, <Navigate to="/error" />, { refetch }];
  }

  return [data, null, { refetch, fetchMore }];
};
