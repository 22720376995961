// This function is shared across front and backend
export function getSentiment(rating: number) {
  if (rating <= 6) {
    return 'NEGATIVE'; // TODO change to Sentiment.Negative;
  }

  if (rating <= 8) {
    return 'NEUTRAL'; // TODO change to Sentiment.Neutral;
  }

  return 'POSITIVE'; // TODO change to  Sentiment.Positive;
}
