import filledInputClasses from '@mui/material/FilledInput/filledInputClasses';
import { Chip } from '@shared/frontend/components/base/chip';
import { styled, theme, transientOptions } from '@shared/frontend/theme';
import React, { type ComponentProps } from 'react';

import { DropdownOptionMulti } from './dropdown-option';
import { SingleSelectInput, type AutocompleteOption } from './single-select';

const Wrapper = styled('div')`
  position: relative;
  overflow: visible;
`;

type AutocompleteProps = ComponentProps<typeof SingleSelectInput> & {
  $chipColorScheme?: ComponentProps<typeof Chip>['$colorScheme'];
};

const StyledAutocomplete = styled(
  React.forwardRef<HTMLInputElement, AutocompleteProps>((props, ref) => (
    <SingleSelectInput ref={ref} {...props} textInputProps={{ ...props.textInputProps }} />
  )),
  transientOptions,
)<AutocompleteProps>`
  && {
    .${filledInputClasses.root} {
      min-height: 5.2rem;
      background: ${theme.color.white};
      border: ${theme.border};
      ${({ value }) =>
        Array.isArray(value) && !!value?.length
          ? 'padding-top: 2.7rem; padding-bottom: 0.8rem;'
          : `padding-top: ${theme.spacing.m}`};
    }

    .${filledInputClasses.input}:not(:focus) {
      min-width: 0;
      flex-grow: 0;
      padding: 0;
    }
  }
`;

export const MultiSelectInput = React.memo(
  React.forwardRef<HTMLInputElement, AutocompleteProps>((props, ref) => {
    return (
      <Wrapper>
        <StyledAutocomplete
          ref={ref}
          multiple
          renderOption={DropdownOptionMulti}
          renderTags={(value: readonly AutocompleteOption[], getTagProps) =>
            value.map((option: AutocompleteOption, index: number) => {
              const longLabel = typeof option === 'string' ? option : option.label;
              const selectedLabel =
                typeof option === 'string' ? option : option.chipLabel ?? option.label;

              return (
                <Chip
                  $variant="filled"
                  $colorScheme={props.$chipColorScheme ?? 'katipoBlack'}
                  label={selectedLabel}
                  title={longLabel}
                  data-cy="multi-select-chip"
                  data-cy-id={`multi-select-chip ${selectedLabel}`}
                  {...getTagProps({ index })}
                />
              );
            })
          }
          {...props}
        />
      </Wrapper>
    );
  }),
);
