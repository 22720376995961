import { gql } from '@apollo/client';

const metadataFields = gql`
  fragment metadataFields on Metadata {
    topic
    category
    categoryId
    questionText
  }
`;

/**
 * If there are changes to this fragment, ensure that you test subscriptions are working correctly.
 * Notion doc for testing subscriptions: https://www.notion.so/joyoushq/Regression-Testing-across-Joyous-baf7817f5a38451088830663d68a4adf?pvs=4#f4ca0a28f9494afa967ba199b3a615c2
 */
// only fields of messages document, nothing resolved via graphql walk
const messageFields = gql`
  fragment messageFields on BaseMessage {
    _id
    authorId
    type
    upsertTimestamps
    reactions {
      type
      personId
      person {
        name
      }
    }

    ... on ConversationComment {
      text
      translatedText
      hasAIMessageBeenReported
      hasAIMessageBeenFlagged
      themesReportId
      image {
        dialog {
          url
          height
          width
        }
        natural {
          url
        }
      }
      actionablePhrases {
        phrases {
          start
          end
          text
        }
      }
    }
    ... on ConversationPrompt {
      status
      conversationSchemeBlockId
      metadata {
        ...metadataFields
      }
    }
    ... on SelectOneAnswer {
      status
      selectedValue
      options
      translatedOptions
      conversationSchemeBlockId
      metadata {
        ...metadataFields
      }
    }
    ... on SelectManyAnswer {
      status
      selectedValues
      options
      translatedOptions
      conversationSchemeBlockId
      metadata {
        ...metadataFields
      }
    }
    ... on RatingAnswer {
      status
      conversationSchemeBlockId
      ratingValue {
        rating
        sentiment
      }
      legendLabels {
        low
        translatedLow
        high
        translatedHigh
      }
      metadata {
        ...metadataFields
      }
    }
    ... on SelfSelectAnswer {
      status
      selectedValue
      selfSelectAttributeName
      options
      translatedOptions
      inputPlaceholderText
      conversationSchemeBlockId
      isHiddenInOrgUi
      metadata {
        ...metadataFields
      }
    }
    ... on FilteredSelfSelectAnswer {
      status
      selectedValue
      selfSelectAttributeName
      filterAttributeName
      optionsLookup {
        filterAttributeValue
        options
        translatedOptions
      }
      inputPlaceholderText
      conversationSchemeBlockId
      metadata {
        ...metadataFields
      }
    }
  }
  ${metadataFields}
`;

export default messageFields;
