import { useConversationContext } from '@shared/frontend/components/conversation/conversation-context';
import { MessageMenu } from '@shared/frontend/components/conversation/message-menu';
import { MessageContext } from '@shared/frontend/components/conversation/message/message-context';
import { useLoggedInUser } from '@shared/frontend/components/logged-in-user-context';
import { getBorderRadii } from '@shared/frontend/components/message-bubble';
import { styled, theme, transientOptions } from '@shared/frontend/theme';
import {
  isGraphQLComment,
  isGraphQLConversationPrompt,
  isGraphQLFilteredSelfSelect,
  isGraphQLSelfSelect,
  type GraphQLMessage,
} from '@shared/graphql/message-utils';
import { getIsAuthorJoyous, getIsAuthorUser } from '@shared/utils/author-utils';
import React from 'react';

import { type InitialMessageActionStateHookType } from './message/types';

type WrapperProps = {
  $isRight?: boolean;
};

export const Wrapper = styled('div', transientOptions)<WrapperProps>`
  margin: ${theme.spacing.l} ${theme.spacing.s};
  display: flex;
  flex-direction: ${({ $isRight }) => ($isRight ? 'row-reverse' : 'row')};
`;

type MessageGroupContainerProps = {
  $isRight?: boolean;
};

export const MessageGroupContainer = styled('div', transientOptions)<MessageGroupContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $isRight }) => ($isRight ? 'flex-end' : 'flex-start')};
  flex-grow: 0;
  max-width: 83%;
  flex-basis: 83%;

  @media (min-width: ${theme.breakpointPixels.md}) {
    max-width: calc(100% * 2 / 3);
    flex-basis: calc(100% * 2 / 3);
  }
`;

const JoyousMessageGroupContainer = styled('div')`
  max-width: 100%;
`;

// Check if the next message is ConversationPrompt or a Self Select (Filtered or Unfiltered)
// so rounding can be applied on the bottom corners of the current message
const isNextConversationPromptOrSelfSelect = (
  messageGroup: GraphQLMessage[],
  nextIndex: number,
) => {
  if (!messageGroup[nextIndex]) return false;
  return (
    isGraphQLConversationPrompt(messageGroup[nextIndex]) ||
    isGraphQLSelfSelect(messageGroup[nextIndex]) ||
    isGraphQLFilteredSelfSelect(messageGroup[nextIndex])
  );
};

type Props = {
  messageGroup: GraphQLMessage[];
  initialMessageActionStateHook?: InitialMessageActionStateHookType;
};

const MessageGroup = ({ messageGroup, initialMessageActionStateHook }: Props) => {
  const loggedInUser = useLoggedInUser();

  const { messageInEditMode } = useConversationContext();

  const firstMessageInGroup = messageGroup[0];

  if (!loggedInUser || !firstMessageInGroup) {
    return null;
  }

  const { authorId } = firstMessageInGroup;
  const isAuthorJoyous = getIsAuthorJoyous(authorId);
  const isAuthorUser = getIsAuthorUser(authorId, loggedInUser._id);

  const isGroupHighlighted =
    !!messageInEditMode &&
    !isGraphQLComment(messageInEditMode) &&
    messageGroup.some((message) => message._id === messageInEditMode._id);

  const messages = messageGroup.map((message, index) => {
    const borderRadii = getBorderRadii(
      index,
      messageGroup.length,
      isAuthorJoyous,
      isAuthorUser,
      isNextConversationPromptOrSelfSelect(messageGroup, index + 1),
    );

    return (
      <MessageContext.Provider
        key={message._id}
        value={{
          message,
          messageGroupAuthorId: authorId,
          messageIndexInGroup: index,
        }}
      >
        <MessageMenu
          borderRadii={borderRadii}
          groupPosition={index}
          groupLength={messageGroup.length}
          isBorderHighlighted={isGroupHighlighted}
          initialMessageActionStateHook={initialMessageActionStateHook}
        />
      </MessageContext.Provider>
    );
  });

  return (
    <Wrapper $isRight={isAuthorUser} data-cy="message-group" data-dd-action-name="message-group">
      <MessageGroupContainer>
        {isAuthorJoyous ? (
          <JoyousMessageGroupContainer>{messages}</JoyousMessageGroupContainer>
        ) : (
          messages
        )}
      </MessageGroupContainer>
    </Wrapper>
  );
};

export default MessageGroup;
