import { StyledIcon } from '@components/base/styled-icon';
import { BodyUi } from '@components/base/typography';
import { css, styled, theme } from '@frontend/theme';
import { Link } from '@tanstack/react-router';
import React from 'react';
import { useShowTranslationLanguagePrompt } from '@frontend/hooks/use-language-setting';

import { pushUnansweredConversationsButtonEvent } from '../../../utils/heap-utils';
import { CONVERSATION_WINDOW_SLIDE, REMINDER_BUTTON_DROP } from '../../animation-timings';

export const promptButtonStyles = css`
  gap: ${theme.spacing.xxs};
  height: 2.8rem;
  display: flex;
  align-items: center;

  && {
    padding: 0 ${theme.spacing.s} 0 ${theme.spacing.xs};
    position: absolute;
    bottom: -4.4rem;
    left: 50%;
    border-radius: 1.4rem;
    border: 0.1rem solid ${theme.color.joyousDogOrange[400]};
    background-color: ${theme.color.white};
    color: ${theme.color.joyousDogOrange[400]};
    opacity: 0;

    transform: translate(-50%, -100%);
    animation: ${REMINDER_BUTTON_DROP.animation} ${REMINDER_BUTTON_DROP.duration}ms ease forwards;
    animation-delay: ${CONVERSATION_WINDOW_SLIDE.duration}ms;
  }
`;

const StyledLink = styled(Link)`
  ${promptButtonStyles}
`;

export const ReminderButton = () => {
  const isShowingTranslationPrompt = useShowTranslationLanguagePrompt();

  // If the translation prompt is showing, don't show the reminder button as well
  if (isShowingTranslationPrompt) {
    return null;
  }

  return (
    <StyledLink to="/conversations" onClick={pushUnansweredConversationsButtonEvent}>
      <StyledIcon iconName="ArrowBack" $size="1.8rem" $colorScheme="joyousDogOrange" />
      <BodyUi $customColor={theme.color.joyousDogOrange[400]} $noWrap $fontWeight="medium">
        Respond to questions you missed
      </BodyUi>
    </StyledLink>
  );
};
