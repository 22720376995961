import { Button, ButtonProps as BaseButtonProps } from '@shared/frontend/components/base/button';
import React, { useState } from 'react';

import type { DialogColorScheme } from '.';

export type FooterButtonProps = {
  $colorScheme: DialogColorScheme;
  text: string;
  onClick: () => void;
  requireConfirmation?: boolean;
  loading?: boolean;
  disabled?: boolean;
  'data-cy': string;
} & Partial<BaseButtonProps>;

export const FooterButton = ({
  $colorScheme,
  $variant,
  requireConfirmation,
  loading,
  text,
  onClick,
  'data-cy': dataCy,
  ...restOfButtonProps
}: FooterButtonProps) => {
  const [shouldConfirm, setShouldConfirm] = useState(false);

  const displayConfirmation = requireConfirmation && shouldConfirm;

  return (
    <Button
      $variant={$variant ?? 'ghost'}
      $colorScheme={displayConfirmation ? 'microPigPink' : $colorScheme}
      $loading={loading}
      onClick={() => {
        if (!requireConfirmation || displayConfirmation) {
          onClick();
        } else {
          setShouldConfirm(true);
        }
      }}
      data-cy={displayConfirmation ? `${dataCy}-confirmation` : dataCy}
      {...restOfButtonProps}
    >
      {displayConfirmation ? 'Are you sure?' : text}
    </Button>
  );
};
