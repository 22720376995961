import { CircularProgress } from '@components/base/progress';
import { styled } from '@frontend/theme';
import { Navigate, useNavigate, useParams, useSearch } from '@tanstack/react-router';
import React, { useEffect } from 'react';

import { MAGIC_TOKEN_LOCAL_STORAGE_KEY, magicTokenLocalStorage } from '../../utils/auth-utils';
import { tokenManager } from '../../utils/token-manager';
import { useMagicTokenAuth } from './use-magic-token-auth';

export const PageWrapper = styled('div')`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type TokenAuthProps = {
  token: string;
  selectedConversationId: string;
};

const TokenAuth = ({ token, selectedConversationId }: TokenAuthProps) => {
  const navigate = useNavigate();

  useMagicTokenAuth({
    magicToken: token,
    onSuccess: (hasAddedNewDevice?: boolean) =>
      navigate({
        to: '/conversations/$selectedConversationId',
        params: { selectedConversationId },
        search: hasAddedNewDevice ? { newDevice: hasAddedNewDevice } : {},
      }),
    onError: () => navigate({ to: '/error/authentication' }),
  });

  return (
    <PageWrapper>
      <CircularProgress size="4rem" />
    </PageWrapper>
  );
};

export const Authentication = () => {
  const currentUserAccessToken = tokenManager.getAccessTokenPayload();
  const { personTokenId } = useParams({ from: '/AppLayout/$personTokenId' });
  const { c } = useSearch({ from: '/AppLayout/$personTokenId' });

  useEffect(() => {
    magicTokenLocalStorage.setItem(MAGIC_TOKEN_LOCAL_STORAGE_KEY, personTokenId);
    // We only want to execute this once, when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // notifications use `c=conversationId` to help fit in the SMS size limit
  const finalConversationId = c || 'latest';

  if (personTokenId) {
    return <TokenAuth token={personTokenId} selectedConversationId={finalConversationId} />;
  }

  if (currentUserAccessToken) {
    return (
      <Navigate
        to="/conversations/$selectedConversationId"
        params={{ selectedConversationId: finalConversationId }}
      />
    );
  }

  return <Navigate to="/error" />;
};
