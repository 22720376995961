import { create } from 'zustand';

// When we can't predict at all if AIF would respond, we show typing indicator if and when AIF response is received
// The AIF response is not rendered until this arbitrary delay has passed.
const DELAYED_AIF_TYPING_DURATION_MS = 3000;
// When we can predict that AIF would respond, the addComment mutation response will tell us and we can start showing typing indicator
// much earlier. There is still a chance an AIF response doesn't come, so this is the max duration we show typing indicator for.
const EARLY_AIF_TYPING_MAX_DURATION_MS = 5000;

let TIMEOUT_ID: null | NodeJS.Timeout = null;

type AIFIsTypingStore = {
  conversationAIFIsTypingTo: string | null;
  actions: {
    triggerDelayedAIFIsTyping: (
      awaitablePromiseToWrap: () => Promise<void>,
      conversationAIFIsTypingTo: string,
    ) => Promise<void>;
    triggerEarlyAIFIsTyping: (conversationAIFIsTypingTo: string) => Promise<void>;
  };
};

const useAIFIsTypingStore = create<AIFIsTypingStore>((set, get) => ({
  conversationAIFIsTypingTo: null,
  actions: {
    triggerDelayedAIFIsTyping: async (awaitablePromiseToWrap, conversationAIFIsTypingTo) => {
      if (TIMEOUT_ID) {
        return;
      }
      if (get().conversationAIFIsTypingTo) {
        set({ conversationAIFIsTypingTo: null });
        await awaitablePromiseToWrap();
        return;
      }

      set({ conversationAIFIsTypingTo });

      await new Promise((resolve) => {
        TIMEOUT_ID = setTimeout(resolve, DELAYED_AIF_TYPING_DURATION_MS);
      });

      TIMEOUT_ID = null;

      await awaitablePromiseToWrap();
      set({ conversationAIFIsTypingTo: null });
    },
    triggerEarlyAIFIsTyping: async (conversationAIFIsTypingTo: string) => {
      const currentConversationAIFIsTypingTo = get().conversationAIFIsTypingTo;
      if (currentConversationAIFIsTypingTo) {
        return;
      }
      set({ conversationAIFIsTypingTo });
      await new Promise((resolve) => {
        setTimeout(resolve, EARLY_AIF_TYPING_MAX_DURATION_MS);
      });
      set({ conversationAIFIsTypingTo: null });
    },
  },
}));

export const useConversationAIFIsTypingTo = () =>
  useAIFIsTypingStore((state) => state.conversationAIFIsTypingTo);
export const useDelayedAIFIsTypingWrapper = () =>
  useAIFIsTypingStore((state) => state.actions.triggerDelayedAIFIsTyping);
export const useTriggerEarlyAIFIsTyping = () =>
  useAIFIsTypingStore((state) => state.actions.triggerEarlyAIFIsTyping);
