import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';

import { TeamMemberUiLocalStorageManager } from './team-member-ui-local-storage-manager';

const DEVICE_ID_LOCAL_STORAGE_KEY = 'deviceId';
// If we ever remove the magic token from local storage workaround, just removing these lines should indicate us all the places that need to be updated
export const MAGIC_TOKEN_LOCAL_STORAGE_KEY = 'magicToken';

export const magicTokenLocalStorage = new TeamMemberUiLocalStorageManager();

export const getDeviceId = async () => {
  const deviceIdFromStorage = magicTokenLocalStorage.getItem(DEVICE_ID_LOCAL_STORAGE_KEY);

  if (typeof deviceIdFromStorage === 'string' && !!deviceIdFromStorage) {
    return deviceIdFromStorage;
  }

  const deviceId = await getFingerprint();
  magicTokenLocalStorage.setItem(DEVICE_ID_LOCAL_STORAGE_KEY, deviceId);
  return deviceId;
};
