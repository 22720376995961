import { useReactiveVar } from '@apollo/client';
import { CircularProgress } from '@shared/frontend/components/base/progress';
import { StyledIcon } from '@shared/frontend/components/base/styled-icon';
import { imageUrlOfImageModalStateVar } from '@shared/frontend/components/conversation/conversation-state';
import { DialogInfo } from '@shared/frontend/components/dialog';
import { styled } from '@shared/frontend/theme';
import React, { useEffect, useState } from 'react';

const ImageContainer = styled('div')`
  display: flex;
  max-height: 85vh;
  height: 85vh;
  max-width: 100%;
  justify-content: center;
  align-items: center;
`;

type ImageProps = {
  imageLoaded: boolean;
};

// Need min width/height for IE11
const Image = styled('img')`
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  min-width: 0.01rem;
  min-height: 0.01rem;
  ${({ imageLoaded }: ImageProps) => !imageLoaded && 'display: none;'};
`;

const handleImageClick = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
  event.stopPropagation();
};

export const ImageDisplayDialog = () => {
  const imageUrlOfImageModal = useReactiveVar(imageUrlOfImageModalStateVar);
  const closeImageModal = () => imageUrlOfImageModalStateVar(null);

  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(!imageUrlOfImageModal);

  useEffect(() => {
    setImageError(!imageUrlOfImageModal);
  }, [imageUrlOfImageModal]);

  const handleImageError = () => {
    setImageLoaded(true);
    setImageError(true);
  };

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  if (imageUrlOfImageModal == null) {
    return null;
  }

  return (
    <DialogInfo open fullScreen fullWidth title="" onClose={closeImageModal} data-cy="modal">
      <ImageContainer onClick={closeImageModal} data-cy="modal-full-size-image-container">
        {imageError ? (
          <StyledIcon iconName="BrokenImage" $size="15.0rem" />
        ) : (
          <Image
            data-cy="modal-full-size-image"
            src={imageUrlOfImageModal ?? ''}
            onLoad={handleImageLoaded}
            onError={handleImageError}
            imageLoaded={imageLoaded}
            alt="User uploaded image"
            onClick={handleImageClick}
          />
        )}
        {!imageLoaded && !imageError && <CircularProgress />}
      </ImageContainer>
    </DialogInfo>
  );
};
