/** Helper to add opacity to RGB values. Valid values are between 0 and 1. */
export const colorWithOpacity = (rgb: string, opacity: number): string =>
  rgb.replace('rgb', 'rgba').replace(')', `, ${opacity})`);

const black = 'rgb(0, 0, 0)' as const; // #000000
const white = 'rgb(255, 255, 255)' as const; // #ff0000
const whiteSubtleBlue = 'rgb(248, 251, 253)' as const; // #F8FBFD

const katipoBlack = {
  25: 'rgb(243, 243, 243)', // #F3F3F3
  50: 'rgb(231, 231, 231)', // #E7E7E7
  100: 'rgb(195, 195, 195)', // #C3C3C3
  200: 'rgb(156, 156, 156)', // #9C9C9C
  300: 'rgb(116, 116, 116)', // #747474
  400: 'rgb(86, 86, 86)', // #565656
  500: 'rgb(56, 56, 56)', // #383838 - default value!
  600: 'rgb(50, 50, 50)', // #323232
  700: 'rgb(43, 43, 43)', // #2B2B2B
  800: 'rgb(36, 36, 36)', // #242424
  900: 'rgb(23, 23, 23)', // #171717
} as const;

const ducksBlue = {
  25: 'rgb(241, 247, 251)', // #F1F7FB
  50: 'rgb(228, 239, 246)', // #E4EFF6
  100: 'rgb(188, 215, 235)', // #BCD7EB
  200: 'rgb(142, 188, 221)', // #8EBCDD
  300: 'rgb(97, 161, 207)', // #61A1CF
  400: 'rgb(62, 140, 198)', // #3E8CC6
  500: 'rgb(31, 120, 186)', // #1F78BA - default value!
  600: 'rgb(26, 113, 181)', // #1A71B5
  700: 'rgb(21, 102, 172)', // #1566AC
  800: 'rgb(17, 92, 165)', // #115CA5
  900: 'rgb(22, 73, 149)', // #164995
} as const;

const joyousBlue = {
  25: 'rgb(243, 251, 252)', // #F3FBFC
  50: 'rgb(230, 248, 250)', // #e6f8fa
  100: 'rgb(194, 236, 243)', // #c2ecf3
  200: 'rgb(153, 224, 235)', // #99e0eb
  300: 'rgb(112, 212, 226)', // #70d4e2
  400: 'rgb(81, 202, 220)', // #51cadc
  500: 'rgb(50, 193, 214)', // #32c1d6 - default value!
  600: 'rgb(45, 187, 209)', // #2dbbd1
  700: 'rgb(38, 179, 204)', // #26b3cc
  800: 'rgb(31, 171, 198)', // #1fabc6
  900: 'rgb(19, 158, 188)', // #139ebc
} as const;

const joyousDogOrange = {
  25: 'rgb(254, 246, 242)', // #FEF6F2
  50: 'rgb(253, 237, 229)', // #fdede5
  100: 'rgb(249, 209, 190)', // #f9d1be
  200: 'rgb(246, 179, 147)', // #f6b393
  300: 'rgb(242, 149, 103)', // #f29567
  400: 'rgb(239, 126, 71)', // #ef7e47
  500: 'rgb(236, 103, 38)', // #ec6726 - default value!
  600: 'rgb(234, 95, 34)', // #ea5f22
  700: 'rgb(231, 84, 28)', // #e7541c
  800: 'rgb(228, 74, 23)', // #e44a17
  900: 'rgb(223, 57, 13)', // #df390d
} as const;

const microPigPink = {
  25: 'rgb(254, 240, 248)', // #FEF0F8
  50: 'rgb(253, 226, 242)', // #FDE2F2
  100: 'rgb(251, 184, 224)', // #FBB8E0
  200: 'rgb(249, 136, 203)', // #F988CB
  300: 'rgb(246, 88, 182)', // #F658B6
  400: 'rgb(244, 53, 166)', // #F435A6
  500: 'rgb(242, 17, 150)', // #F21196 - default value!
  600: 'rgb(240, 15, 142)', // #F00F8E
  700: 'rgb(238, 12, 131)', // #EE0C83
  800: 'rgb(237, 13, 123)', // #ED0D7B
  900: 'rgb(232, 5, 104)', // #E80568
} as const;

const kereruPurple = {
  25: 'rgb(248, 244, 251)', // #F8F4FB
  50: 'rgb(242, 234, 248)', // #F2EAF8
  100: 'rgb(224, 203, 239)', // #E0CBEF
  200: 'rgb(203, 168, 228)', // #CBA8E4
  300: 'rgb(182, 133, 217)', // #B685D9
  400: 'rgb(166, 106, 208)', // #A66AD0
  500: 'rgb(150, 80, 200)', // #9650C8 - default value!
  600: 'rgb(142, 73, 194)', // #8E49C2
  700: 'rgb(131, 64, 187)', // #8340BB
  800: 'rgb(121, 55, 180)', // #7937B4
  900: 'rgb(104, 39, 167)', // #6827A7
} as const;

const happyYellow = {
  25: 'rgb(255, 251, 243)', // #FFFBF3
  50: 'rgb(254, 246, 231)', // #fef6e7
  100: 'rgb(253, 233, 195)', // #fde9c3
  200: 'rgb(251, 218, 155)', // #fbda9b
  300: 'rgb(249, 203, 114)', // #f9cb72
  400: 'rgb(248, 192, 84)', // #f8c054
  500: 'rgb(247, 181, 54)', // #f7b536 - default value!
  600: 'rgb(246, 174, 48)', // #f6ae30
  700: 'rgb(245, 165, 41)', // #f5a529
  800: 'rgb(243, 157, 34)', // #f39d22
  900: 'rgb(241, 141, 22)', // #f18d16
} as const;

const whiteWithOpacity = {
  20: colorWithOpacity(white, 0.2),
  30: colorWithOpacity(white, 0.3),
  50: colorWithOpacity(white, 0.5), // - default value!
  70: colorWithOpacity(white, 0.7),
} as const;

const grey = {
  regular: 'rgb(242, 242, 242)', // #f2f2f2
  light: 'rgb(248, 248, 249)', // #f8f8f9
  50: colorWithOpacity('rgb(231, 233, 236)', 0.5),
} as const;

export const color = {
  black,
  grey,
  white,
  whiteWithOpacity,
  whiteSubtleBlue,
  ducksBlue,
  happyYellow,
  joyousBlue,
  joyousDogOrange,
  katipoBlack,
  kereruPurple,
  microPigPink,
} as const;

export const gradient = {
  scoreBlue: `linear-gradient(
    328deg,
    rgba(105, 123, 205, 0.25) 18.96%,
    rgba(47, 179, 211, 0.25) 57.68%,
    rgba(35, 192, 213, 0.25) 77.69%,
    rgba(34, 183, 209, 0.25) 89.85%,
    rgba(31, 162, 201, 0.25) 105.82%,
    rgba(31, 158, 200, 0.25) 105.83%
  )`,
  scoreBlueSelected: `linear-gradient(
    328deg,
    rgba(105, 123, 205, 0.10) 18.96%,
    rgba(47, 179, 211, 0.10) 57.68%,
    rgba(35, 192, 213, 0.10) 77.69%,
    rgba(34, 183, 209, 0.10) 89.85%,
    rgba(31, 162, 201, 0.10) 105.82%,
    rgba(31, 158, 200, 0.10) 105.83%
  )`,
  horizontalBlue: `linear-gradient(
    90deg,
    rgba(137, 91, 201, 0.3) -18.99%,
    rgba(105, 123, 205, 0.3) -0.52%,
    rgba(122, 153, 212, 0.3) 18.87%,
    rgba(64, 164, 201, 0.3) 39.7%,
    rgba(62, 140, 198, 0.3) 74.84%
  )`,
  horizontalBlueSelected: `linear-gradient(
    328.47deg,
    rgba(105, 123, 205, 0.1) 18.96%,
    rgba(47, 179, 211, 0.1) 57.68%,
    rgba(35, 192, 213, 0.1) 77.69%,
    rgba(34, 183, 209, 0.1) 89.85%,
    rgba(31, 162, 201, 0.1) 105.82%,
    rgba(31, 158, 200, 0.1) 105.83%
  )`,
  horizontalSunset: `linear-gradient(
    90deg,
    rgba(246, 138, 27, 0.3) 0%,
    rgba(242, 107, 32, 0.3) 25.45%,
    rgba(241, 94, 34, 0.3) 43.02%,
    rgba(241, 62, 82, 0.3) 73.09%,
    rgba(242, 17, 150, 0.3) 99.92%
  )`,
} as const;
