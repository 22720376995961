import newMessageAudioDataUrl from '@frontend/assets/happy-pop.mp3';
import { Howl } from 'howler';

let newMessageSound: Howl | null;

export const playNotificationSound = () => {
  if (!newMessageSound) {
    // esbuild transforms audio import into data url with application/octet-stream mime type
    // this is fine for firefox, but chrome requires audio/mpeg mime type
    const dataUrlWithFixedMimeType = newMessageAudioDataUrl.replace(
      'data:application/octet-stream',
      'data:audio/mpeg',
    );
    // howler supports multiple sources and will play the first one that is supported by the browser/device
    newMessageSound = new Howl({ src: [newMessageAudioDataUrl, dataUrlWithFixedMimeType] });
  }
  newMessageSound.play();
};
