import { useLoggedInUser } from '@shared/frontend/components/logged-in-user-context';

export const useOpenHubSpotChat = (): [boolean, undefined | (() => void)] => {
  const loggedInUser = useLoggedInUser();
  const { commentingDisabled } = loggedInUser?.permissions ?? {};

  // Ignore if the query errors, there may be no logged in user
  // Eg for the token expired error page
  // In this case we allow hubspot access

  const onOpenHubSpot = () => {
    const { BUILD_ENV } = process.env;
    if (BUILD_ENV !== 'prod') {
      return;
    }

    if (!commentingDisabled) {
      window.HubSpotConversations.widget.open();
    }
  };

  return [!!commentingDisabled, onOpenHubSpot];
};
