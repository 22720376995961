import { customAlphabet, customRandom } from 'nanoid';

type RandomNumberGenerator = () => number;
let randomForIdGeneration: RandomNumberGenerator | null = null;

// Used for the generator code to produce predictable ids
export const setRandomForIdGeneration = (newRandomForIdGeneration: RandomNumberGenerator) => {
  randomForIdGeneration = newRandomForIdGeneration;
};

const getNanoId = (alphabet: string, shortId: boolean | undefined) => {
  if (randomForIdGeneration) {
    return customRandom(alphabet, shortId ? 8 : 16, (size) =>
      new Uint8Array(size).map(() => {
        if (!randomForIdGeneration) {
          throw new Error('The randomForIdGeneration was nullified before the id was generated');
        }
        return 256 * randomForIdGeneration();
      }),
    );
  }

  // The default case is used in production code
  return customAlphabet(alphabet, shortId ? 8 : 16);
};

export const defaultGen = (shortId: boolean | undefined) => {
  const nanoid = getNanoId(
    'ModuleSymbhasOwnPr-0123456789ABCDEFGHNRVfgctiUvz_KqYTJkLxpZXIjQW',
    shortId,
  );
  return nanoid();
};

// sms does not play well with special characters
export const noSpecialCharactersGen = (shortId: boolean | undefined) => {
  const nanoid = getNanoId(
    'ModuleSymbhasOwnPr0123456789ABCDEFGHNRVfgctiUvzKqYTJkLxpZXIjQW',
    shortId,
  );
  return nanoid();
};

// change length from default 21 to 16
// Calculation for 16 char ids
// 9,000,000 million active joyous users
// Each receiving 2 questions a week
// 20 ids created per conversation
// = 595 ids per second
// Rounded up to 1000 ids per second the result is ~981 years needed, in order to have a 1% probability of at least one collision.

export type GenerateIdOptions = {
  noSpecialCharacters?: boolean;
  shortId?: boolean;
};

const generateId = (options?: GenerateIdOptions): string => {
  const id = options?.noSpecialCharacters
    ? noSpecialCharactersGen(options?.shortId)
    : defaultGen(options?.shortId);

  if (id.charAt(0) === '-') {
    return generateId(options);
  }

  return id;
};

export default generateId;
