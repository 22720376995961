import MuiMenuList from '@mui/material/MenuList';
import { StyledIcon } from '@shared/frontend/components/base/styled-icon';
import { useConversationContext } from '@shared/frontend/components/conversation/conversation-context';
import { imageUrlOfImageModalStateVar } from '@shared/frontend/components/conversation/conversation-state';
import { FeatureCommentMenuItem } from '@shared/frontend/components/conversation/message-menu/feature-comment-menu-item';
import { ReactionList } from '@shared/frontend/components/conversation/message-menu/reaction-list';
import { useMenuItemMap } from '@shared/frontend/components/conversation/message-menu/use-menu-item-map';
import { useMessageProperties } from '@shared/frontend/components/conversation/message/use-message-properties';
import { useWidgetMutationMap } from '@shared/frontend/components/conversation/message/widget-map-utils';
import { MenuItem } from '@shared/frontend/components/menu';
import { styled, theme } from '@shared/frontend/theme';
import {
  isGraphQLComment,
  isGraphQLFilteredSelfSelect,
  isGraphQLRating11,
  isGraphQLRatingENPS,
  isGraphQLRatingManaOra,
  isGraphQLSelectMany,
  isGraphQLSelectOne,
  isGraphQLSelfSelect,
  type GraphQLWidget,
} from '@shared/graphql/message-utils';
import React, { useCallback } from 'react';

export const ABOUT_AI_FACILITATOR_URL = 'http://help.joyoushq.com/how-does-the-ai-facilitator-work';

const MenuList = styled(MuiMenuList)`
  && {
    border-top: ${theme.border};
    padding: 0;
  }
`;

type ContentProps = {
  closePopper: () => void;
  setIsSelected: (isSelected: boolean) => void;
  handleMenuClose: () => void;
  setIsShowDeleteConfirm: (isShowDeleteConfirm: boolean) => void;
  setIsShowReportMessageConfirmation: (isShowReportMessageConfirmation: boolean) => void;
  setIsShowFlagMessageMenu: (isShowFlagMessageMenu: boolean) => void;
};

export const Content = ({
  closePopper,
  setIsSelected,
  handleMenuClose,
  setIsShowDeleteConfirm,
  setIsShowReportMessageConfirmation,
  setIsShowFlagMessageMenu,
}: ContentProps) => {
  const { message } = useMessageProperties();

  const isComment = isGraphQLComment(message);

  const { conversation, setMessageInEditMode } = useConversationContext();
  const {
    hasFeatureButton,
    hasViewButton,
    hasEditButton,
    hasDeleteButton,
    hasAIMessageReport,
    hasAIMessageFlag,
    hasAIMessageInfo,
  } = useMenuItemMap();

  const widgetMutationMap = useWidgetMutationMap();
  const handleDeleteWidget = () => {
    const widgetMutationFunction = widgetMutationMap[message.type];
    widgetMutationFunction(conversation, message as GraphQLWidget, null);
    handleMenuClose();
    closePopper();
  };

  const getMenuItemText = () => {
    if (
      isGraphQLSelectOne(message) ||
      isGraphQLSelectMany(message) ||
      isGraphQLSelfSelect(message) ||
      isGraphQLFilteredSelfSelect(message)
    ) {
      return 'selection';
    }

    if (
      isGraphQLRating11(message) ||
      isGraphQLRatingENPS(message) ||
      isGraphQLRatingManaOra(message)
    ) {
      return 'response';
    }

    if (isComment && message.image && !message.text) {
      return 'image';
    }

    return 'message';
  };

  const handleOnEdit = useCallback(() => {
    setMessageInEditMode(message);
    handleMenuClose();
    closePopper();
  }, [message, setMessageInEditMode]);

  const handleViewImage = () => {
    if (isComment) {
      imageUrlOfImageModalStateVar(message.image?.natural?.url ?? null);
    }
    handleMenuClose();
    closePopper();
  };

  const hasMenuItems =
    hasFeatureButton ||
    hasEditButton ||
    hasViewButton ||
    hasDeleteButton ||
    hasAIMessageReport ||
    hasAIMessageInfo;

  return (
    <>
      <ReactionList closePopper={closePopper} setIsSelected={setIsSelected} />
      {hasMenuItems && (
        <MenuList data-cy="message-menu">
          {hasFeatureButton && (
            <FeatureCommentMenuItem
              handleMenuClose={() => {
                handleMenuClose();
                closePopper();
              }}
            />
          )}
          {hasEditButton && (
            <MenuItem onClick={() => handleOnEdit()} tabIndex={0} data-cy="message-edit-button">
              <StyledIcon iconName="Edit" $size="1.8rem" />
              Edit {getMenuItemText()}
            </MenuItem>
          )}
          {hasViewButton && (
            <MenuItem onClick={() => handleViewImage()} tabIndex={0} data-cy="message-view-button">
              <StyledIcon iconName="Image" $size="1.8rem" />
              View image
            </MenuItem>
          )}
          {hasDeleteButton && (
            <MenuItem
              onClick={() => (isComment ? setIsShowDeleteConfirm(true) : handleDeleteWidget())}
              tabIndex={0}
              data-cy="message-delete-button"
            >
              <StyledIcon iconName="Delete" $size="1.8rem" />
              Remove {getMenuItemText()}
            </MenuItem>
          )}
          {hasAIMessageFlag && (
            <MenuItem
              onClick={() => setIsShowFlagMessageMenu(true)}
              tabIndex={0}
              data-cy="message-flag-button"
            >
              <StyledIcon iconName="Flag" $size="1.8rem" $colorScheme="kereruPurple" />
              Flag {getMenuItemText()}
            </MenuItem>
          )}
          {hasAIMessageReport && (
            <MenuItem
              onClick={() => setIsShowReportMessageConfirmation(true)}
              tabIndex={0}
              data-cy="message-report-button"
            >
              <StyledIcon iconName="ThumbDown" $size="1.8rem" $colorScheme="kereruPurple" />
              Report {getMenuItemText()}
            </MenuItem>
          )}
          {hasAIMessageInfo && (
            <MenuItem
              tabIndex={0}
              data-cy="message-ai-facilitator-info-button"
              onClick={() => window.open(ABOUT_AI_FACILITATOR_URL, '_blank')}
            >
              <StyledIcon iconName="Info" $size="1.8rem" $colorScheme="kereruPurple" />
              About the AI facilitator
            </MenuItem>
          )}
        </MenuList>
      )}
    </>
  );
};
