import { ApolloLink, fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { datadogRum } from '@datadog/browser-rum';
import {
  isAuthenticationNetworkError,
  isMaintenanceNetworkError,
  isServerParseError,
  isTooManyRequestsNetworkError,
} from '@shared/graphql/apollo-client-helpers';
import apolloRegionModule from '@shared/graphql/apollo-region-module';
import {
  isAuthenticationExtensions,
  isExpiredAccessTokenExtensions,
  isForbiddenExtensions,
  isIncorrectRegionExtensions,
  isIncorrectVersionExtensions,
} from '@shared/graphql/errors/extensions';
import { getAuthLink, getHttpLinks, getVersionLink } from '@shared/utils/apollo-link';
import { WS_EXPIRED_ACCESS_TOKEN_CODE, WS_UNAUTHENTICATED_CODE } from '@shared/utils/constants';
import { createClient } from 'graphql-ws';

import { tokenManager } from '../utils/token-manager';

const BASE_FUNCTION_PATH = 'team-member-ui.src.apollo.link';

const {
  BUILD_ENV = '',
  BUILD_APOLLO_URI = '',
  BUILD_APOLLO_SUB = '',
  BUILD_APOLLO_DEVICE,
  BUILD_LOCAL_IP = '',
  BUILD_VERSION,
  BUILD_CLOUD_PROVIDER = '',
} = process.env;

export const apolloRegionModuleInstance = apolloRegionModule(
  BUILD_ENV,
  BUILD_APOLLO_URI,
  BUILD_APOLLO_SUB,
  BUILD_APOLLO_DEVICE === 'true',
  BUILD_LOCAL_IP,
  BUILD_CLOUD_PROVIDER,
);
const UNBATCHED_QUERIES = [
  'conversationWithParticipants',
  'teamMemberConversations',
  'magicTokenAuth',
];

const onAuthenticationError = () => {
  tokenManager.clear();
  location.reload();
};

export const getSubscriptionLinkAndClient = (uriResolver: () => string) => {
  const functionPath = `${BASE_FUNCTION_PATH}.getSubscriptionLinkAndClient`;

  datadogRum.addAction('Creating wsClient', { functionPath });

  const wsClient = createClient({
    url: uriResolver,
    connectionParams: async () => {
      if (tokenManager.ongoingRenewal) {
        await tokenManager.ongoingRenewal;
      }

      const authorizationToken = tokenManager.getAccessTokenJwtFromStorage();

      return { authorizationToken };
    },
    on: {
      closed: (event) => {
        const { code } = event as CloseEvent;
        datadogRum.addAction('Closed wsClient.', { functionPath, code });

        if (code === WS_EXPIRED_ACCESS_TOKEN_CODE) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          tokenManager.refreshTokens(); // We await the ongoingRenewal in the connectionParams function
        }

        if (code === WS_UNAUTHENTICATED_CODE) {
          onAuthenticationError();
        }
      },
      error: (event) => {
        datadogRum.addAction('Error on wsClient.', { functionPath, event });
      },
      opened: () => {
        datadogRum.addAction('Opened wsClient.', { functionPath });
      },
    },
  });
  return { wsClient, subscriptionLink: new GraphQLWsLink(wsClient) };
};

export const initializeLink = () => {
  const functionPath = `${BASE_FUNCTION_PATH}.initializeLink`;

  const { wsClient, subscriptionLink } = getSubscriptionLinkAndClient(
    apolloRegionModuleInstance.getSubscriptionUri,
  );

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    // Do not swap the graphQLErrors and networkError error handling order
    // It has implications on our refresh token flow
    if (graphQLErrors?.length) {
      for (const { extensions } of graphQLErrors) {
        if (isExpiredAccessTokenExtensions(extensions)) {
          datadogRum.addAction('Expired access token, refreshing tokens.', { functionPath });
          return fromPromise(tokenManager.refreshTokens())
            .filter((value) => Boolean(value))
            .flatMap(() => forward(operation));
        }

        if (isForbiddenExtensions(extensions) || isAuthenticationExtensions(extensions)) {
          datadogRum.addError('Forbidden or Auth error. Clearing local storage data.', {
            functionPath,
            errorCode: extensions.code,
          });
          onAuthenticationError();
          break;
        }

        if (isIncorrectRegionExtensions(extensions)) {
          datadogRum.addAction('Incorrect region. Setting new region.', { functionPath });
          const { newRegion } = extensions;
          apolloRegionModuleInstance.setUris(newRegion);
          wsClient.terminate();
          return forward(operation);
        }

        if (isIncorrectVersionExtensions(extensions) && BUILD_VERSION) {
          datadogRum.addAction('Incorrect version.', { functionPath });
          const storedClientVersion = sessionStorage.getItem('clientVersion');

          if (!storedClientVersion || storedClientVersion !== BUILD_VERSION) {
            datadogRum.addAction('Incorrect version. Setting clientVersion in storage.', {
              functionPath,
            });
            sessionStorage.setItem('clientVersion', BUILD_VERSION);
            window.location.reload();
          }
        }
      }
    }

    if (networkError && isServerParseError(networkError)) {
      if (isMaintenanceNetworkError(networkError)) {
        datadogRum.addError(' Maintenance network error.', { functionPath });
        if (window.location.pathname !== '/error/maintenance') {
          window.location.assign('/error/maintenance');
        }
      }

      if (isTooManyRequestsNetworkError(networkError)) {
        datadogRum.addError('Too many requests.', { functionPath });
        if (window.location.pathname !== '/error/toomanyrequests') {
          window.location.assign('/error/toomanyrequests');
        }
      }

      if (isAuthenticationNetworkError(networkError)) {
        datadogRum.addError('Authentication network error.', { functionPath });
        if (!window.location.pathname.startsWith('/error')) {
          onAuthenticationError();
        }
      }
    }
  });

  const buildLink = getVersionLink(BUILD_VERSION);
  const authLink = getAuthLink(tokenManager);

  const baseLinks = [errorLink, buildLink, authLink];
  const { basicHttpLink, batchHttpLink } = getHttpLinks(apolloRegionModuleInstance);

  const basicQueryOrMutationLink = ApolloLink.from([...baseLinks, basicHttpLink]);
  const batchQueryOrMutationLink = ApolloLink.from([...baseLinks, batchHttpLink]);

  /*
    This link checks if the operation is a subscription.
    If it is, we use our subscription link to retrieve data over WebSockets.
    If it is a query or mutation, we retrieve data over HTTP.
  */

  return ApolloLink.split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    subscriptionLink,
    ApolloLink.split(
      ({ operationName }) => UNBATCHED_QUERIES.includes(operationName),
      basicQueryOrMutationLink, // run optimised queries on a separate, unbatched link
      batchQueryOrMutationLink,
    ),
  );
};
