import createSvgIcon from '@mui/material/utils/createSvgIcon';
import React from 'react';

export const ReplyIcon = createSvgIcon(
  <svg viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.25 21.5V14.975C25.25 13.625 24.775 12.475 23.825 11.525C22.875 10.575 21.725 10.1 20.375 10.1H4.775L10.55 15.875L8.975 17.45L0.5 8.975L8.975 0.5L10.55 2.075L4.775 7.85H20.375C22.325 7.85 24 8.54375 25.4 9.93125C26.8 11.3188 27.5 13 27.5 14.975V21.5H25.25Z"
      fill="currentColor"
    />
  </svg>,
  'ReplyIcon',
);
