import { getTypographyStyles } from '@shared/frontend/components/base/typography';
import { type ColorOptions } from '@shared/frontend/components/conversation/message/types';
import { MessageBubble } from '@shared/frontend/components/message-bubble';
import { styled, theme } from '@shared/frontend/theme';

type CommentMessageBubbleProps = {
  $isHighlighted: boolean;
  $colorOptions: ColorOptions;
  $fullWidth?: boolean;
  $isBorderHighlighted?: boolean;
};

export const CommentMessageBubble = styled(MessageBubble)<CommentMessageBubbleProps>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  ${({ $colorOptions, $isHighlighted }) =>
    `
    background: ${$isHighlighted ? $colorOptions.selectedBackground : $colorOptions.background};
    ${$isHighlighted ? `border: 0.1rem solid ${$colorOptions.border};` : ''}
  `}
  background-origin: border-box;

  ${({ $isBorderHighlighted }) =>
    $isBorderHighlighted ? `border: 0.1rem solid ${theme.color.joyousBlue[300]};` : ''}

  & > div:not(:last-child) {
    margin-bottom: 0.4rem;
  }
`;

export const BracketText = styled('span')`
  ${getTypographyStyles({ $variant: 'BodyUi', $customColor: theme.text.secondary })}
`;
