export function isNumeric(num: string): boolean {
  return !Number.isNaN(Number(num)) && !Number.isNaN(parseFloat(num));
}

// min and max included
export function randomIntFromInterval(min: number, max: number): number {
  const intMin = Math.ceil(min);
  const intMax = Math.floor(max);
  return Math.floor(Math.random() * (intMax - intMin + 1) + intMin);
}

export function roundAccuratelyToOneDecimalPoint(value: number): number {
  return Math.round(value * 10) / 10;
}

// https://stackoverflow.com/a/11832950
export const roundAccuratelyToTwoDecimalPoint = (value: number) =>
  Math.round((value + Number.EPSILON) * 100) / 100;

const getRoundedPercentageDifference = (fractionValue: number, totalValue: number) => {
  const percentage = (fractionValue * 100) / totalValue;
  const roundedDownPercentageInteger = Math.floor((fractionValue * 100) / totalValue);
  return percentage - roundedDownPercentageInteger;
};

// sum of sentiment percentages rounded to nearest integers can be more than 100
// to ensure that the total sum is equal to 100 the following algorithm has been implemented:
// https://stackoverflow.com/a/13483710/4761428
// const [num1Percentage, num2Percentage, num3Percentage] = ([num1, num2, num3], total)
export const getAccuratelyRoundedPercentages = (replies: number[], totalReplies: number) => {
  if (!totalReplies) {
    return replies;
  }

  const percentageArray = replies.map((reply, index) => {
    return {
      roundedDown: Math.floor((reply * 100) / totalReplies),
      roundDiff: getRoundedPercentageDifference(reply, totalReplies),
      originalIndex: index,
    };
  });

  const difference = 100 - percentageArray.reduce((sum, { roundedDown }) => sum + roundedDown, 0);

  const accuratelyRoundedPercentages: number[] = [];

  percentageArray
    .sort(({ roundDiff: diff1 }, { roundDiff: diff2 }) => (diff1 > diff2 ? -1 : 1))
    .forEach(({ roundedDown, originalIndex }, index) => {
      if (index < difference && roundedDown !== 0) {
        accuratelyRoundedPercentages[originalIndex] = roundedDown + 1;
      } else {
        accuratelyRoundedPercentages[originalIndex] = roundedDown;
      }
    });

  return accuratelyRoundedPercentages;
};

export const sumAccumulator = <T, K extends keyof T>(array: T[], key: K) =>
  array.reduce((memo, current) => {
    const currentValue = current[key];
    if (typeof currentValue !== 'number') {
      return memo;
    }
    return memo + currentValue;
  }, 0);
