import TeKoreKore from '@shared/frontend/assets/mana-ora/0.png';
import TePoteKitea from '@shared/frontend/assets/mana-ora/1.png';
import TiheiwaMauriOraFlourishing from '@shared/frontend/assets/mana-ora/10.png';
import TePoTangotango from '@shared/frontend/assets/mana-ora/2.png';
import TePoWhawha from '@shared/frontend/assets/mana-ora/3.png';
import TePoteWhiwhia from '@shared/frontend/assets/mana-ora/4.png';
import KiteWheiAoIntentional from '@shared/frontend/assets/mana-ora/5.png';
import KiteWheiAoDeliberate from '@shared/frontend/assets/mana-ora/6.png';
import KiteAoMaramaPractice from '@shared/frontend/assets/mana-ora/7.png';
import KiteAoMaramaConsolidating from '@shared/frontend/assets/mana-ora/8.png';
import TiheiwaMauriOraLiving from '@shared/frontend/assets/mana-ora/9.png';
import { theme } from '@shared/frontend/theme';

const MANA_ORA_COLORS = {
  0: theme.color.katipoBlack[900],
  1: theme.color.katipoBlack[600],
  2: theme.color.katipoBlack[400],
  3: theme.color.katipoBlack[200],
  4: theme.color.katipoBlack[100],
  5: 'rgb(206, 51, 44)', // #ce332c red
  6: theme.color.happyYellow[900],
  7: theme.color.white,
  8: theme.color.joyousBlue[200],
  9: theme.color.joyousBlue[700],
  10: 'rgb(35, 184, 85)', // #23b855 green
};

type ManaOraMapType = {
  [index: number]: {
    text: string;
    image: string;
    backgroundColor: string;
    color: string;
  };
};

export const MANA_ORA_MAP: ManaOraMapType = {
  0: {
    text: 'Te Kore Kore\nThe Darkest State',
    image: TeKoreKore,
    backgroundColor: MANA_ORA_COLORS[0],
    color: theme.color.white,
  },
  1: {
    text: 'Te Po tē Kitea\nThe Unseeing State',
    image: TePoteKitea,
    backgroundColor: MANA_ORA_COLORS[1],
    color: theme.color.white,
  },
  2: {
    text: 'Te Po Tangotango\nThe Awakening State',
    image: TePoTangotango,
    backgroundColor: MANA_ORA_COLORS[2],
    color: theme.color.white,
  },
  3: {
    text: 'Te Po Whawha\nThe Awareness State',
    image: TePoWhawha,
    backgroundColor: MANA_ORA_COLORS[3],
    color: theme.text.primary,
  },
  4: {
    text: 'Te Po tē Whiwhia\nThe Action State',
    image: TePoteWhiwhia,
    backgroundColor: MANA_ORA_COLORS[4],
    color: theme.text.primary,
  },
  5: {
    text: 'Ki te Whei-Ao\nThe Intentional State',
    image: KiteWheiAoIntentional,
    backgroundColor: MANA_ORA_COLORS[5],
    color: theme.color.white,
  },
  6: {
    text: 'Ki te Whei-Ao\nThe Deliberate State',
    image: KiteWheiAoDeliberate,
    backgroundColor: MANA_ORA_COLORS[6],
    color: theme.text.primary,
  },
  7: {
    text: 'Ki te Ao Marama\nThe Practice State',
    image: KiteAoMaramaPractice,
    backgroundColor: MANA_ORA_COLORS[7],
    color: theme.text.primary,
  },
  8: {
    text: 'Ki te Ao Marama\nThe Consolidating State',
    image: KiteAoMaramaConsolidating,
    backgroundColor: MANA_ORA_COLORS[8],
    color: theme.text.primary,
  },
  9: {
    text: 'Tihei-wa Mauri Ora\nThe Living State',
    image: TiheiwaMauriOraLiving,
    backgroundColor: MANA_ORA_COLORS[9],
    color: theme.text.primary,
  },
  10: {
    text: 'Tihei-wa Mauri Ora\nThe Flourishing State',
    image: TiheiwaMauriOraFlourishing,
    backgroundColor: MANA_ORA_COLORS[10],
    color: theme.text.primary,
  },
};
