import createSvgIcon from '@mui/material/utils/createSvgIcon';
import React from 'react';

export const CollapseIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(6, 2)">
      <path
        d="M1.4 20L0 18.6L6 12.6L12 18.6L10.6 20L6 15.4L1.4 20ZM6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z"
        fill="#1F78BA"
      />
    </g>
  </svg>,
  'CollapseIcon',
);
