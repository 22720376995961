import { type TokenManager } from './token-manager';

// Types copied from https://gist.github.com/radiofreejohn/2348eac5e121e2afc5d3a2e6e0c9a4e5
// The user radiofreejohn is one of heap.io core committers
export type Heap = {
  track: (event: string, properties?: Record<string, unknown>) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Record<string, unknown>) => void;
  addEventProperties: (properties: Record<string, unknown>) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
};

export type ErrorHandler = (error: unknown) => void;

// eslint-disable-next-line import/no-mutable-exports, @typescript-eslint/no-empty-function
export let errorHandler: ErrorHandler = () => {}; // By default do nothing when an error happens

export const setHeapUtilsErrorHandler = (errorHandlerToUse: ErrorHandler): void => {
  errorHandler = errorHandlerToUse;
};

// The heap script has been temporarily removed from index.html in team-member-ui to pause the events.
export const initHeap = (
  ui: 'organization-ui' | 'team-member-ui',
  tokenManager: TokenManager,
  heapId?: string,
) => {
  try {
    const accessTokenPayload = tokenManager.getAccessTokenPayload();
    const extendedIdTokenPayload = tokenManager.getExtendedIdTokenPayload();
    const { heap } = window as unknown as { heap?: Heap };

    if (heap && heapId) {
      const isFromExtendedLogin = !!extendedIdTokenPayload;
      const { orgId: extendedIdTokenOrgId = '' } = extendedIdTokenPayload ?? {};
      const { orgId: accessTokenOrgId = '' } = accessTokenPayload ?? {};
      heap.clearEventProperties();
      heap.addEventProperties({
        isFromExtendedLogin: ui === 'organization-ui' ? isFromExtendedLogin : undefined,
        orgId: isFromExtendedLogin ? extendedIdTokenOrgId : accessTokenOrgId,
        ui,
      });
    }
  } catch (error) {
    // We don't want any analytics error to affect the user
    errorHandler(error);
  }
};
