import { Caption } from '@shared/frontend/components/base/typography';
import { useMessageProperties } from '@shared/frontend/components/conversation/message/use-message-properties';
import { styled, theme, transientOptions } from '@shared/frontend/theme';
import { formatDate } from '@shared/utils/date';
import React from 'react';

type ContainerProps = {
  $isRight: boolean;
};

const Container = styled('div', transientOptions)<ContainerProps>`
  position: absolute;
  min-width: 100%;
  bottom: 100%;
  padding: 0 ${theme.spacing.xs} 0 1.2rem;
  ${({ $isRight }) => ($isRight ? 'right: 0;' : '')}
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.s};
`;

type NameWrapperProps = {
  $shouldTruncate?: boolean;
};

const NameWrapper = styled('div', transientOptions)<NameWrapperProps>`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xxs};
  ${({ $shouldTruncate }) => ($shouldTruncate ? 'max-width: 18rem;' : '')}

  @media (max-width: ${theme.breakpointPixels.sm}) {
    max-width: 60vw;
  }
`;

const getTextColor = (isResponderMessage: boolean, isAuthorJoyousAI: boolean) => {
  if (isAuthorJoyousAI) {
    return theme.color.kereruPurple[300];
  }
  if (isResponderMessage) {
    return theme.color.ducksBlue[300];
  }
  return theme.color.katipoBlack[300];
};

type Props = {
  shouldTruncate?: boolean;
};

const MessageDetails = ({ shouldTruncate }: Props) => {
  const { message, isRight, isResponderMessage, authorName, isAuthorJoyousAI } =
    useMessageProperties();
  const { upsertTimestamps } = message;

  const messageTime = upsertTimestamps[0];
  const dateString = formatDate(messageTime);

  const textColor = getTextColor(isResponderMessage, isAuthorJoyousAI);

  return (
    <Container data-dd-action-name="message-details" $isRight={isRight}>
      <NameWrapper $shouldTruncate={shouldTruncate}>
        {authorName && (
          <Caption $noWrap $customColor={textColor}>
            {authorName}
          </Caption>
        )}
      </NameWrapper>
      <Caption $noWrap $customColor={textColor}>
        {dateString}
      </Caption>
    </Container>
  );
};

export default MessageDetails;
