import { getTypographyStyles } from '@components/base/typography';
import { TranslateLanguagePrompt } from '@components/translate-language-prompt';
import { styled, theme } from '@frontend/theme';
import React from 'react';

import { promptButtonStyles } from './reminder-button';

const Wrapper = styled('div')`
  ${promptButtonStyles}

  p, button {
    ${getTypographyStyles({
      $variant: 'BodyUi',
      $customColor: theme.color.joyousDogOrange[500],
      $fontWeight: 'medium',
    })};
  }

  &&&& svg {
    color: ${theme.color.joyousDogOrange[500]};
    width: 1.4rem;
    height: 1.4rem;
  }
`;

export const TranslationPromptButton = () => {
  return <TranslateLanguagePrompt Wrapper={Wrapper} />;
};
