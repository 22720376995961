import { getBrowserLanguage } from '@shared/utils/browser-language';
import React, { type ReactNode } from 'react';

import { lookupStaticTranslation } from '../../utils/static-translations';
import {
  LanguageSetting,
  useLanguageSetting,
  useLanguageSettingLoading,
  useToggleLanguageSetting,
} from '../hooks/use-language-setting';
import { styled } from '../theme';
import { Button } from './base/button';
import {
  useAutoTranslateConversationsEnabled,
  useUserPreferredLocale,
} from './logged-in-user-context';

const StyledButton = styled(Button)`
  && {
    flex-shrink: 0;
  }
`;

export const useGetStaticTranslation = (
  originalText: string,
  alwaysTranslate?: boolean,
): string => {
  const autoTranslateConversationsEnabled = useAutoTranslateConversationsEnabled();
  const languageSetting = useLanguageSetting();
  const userPreferredLocale = useUserPreferredLocale();

  if (!autoTranslateConversationsEnabled && !alwaysTranslate) {
    return originalText;
  }

  if (languageSetting === LanguageSetting.ORIGINAL && !alwaysTranslate) {
    return originalText;
  }

  // fall back to browser locale if user locale is not set
  const targetLocale = userPreferredLocale || getBrowserLanguage();
  const translatedText = lookupStaticTranslation(originalText, targetLocale);

  if (!translatedText) {
    return originalText;
  }

  return translatedText;
};

/**
 * Render static translation for `orignalText` if exists, and multi-language is enabled.
 * Adheres to user language settings, unless `alwaysTranslate = true`.
 * Renders original text if no translation exists.
 */
export const StaticTranslate = ({
  originalText,
  alwaysTranslate,
}: {
  originalText: string;
  alwaysTranslate?: boolean;
}) => <>{useGetStaticTranslation(originalText, alwaysTranslate)}</>;

/**
 * Returns `whenOriginal` or`whenTranslated` based on user language settings.
 * Returns `whenOriginal` if multi-language is disabled
 * Returns `whenOriginal` if `whenTranslated` is not defined.
 */
export const useGetToggleableTranslatedText = <T,>(
  whenOriginal: T,
  whenTranslated: T,
  alwaysTranslate?: boolean,
): T | null => {
  const autoTranslateConversationsEnabled = useAutoTranslateConversationsEnabled();
  const languageSetting = useLanguageSetting();

  if (!alwaysTranslate && !autoTranslateConversationsEnabled) {
    return whenOriginal;
  }
  if (languageSetting === LanguageSetting.ORIGINAL) {
    return whenOriginal;
  }

  return whenTranslated ? whenTranslated : whenOriginal;
};

/**
 * Renders `whenOriginal` or`whenTranslated` based on user language settings.
 * Renders `whenOriginal` if multi-language is disabled
 * Renders `whenOriginal` if `whenTranslated` is not defined.
 */
export const ToggleableTranslation = ({
  whenOriginal,
  whenTranslated,
  alwaysTranslate,
}: {
  whenOriginal: ReactNode;
  whenTranslated?: ReactNode;
  alwaysTranslate?: boolean;
}): JSX.Element => (
  <>{useGetToggleableTranslatedText(whenOriginal, whenTranslated, alwaysTranslate)}</>
);

type ToggleTranslateButtonProps = {
  whenOriginal?: string;
  whenTranslated?: string;
  alwaysTranslate?: boolean;
};

export const ToggleTranslateButton = ({
  whenOriginal = 'View translation',
  whenTranslated = 'See original language',
  alwaysTranslate = false,
}: ToggleTranslateButtonProps) => {
  const languageSettingLoading = useLanguageSettingLoading();
  const autoTranslateConversationsEnabled = useAutoTranslateConversationsEnabled();

  const toggleLanguageSetting = useToggleLanguageSetting();

  if (!alwaysTranslate && !autoTranslateConversationsEnabled) {
    return null;
  }

  return (
    <StyledButton
      data-cy="translate-button"
      $variant="ghost"
      $colorScheme="ducksBlue"
      $leftIcon="Translate"
      onClick={toggleLanguageSetting}
      disabled={languageSettingLoading}
    >
      <ToggleableTranslation
        whenOriginal={<StaticTranslate originalText={whenOriginal} alwaysTranslate />}
        whenTranslated={<StaticTranslate originalText={whenTranslated} alwaysTranslate />}
        alwaysTranslate={alwaysTranslate}
      />
    </StyledButton>
  );
};
