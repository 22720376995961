import { IconButton } from '@shared/frontend/components/base/icon-button';
import { styled } from '@shared/frontend/theme';
import React, { useCallback } from 'react';

import UploadImageButton from './upload-image-button';

const Container = styled('div')`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.2rem;
`;

export const ButtonWrapper = styled('div')`
  margin: 0;
`;

const getLeftButtonComponent = (
  showCancelEditButton: boolean,
  hasUploadImageButton: boolean,
  clearMessageInEditMode: () => void,
  handleOnFileSelected: (e: React.ChangeEvent<HTMLInputElement>) => void,
) => {
  if (showCancelEditButton) {
    return (
      <IconButton iconName="Cancel" aria-label="cancel-edit" onClick={clearMessageInEditMode} />
    );
  }
  if (hasUploadImageButton) {
    return <UploadImageButton onFileSelected={handleOnFileSelected} />;
  }

  return null;
};

type Props = {
  showCancelEditButton: boolean;
  onFileSelected?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  clearMessageInEditMode: () => void;
  hasUploadImageButton: boolean;
  children: React.ReactNode;
};

const InputWrapper = ({
  showCancelEditButton,
  onFileSelected,
  clearMessageInEditMode,
  hasUploadImageButton,
  children,
}: Props) => {
  const handleOnFileSelected = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onFileSelected) {
        await onFileSelected(e);
      }
    },
    [onFileSelected],
  );

  const leftButtonComponent = getLeftButtonComponent(
    showCancelEditButton,
    hasUploadImageButton,
    clearMessageInEditMode,
    handleOnFileSelected,
  );

  return (
    <Container>
      {leftButtonComponent && <ButtonWrapper>{leftButtonComponent}</ButtonWrapper>}
      {children}
    </Container>
  );
};

export default React.memo(InputWrapper);
