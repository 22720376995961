import { getFormattedDate } from '@shared/utils/formatters';

export enum TextTokens {
  // Tokens used when creating campaigns from campaign templates
  OrgName = 'org',

  // Tokens used when creating deliveries
  GivenName = 'givenName',
  Url = 'url',
  CampaignName = 'campaign',
  CampaignDate = 'campaignDate',
}

type CommonTemplateValues = {
  [TextTokens.GivenName]: string;
  [TextTokens.Url]: string;
  [TextTokens.CampaignName]: string;
  [TextTokens.CampaignDate]: string;
};

type CommonTemplateArgs = {
  personGivenName: string;
  url: string;
  campaignName: string;
  campaignFirstSend: Date;
  campaignTimezone: string;
};

export const getCommonTemplateValues = ({
  personGivenName,
  url,
  campaignName,
  campaignFirstSend,
  campaignTimezone,
}: CommonTemplateArgs): CommonTemplateValues => ({
  [TextTokens.GivenName]: personGivenName,
  [TextTokens.Url]: url,
  [TextTokens.CampaignName]: campaignName,
  [TextTokens.CampaignDate]: getFormattedDate(campaignFirstSend.getTime(), campaignTimezone),
});

export const replaceTokensInText = (
  templateText: string,
  templateValues: Record<string, string | number>,
  ignoreMissingValues = false,
) => {
  const caseInsensitiveTemplateValues = Object.keys(templateValues).reduce<Record<string, string>>(
    (acc, key) => {
      acc[key.toLowerCase()] = templateValues[key]?.toString();
      return acc;
    },
    {},
  );

  return templateText.replace(/\{\{(\w+)\}\}/gi, (_, key: string) => {
    const value = caseInsensitiveTemplateValues[key.toLowerCase()];

    if (ignoreMissingValues) {
      return value ?? `{{${key}}}`;
    }
    return value ?? '';
  });
};
