import { useLazyQuery } from '@apollo/client';
import {
  THEMES_REPORT_DOWNLOAD,
  type ThemesReportDownloadQueryResponse,
} from '@shared/graphql/queries/themes-report-download';
import { b64toBlob } from '@shared/utils/base64';

type Props = {
  campaignId: string;
  themesReportId?: string;
  conversationId?: string;
};

export const useThemesReportDownload = ({ campaignId, themesReportId, conversationId }: Props) => {
  const [themesReportDownload, { loading, error }] =
    useLazyQuery<ThemesReportDownloadQueryResponse>(THEMES_REPORT_DOWNLOAD, {
      fetchPolicy: 'no-cache',
    });

  const downloadReport = async (event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }

    if (!themesReportId) {
      return;
    }

    const response = await themesReportDownload({
      variables: {
        campaignId,
        themesReportId,
        conversationId,
      },
    });

    if (!response.data?.themesReportDownload.base64String) {
      return;
    }

    const { base64String } = response.data.themesReportDownload;
    const blob = b64toBlob(base64String, 'application/pdf');

    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  };

  return {
    downloadReport,
    loading,
    error,
  };
};
