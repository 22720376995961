import { useReactiveVar } from '@apollo/client';
import { StyledIcon } from '@shared/frontend/components/base/styled-icon';
import { styled, theme } from '@shared/frontend/theme';
import React from 'react';

import { MessageStatus, messagesBeingSentStateVar } from '../conversation-state';

type WrapperProps = {
  $isFirstMessage: boolean;
};

const Wrapper = styled('div')<WrapperProps>`
  right: -2rem;
  bottom: ${({ $isFirstMessage }) => ($isFirstMessage ? `-${theme.spacing.xs}` : '0rem')};
  position: absolute;
  display: flex;
`;

const getSendingStatusIcon = (status: MessageStatus) => {
  switch (status) {
    case MessageStatus.Sending:
      return <StyledIcon iconName="CheckCircleOutline" $size="1.6rem" $colorScheme="ducksBlue" />;
    case MessageStatus.Sent:
      return <StyledIcon iconName="CheckCircle" $size="1.6rem" $colorScheme="ducksBlue" />;
    case MessageStatus.Error:
      return <StyledIcon iconName="Error" $size="1.6rem" $colorScheme="ducksBlue" />;
    default:
      // message not being sent or unknown status
      return null;
  }
};

type Props = {
  isFirstMessage: boolean;
  messageId: string;
  messageGroupLength: number;
  messageIndex: number;
};

export const MessageSendingStatus = ({
  isFirstMessage,
  messageId,
  messageGroupLength,
  messageIndex,
}: Props) => {
  const messagesBeingSent = useReactiveVar(messagesBeingSentStateVar);

  const status = messagesBeingSent[messageId];

  const isSending = status !== MessageStatus.Sent;
  const isLastMessageInGroup = messageIndex === messageGroupLength - 1;

  const shouldDisplaySendingStatus = !!status && (isSending || isLastMessageInGroup);

  if (shouldDisplaySendingStatus) {
    return (
      <Wrapper $isFirstMessage={isFirstMessage} data-cy="sending-status">
        {getSendingStatusIcon(status)}
      </Wrapper>
    );
  }

  return null;
};
