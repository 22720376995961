import { gql } from '@apollo/client';

export const ownerFields = gql`
  fragment ownerFields on Person {
    _id
    orgId
    name
    givenName
    familyName
    avatar {
      initials
    }
    attributes {
      name
      value
    }
    manager {
      name
    }
  }
`;

export const authorFields = gql`
  fragment authorFields on Person {
    _id
    name
    givenName
    isJoyousAdmin
    avatar {
      initials
    }
  }
`;
