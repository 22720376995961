import FormControlLabel, {
  formControlLabelClasses,
  type FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import MuiCheckbox, { checkboxClasses } from '@mui/material/Checkbox';
import { svgIconClasses } from '@mui/material/SvgIcon';
import React from 'react';

import { type ThemeColorScheme, styled, theme, transientOptions } from '../../theme';
import { BodyUi, Caption } from './typography';

type CheckboxSize = '2.4rem' | '2.0rem' | '1.8rem' | '1.4rem';

type CheckboxColorScheme = Extract<
  ThemeColorScheme,
  'ducksBlue' | 'kereruPurple' | 'microPigPink' | 'katipoBlack' | 'happyYellow' | 'joyousDogOrange'
>;

type Props = {
  $size: CheckboxSize;
  $colorScheme?: CheckboxColorScheme;
};

export const Checkbox = styled(MuiCheckbox, transientOptions)<Props>`
  && {
    width: ${({ $size }) => $size};
    height: ${({ $size }) => $size};
    padding: 0;

    .${svgIconClasses.root} {
      font-size: ${({ $size }) => $size};
      color: ${({ $colorScheme = 'ducksBlue' }) => theme.color[$colorScheme][400]};
      ${({ disabled, $colorScheme = 'ducksBlue' }) =>
        disabled && `color: ${theme.color[$colorScheme][100]};`}
    }

    ${({ readOnly }) => readOnly && `pointer-events: none;`}
  }
`;

const CheckboxTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.xxs};
`;

type CheckboxWithTextProps = Omit<FormControlLabelProps, 'control'> &
  Props & {
    label: string;
    caption?: string;
  };

export const CheckboxWithText = styled(
  ({ $size, $colorScheme, label, caption, ...props }: CheckboxWithTextProps) => (
    <FormControlLabel
      {...props}
      control={<Checkbox $size={$size} $colorScheme={$colorScheme} />}
      label={
        <CheckboxTextWrapper>
          <BodyUi $fontWeight="medium">{label}</BodyUi>
          <Caption>{caption}</Caption>
        </CheckboxTextWrapper>
      }
    />
  ),
)`
  && {
    &.${formControlLabelClasses.root} {
      margin: 0;
      display: flex;
      flex-direction: row;
      column-gap: ${theme.spacing.s};
    }

    && .${checkboxClasses.root} {
      /* vertically align the checkbox with the label */
      align-self: flex-start;
      padding: 1.1rem;
      margin-top: -0.25rem;

      &:hover {
        background-color: ${({ $colorScheme = 'ducksBlue' }) => theme.color[$colorScheme][100]};
      }
    }
  }
`;
