import convertFileToDataURI from '@shared/utils/convert-file-to-data-URI';
import { useState } from 'react';

export const useFileUploadState = (
  updateLogoImageCallback?: (dataURI: string) => void,
): [
  string | null,
  React.Dispatch<React.SetStateAction<string | null>>,
  (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>,
  (event: React.ClipboardEvent<HTMLDivElement>) => void,
] => {
  const [fileDataURI, setFileDataURI] = useState<string | null>(null);

  const convertAndUpdateImage = async (file: File) => {
    const dataURI = await convertFileToDataURI(file, { maxWidth: 1024, maxHeight: 1024 });
    setFileDataURI(dataURI);

    if (updateLogoImageCallback) {
      updateLogoImageCallback(dataURI);
    }
  };

  const handlePaste = async (event: React.ClipboardEvent<HTMLDivElement>) => {
    if (!event.clipboardData?.items) {
      return;
    }

    const item = Array.from(event.clipboardData.items)[0];
    if (item?.type?.includes('image')) {
      const file = item.getAsFile();

      if (!file) {
        return;
      }

      await convertAndUpdateImage(file);
    }
  };

  const onFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    // if the selection of an image was canceled, don't do anything.
    if (!e.target.files || e.target.files.length === 0) return;

    const file = e.target.files[0];
    e.target.value = ''; // Clear value so the same thing can be uploaded twice.

    await convertAndUpdateImage(file);
  };

  return [fileDataURI, setFileDataURI, onFileSelected, handlePaste];
};
