import createSvgIcon from '@mui/material/utils/createSvgIcon';
import React from 'react';

export const EditedThemeIcon = createSvgIcon(
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <path
      d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 13.875C6.5175 13.875 4.5 11.8575 4.5 9.375H6C6 11.0325 7.3425 12.375 9 12.375C10.6575 12.375 12 11.0325 12 9.375C12 7.695 10.6125 6.3075 8.88 6.3825L10.0575 7.56L9 8.625L6 5.625L9 2.625L10.0575 3.6825L8.8575 4.8825C11.46 4.8 13.5 6.885 13.5 9.375C13.5 11.8575 11.4825 13.875 9 13.875Z"
      fill="currentColor"
    />
  </svg>,
  'editedThemeIcon',
);
