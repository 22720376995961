import { GraphQLMessage } from '@shared/graphql/message-utils';
import { createContext, useContext } from 'react';

type Props = {
  message: GraphQLMessage;
  messageGroupAuthorId: string;
  messageIndexInGroup: number;
};

export const MessageContext = createContext<Props>({
  message: {} as GraphQLMessage,
  messageGroupAuthorId: '',
  messageIndexInGroup: -1,
});

export const useMessageContext = () => useContext(MessageContext);
