import { keyframes } from '@frontend/theme';

export const CONVERSATION_WINDOW_SLIDE = {
  duration: 300,
};

export const REMINDER_BUTTON_DROP = {
  animation: keyframes`
    to {
      opacity: 1;
      transform: translate(-50%, 0%);
    }
  `,
  duration: 300,
};
