import createSvgIcon from '@mui/material/utils/createSvgIcon';
import React from 'react';

export const NewThemeIcon = createSvgIcon(
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM12.75 9.75H9.75V12.75H8.25V9.75H5.25V8.25H8.25V5.25H9.75V8.25H12.75V9.75Z"
      fill="currentColor"
    />
  </svg>,
  'newThemeIcon',
);
