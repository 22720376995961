import linearProgressClasses from '@mui/material/LinearProgress/linearProgressClasses';
import { Button } from '@shared/frontend/components/base/button';
import { ProgressBar } from '@shared/frontend/components/base/progress-bar';
import { BodyUi } from '@shared/frontend/components/base/typography';
import { useConversationContext } from '@shared/frontend/components/conversation/conversation-context';
import { useWidgetMutationMap } from '@shared/frontend/components/conversation/message/widget-map-utils';
import { StaticTranslate } from '@shared/frontend/components/translate';
import { styled, theme } from '@shared/frontend/theme';
import {
  isGraphQLAnsweredWidget,
  isGraphQLFilteredSelfSelect,
  isGraphQLSelfSelect,
  isGraphQLWidget,
  isSkippedGraphQLWidget,
  type GraphQLWidget,
} from '@shared/graphql/message-utils';
import React from 'react';

const StyledProgressBar = styled(ProgressBar)`
  && {
    .${linearProgressClasses.root} {
      max-width: 9rem;
    }
  }
`;

const StyledSkipButton = styled(Button)`
  && {
    min-height: 2.8rem;
    padding: 0;
    margin-left: ${theme.spacing.l};
    min-width: 4.6rem;
  }
`;

type Props = {
  widgets: GraphQLWidget[];
  activeWidget: GraphQLWidget | null;
  commentingDisabled: boolean;
};

export const ConversationProgress = ({ widgets, activeWidget, commentingDisabled }: Props) => {
  const { conversation, messageInEditMode, setMessageInEditMode } = useConversationContext();
  const WIDGET_MUTATION_MAP = useWidgetMutationMap();

  const widgetCount = conversation.conversationSchemeWidgetCount;
  const { isCampaignClosed } = conversation;

  const hasRespondedToAllQuestions =
    widgets.filter((widget) => isGraphQLAnsweredWidget(widget) || isSkippedGraphQLWidget(widget))
      .length === widgetCount;

  const messageBeingEditedIsWidget = isGraphQLWidget(messageInEditMode);

  const showConversationProgress =
    !isCampaignClosed &&
    (!hasRespondedToAllQuestions || messageBeingEditedIsWidget || commentingDisabled);

  if (!showConversationProgress || !activeWidget) {
    return null;
  }

  const widgetIndex = widgets.map(({ _id }) => _id).indexOf(activeWidget._id) + 1;

  const skipWidgetAnswer = WIDGET_MUTATION_MAP[activeWidget.type];

  const handleSkipClick = () => {
    skipWidgetAnswer(conversation, activeWidget, null);
    setMessageInEditMode(null);
  };

  const showSkipButton =
    !isGraphQLSelfSelect(activeWidget) &&
    !isGraphQLFilteredSelfSelect(activeWidget) &&
    ((!hasRespondedToAllQuestions && !messageInEditMode) || messageBeingEditedIsWidget);
  const progressPercentage =
    hasRespondedToAllQuestions && !messageInEditMode
      ? 100
      : ((widgetIndex - 1) / widgetCount) * 100;

  return (
    <>
      <StyledProgressBar value={progressPercentage} left={`${widgetIndex} of ${widgetCount}`} />
      {showSkipButton && (
        <StyledSkipButton
          $colorScheme="ducksBlue"
          $variant="ghost"
          onClick={handleSkipClick}
          data-cy="skip-button"
        >
          <BodyUi>
            <StaticTranslate originalText="Skip" />
          </BodyUi>
        </StyledSkipButton>
      )}
    </>
  );
};
