import filledInputClasses from '@mui/material/FilledInput/filledInputClasses';
import formHelperTextClasses from '@mui/material/FormHelperText/formHelperTextClasses';
import formLabelClasses from '@mui/material/FormLabel/formLabelClasses';
import inputAdornmentClasses from '@mui/material/InputAdornment/inputAdornmentClasses';
import inputBaseClasses from '@mui/material/InputBase/inputBaseClasses';
import inputLabelClasses from '@mui/material/InputLabel/inputLabelClasses';
import MuiTextField, { type TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { type IconSize } from '@shared/frontend/components/base/styled-icon';
import { getTypographyStyles } from '@shared/frontend/components/base/typography';
import { styled, theme } from '@shared/frontend/theme';
import React from 'react';

const iconSize: IconSize = '1.8rem';

export type TextInputProps = {
  label?: MuiTextFieldProps['label'];
  'data-cy'?: string;
  $helperTextColor?: string;
} & MuiTextFieldProps;

export const TextInput = styled(
  React.forwardRef<HTMLDivElement, TextInputProps>((props, ref) => (
    <MuiTextField
      {...props}
      inputRef={ref}
      fullWidth
      variant="filled"
      InputProps={{ ...props.InputProps, disableUnderline: true }}
      // @ts-expect-error the type does not explicitly define the data-cy prop, but it still gets passed to the HTML element
      FormHelperTextProps={{ ...props.FormHelperTextProps, 'data-cy': 'input-helper-text' }}
    />
  )),
)<TextInputProps>`
  && {
    .${filledInputClasses.root} {
      border-radius: ${theme.borderRadius.md};
      border: ${theme.border};
      background-color: ${theme.color.white};
      ${({ label }) => (!label ? 'padding: 0.8rem;' : '')}
    }

    /* Label */
    .${formLabelClasses.root} {
      ${getTypographyStyles({ $variant: 'BodyUi', $customColor: theme.text.muted })}
      transform: translate(1.2rem, 1.8rem);

      &.${formLabelClasses.focused} {
        color: ${theme.text.muted};
      }

      &.${formLabelClasses.error} {
        color: ${theme.text.error};
      }

      &.${formLabelClasses.disabled} {
        color: ${theme.text.muted};
      }
    }

    /* Label when in top position */
    .${inputLabelClasses.filled}.${inputLabelClasses.shrink} {
      ${getTypographyStyles({ $variant: 'Caption' })}
      /* Remove the scale transform so we can set the font-size to be exactly 1rem */
      transform: translate(1.2rem, 0.8rem);
    }

    /* HelperText */
    .${formHelperTextClasses.root} {
      ${({ $helperTextColor }) =>
        getTypographyStyles({
          $variant: 'Caption',
          $customColor: $helperTextColor ?? theme.text.muted,
        })}
      margin: ${theme.spacing.xs} 0 0 ${theme.spacing.s};

      &.${formHelperTextClasses.error} {
        color: ${theme.text.error};
      }
    }

    /* Input field */
    .${inputBaseClasses.input} {
      ${getTypographyStyles({ $variant: 'BodyUi', $customColor: theme.text.primary })};
    }

    .${inputAdornmentClasses.root} {
      color: ${theme.text.primary};
    }

    .${inputAdornmentClasses.positionEnd}:last-child {
      margin-right: 0.9rem;
    }
  }

  svg {
    width: ${iconSize};
    height: ${iconSize};
  }
`;
