import { ServerParseError } from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';

export const isMaintenanceNetworkError = ({ statusCode, response }: ServerParseError) => {
  const isServerMaintenanceOn = Array.from(response.headers.entries()).find(
    (entry) => entry[0] === 'reason' && entry[1] === 'MAINTENANCE',
  );

  return statusCode === 503 && isServerMaintenanceOn;
};

export const isTooManyRequestsNetworkError = ({ statusCode }: ServerParseError) =>
  statusCode === 429;

export const isAuthenticationNetworkError = ({ statusCode }: ServerParseError) =>
  statusCode === 401 || statusCode === 403;

export const isServerParseError = (error: NetworkError): error is ServerParseError =>
  error?.hasOwnProperty('statusCode') ?? false;

export const isHandledNetworkError = (error: NetworkError) => {
  if (error && isServerParseError(error)) {
    if (
      isMaintenanceNetworkError(error) ||
      isTooManyRequestsNetworkError(error) ||
      isAuthenticationNetworkError(error)
    ) {
      return true;
    }
  }

  return false;
};
