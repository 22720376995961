import { type MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem';
import { type MenuProps, StyledMenu, StyledMenuItem } from '@shared/frontend/components/base/menu';
import React, { type MouseEvent, type ReactNode, createContext, useContext, useState } from 'react';

type MenuContextProps = {
  openMenu: (event: MouseEvent<HTMLButtonElement>) => void;
  closeMenu: () => void;
};

const MenuContext = createContext<MenuContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openMenu: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeMenu: () => {},
});

const useReusableMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return {
    openMenu,
    closeMenu,
    anchorEl,
  };
};

type ReusableMenuProps = {
  renderTriggerComponent: ({
    isOpen,
    openMenu,
  }: {
    isOpen: boolean;
    openMenu: (event: MouseEvent<HTMLButtonElement>) => void;
  }) => React.ReactElement | null | undefined;
  'data-cy'?: string;
  children: ReactNode;
} & MenuProps;

export const Menu = ({
  children,
  renderTriggerComponent,
  'data-cy': dataCy,
  ...menuProps
}: ReusableMenuProps) => {
  const { openMenu, closeMenu, anchorEl } = useReusableMenu();

  const contextValue: MenuContextProps = {
    openMenu,
    closeMenu,
  };

  return (
    <MenuContext.Provider value={contextValue}>
      {renderTriggerComponent({ isOpen: Boolean(anchorEl), openMenu })}
      <StyledMenu
        {...menuProps}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        // @ts-expect-error the types do not allow it but this still gets propagated to the element
        PaperProps={{ 'data-cy': dataCy }}
      >
        {children}
      </StyledMenu>
    </MenuContext.Provider>
  );
};

export const MenuItem = ({ children, onClick, ...rest }: MuiMenuItemProps) => {
  const { closeMenu } = useContext(MenuContext);

  return (
    <StyledMenuItem
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
        closeMenu();
      }}
      {...rest}
    >
      {children}
    </StyledMenuItem>
  );
};
