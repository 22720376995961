export const zIndex = {
  hidden: -1,
  min: 1,
  onTop: 2,
  dialog: 3,
  onTopOfDialog: 4,
  header: 4,
  filterDropdown: 500,
  muiDialogIndex: 1300,
  onTopOfMuiDialog: 1301,
  max: 9999,
} as const;
