import AccessTime from '@mui/icons-material/AccessTime';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Add from '@mui/icons-material/Add';
import Air from '@mui/icons-material/Air';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArticleOutlined from '@mui/icons-material/ArticleOutlined';
import Bookmark from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlined from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarksOutlined from '@mui/icons-material/BookmarksOutlined';
import BrokenImage from '@mui/icons-material/BrokenImage';
import CachedOutlined from '@mui/icons-material/CachedOutlined';
import CalendarMonthOutlined from '@mui/icons-material/CalendarMonthOutlined';
import Cancel from '@mui/icons-material/Cancel';
import Check from '@mui/icons-material/Check';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Circle from '@mui/icons-material/Circle';
import Clear from '@mui/icons-material/Clear';
import Close from '@mui/icons-material/Close';
import CloudDone from '@mui/icons-material/CloudDone';
import CloudUpload from '@mui/icons-material/CloudUpload';
import Compress from '@mui/icons-material/Compress';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import DragIndicator from '@mui/icons-material/DragIndicator';
import Edit from '@mui/icons-material/Edit';
import EditOutlined from '@mui/icons-material/EditOutlined';
import ElectricBolt from '@mui/icons-material/ElectricBolt';
import Error from '@mui/icons-material/Error';
import EventOutlined from '@mui/icons-material/EventOutlined';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Expand from '@mui/icons-material/Expand';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import FilePresentOutlined from '@mui/icons-material/FilePresentOutlined';
import FilterList from '@mui/icons-material/FilterList';
import Flag from '@mui/icons-material/FlagOutlined';
import Forum from '@mui/icons-material/Forum';
import ForumOutlined from '@mui/icons-material/ForumOutlined';
import GppMaybeOutlined from '@mui/icons-material/GppMaybeOutlined';
import GridView from '@mui/icons-material/GridView';
import GroupAdd from '@mui/icons-material/GroupAdd';
import Handyman from '@mui/icons-material/HandymanOutlined';
import HelpOutlineOutlined from '@mui/icons-material/HelpOutlineOutlined';
import Image from '@mui/icons-material/Image';
import ImportExport from '@mui/icons-material/ImportExport';
import Inbox from '@mui/icons-material/InboxOutlined';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import InsertChartOutlined from '@mui/icons-material/InsertChartOutlined';
import LabelOutlined from '@mui/icons-material/LabelOutlined';
import Launch from '@mui/icons-material/Launch';
import LibraryAddOutlined from '@mui/icons-material/LibraryAddOutlined';
import LibraryBooksOutlined from '@mui/icons-material/LibraryBooksOutlined';
import LightbulbOutlined from '@mui/icons-material/LightbulbOutlined';
import Link from '@mui/icons-material/Link';
import ListAlt from '@mui/icons-material/ListAltOutlined';
import LocalLibraryOutlined from '@mui/icons-material/LocalLibraryOutlined';
import Logout from '@mui/icons-material/Logout';
import LoopOutlined from '@mui/icons-material/LoopOutlined';
import Menu from '@mui/icons-material/Menu';
import MenuOpen from '@mui/icons-material/MenuOpen';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import MoreVert from '@mui/icons-material/MoreVert';
import NotificationsNoneOutlined from '@mui/icons-material/NotificationsNoneOutlined';
import OpenInNewOutlined from '@mui/icons-material/OpenInNewOutlined';
import People from '@mui/icons-material/People';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Restore from '@mui/icons-material/Restore';
import RocketLaunch from '@mui/icons-material/RocketLaunch';
import ScreenshotMonitor from '@mui/icons-material/ScreenshotMonitor';
import Search from '@mui/icons-material/Search';
import Send from '@mui/icons-material/Send';
import SentimentDissatisfiedOutlined from '@mui/icons-material/SentimentDissatisfiedOutlined';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import Share from '@mui/icons-material/Share';
import ShareOutlined from '@mui/icons-material/ShareOutlined';
import ShowChartOutlined from '@mui/icons-material/ShowChartOutlined';
import Sort from '@mui/icons-material/Sort';
import SpaceDashboardOutlined from '@mui/icons-material/SpaceDashboardOutlined';
import Star from '@mui/icons-material/Star';
import StarOutline from '@mui/icons-material/StarOutline';
import SummarizeOutlined from '@mui/icons-material/SummarizeOutlined';
import SupportAgent from '@mui/icons-material/SupportAgent';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import TimerOutlined from '@mui/icons-material/TimerOutlined';
import Token from '@mui/icons-material/TokenOutlined';
import Translate from '@mui/icons-material/Translate';
import Tune from '@mui/icons-material/Tune';
import Update from '@mui/icons-material/Update';
import VerifiedUserOutlined from '@mui/icons-material/VerifiedUserOutlined';
import ViewTimelineOutlined from '@mui/icons-material/ViewTimelineOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import Web from '@mui/icons-material/WebOutlined';
import { CollapseIcon } from '@shared/frontend/assets/custom-icons/collapse-icon';
import { EditedThemeIcon } from '@shared/frontend/assets/custom-icons/edited-theme';
import { ExpandIcon } from '@shared/frontend/assets/custom-icons/expand-icon';
import { HideTimelineIcon } from '@shared/frontend/assets/custom-icons/hide-timeline';
import { JoyousLogoSquare } from '@shared/frontend/assets/custom-icons/joyous-logo-square';
import { NewThemeIcon } from '@shared/frontend/assets/custom-icons/new-theme';
import { ClappingHands } from '@shared/frontend/assets/custom-icons/reactions/clapping-hands';
import { GrinningSquintingFace } from '@shared/frontend/assets/custom-icons/reactions/grinning-squinting-face';
import { LightBulb } from '@shared/frontend/assets/custom-icons/reactions/light-bulb';
import { RedHeart } from '@shared/frontend/assets/custom-icons/reactions/red-heart';
import { SlightlySmilingFace } from '@shared/frontend/assets/custom-icons/reactions/slightly-smiling-face';
import { ThumbsDown } from '@shared/frontend/assets/custom-icons/reactions/thumbs-down';
import { ThumbsUp } from '@shared/frontend/assets/custom-icons/reactions/thumbs-up';
import { ReplyIcon } from '@shared/frontend/assets/custom-icons/reply';
import { RobotIcon } from '@shared/frontend/assets/custom-icons/robot';
import { SearchCommentIcon } from '@shared/frontend/assets/custom-icons/search-comment';

export const Icons = {
  // Custom Icons
  JoyousLogoSquare,
  RobotIcon,
  CollapseIcon,
  NewThemeIcon,
  EditedThemeIcon,
  ExpandIcon,
  SearchCommentIcon,
  ReplyIcon,
  HideTimelineIcon,

  // Reactions
  ReactionClappingHands: ClappingHands,
  ReactionGrinningSquintingFace: GrinningSquintingFace,
  ReactionLightBulb: LightBulb,
  ReactionRedHeart: RedHeart,
  ReactionSlightlySmilingFace: SlightlySmilingFace,
  ReactionThumbsDown: ThumbsDown,
  ReactionThumbsUp: ThumbsUp,

  // Material Icons
  AccessTime,
  AccountCircle,
  Add,
  Air,
  ArrowBack,
  ArrowDropDown,
  ArrowForward,
  ArrowLeft,
  ArrowRight,
  ArrowUpward,
  Article: ArticleOutlined,
  Bookmark,
  BookmarkBorder: BookmarkBorderOutlined,
  Bookmarks: BookmarksOutlined,
  BrokenImage,
  Cached: CachedOutlined,
  CalendarMonth: CalendarMonthOutlined,
  Cancel,
  Check,
  CheckCircle,
  CheckCircleOutline,
  Checklist: ChecklistIcon,
  ChevronLeft,
  ChevronRight,
  Circle,
  Clear,
  Close,
  CloudDone,
  CloudUpload,
  Collapse: CollapseIcon,
  Compress,
  Delete: DeleteOutlined,
  Download: FileDownloadOutlined,
  DragIndicator,
  Edit,
  EditOutlined,
  ElectricBolt,
  Error,
  Event: EventOutlined,
  ExitToApp,
  Expand,
  ExpandLess,
  ExpandMore,
  ExpandCustom: ExpandIcon,
  FileDownload: FileDownloadOutlined,
  FilePresent: FilePresentOutlined,
  FilterList,
  Flag,
  Forum,
  ForumOutlined,
  GppMaybeOutlined,
  GridView,
  GroupAdd,
  Handyman,
  Help: HelpOutlineOutlined,
  Image,
  ImportExport,
  Inbox,
  Info: InfoOutlined,
  InsertChart: InsertChartOutlined,
  Label: LabelOutlined,
  Launch,
  LibraryAdd: LibraryAddOutlined,
  LibraryBooks: LibraryBooksOutlined,
  Lightbulb: LightbulbOutlined,
  Link,
  ListAlt,
  LocalLibrary: LocalLibraryOutlined,
  Logout,
  Loop: LoopOutlined,
  Menu,
  MenuOpen,
  MoreHoriz,
  MoreVert,
  NotificationsNone: NotificationsNoneOutlined,
  OpenInNew: OpenInNewOutlined,
  People,
  PhotoCamera,
  Reply: ReplyIcon,
  Restore,
  Robot: RobotIcon,
  RocketLaunch,
  ScreenshotMonitor,
  Search,
  SearchComment: SearchCommentIcon,
  Send,
  SentimentDissatisfied: SentimentDissatisfiedOutlined,
  Settings: SettingsOutlined,
  Share,
  ShareOutlined,
  ShowChart: ShowChartOutlined,
  Sort,
  SpaceDashboard: SpaceDashboardOutlined,
  Star,
  StarOutline,
  Summarize: SummarizeOutlined,
  SupportAgent,
  ThumbDown: ThumbDownAltOutlinedIcon,
  Timer: TimerOutlined,
  Token,
  Translate,
  Tune,
  Update,
  Timeline: ViewTimelineOutlined,
  VerifiedUserOutlined,
  Visibility,
  VisibilityOff: VisibilityOffOutlined,
  Web,
};

export type IconName = keyof typeof Icons;
