export enum ClientId {
  ORG_UI = 'ORG_UI',
  TEAM_MEMBER_UI = 'TEAM_MEMBER_UI',
}

export type AccessTokenPayload = {
  _id: string;
  orgId: string;
  location: string;
  timestamp: number;
  exp: number;
  clientId: ClientId;
};

export type IdTokenPayload = {
  _id: string;
  orgId: string;
  location: string;
};

export type TokenPayload = AccessTokenPayload | IdTokenPayload;
