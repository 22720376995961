import MuiCircularProgress, {
  CircularProgressProps as MuiCircularProgressProps,
} from '@mui/material/CircularProgress';
import MuiLinearProgress, {
  linearProgressClasses as MuiLinearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled, theme, ThemeColorScheme, transientOptions } from '@shared/frontend/theme';
import React from 'react';

export type ProgressColorScheme = Extract<
  ThemeColorScheme,
  'ducksBlue' | 'happyYellow' | 'microPigPink'
>;

const colorMap: Record<
  ProgressColorScheme,
  {
    unfilledColor: string;
    filledColor: string;
  }
> = {
  ducksBlue: {
    unfilledColor: theme.color.ducksBlue[50],
    filledColor: theme.color.ducksBlue[500],
  },
  happyYellow: {
    unfilledColor: theme.color.happyYellow[50],
    filledColor: theme.color.happyYellow[300],
  },
  microPigPink: {
    unfilledColor: theme.color.microPigPink[50],
    filledColor: theme.color.microPigPink[200],
  },
};

type CircularProgressProps = MuiCircularProgressProps & {
  $isCentered?: boolean;
  $colorScheme?: ThemeColorScheme | 'white';
};

export const CircularProgress = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ $colorScheme, $isCentered, className, ...restOfProps }: CircularProgressProps) => {
    if ($isCentered) {
      return (
        <div className={className}>
          <MuiCircularProgress {...restOfProps} />
        </div>
      );
    }

    return <MuiCircularProgress className={className} {...restOfProps} />;
  },
)<CircularProgressProps>`
  &&&& {
    ${({ $isCentered }) =>
      $isCentered &&
      ` display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      `}

    svg {
      ${({ $colorScheme = 'ducksBlue' }) => {
        switch ($colorScheme) {
          case 'white':
            return `color: ${theme.color.white}`;
          default:
            return `color: ${theme.color[$colorScheme][400]}`;
        }
      }}
    }
  }
`;

export const LinearProgress = styled(MuiLinearProgress, transientOptions)<{
  $colorScheme?: ProgressColorScheme;
}>`
  &&& {
    flex: 1;
    border-radius: ${theme.borderRadius[50]};
    background-color: ${({ $colorScheme = 'ducksBlue' }) => colorMap[$colorScheme].unfilledColor};

    .${MuiLinearProgressClasses.bar} {
      background-color: ${({ $colorScheme = 'ducksBlue' }) => colorMap[$colorScheme].filledColor};
    }
  }
`;
