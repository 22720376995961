import { styled, theme, ThemeColorScheme, transientOptions } from '@shared/frontend/theme';
import React from 'react';

import { IconSize } from './styled-icon';
import { Caption } from './typography';

type ColorScheme = Extract<
  ThemeColorScheme,
  'ducksBlue' | 'kereruPurple' | 'joyousDogOrange' | 'microPigPink' | 'katipoBlack'
>;
type ColorMap = Record<ColorScheme, { bgColor: string; textColor: string; iconColor: string }>;

const iconSize: IconSize = '1.8rem';

const colorMap: ColorMap = {
  ducksBlue: {
    textColor: theme.color.ducksBlue[500],
    iconColor: theme.color.ducksBlue[400],
    bgColor: theme.color.ducksBlue[25],
  },
  kereruPurple: {
    textColor: theme.color.kereruPurple[500],
    iconColor: theme.color.kereruPurple[400],
    bgColor: theme.color.kereruPurple[25],
  },
  joyousDogOrange: {
    textColor: theme.color.joyousDogOrange[500],
    iconColor: theme.color.joyousDogOrange[400],
    bgColor: theme.color.joyousDogOrange[25],
  },
  microPigPink: {
    textColor: theme.color.microPigPink[500],
    iconColor: theme.color.microPigPink[400],
    bgColor: theme.color.microPigPink[25],
  },
  katipoBlack: {
    textColor: theme.color.katipoBlack[500],
    iconColor: theme.color.katipoBlack[400],
    bgColor: theme.color.katipoBlack[25],
  },
};

type Props = {
  $colorScheme: ColorScheme;
} & React.ClassAttributes<HTMLSpanElement> &
  React.HTMLAttributes<HTMLSpanElement>;

export const Label = styled(
  (props: Props) => <Caption $fontWeight="medium" {...props} />,
  transientOptions,
)<Props>`
  min-height: 2.8rem;
  height: fit-content;
  width: fit-content;
  padding: ${theme.spacing.xxs} ${theme.spacing.xs};
  border-radius: ${theme.borderRadius.sm};
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.xxs};

  ${({ $colorScheme }) => `
    background-color: ${colorMap[$colorScheme].bgColor};
    color: ${colorMap[$colorScheme].textColor};

    svg {
      color: ${colorMap[$colorScheme].iconColor};
      height: ${iconSize};
      width: ${iconSize};
    }
  `};
`;
