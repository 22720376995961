export * from './joyous-error';

export * from './optional';

export * from './token-payload-types';

export * from './attribute-types';

export * from './local-storage-manager-types';

export type WithRequired<T, K extends keyof T> = T & Required<Pick<T, K>>;

// Utility type to convert a union type to an intersection type
export type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;

export type Nullable<T> = {
  [P in keyof T]-?: T[P] extends undefined ? null : T[P] | null;
};
