import { breakpointPixels } from './breakpoint';
import { color, colorWithOpacity, gradient } from './color';
import { font } from './font';
import { spacing } from './spacing';
import { zIndex } from './z-index';
import * as styledHelpers from './styled';

const primary = color.ducksBlue[500];
const secondary = color.ducksBlue[400];
const error = color.microPigPink[900];

const surface = color.white;
const surfaceLight = color.whiteSubtleBlue;

const background = color.ducksBlue[25];
const backgroundDialog = colorWithOpacity(color.katipoBlack[500], 0.5);

const borderColor = color.ducksBlue[50];
const border = `0.1rem solid ${borderColor}`;

const text = {
  primary: color.katipoBlack[900],
  secondary: color.katipoBlack[400],
  muted: color.katipoBlack[300],
  error,
} as const;

const borderRadius = {
  sm: '0.5rem',
  md: '1.2rem',
  lg: '2.4rem',
  // This is to ensure elements have a radius that is 50% of their height
  // https://stackoverflow.com/questions/27233295/possible-to-make-border-radius-equal-to-half-the-total-height-without-javascript
  50: '999rem',
} as const;

const shadows = {
  light: `0 0.4rem 0.4rem 0 ${colorWithOpacity(color.black, 0.05)}`,
  medium: `0 0.4rem 0.4rem 0 ${colorWithOpacity(color.black, 0.1)}`,
  heavy: `0 0.4rem 1rem 0 ${colorWithOpacity(color.black, 0.25)}`,
  even: `0 0 0.4rem 0 ${colorWithOpacity(color.black, 0.25)}`,
} as const;

const glassBlur = `
  background: linear-gradient(
    270deg,
    ${color.white},
    ${colorWithOpacity(color.white, 0.2)} 10%,
    ${colorWithOpacity(color.white, 0.2)} 90%,
    ${color.white}
  );
  backdrop-filter: blur(0.75rem);
  --webkit-backdrop-filter: blur(0.75rem);

  /* background-color used as a fallback when backdrop-filter is not supported (e.g: Firefox v102 and below) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: ${color.white};
  }
`;

const scrollbar = {
  thumbColor: 'rgb(199, 199, 199)', // #c7c7c7 light grey
  thumbHoverColor: '141, 141, 141', // #8d8d8d dark grey
};

export const theme = {
  breakpointPixels,
  color,
  text,
  spacing,
  font,
  surface,
  surfaceLight,
  border,
  borderColor,
  borderRadius,
  primary,
  secondary,
  error,
  background,
  backgroundDialog,
  shadows,
  glassBlur,
  zIndex,
  gradient,
  scrollbar,
};

export const { styled, css, keyframes, transientOptions } = { ...styledHelpers };

export type ThemeColorScheme = keyof Pick<
  typeof color,
  | 'ducksBlue'
  | 'happyYellow'
  | 'joyousBlue'
  | 'joyousDogOrange'
  | 'katipoBlack'
  | 'kereruPurple'
  | 'microPigPink'
>;
