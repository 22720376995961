import { styled, theme, ThemeColorScheme, transientOptions } from '@shared/frontend/theme';
import React, { MouseEventHandler } from 'react';

import { IconName } from '../icons';
import { IconSize, StyledIcon } from './styled-icon';
import { BodyConversation, getTypographyStyles } from './typography';

type ColorScheme = Extract<ThemeColorScheme, 'ducksBlue' | 'kereruPurple' | 'microPigPink'>;

const iconSize: IconSize = '2.4rem';

const colorMap: Record<
  ColorScheme,
  {
    titleColor: string;
    iconColor: string;
    bgColor: string;
  }
> = {
  ducksBlue: {
    titleColor: theme.color.ducksBlue[500],
    iconColor: theme.color.ducksBlue[400],
    bgColor: theme.color.ducksBlue[50],
  },
  kereruPurple: {
    titleColor: theme.color.kereruPurple[500],
    iconColor: theme.color.kereruPurple[400],
    bgColor: theme.color.kereruPurple[50],
  },
  microPigPink: {
    titleColor: theme.color.microPigPink[500],
    iconColor: theme.color.microPigPink[400],
    bgColor: theme.color.microPigPink[50],
  },
};

type ColorProps = {
  $colorScheme: ColorScheme;
};

const AlertWrapper = styled('div', transientOptions)<ColorProps>`
  ${getTypographyStyles({ $variant: 'BodyConversation' })};

  display: flex;
  column-gap: ${theme.spacing.s};
  padding: ${theme.spacing.s} ${theme.spacing.m};
  background: ${({ $colorScheme }) => colorMap[$colorScheme].bgColor};
  border-radius: ${theme.borderRadius.sm};
  min-width: 35rem;
`;

const ContentWrapper = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: ${theme.spacing.xxs};
`;

const CompactContentWrapper = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  column-gap: ${theme.spacing.s};
`;

const TitleWrapper = styled('div', transientOptions)<ColorProps>`
  ${getTypographyStyles({ $variant: 'H1', $fontWeight: 'medium' })};

  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.xs};
  color: ${({ $colorScheme }) => colorMap[$colorScheme].titleColor};
`;

type BaseAlertProps = {
  iconName?: IconName;
  body: React.ReactNode;
  actions?: React.ReactNode;
  onClick?: MouseEventHandler;
} & ColorProps;

type AlertProps = {
  title: React.ReactNode;
} & BaseAlertProps;

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  ({ $colorScheme, title, iconName, body, actions, onClick }: AlertProps, ref) => {
    return (
      <AlertWrapper ref={ref} $colorScheme={$colorScheme} onClick={onClick}>
        <ContentWrapper>
          <TitleWrapper $colorScheme={$colorScheme}>
            {iconName && (
              <StyledIcon iconName={iconName} $size={iconSize} $colorScheme={$colorScheme} />
            )}
            {title}
          </TitleWrapper>
          {body}
        </ContentWrapper>
        {actions}
      </AlertWrapper>
    );
  },
);

export const AlertCompact = ({
  $colorScheme,
  iconName,
  body,
  actions,
  onClick,
}: BaseAlertProps) => {
  return (
    <AlertWrapper $colorScheme={$colorScheme} onClick={onClick}>
      <CompactContentWrapper>
        {iconName && (
          <StyledIcon iconName={iconName} $size={iconSize} $colorScheme={$colorScheme} />
        )}
        <BodyConversation>{body}</BodyConversation>
      </CompactContentWrapper>
      {actions}
    </AlertWrapper>
  );
};
