import { localesWithCurrency } from '@shared/utils/locale-utils';
import React, { useState } from 'react';

import {
  useLanguageStoreActions,
  useShowTranslationLanguagePrompt,
} from '../hooks/use-language-setting';
import { styled } from '../theme';
import { UnstyledButton } from './base/button';
import { StyledIcon } from './base/styled-icon';
import { BodyUi } from './base/typography';
import {
  useAutoTranslateConversationsEnabled,
  useUserPreferredLocale,
} from './logged-in-user-context';
import { UserSettingsDialog } from './user-settings/user-settings-dialog';

type TranslateButtonProps = {
  Wrapper: React.ElementType;
};

const PromptButton = styled(UnstyledButton)`
  && {
    text-decoration: underline;
  }
`;
export const TranslateLanguagePrompt = ({ Wrapper }: TranslateButtonProps) => {
  const isTranslationsEnabled = useAutoTranslateConversationsEnabled();
  const userPreferredLocale = useUserPreferredLocale();
  const languageName = localesWithCurrency.find(
    ({ localeCode }) => localeCode === userPreferredLocale,
  )?.language.name;

  const isShowingTranslationPrompt = useShowTranslationLanguagePrompt();
  const { setShowTranslationLanguagePrompt } = useLanguageStoreActions();
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  const toggleSettingsDialog = () => setIsSettingsDialogOpen((prev) => !prev);

  const onChange = () => {
    toggleSettingsDialog();
  };
  const onDismiss = () => setShowTranslationLanguagePrompt(false);

  return (
    <>
      {isShowingTranslationPrompt && isTranslationsEnabled && languageName && (
        <Wrapper data-cy="translate-language-prompt">
          <StyledIcon iconName="Translate" $size="1.8rem" $colorScheme="ducksBlue" />
          <BodyUi $noWrap>Showing in: {languageName}.</BodyUi>
          <PromptButton onClick={onChange}>Change</PromptButton> |{' '}
          <PromptButton onClick={onDismiss}>Dismiss</PromptButton>
        </Wrapper>
      )}
      <UserSettingsDialog open={isSettingsDialogOpen} onClose={toggleSettingsDialog} />
    </>
  );
};
