import { gql } from '@apollo/client';

import conversationUpdatedFields from '../fragments/conversation-updated-fields';

const ON_MANY_CONVERSATIONS_UPDATED = gql`
  subscription onManyConversationsUpdated($client: ClientType!) {
    manyConversationsUpdated(client: $client) {
      updates {
        ...conversationUpdatedFields
      }
    }
  }
  ${conversationUpdatedFields}
`;

export default ON_MANY_CONVERSATIONS_UPDATED;
