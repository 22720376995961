import { createOneToOneMap } from './general';

type Country = {
  code: string;
  name: string;
};

const countries: Record<string, Country> = {
  AE: { code: 'AE', name: 'United Arab Emirates' },
  AF: { code: 'AF', name: 'Afghanistan' },
  AL: { code: 'AL', name: 'Albania' },
  AM: { code: 'AM', name: 'Armenia' },
  AU: { code: 'AU', name: 'Australia' },
  AZ: { code: 'AZ', name: 'Azerbaijan' },
  BA: { code: 'BA', name: 'Bosnia and Herzegovina' },
  BD: { code: 'BD', name: 'Bangladesh' },
  BG: { code: 'BG', name: 'Bulgaria' },
  BR: { code: 'BR', name: 'Brazil' },
  CA: { code: 'CA', name: 'Canada' },
  CH: { code: 'CH', name: 'Switzerland' },
  CN: { code: 'CN', name: 'China' },
  CZ: { code: 'CZ', name: 'Czech Republic' },
  DE: { code: 'DE', name: 'Germany' },
  DK: { code: 'DK', name: 'Denmark' },
  EE: { code: 'EE', name: 'Estonia' },
  ES: { code: 'ES', name: 'Spain' },
  ET: { code: 'ET', name: 'Ethiopia' },
  FI: { code: 'FI', name: 'Finland' },
  FR: { code: 'FR', name: 'France' },
  GB: { code: 'GB', name: 'United Kingdom' },
  GE: { code: 'GE', name: 'Georgia' },
  GR: { code: 'GR', name: 'Greece' },
  HR: { code: 'HR', name: 'Croatia' },
  HT: { code: 'HT', name: 'Haiti' },
  HU: { code: 'HU', name: 'Hungary' },
  ID: { code: 'ID', name: 'Indonesia' },
  IE: { code: 'IE', name: 'Ireland' },
  IL: { code: 'IL', name: 'Israel' },
  IN: { code: 'IN', name: 'India' },
  IR: { code: 'IR', name: 'Iran' },
  IS: { code: 'IS', name: 'Iceland' },
  IT: { code: 'IT', name: 'Italy' },
  JP: { code: 'JP', name: 'Japan' },
  KR: { code: 'KR', name: 'South Korea' },
  KZ: { code: 'KZ', name: 'Kazakhstan' },
  LK: { code: 'LK', name: 'Sri Lanka' },
  LT: { code: 'LT', name: 'Lithuania' },
  LV: { code: 'LV', name: 'Latvia' },
  MK: { code: 'MK', name: 'North Macedonia' },
  MN: { code: 'MN', name: 'Mongolia' },
  MT: { code: 'MT', name: 'Malta' },
  MX: { code: 'MX', name: 'Mexico' },
  MY: { code: 'MY', name: 'Malaysia' },
  NG: { code: 'NG', name: 'Nigeria' },
  NL: { code: 'NL', name: 'Netherlands' },
  NO: { code: 'NO', name: 'Norway' },
  NZ: { code: 'NZ', name: 'New Zealand' },
  PH: { code: 'PH', name: 'Philippines' },
  PK: { code: 'PK', name: 'Pakistan' },
  PL: { code: 'PL', name: 'Poland' },
  PT: { code: 'PT', name: 'Portugal' },
  RO: { code: 'RO', name: 'Romania' },
  RS: { code: 'RS', name: 'Serbia' },
  RU: { code: 'RU', name: 'Russia' },
  SA: { code: 'SA', name: 'Saudi Arabia' },
  SE: { code: 'SE', name: 'Sweden' },
  SG: { code: 'SG', name: 'Singapore' },
  SI: { code: 'SI', name: 'Slovenia' },
  SK: { code: 'SK', name: 'Slovakia' },
  SO: { code: 'SO', name: 'Somalia' },
  TH: { code: 'TH', name: 'Thailand' },
  TR: { code: 'TR', name: 'Turkey' },
  TW: { code: 'TW', name: 'Taiwan' },
  TZ: { code: 'TZ', name: 'Tanzania' },
  UA: { code: 'UA', name: 'Ukraine' },
  US: { code: 'US', name: 'United States' },
  UZ: { code: 'UZ', name: 'Uzbekistan' },
  VN: { code: 'VN', name: 'Vietnam' },
  ZA: { code: 'ZA', name: 'South Africa' },
} as const;

type Language = {
  code: string;
  name: string;
};

// languages supported by AWS Translate
const languages: Record<string, Language> = {
  AF: { code: 'af', name: 'Afrikaans' },
  AM: { code: 'am', name: 'Amharic' },
  AR: { code: 'ar', name: 'Arabic' },
  AZ: { code: 'az', name: 'Azerbaijani' },
  BG: { code: 'bg', name: 'Bulgarian' },
  BN: { code: 'bn', name: 'Bengali' },
  BS: { code: 'bs', name: 'Bosnian' },
  CA: { code: 'ca', name: 'Catalan' },
  CS: { code: 'cs', name: 'Czech' },
  CY: { code: 'cy', name: 'Welsh' },
  DA: { code: 'da', name: 'Danish' },
  DE: { code: 'de', name: 'German' },
  EL: { code: 'el', name: 'Greek' },
  EN: { code: 'en', name: 'English' },
  ES: { code: 'es', name: 'Spanish' },
  ET: { code: 'et', name: 'Estonian' },
  FA: { code: 'fa', name: 'Farsi' },
  FI: { code: 'fi', name: 'Finnish' },
  FAAF: { code: 'fa-AF', name: 'Farsi' },
  FR: { code: 'fr', name: 'French' },
  FRCA: { code: 'fr-CA', name: 'French' },
  GA: { code: 'ga', name: 'Irish' },
  GU: { code: 'gu', name: 'Gujarati' },
  HA: { code: 'ha', name: 'Hausa' },
  HE: { code: 'he', name: 'Hebrew' },
  HI: { code: 'hi', name: 'Hindi' },
  HR: { code: 'hr', name: 'Croatian' },
  HT: { code: 'ht', name: 'Haitian Creole' },
  HU: { code: 'hu', name: 'Hungarian' },
  HY: { code: 'hy', name: 'Armenian' },
  ID: { code: 'id', name: 'Indonesian' },
  IS: { code: 'is', name: 'Icelandic' },
  IT: { code: 'it', name: 'Italian' },
  JA: { code: 'ja', name: 'Japanese' },
  KA: { code: 'ka', name: 'Georgian' },
  KK: { code: 'kk', name: 'Kazakh' },
  KN: { code: 'kn', name: 'Kannada' },
  KO: { code: 'ko', name: 'Korean' },
  LT: { code: 'lt', name: 'Lithuanian' },
  LV: { code: 'lv', name: 'Latvian' },
  MK: { code: 'mk', name: 'Macedonian' },
  ML: { code: 'ml', name: 'Malayalam' },
  MN: { code: 'mn', name: 'Mongolian' },
  MR: { code: 'mr', name: 'Marathi' },
  MS: { code: 'ms', name: 'Malay' },
  MT: { code: 'mt', name: 'Maltese' },
  ESMX: { code: 'es-MX', name: 'Spanish' },
  NL: { code: 'nl', name: 'Dutch' },
  NO: { code: 'no', name: 'Norwegian' },
  PA: { code: 'pa', name: 'Punjabi' },
  PL: { code: 'pl', name: 'Polish' },
  PS: { code: 'ps', name: 'Pashto' },
  PTPT: { code: 'pt-PT', name: 'Portuguese' },
  PT: { code: 'pt', name: 'Portuguese' },
  RO: { code: 'ro', name: 'Romanian' },
  RU: { code: 'ru', name: 'Russian' },
  SI: { code: 'si', name: 'Sinhala' },
  SK: { code: 'sk', name: 'Slovak' },
  SL: { code: 'sl', name: 'Slovenian' },
  SO: { code: 'so', name: 'Somali' },
  SQ: { code: 'sq', name: 'Albanian' },
  SR: { code: 'sr', name: 'Serbian' },
  SV: { code: 'sv', name: 'Swedish' },
  SW: { code: 'sw', name: 'Swahili' },
  TA: { code: 'ta', name: 'Tamil' },
  TE: { code: 'te', name: 'Telugu' },
  TH: { code: 'th', name: 'Thai' },
  TL: { code: 'tl', name: 'Tagalog' },
  TR: { code: 'tr', name: 'Turkish' },
  UK: { code: 'uk', name: 'Ukrainian' },
  UR: { code: 'ur', name: 'Urdu' },
  UZ: { code: 'uz', name: 'Uzbek' },
  VI: { code: 'vi', name: 'Vietnamese' },
  ZH: { code: 'zh', name: 'Chinese - Simplified' },
  ZHTW: { code: 'zh-TW', name: 'Chinese - Traditional' },
} as const;

type LocaleWithCurrency = {
  localeCode: string;
  currency: string;
  country: Country;
  language: Language;
};

export const localesWithCurrency: LocaleWithCurrency[] = [
  { localeCode: 'bg-BG', currency: 'BGN', country: countries.BG, language: languages.BG },
  { localeCode: 'cs-CZ', currency: 'CZK', country: countries.CZ, language: languages.CS },
  { localeCode: 'da-DK', currency: 'DKK', country: countries.DK, language: languages.DA },
  { localeCode: 'de-DE', currency: 'EUR', country: countries.DE, language: languages.DE },
  { localeCode: 'el-GR', currency: 'EUR', country: countries.GR, language: languages.EL },
  { localeCode: 'en-GB', currency: 'GBP', country: countries.GB, language: languages.EN },
  { localeCode: 'en-NZ', currency: 'NZD', country: countries.NZ, language: languages.EN },
  { localeCode: 'en-US', currency: 'USD', country: countries.US, language: languages.EN },
  { localeCode: 'es-ES', currency: 'EUR', country: countries.ES, language: languages.ES },
  { localeCode: 'es-MX', currency: 'MXN', country: countries.MX, language: languages.ESMX },
  { localeCode: 'et-EE', currency: 'EUR', country: countries.EE, language: languages.ET },
  { localeCode: 'fr-CA', currency: 'CAD', country: countries.CA, language: languages.FRCA },
  { localeCode: 'fr-FR', currency: 'EUR', country: countries.FR, language: languages.FR },
  { localeCode: 'hu-HU', currency: 'HUF', country: countries.HU, language: languages.HU },
  { localeCode: 'hy-AM', currency: 'AMD', country: countries.AM, language: languages.HY },
  { localeCode: 'it-IT', currency: 'EUR', country: countries.IT, language: languages.IT },
  { localeCode: 'lt-LT', currency: 'EUR', country: countries.LT, language: languages.LT },
  { localeCode: 'lv-LV', currency: 'EUR', country: countries.LV, language: languages.LV },
  { localeCode: 'nl-NL', currency: 'EUR', country: countries.NL, language: languages.NL },
  { localeCode: 'no-NO', currency: 'NOK', country: countries.NO, language: languages.NO },
  { localeCode: 'pl-PL', currency: 'PLN', country: countries.PL, language: languages.PL },
  { localeCode: 'pt-BR', currency: 'BRL', country: countries.BR, language: languages.PT },
  { localeCode: 'pt-PT', currency: 'EUR', country: countries.PT, language: languages.PTPT },
  { localeCode: 'ro-RO', currency: 'RON', country: countries.RO, language: languages.RO },
  { localeCode: 'sq-AL', currency: 'ALL', country: countries.AL, language: languages.SQ },
  { localeCode: 'sv-SE', currency: 'SEK', country: countries.SE, language: languages.SV },
  { localeCode: 'tr-TR', currency: 'TRY', country: countries.TR, language: languages.TR },
] as const;

const localeCodeToAWSLanguageCode = createOneToOneMap(
  localesWithCurrency,
  (locales) => locales.localeCode.toLowerCase(),
  (locales) => locales.language.code,
);

const joyousSupportedAWSLanguageCodes = new Set(
  localesWithCurrency.map((locale) => locale.language.code.toLowerCase()),
);
export const joyousSupportedLocaleCodes = new Set(
  localesWithCurrency
    .map((locale) => locale.localeCode.toLowerCase())
    .filter((locale) => !locale.startsWith('en') || locale === 'en-us'), // we want any en codes to map to en-US
);

export const getLanguageMajorCode = (locale: string) => locale.split('-')[0];

/**
 * Retrieves the AWS language code associated with a given locale code.
 * We use this because AWS Translate officially specifies specific language codes, and we need to map our locale codes to those.
 * If we provide a potentially unsupported language code to AWS Translate, we may get unexpected results.
 */
export const getAWSLanguageCodeFromLocaleCode = (localeCode: string): string | null => {
  const directMatch = localeCodeToAWSLanguageCode[localeCode.toLowerCase()];
  if (directMatch) {
    return directMatch;
  }

  const languageMajorCode = getLanguageMajorCode(localeCode);
  const isMatchOnMajorCode = joyousSupportedAWSLanguageCodes.has(languageMajorCode.toLowerCase());
  if (isMatchOnMajorCode) {
    return languageMajorCode;
  }

  return null;
};

/**
 * Converts a locale/language code sent from the browser to one of our supported ones.
 * Users can set their browser language to one of many possible values, which all have different locale codes.
 * If we persist a locale code that we do not support, we may run into issues when trying to translate text.
 */
export const getLocaleFromBrowserLocale = (browserLocale: string): string => {
  if (joyousSupportedLocaleCodes.has(browserLocale.toLowerCase())) {
    return browserLocale;
  }

  const languageMajorCode = getLanguageMajorCode(browserLocale.toLowerCase());
  const matchingLocale = localesWithCurrency
    // ensure en-GB and en-NZ map to en-US
    .filter((locale) => !locale.language.code.startsWith('en') || locale.language.code === 'en-US')
    .find((locale) => locale.language.code.toLowerCase() === languageMajorCode);

  if (matchingLocale) {
    return matchingLocale.localeCode;
  }

  return 'en-US';
};
