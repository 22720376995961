import { createRoute, Navigate } from '@tanstack/react-router';
import React from 'react';

import { App } from '../app';
import { Error, ErrorType } from '../error';
import { Authentication } from '../features/authentication/authentication';
import { PageWithConversations } from '../features/page-layout/page-with-conversations';
import { rootRoute } from './root-route';

const AppLayout = createRoute({
  getParentRoute: () => rootRoute,
  id: 'AppLayout',
  component: App,
});

const baseRoute = createRoute({
  getParentRoute: () => AppLayout,
  path: '/',
  component: () => (
    <Navigate
      to="/conversations/$selectedConversationId"
      params={{ selectedConversationId: 'latest' }}
    />
  ),
});

const authenticationRoute = createRoute({
  getParentRoute: () => AppLayout,
  path: '/$personTokenId',
  component: Authentication,
});

const conversationsRoute = createRoute({
  getParentRoute: () => AppLayout,
  path: 'conversations',
  component: PageWithConversations,
});

const conversationWithPathRoute = createRoute({
  getParentRoute: () => AppLayout,
  path: 'conversations/$selectedConversationId',
  component: PageWithConversations,
});

const statusPageLayout = createRoute({
  getParentRoute: () => AppLayout,
  id: 'StatusPageLayout',
});

const errorRoute = createRoute({
  getParentRoute: () => statusPageLayout,
  path: 'error',
  component: Error,
});

const authenticationErrorRoute = createRoute({
  getParentRoute: () => statusPageLayout,
  path: 'error/authentication',
  component: () => <Error errorType={ErrorType.Authentication} />,
});

const deviceErrorRoute = createRoute({
  getParentRoute: () => statusPageLayout,
  path: 'error/device',
  component: () => <Error errorType={ErrorType.Device} />,
});

const tooManyRequestsRoute = createRoute({
  getParentRoute: () => statusPageLayout,
  path: 'error/toomanyrequests',
  component: () => <Error errorType={ErrorType.TooManyRequests} />,
});

const maintenanceRoute = createRoute({
  getParentRoute: () => statusPageLayout,
  path: 'error/maintenance',
  component: () => <Error errorType={ErrorType.Maintenance} />,
});

const statusPageRouteTree = statusPageLayout.addChildren([
  errorRoute,
  tooManyRequestsRoute,
  maintenanceRoute,
  authenticationErrorRoute,
]);

export const AppLayoutTree = AppLayout.addChildren([
  baseRoute,
  authenticationRoute,
  deviceErrorRoute,
  conversationsRoute,
  conversationWithPathRoute,
  statusPageRouteTree,
]);
