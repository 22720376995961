/*
 * Parses and displays question text with potential markdown urls as clickable links
 */
import { styled } from '@shared/frontend/theme';
import React, { Fragment } from 'react';

import ClickableURL from './clickable-url';

// eg: Do you like ([chocolate](google.com))?
// becomes: Do you like (<a href="google.com">chocolate</a>)
const LINK_MARKDOWN_REGEX = /\[([^[\]()]+)\]\(([^[\]()]+)\)/g;

type RegexMatch = {
  rawMarkDownText: string;
  text: string;
  link: string;
};

type TextToken = {
  text: string;
  link?: string;
};

export const UnderlinedText = styled('span')`
  text-decoration: underline;
`;

// a match should look like ["[chocolate](google.com)", "chocolate", "google.com"]
export const parseRawTextForLinkMatches = (rawText: string): RegexMatch[] =>
  Array.from(rawText.matchAll(LINK_MARKDOWN_REGEX))
    .map((match: RegExpMatchArray) => {
      if (!match || match.length !== 3) {
        return null;
      }
      return {
        rawMarkDownText: match[0],
        text: match[1],
        link: match[2],
      };
    })
    .filter(Boolean) as RegexMatch[];

export const tokenizeTextWithMatches = (rawText: string, matches: RegexMatch[]): TextToken[] => {
  if (!matches || !matches.length) {
    return [{ text: rawText }];
  }

  return matches.reduce(
    ({ memo, string }: { memo: TextToken[]; string: string }, match, matchIndex) => {
      const { rawMarkDownText, text, link } = match;

      const indexOfReplacement = string.indexOf(rawMarkDownText);
      const beforeMatch = string.substring(0, indexOfReplacement);
      const afterMatch = string.substring(indexOfReplacement + rawMarkDownText.length);

      if (beforeMatch) {
        memo.push({ text: beforeMatch });
      }

      memo.push({
        text,
        link,
      });

      if (matchIndex === matches.length - 1 && afterMatch) {
        memo.push({ text: afterMatch });
      }
      return { memo, string: afterMatch };
    },
    { memo: [], string: rawText },
  ).memo;
};

export const buildQuestionTextWithLinks = (textTokens: TextToken[], renderLinksAsText?: boolean) =>
  textTokens.map(({ text, link }) => {
    if (!link) {
      return <Fragment key={`${text}`}>{text}</Fragment>;
    }
    return renderLinksAsText ? (
      <UnderlinedText key={text}>{text}</UnderlinedText>
    ) : (
      <ClickableURL url={link} raw={text} key={text} />
    );
  });

export const QuestionText = ({
  rawText,
  renderLinksAsText,
}: {
  rawText: string;
  renderLinksAsText?: boolean;
}) => {
  if (!rawText) {
    return null;
  }

  const linkMatches = parseRawTextForLinkMatches(rawText);
  const textTokens = tokenizeTextWithMatches(rawText, linkMatches);

  return <>{buildQuestionTextWithLinks(textTokens, renderLinksAsText)}</>;
};
